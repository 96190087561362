import './index.less';

import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import vsf from '@vs/vsf-boot';
import vsfBoot, { definePage } from '@vs/vsf-boot';
import { Avatar, Dropdown, Image, Input, Popover } from '@vs/vsf-kit';
import { useSize } from 'ahooks';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import DoctorAvatar from '@/assets/doctor.jpeg';
import Logo from '@/assets/logo.png';
import { getPrefixCls } from '@/config';
import Icon from '@/module/Icon';
import { items as Items } from '@/pages/Index/index';
import routes from '@/router';

import Application from './application';
import Settings from './settings';

const middle_size = 1600;
const small_size = 1200;

const Index = (props) => {
  const { common, user, parameter } = props?.stores || {};
  const {
    getApplicationList,
    applicationList,
    setCurrentApplication,
    currentApplication,
    getFunctionWithApplication,
    getSystemParameter,
    getLocalParameter,
    systemParameter,
    localParameter,
    leftFunctionList,
  } = common || {};

  const { staffInfo, hospitalType, getStaffInfo, logout, clearData } =
    user || {};

  const items: any[] = [
    {
      key: '4',
      // danger: true,
      label: '退出登录',
      onClick: logout,
    },
  ];

  const header = getPrefixCls('header-main');
  const [mediaSize, setMediaSize] = useState<string>();
  const [applicationValue, setApplicationValue] = useState<any>();
  const [collaspedApplicationOpen, setCollaspedApplicationOpen] =
    useState<boolean>(false);

  const size = useSize(document.body);

  const handleSetMediaSize = useCallback(() => {
    if (size) {
      if (size?.width >= small_size && size?.width < middle_size) {
        setMediaSize('middle');
      } else if (size?.width < small_size) {
        setMediaSize('small');
      } else {
        setMediaSize('normal');
      }
    }
  }, [size]);

  useEffect(() => {
    if (currentApplication) {
      document.title = currentApplication?.name ?? '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApplication]);

  const collaspedApplicationList = useCallback(() => {
    return (
      <div className="collasped-application-list">
        {(applicationList ?? [])?.map((item) => (
          <div
            className="item"
            key={item.id}
            onClick={() => {
              setApplicationValue(item?.id);
              setCurrentApplication(item);
              setCollaspedApplicationOpen(false);
            }}
          >
            {item?.name}
          </div>
        ))}
      </div>
    );
  }, [applicationList, setCurrentApplication]);

  const collaspedContent = useCallback(() => {
    return (
      <div className="collasped-content">
        <Popover
          placement="leftTop"
          content={collaspedApplicationList()}
          trigger="click"
          overlayClassName="collasped-application"
          open={collaspedApplicationOpen}
          onOpenChange={(v) => {
            setCollaspedApplicationOpen(v);
          }}
        >
          <div className="item">
            <span>{currentApplication?.name ?? '切换工作站'}</span>
            <Icon type="icon-xia" size={26} />
          </div>
        </Popover>
        <div className="item">
          <span>消息通知</span>
        </div>
        <div className="item">
          <span>帮助</span>
        </div>
      </div>
    );
  }, [collaspedApplicationList, collaspedApplicationOpen, currentApplication]);

  const renderTools = useCallback(() => {
    if (mediaSize) {
      if (mediaSize === 'normal') {
        return (
          <>
            <div className="application">
              <Application
                dataSource={applicationList}
                value={applicationValue}
                onChange={(v) => {
                  setApplicationValue(v?.id);
                  setCurrentApplication(v);
                }}
              />
            </div>

            <div className="operation-icon">
              <div className="header-icon">
                <Icon type="icon-a-Remindtixing" size={26} />
              </div>
              <div className="header-icon">
                <Settings />
              </div>
            </div>
          </>
        );
      } else if (mediaSize === 'middle' || mediaSize === 'small') {
        return (
          <div className="operation-icon">
            <Popover
              placement="bottomRight"
              content={collaspedContent()}
              trigger="click"
            >
              <Icon type="icon-youshangjiaogengduo" size={26} />
            </Popover>
          </div>
        );
      }
    }
  }, [
    applicationList,
    applicationValue,
    collaspedContent,
    mediaSize,
    setCurrentApplication,
  ]);

  useEffect(() => {
    handleSetMediaSize();
  }, [handleSetMediaSize]);

  useEffect(() => {
    getApplicationList(staffInfo?.loginName);
  }, [getApplicationList, staffInfo?.loginName]);

  // useEffect(() => {
  //   getSystemParameter();
  // }, [getSystemParameter]);

  // useEffect(() => {
  //   getLocalParameter();
  // }, [getLocalParameter]);

  const handleInitApplication = useCallback(() => {
    setApplicationValue(currentApplication?.id);
    getLocalParameter?.();
    getSystemParameter?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentApplication]);

  useEffect(() => {
    handleInitApplication();
  }, [handleInitApplication]);

  useEffect(() => {
    if (applicationValue) {
      const target = applicationList?.find(
        (item) => item?.id === applicationValue,
      );
      if (target) {
        getFunctionWithApplication(target?.templateApplicationId);
      }
    }
  }, [applicationValue, getFunctionWithApplication, applicationList]);

  useEffect(() => {
    getStaffInfo?.();
  }, [getStaffInfo]);

  const background = useMemo(() => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    const rgb = 'rgb(' + r + ',' + g + ',' + b + ')';
    return rgb;
  }, []);
  const name = useMemo(() => {
    const title = Items?.flatMap((item) => [
      item,
      ...item?.children?.flatMap((item) => item?.children),
    ])
      ?.flat(Infinity)
      ?.find(
        (item) => (item as any)?.path === props?.history?.location?.pathname,
      );
    return (title as any)?.label as string;
  }, [props?.history?.location?.pathname]);

  return (
    <div className={header}>
      <div className="left">
        <Image className="logo" src={Logo} width={50} preview={false} />
        <div className="title">浙江大学医学院附属医院</div>
        {mediaSize === 'small' ? null : (
          <div className="detail">
            ({hospitalType === 'out' ? '门诊-' : '住院-'}(
            {currentApplication?.department?.branchInstitution
              ?.institutionName ?? ''}
            ))
          </div>
        )}
      </div>
      <div className="right">
        <div className="total-search">
          <Input width={280} addonBefore={<Icon type="icon-sousuo" />} />
        </div>
        <div className="department">
          科室: {currentApplication?.department?.departmentName ?? ''}
        </div>
        <div className="name">
          <Dropdown menu={{ items }} overlayClassName="aspirin-menu-box">
            <div
              style={{
                display: 'flex',
                gap: 5,
                alignItems: 'center',
              }}
            >
              <Avatar
                src={staffInfo?.staffInfoNoteDto?.staffPhoto ?? ''}
                style={{ background }}
                className="avatar"
              >
                {staffInfo?.staffName?.slice(
                  staffInfo?.staffName?.length - 2,
                ) ?? '管理员'}
              </Avatar>
              <span>{staffInfo?.staffName ?? ''}</span>
            </div>
          </Dropdown>
        </div>
        {renderTools()}
      </div>
    </div>
  );
};

export default definePage(Index);
