import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Divider,
  Row,
  Table,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useGetState, useRequest } from 'ahooks';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef } from 'react';

import { isMinScreen } from '@/config';
import Container from '@/module/Container';
import Loading from '@/module/Loading';
import {
  ButtonAndKeyDown,
  ComplexSelect,
  Flex,
} from '@/pages/Index/components';
import { useFetch } from '@/pages/Index/components/func/hooks';
import { getScrollY, labelColWidth } from '@/utils';

const onSpliced = (input) => {
  const _ = window._ ?? {};
  const output: any = [];
  output.push(
    ...(_.get(input, 'data')?.map((d, i) => {
      const obj: any = {};
      obj.id = _.get(d, 'id');
      obj.drugPrescriptionDispenseDetailId = _.get(
        d,
        'drugPrescriptionDispenseDetailId',
      );
      obj.orderPerformPlanRecordId = _.get(d, 'orderPerformPlanRecordId');
      obj.displayId = _.get(d, 'patient.displayId');
      obj.bedLabel = _.get(d, 'inpVisit.patientInHospital.bed.bedLabel');
      obj.name = _.get(d, 'patient.name');
      obj.gender = _.get(d, 'patient.gender');
      obj.repeatIndicator = _.get(d, 'order.repeatIndicator');
      obj.orderText = _.get(d, 'order.orderText');
      obj.amountAndUnit = _.get(d, 'amountAndUnit');
      obj.refundAmount = _.get(d, 'refundAmount');
      obj.planDateTime = _.get(d, 'planDateTime');
      obj.performStatus = _.get(d, 'performStatus');
      obj.administration = _.get(d, 'orderDrug.administration');
      obj.frequency = _.get(d, 'orderDrug.frequency.frequency');
      obj.packageUnit = _.get(d, 'orderDrug.packageUnit');
      obj.packageSpecification = _.get(
        d,
        'orderDrug.frequency.packageSpecification',
      );
      obj.departmentName = _.get(d, 'performDepartment.departmentName');
      return obj;
    }) ?? []),
  );
  return output;
};

const DrugSelect = (props) => {
  const { value, onChange } = props;

  const columns = [
    {
      dataIndex: ['drugProductInfo', 'drugProductName'],
      title: '药品商品名称',
    },
    {
      dataIndex: ['packageSpecification'],
      title: '规格',
    },
    {
      dataIndex: ['drugProductInfo', 'drugFirmInfo', 'firmName'],
      title: '厂家',
    },
  ];
  const {
    loading,
    data: dataSource,
    run: get,
  } = useRequest(
    async (inputCodeLike) => {
      const res =
        await vsf?.services?.DrugDrugController_queryProductWithCurrentPrice_4621c9?.(
          {
            qto: {
              inputCodeLike,
            },
          },
        );
      if (res?.code === 200) {
        return (res?.data?.result ?? [])?.map((item) => ({
          ...item,
          drugProductName: item?.drugProductInfo?.drugProductName,
          key: item?.id,
        }));
      }
    },
    {
      manual: true,
    },
  );

  const onSearch = (value) => {
    if (value?.trim()) {
      get?.(value);
    }
  };

  return (
    <ComplexSelect
      mode="radio"
      onSearch={onSearch}
      dropdownMatchSelectWidth={700}
      hideSelectIcon
      showLabel="drugProductName"
      value={value?.value ? value : ''}
      placeholder="请选择药品"
      allowClear
      onChange={(value) => {
        onChange?.({
          label: value?.drugProductName,
          value: value?.key,
        });
      }}
    >
      <Table
        columns={columns}
        dataSource={dataSource}
        showHeader={false}
        size="middle"
        loading={{
          indicator: (
            <Flex justify="center" align="center">
              <Loading />
            </Flex>
          ),
          spinning: loading,
        }}
        scroll={{
          y: 400,
        }}
      />
    </ComplexSelect>
  );
};

/**
 * 病区西药退药申请
 * @returns {JSX.Element}
 */
const ApplicationForWesternMedicineRefundInWard = (props) => {
  const { inpVisitId } = props?.value || {},
    planDateTime = [
      dayjs()?.subtract(31, 'day')?.format('YYYY-MM-DD HH:mm:ss'),
      dayjs()?.format('YYYY-MM-DD HH:mm:ss'),
    ],
    search = useRef<any>({
      planDateTimeBiggerThanEqual: dayjs()
        ?.subtract(31, 'day')
        ?.format('YYYY-MM-DD HH:mm:ss'),
      planDateTimeLessThan: dayjs()?.format('YYYY-MM-DD HH:mm:ss'),
    }),
    table = useRef<any>(null),
    { data: InpStorageData, run: InpStorageRun } = useRequest(
      async () => {
        const res =
          await vsf?.services?.ClinicRecordOrderDrugController_getInpStorageIds_10643e?.(
            {
              temp: false,
            },
          );

        return res?.data ?? [];
      },
      { manual: true },
    );
  useEffect(() => InpStorageRun?.(), [InpStorageRun]);
  const onFetch = useCallback(
    async (params) => {
      if (!inpVisitId) {
        return {
          data: [],
          total: 0,
        };
      }
      const res =
        await vsf?.services?.InpRefundDispenseController_getPlanAndDispenseForInpRefundDrugApply_f6c86d?.(
          {
            qto: {
              from: params?.pagination?.from ?? 0,
              size: params?.pagination?.size ?? 20,
              ...params?.search,
              inpVisitIdIs: inpVisitId,
              ...search?.current,
            },
          },
        );
      return {
        data: onSpliced?.(res),
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [inpVisitId],
  );

  const onSave = useFetch({
    fetch: async () => {
      // onClick={(event) => {
      const input =
        vsf?.refs?.tableInpOrderApply
          ?.getValues?.()
          ?.filter((m) => m?.refundAmount > 0)
          .map((m) => ({
            planId: m?.orderPerformPlanRecordId,
            refundAmount: m?.refundAmount,
            drugPrescriptionDispenseDetailId:
              m?.drugPrescriptionDispenseDetailId,
          })) ?? [];
      console.log(input);
      if (input?.length <= 0) return;
      const res: any =
        vsf?.services?.InpRefundDispenseController_saveInpDrugRefundApply_bf29f0?.(
          { applies: input },
        );
      return res;
      // }}
      // const res =
      //   vsf?.services?.InpRefundDispenseController_saveInpDrugRefundApply_bf29f0?.(
      //     {
      //       applies: [],
      //     },
      //   );
      // return res;
    },
    message: '保存',
  });

  return (
    <Flex
      vertical
      style={{
        height: '100%',
      }}
    >
      <Flex vertical>
        <div>
          <VSForm
            id="VSForm"
            labelAlign="left"
            onChange={(value) => {
              search.current = value;
            }}
          >
            <VSFormLayout columnCount={4} labelCol={labelColWidth[7]}>
              <VSFormItem
                label="计划执行日期开始"
                valueType="limitTime"
                name={['planDateTimeBiggerThanEqual']}
                initialValue={planDateTime}
                fieldProps={{
                  day: 7,
                }}
                transform={(value) => {
                  const [planDateTimeBiggerThanEqual, planDateTimeLessThan] = (
                    value ?? []
                  )?.map((item) =>
                    dayjs?.(item)?.format('YYYY-MM-DD HH:mm:ss'),
                  );
                  return {
                    planDateTimeBiggerThanEqual,
                    planDateTimeLessThan,
                  };
                }}
              />
              <VSFormItem
                label="药品名称"
                valueType="custom"
                name={['drugPriceItemIdIs']}
                transform={(value) => {
                  return {
                    drugPriceItemIdIs: value?.value,
                  };
                }}
              >
                <DrugSelect />
              </VSFormItem>
              <VSFormItem
                label="执行状态"
                valueType="select"
                name={['performStatusIn']}
                fieldProps={{
                  mode: 'multiple',
                }}
                dataSource={
                  vsf?.stores?.dbenums?.enums?.ORDER_PERFORM_STATUS_ENUM ?? [
                    { label: '未执行', value: 'NOT_EXECUTED' },
                    { label: '已执行', value: 'EXECUTED' },
                    { label: '执行中', value: 'PROCESSING' },
                    { label: '执行暂停', value: 'SUSPEND' },
                    { label: '执行作废', value: 'DEPRECATE' },
                  ]
                }
              />
              <VSFormItem
                label="发药库房"
                valueType="select"
                name={['performDepartmentIdIn']}
                fieldProps={{
                  mode: 'multiple',
                }}
                dataSource={InpStorageData}
              />
            </VSFormLayout>
          </VSForm>
        </div>
        <Flex gap={10} justify="flex-end">
          <Button type="primary" onClick={() => table?.current?.reload()}>
            查询
          </Button>
          <Button type="primary" ghost>
            重置
          </Button>
          <Button
            type="primary"
            onClick={() => {
              search.current = {
                ...search.current,
                isGetStopPlanIs: true,
              };
              table?.current?.reload?.();
            }}
          >
            一键提取停医嘱未退药
          </Button>
          <Button
            type="primary"
            onClick={() => {
              search.current = {
                ...search.current,
                isGetRefundApplyIs: true,
              };
              table?.current?.reload?.();
            }}
          >
            提取已申请记录
          </Button>
        </Flex>
      </Flex>
      <Divider
        style={{
          background: 'var(--blue)',
        }}
      />
      <Flex flex={1} vertical justify="space-between">
        <VSPromiseTable
          id="tableInpOrderApply"
          scroll={{
            y: isMinScreen ? 400 : 550,
          }}
          onFetch={onFetch}
          editable={{
            editType: 'multiple',
            onCanDelete: (data) => {
              return false;
            },
            columnProps: { hideInTable: true },
          }}
          ref={table}
          className="aspirin-table-no-background-box"
        >
          <VSTableColumn
            width={218}
            dataIndex={['orderText']}
            title="医嘱内容"
            editable={false}
            valueType="text"
            fieldProps={{}}
          />
          <VSTableColumn
            width={68}
            dataIndex={['repeatIndicator']}
            title="长临"
            editable={false}
            valueType="text"
            fieldProps={{}}
            render={(_, { repeatIndicator }) =>
              repeatIndicator ? '长期' : '临时'
            }
          />
          <VSTableColumn
            width={78}
            dataIndex={['packageSpecification']}
            title="包装规格"
            editable={false}
            valueType="text"
            fieldProps={{}}
          />
          <VSTableColumn
            width={78}
            dataIndex={['packageUnit']}
            title="包装单位"
            editable={false}
            valueType="text"
            fieldProps={{}}
          />
          <VSTableColumn
            width={158}
            dataIndex={['planDateTime']}
            title="执行计划时间"
            editable={false}
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          />

          <VSTableColumn
            width={78}
            dataIndex={['performStatus']}
            title="执行状态"
            editable={false}
            valueType="select"
            dataSource={[
              { label: '未执行', value: 'NOT_EXECUTED' },
              { label: '已执行', value: 'EXECUTED' },
              { label: '执行中', value: 'PROCESSING' },
              { label: '执行暂停', value: 'SUSPEND' },
              { label: '执行作废', value: 'DEPRECATE' },
            ]}
            fieldProps={{}}
          />
          <VSTableColumn
            width={108}
            dataIndex={['departmentName']}
            title="发药药房"
            editable={false}
            valueType="text"
            fieldProps={{}}
          />
          <VSTableColumn
            width={108}
            dataIndex={['amountAndUnit']}
            title="可退药数量"
            editable={false}
            valueType="text"
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['refundAmount']}
            title="退药数量"
            valueType="digit"
            fieldProps={{}}
          />
          {/* <VSTableColumn
            dataIndex={['administration']}
            title="停止时间"
            editable={false}
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.ADMINISTRATION_DICT}
          /> */}
        </VSPromiseTable>
        <Divider
          style={{
            background: 'var(--blue)',
          }}
        />
        <Flex gap={10} justify="flex-end">
          <ButtonAndKeyDown
            methodKey="altKey"
            ordinaryKey="R"
            type="primary"
            ghost
            onClick={() => {}}
          >
            清屏
          </ButtonAndKeyDown>
          <ButtonAndKeyDown
            methodKey="altKey"
            ordinaryKey="P"
            type="primary"
            ghost
            onClick={() => {}}
          >
            打印
          </ButtonAndKeyDown>
          <ButtonAndKeyDown
            methodKey="altKey"
            ordinaryKey="S"
            type="primary"
            onClick={() => {
              onSave?.();
            }}
          >
            保存
          </ButtonAndKeyDown>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default definePage(ApplicationForWesternMedicineRefundInWard);
