import './index.less';
import './convert';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Col,
  message,
  Modal,
  Row,
  Section,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { useGetState, useLockFn, useRequest, useUpdateEffect } from 'ahooks';
import { Button } from 'antd';
import { Empty, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { isMinScreen } from '@/config';
import Icon from '@/module/cpoe/Icon';
import Loading from '@/module/Loading';
import ComplexSelect from '@/pages/Index/components/complex_select';
import { idCard, idCardMessage, passport, passportMessages } from '@/pattern';
import { positive, positiveMessage } from '@/pattern';
import { getScrollY } from '@/utils';
import { calculateAge, labelColWidth } from '@/utils/index';
import { add } from '@/utils/setDataUtil';
import storage from '@/utils/storage';

import {
  convertAddChargeSolutionPatientForm2CreateChargeSolutionPatientReq,
  convertEditChargeSolutionPatientForm2UpdateChargeSolutionPatientReq,
  convertQueryChargeSolutionPatientPageRes2ChargeSolutionPatientListRo,
} from './convert';

const Index = (props) => {
  const {
    stores: {
      user: { staffInfo },
    },
  } = props;
  // 收费方案项目停用弹窗
  const [open, setOpen] = useState(false);
  // 新增弹窗
  const [openAdd, setOpenAdd] = useState(false);
  // 左侧table选中行
  const [selectedChargeSolutionRow, setSelectedChargeSolutionRow] =
    useState<any>();
  // 右侧table选中行
  const [selectedRow, setSelectedRow] = useState<any>();
  // 左侧table
  const onFetch_t6990 = useCallback(async (params) => {
    setSelectedChargeSolutionRow(null);
    const res =
      await vsf?.services?.ChargeSolutionDtoController_getChargeSolutionList_114af0?.(
        {
          qto: {
            from: params?.pagination?.from ?? 0,
            size: params?.pagination?.size ?? 20,
            ...params?.search,
            ...params?.extra,
            ...(params ?? {}),
            judgeWayIs: 'PATIENT_LIST',
          },
        },
      );
    return res?.data;
  }, []);
  // 右侧table
  const onFetch_t3047 = useCallback(async (params) => {
    setSelectedRow(null);
    if (!params?.extra?.selectedChargeSolutionRow?.id) return [];
    const res =
      await vsf?.services?.ChargeSolutionPatientListDtoController_queryChargeSolutionPatientPage_d68140?.(
        {
          qto: {
            from: params?.pagination?.from ?? 0,
            size: params?.pagination?.size ?? 20,
            ...params?.search,
            ...(params ?? {}),
            chargeSolutionIdIs: params?.extra?.selectedChargeSolutionRow?.id,
            identificationNumberIs:
              params?.search?.identificationNumberIs?.length <= 0
                ? null
                : params?.search?.identificationNumberIs,
          },
        },
      ); // TODO: 请确认入参来源及出参用法

    const output =
      convertQueryChargeSolutionPatientPageRes2ChargeSolutionPatientListRo?.(
        res,
      );
    return {
      data: output ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);
  // 编辑/新增
  const [operationType, setOperationType] = useState('add');
  // 姓名是否可以编辑
  const [canEditName, setCanEditName] = useState(true);
  // 新增/编辑
  const handelAdd = async (v = false, type = 'add') => {
    setOpenAdd(v);
    if (type == 'edit') {
      // identificationNumber
      setTimeout(async () => {
        const res =
          await vsf?.services?.PatientMainController_getPatientByIdNumber_7b5fb1?.(
            { idNumber: selectedRow?.identificationNumber },
          );
        if (res?.code == 200 && res?.data?.length > 0) {
          setCanEditName(false);
        } else {
          setCanEditName(true);
        }
        vsf.refs?.addChargeSolutionListForm?.setFieldsValue({
          ...selectedRow,
          stopIndicator: [selectedRow?.stopIndicator],
        });
      }, 100);
    }
    if (type == 'add') {
      // 新增默认可以编辑姓名
      setCanEditName(true);
    }
  };

  return (
    <div className="special_charge_list_scheme">
      <div className="special_charge_list_scheme_left">
        <VSPromiseTable
          pagination={false}
          id="chargeSolutionPatientListSolutionList"
          className="
          aspirin-table-header-background-color
         aspirin-table-row-hover-background-color
         aspirin-table-nopadding
         aspirin-table-body-empty-transparent-background-color
         "
          rowClassName={(record, rowIndex) => {
            return record?.id == selectedChargeSolutionRow?.id
              ? 'aspirin-table-row-select-background-color'
              : '';
          }}
          rowClick={{
            rowClickType: 'select',
            onRowClick: (_value) => {
              if (_value?.id == selectedChargeSolutionRow?.id) {
                setSelectedChargeSolutionRow(null);
              } else {
                setSelectedChargeSolutionRow({ ..._value });
              }
            },
          }}
          onFetch={onFetch_t6990}
          scroll={{
            y: isMinScreen ? getScrollY(200) : getScrollY(380),
          }}
        >
          <VSTableColumn
            dataIndex={['chargeSolutionCode']}
            title="方案ID"
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['chargeSolutionName']}
            title="方案名称"
            valueType="text"
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['solutionMemo']}
            title="方案说明"
            valueType="text"
            fieldProps={{}}
          />
        </VSPromiseTable>
      </div>
      <div className="special_charge_list_scheme_right widthAutoFill">
        <div style={{ flex: 1 }} className="widthAutoFill">
          <VSPromiseTable
            className="
           aspirin-table-header-background-color
          aspirin-table-row-hover-background-color
          aspirin-table-nopadding
          aspirin-table-body-empty-transparent-background-color
          "
            rowKey="id"
            // rowSelection={{
            //   selectType: 'multiple',
            //   selectedRows: selectRows,
            //   onSelectedRowsChange: setSelectRows,
            // }}
            rowClassName={(record) => {
              return record?.id == selectedRow?.id
                ? 'aspirin-table-row-select-background-color'
                : '';
            }}
            rowClick={{
              onRowClick: (_value) => {
                if (_value?.id == selectedRow?.id) {
                  setSelectedRow(null);
                } else {
                  setSelectedRow({ ..._value });
                }
              },
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  if (record?.id == selectedRow?.id) {
                    setSelectedRow(null);
                  } else {
                    setSelectedRow({ ...record });
                  }
                },
                onDoubleClick: async () => {
                  const res =
                    await vsf.refs.chargeSolutionPatientList.saveEdit();
                  if (res) {
                    vsf.refs.chargeSolutionPatientList.startEdit(record?.id);
                  }
                },
              };
            }}
            id="chargeSolutionPatientList"
            pagination={false}
            searchConfig={{
              labelAlign: 'left',
              labelWidth: 110,
            }}
            scroll={{
              x: 1300,
              y: getScrollY(360),
            }}
            onFetch={onFetch_t3047}
            extraParams={{ selectedChargeSolutionRow }}
            onRemove={async (data) => {
              return true;
            }}
            // onRecord={async () => {
            //   return {
            //     id: `TEMP_${Math?.random?.()}`,
            //     // chargeSolutionId: selectedChargeSolutionRow?.id,
            //   };
            // }}
          >
            <VSTableColumn
              dataIndex={['identificationNumberIs']}
              title="身份证号"
              valueType="text"
              search
              order={0}
              columnKey={'identificationNumberIs'}
              hideInTable
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['nameLike']}
              title="姓名"
              valueType="text"
              search
              order={0}
              columnKey={'nameLike'}
              hideInTable
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['displayId']}
              title="患者ID"
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['name']}
              title="姓名"
              valueType="text"
              fieldProps={{}}
            />
            <VSTableColumn
              dataIndex={['gender']}
              title="性别"
              valueType="select"
              dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
              fieldProps={{}}
            />
            <VSTableColumn
              dataIndex={['identificationNumber']}
              title="身份证号"
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['stopIndicator']}
              title="停用标识"
              valueType="select"
              fieldProps={{}}
              dataSource={[
                { label: '是', value: true },
                { label: '否', value: false },
              ]}
            />

            <VSTableColumn
              dataIndex={['createStaffName']}
              title="创建操作员"
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['createdAt']}
              title="创建日期"
              valueType="date"
              fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            />

            <VSTableColumn
              dataIndex={['lastModifyStaffName']}
              title="最近修改操作员"
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['updatedAt']}
              title="最近修改时间"
              valueType="date"
              fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
            />
          </VSPromiseTable>
        </div>
        <div className="special_charge_list_scheme_bottom">
          <Button
            onClick={() => {
              setSelectedChargeSolutionRow(null);
              setTimeout(() => {
                vsf.refs?.chargeSolutionPatientListSolutionList?.reload();
                vsf.refs?.chargeSolutionPatientList?.reload();
                vsf.refs?.chargeSolutionPatientList
                  ?.getSearchForm()
                  ?.resetFields();
              }),
                100;
            }}
            type="primary"
            ghost
            children="清屏"
          />
          <Button
            onClick={() => {
              Modal.confirm({
                title: '人员启用',
                okText: '确认',
                cancelText: '取消',
                onOk: async (values) => {
                  const res =
                    await vsf?.services?.ChargeSolutionPatientListBOController_updateChargeSolutionPatientStopIndicator_d47602?.(
                      {
                        chargeSolutionPatientId: selectedRow?.id,
                      },
                    );

                  if (res?.code == 200) {
                    message.success('启用成功');
                    vsf.refs?.chargeSolutionPatientList?.reload();
                  }
                },
              });
            }}
            disabled={!selectedRow?.id || selectedRow?.stopIndicator}
            ghost
            type="primary"
            children="停用"
          />
          <Button
            ghost
            onClick={async () => {
              // handelStop(true);
              Modal.confirm({
                title: '人员停用',
                okText: '确认',
                cancelText: '取消',
                onOk: async (values) => {
                  const res =
                    await vsf?.services?.ChargeSolutionPatientListBOController_updateChargeSolutionPatientStopIndicator_d47602?.(
                      {
                        chargeSolutionPatientId: selectedRow?.id,
                      },
                    );

                  if (res?.code == 200) {
                    message.success('停用成功');
                    vsf.refs?.chargeSolutionPatientList?.reload();
                  }
                },
              });
            }}
            type="primary"
            children="启用"
            disabled={!selectedRow?.id || !selectedRow?.stopIndicator}
          />
          <Button
            onClick={async () => {
              if (!selectedRow?.id) {
                return message.info('请选择要编辑的收费方案名单');
              }
              setOperationType('edit');
              handelAdd(true, 'edit');
            }}
            type="primary"
            children="编辑"
            disabled={!selectedRow?.id}
          />
          <Button
            onClick={async () => {
              setOperationType('add');
              handelAdd(true);
            }}
            disabled={!selectedChargeSolutionRow?.id}
            type="primary"
            children="新增"
          />
        </div>
      </div>
      <Modal
        centered
        open={openAdd}
        // title="新增人员"
        title={operationType == 'add' ? '新增人员' : '编辑人员'}
        okText="确认"
        cancelText="取消"
        onCancel={() => {
          handelAdd(false);
        }}
        onOk={async (values) => {
          const formValue = vsf.refs.addChargeSolutionListForm.getFieldsValue();
          // 新增
          const output =
            convertAddChargeSolutionPatientForm2CreateChargeSolutionPatientReq?.(
              {
                ...formValue,
                chargeSolutionId: selectedChargeSolutionRow?.id,
                stopIndicator:
                  formValue?.stopIndicator?.length > 0
                    ? formValue?.stopIndicator?.[0]
                    : false,
              },
            );
          // 编辑
          const output1 =
            convertEditChargeSolutionPatientForm2UpdateChargeSolutionPatientReq?.(
              {
                ...formValue,
                chargeSolutionId: selectedChargeSolutionRow?.id,
                id: selectedRow?.id,
                stopIndicator:
                  formValue?.stopIndicator?.length > 0
                    ? formValue?.stopIndicator?.[0]
                    : false,
              },
            );
          const res =
            operationType == 'add'
              ? await vsf?.services?.ChargeSolutionPatientListBOController_createChargeSolutionPatient_7f550b?.(
                  output,
                )
              : await vsf?.services?.ChargeSolutionPatientListBOController_updateChargeSolutionPatient_a97869?.(
                  output1,
                );

          if (res?.code == 200) {
            operationType == 'add'
              ? message.success('新增成功')
              : message.success('编辑成功');
            handelAdd(false);
            vsf.refs?.chargeSolutionPatientList?.reload();
          }
        }}
      >
        <VSForm id="addChargeSolutionListForm" labelAlign="left">
          <VSFormLayout key="0" columnCount={1} labelCol={labelColWidth[6]}>
            <VSFormItem
              name={['identificationNumber']}
              label="身份证号"
              valueType="text"
              rules={[
                {
                  pattern: idCard,
                  message: idCardMessage,
                },
              ]}
              fieldProps={{
                // onChange: () => {
                //   vsf.refs?.addChargeSolutionListForm?.setFieldsValue({
                //     name: null,
                //     patientId: null,
                //   });
                // },
                onChange: async (e: any) => {
                  // if (e.code === 'Enter') {
                  const res =
                    await vsf?.services?.PatientMainController_getPatientByIdNumber_7b5fb1?.(
                      { idNumber: e?.target?.value },
                    );
                  if (res?.code == 200 && res?.data?.length > 0) {
                    vsf.refs?.addChargeSolutionListForm?.setFieldsValue({
                      name: res?.data?.[0]?.name,
                      patientId: res?.data?.[0]?.id,
                    });
                    setCanEditName(false);
                  } else {
                    vsf.refs?.addChargeSolutionListForm?.setFieldsValue({
                      name: null,
                      patientId: null,
                    });
                    setCanEditName(true);
                  }
                  // }
                },
              }}
              required={true}
            />

            <VSFormItem
              name={['name']}
              label="姓名"
              valueType="text"
              rules={[
                {
                  message: '姓名长度不合法',
                  type: 'string',
                  min: 0,
                  max: 1024,
                },
              ]}
              fieldProps={{ disabled: !canEditName }}
              // fieldProps={{ disabled: operationType == 'edit' }}
              required={true}
            />
            <VSFormItem
              name={['patientId']}
              label="主索引id"
              valueType="text"
              // fieldProps={{ disabled: !canEditName }}
              hidden
            />

            <VSFormItem
              name={['stopIndicator']}
              label="停用标识"
              valueType="checkbox"
              initialValue={[false]}
              fieldProps={{}}
              dataSource={[{ label: '停用', value: true }]}
            />
          </VSFormLayout>
        </VSForm>
      </Modal>
    </div>
  );
};

export default definePage(Index);
// 收费方案项目维护
