import {
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
  Button,
  message,
  Space,
  Row,
  Col,
  InputNumber,
  Form,
} from '@vs/vsf-kit';
import { Table, Empty } from 'antd';
import { memo, useEffect, useRef, useContext, useState, Fragment } from 'react';
import ComplexSelect from '@/pages/Index/components/complex_select';
import {
  askFixed,
  askJustOnce,
  askTotalAmount,
} from '@/pages/Index/components/func/ask';
import Icon from '@/module/Icon';
import { useGetState, useLockFn, useRequest, useUpdateEffect } from 'ahooks';
import { CreateScreen } from '@/pages/Index/components/screen';
import DragLine from '@/pages/Index/components/drag_line';
import ButtonAndKeyDown from '@/pages/Index/components/button_and_keydown';
import Loading from '@/module/Loading';
import Tip from '@/pages/Index/components/func/Tip';
import vsf, { definePage } from '@vs/vsf-boot';

import './index.less';
import {
  useFetch,
  useOnChangeValue,
} from '@/pages/Index/components/func/hooks';
import { rest } from 'lodash';
import { Determine, Flex } from '@/pages/Index/components';
import SearchContainer from '@/module/Search';
import ShowAmount from './amount';
import { getScrollYRem, labelNumberWidth } from '@/utils';

const Selected = ({ recode, onChange, form, value }) => {
  const {
    data: dataSource,
    loading,
    run: onFetch,
  } = useRequest(
    async (params) => {
      const res =
        await vsf?.services?.PriceListDtoController_getAllByPriceItemReviewNewQtoByPriceItemReviewNewQto_f9d7e6?.(
          {
            qto: {
              ...params,
              auditIndicatorIs: 'PASS',
            },
          },
        );
      if (res?.code === 200) {
        return (
          res.data
            ?.map((item) => {
              item.itemSpecification = `${item.itemSpecification}/${item.unit}`;
              item.key = item.itemId;
              return item;
            })
            ?.slice(0, 50) ?? []
        );
      }
    },
    {
      manual: true,
      debounceWait: 500,
    },
  );

  // /**
  //  * 过滤除了自己的，其他已选项
  //  */
  // const otherSelected = vsf.refs?.PriceTable.getValues()
  //   .filter((item) => {
  //     return (
  //       (item?.priceItem?.itemName?.value ?? item.priceItem?.itemId) !==
  //       (value?.value ?? value)
  //     );
  //   })
  //   .map((item) => item.priceItem?.itemId ?? item.chargeItemId);

  // const options = dataSource.filter((item) => {
  //   return !otherSelected.includes(item.itemId);
  // });

  // useEffect(() => {
  //   onFetch({
  //     size: 50,
  //   });
  // }, []);

  const ref = useRef(null);

  useEffect(() => {
    if (String(recode?.record?.id)?.startsWith('create')) {
      ref?.current?.focus?.();
    }
  }, []);

  return (
    <ComplexSelect
      mode="radio"
      showLabel="itemName"
      ref={ref}
      value={value?.label ?? value}
      onChange={(value) => {
        const [itemSpecification, unit] = value.itemSpecification.split('/');
        form.setFieldValue(recode.recordKey, {
          ...form.getFieldValue(recode.recordKey),
          chargeItemClass: value.itemClass,
          chargeItemId: value.itemId,
          priceItem: {
            ...value,
            itemName: {
              label: value.itemName,
              value: value.itemId,
            },
            itemSpecification,
            unit,
          },
        });
        onChange({
          label: value.itemName,
          value: value.itemId,
        });
      }}
      onSearch={(value) => {
        if (value.trim()) {
          onFetch({
            itemNameLike: value.trim(),
          });
        }
      }}
      hideSelectIcon
      dropdownMatchSelectWidth={false}
      dropdownStyle={{
        width: 700,
      }}
      placeholder="搜索价格项目名称/首字母"
      listHeight={1000}
    >
      <Table
        style={{
          width: 700,
        }}
        scroll={{
          y: 400,
        }}
        pagination={false}
        loading={{
          indicator: (
            <Row
              justify="center"
              style={{
                left: '40%',
              }}
            >
              <Col span={24}>
                <Loading />
              </Col>
            </Row>
          ),
          spinning: loading,
        }}
        locale={{
          emptyText: (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description="搜索价格项目名称/首字母"
            />
          ),
        }}
        rowClassName="aspirin-table-row-box"
        dataSource={dataSource}
        size="large"
        virtual
        columns={[
          {
            title: '价格项目名称',
            dataIndex: 'itemName',
            key: 'item_name',
            width: 100,
          },
          {
            title: '规格/单位',
            dataIndex: 'itemSpecification',
            key: 'item_specification',
            width: 100,
          },
          {
            title: '标准编码',
            dataIndex: 'standardCode',
            key: 'standard_code',
            width: 100,
          },
        ]}
      />
    </ComplexSelect>
  );
};

const InputNumberRender = (props) => {
  const { recode, onChange, form, value, amount } = props;
  const ref = useRef(null);
  const watch = form.getFieldValue([
    recode?.recordKey,
    'priceItem',
    'itemName',
  ]);

  useUpdateEffect(() => {
    ref?.current?.focus();
  }, [watch]);
  return (
    <InputNumber
      onChange={(value) => {
        onChange(value);
      }}
      ref={ref}
      value={value}
    />
  );
};

const TableRender = (props) => {
  const { dom, value } = props;
  const bottom = useRef(null);

  const onScreen = useContext(CreateScreen);
  const [oldState, setOldState] = useState([]);
  const [newState, setNewState, getNewState] = useGetState([]);
  const [priceDataSource, setPriceDataSource] = useState([]);

  const amount = useRef(null);

  const int = (value) => {
    const status = value?.filter((item) =>
      ['SINGLE_TOTAL', 'TAKE_HIGH']?.includes(item?.examCalculation),
    );
    const data = value?.filter(
      (item) => item?.priceItem?.priceAttribute === 'VARIABLE',
    );
    if (status?.length) {
      amount?.current?.onChange?.(
        '对照价表项目中存在非“累加”项目（“多项目取高”或“单项目合计”）',
      );
    } else if (data?.length) {
      amount?.current?.onChange?.('对照价表项目中存在价格属性为“可变”的项目');
    } else {
      amount?.current?.onChange?.([...value]);
    }
  };
  const getPriceDataSource = async () => {
    const res =
      await vsf?.services?.BaseCodeDtoServiceController_getValidCodeList_3cb2b9?.(
        { codeType: 'RECK_ITEM_CLASS_DICT' },
      );
    if (res.code === 200) {
      setPriceDataSource(
        res.data.map((item) => ({
          label: item.name,
          value: item.code,
        })),
      );
    }
  };
  const onPriceFetch = async () => {
    if (props.clinicItemId) {
      const res =
        await vsf?.services?.ClinicItemBaseController_getChargeByClinicItemId_03609f?.(
          { clinicItemId: props.clinicItemId },
        );
      if (res.code === 200) {
        const list = res.data.map((item) => {
          console.log(item, 'popopo');
          return {
            ...item,
            priceItem: {
              ...item.priceItem,
              itemName: {
                label: item.priceItem?.itemName,
                value: item.chargeItemId,
              },
            },
          };
        });
        setOldState(list);
        setNewState(list);
        int(list);
      }
    } else {
      setOldState([]);
      setNewState([]);
      amount?.current?.onChange?.([]);
    }
  };
  useEffect(() => {
    getPriceDataSource();
  }, []);

  useUpdateEffect(() => {
    if (props?.clinicItemId) {
      onPriceFetch();
    } else {
      setOldState([]);
      setNewState([]);
    }
  }, [props?.clinicItemId]);

  const remove = (_, data) => {
    const onClick = (event) => {
      event.stopPropagation();
      if (oldState.map((item) => item.id).includes(data.id)) {
        Tip({
          title: '操作窗口',
          content: `确定删除{【${data.priceItem?.itemName.label}】}，删除后不可恢复！`,
          onOk: () => {
            vsf.refs.PriceTable.remove(data);
          },
          centered: true,
          okText: '确定',
          cancelText: '取消',
        });
      } else {
        vsf?.refs?.PriceTable?.remove(data);
      }
    };
    return props?.value?.stopIndicator || props?.disabled ? (
      <Icon
        type="icon-a-shanchujinyong"
        size={24}
        style={{
          cursor: 'not-allowed',
        }}
      />
    ) : (
      <Icon type="icon-shanchu" size={24} onClick={onClick} />
    );
  };
  /**
   * 审核函数
   */
  const onAudit = () => {
    Tip({
      onOk: async () => {
        const res =
          await vsf?.services?.ClinicItemBaseController_auditClinicItem_a1c916?.(
            {
              id: value?.id,
            },
          );

        if (res.code === 200) {
          props.reload();
          message.success('审核对照价表成功');
        }
      },
      content: newState?.length
        ? `确定审核{【${value.clinicItemName}】}项目?`
        : `{【${value.clinicItemName}】}项目是否为不计价项目?`,
      title: '操作窗口',
      okButtonProps: {},
    });
  };

  /**
   * 保存函数
   */
  const onSubmit = useFetch({
    fetch: async () => {
      const { add, update, remove } = askJustOnce({
        oldState,
        newState: getNewState()?.map((item, index) => ({
          ...item,
          cardinality: item?.cardinality ?? null,
          addCoefficient: item?.addCoefficient ?? null,
          cappingNumber: item?.cappingNumber ?? null,
          chargeItemNumber: index + 1,
        })),
        id: 'id',
      });
      return vsf?.services?.ClinicItemBaseController_addAndUpdateClinicCharge_14cb6a?.(
        {
          addClinicChargeBtoList: [
            {
              id: value.id,
              clinicVsChargeBtoList: add.map((item) => {
                delete item.id;
                return item;
              }),
            },
          ],
          updateClinicChargeBtoList: [
            {
              id: value.id,
              clinicVsChargeBtoList: update,
            },
          ],
          removeClinicChargeBtoList: [
            {
              id: value.id,
              clinicVsChargeBtoList: remove,
            },
          ],
        },
      );
    },
    success: () => {
      props?.reload();
      onPriceFetch();
    },
    error: (error) => {
      console.log(error);
    },
    message: '保存',
  });
  return (
    <Fragment>
      <DragLine.Container
        minSize={88}
        style={{ height: props?.height, overflow: 'hidden' }}
        // style={{ height: 500, overflow: 'hidden' }}
        onDragMove={props?.onDragMove}
      >
        {props?.children}
        <DragLine
          style={{
            margin: '10px 0',
          }}
        />
        <div className="aspirin-compare-price-bottom-box" ref={bottom}>
          <div
            className="aspirin-compare-price-box"
            style={{
              zIndex: 999,
              position: 'relative',
              // height: bottom.current?.offsetHeight,
              height: '100%',
              // overflowY: 'auto',
            }}
          >
            <Flex vertical gap={20}>
              <Flex justify="space-between">
                <SearchContainer.Header
                  title={
                    <Flex>
                      <span>对照价表项目</span>
                      <Determine condition={!!value?.clinicItemName}>
                        <span
                          style={{ color: 'var(--blue)', textIndent: 0 }}
                        >{`(${value?.clinicItemName})`}</span>
                      </Determine>
                    </Flex>
                  }
                />
                <Flex gap={20} align="center">
                  <ShowAmount ref={amount} />
                  <ButtonAndKeyDown
                    preventDefault
                    type="primary"
                    ordinaryKey="A"
                    methodKey="altKey"
                    disabled={value.stopIndicator || props?.disabled}
                    onClick={() =>
                      vsf?.refs?.PriceTable?.add?.({
                        id: 'create_' + Math.random(),
                      })
                    }
                  >
                    添加
                  </ButtonAndKeyDown>
                </Flex>
              </Flex>
              <Flex>
                <VSTable
                  editable={{
                    editType: value?.stopIndicator ? 'single' : 'multiple',
                    columnProps: {
                      dataIndex: ['__vs__table__edit'],
                      hideInTable: true,
                    },
                    disableDeleteConfirm: false,
                    switchMode: true,
                  }}
                  bordered
                  pagination={false}
                  className="aspirin-table aspirin-table-search-padding-no"
                  id="PriceTable"
                  scroll={{
                    x: 2000,
                    // y: bottom.current?.offsetHeight - 105,
                    y: getScrollYRem(55),
                  }}
                  onRecord={() => ({ id: 'create_' + Math.random() })}
                  value={newState}
                  onChange={(value) => {
                    int(value);
                    setNewState(value);
                  }}
                  rowClassName={() => {
                    const name = ['aspirin-vs-table-row-box'];
                    if (props?.value?.stopIndicator || props?.disabled)
                      name.push('aspirin-row-disabled-box');
                    return name.join(' ');
                  }}
                >
                  <VSTableColumn
                    dataIndex={['id']}
                    title="ID"
                    valueType="text"
                    hideInTable
                    preview
                  />
                  <VSTableColumn
                    dataIndex={['chargeItemClass']}
                    title="类别"
                    valueType="select"
                    dataSource={priceDataSource}
                    fieldProps={{
                      disabled: true,
                    }}
                    preview
                  />
                  <VSTableColumn
                    dataIndex={['priceItem', 'itemName']}
                    title="价表项目名称"
                    valueType="select"
                    width={200}
                    renderFormItem={(...arr) => {
                      const [schema, recode, form] = arr;
                      return (
                        <Selected
                          recode={recode}
                          form={form}
                          onChange={schema.originProps.onChange}
                        />
                      );
                    }}
                    formItemProps={{
                      rules: [
                        {
                          required: true,
                        },
                      ],
                    }}
                    fieldProps={{
                      onChange: (value) => {
                        console.log(value);
                      },
                    }}
                  />
                  <VSTableColumn
                    dataIndex={['priceItem', 'itemSpecification']}
                    title="规格"
                    valueType="text"
                    preview
                    width={labelNumberWidth(12)}
                    fieldProps={{
                      disabled: true,
                    }}
                    ellipsis={{
                      showTitle: true,
                    }}
                  />
                  <VSTableColumn
                    dataIndex={['amount']}
                    title="数量"
                    valueType="digit"
                    initialValue={1}
                    formItemProps={{
                      rules: [
                        {
                          required: true,
                        },
                      ],
                    }}
                    renderFormItem={(...arr) => {
                      const [schema, recode, form] = arr;
                      return (
                        <InputNumberRender
                          recode={recode}
                          form={form}
                          amount={amount}
                          onChange={schema.originProps.onChange}
                        />
                      );
                    }}
                    // fieldProps={{
                    //   min: 1,
                    //   precision: 2
                    // }}
                  />

                  <VSTableColumn
                    dataIndex={['priceItem', 'unit']}
                    title="单位"
                    valueType="text"
                    // renderFormItem={(...[, recode]) => recode?.record?.priceItem?.unit}
                    preview
                    fieldProps={{
                      disabled: true,
                    }}
                  />

                  <VSTableColumn
                    dataIndex={['priceItem', 'standardCode']}
                    title="标准编码"
                    valueType="text"
                    // renderFormItem={(...[, recode]) => recode?.record?.priceItem?.standardCode}
                    preview
                    fieldProps={{
                      disabled: true,
                    }}
                  />

                  <VSTableColumn
                    dataIndex={['priceItem', 'medicalInsuranceType']}
                    title="医保类别"
                    valueType="select"
                    dataSource={[
                      { label: '甲', value: 'ALPHA' },
                      { label: '乙', value: 'BETA' },
                      { label: '丙', value: 'GAMMA' },
                    ]}
                    // renderFormItem={(...[, recode]) => recode?.record?.priceItem?.medicalInsuranceType}
                    preview
                    fieldProps={{
                      disabled: true,
                    }}
                  />

                  <VSTableColumn
                    dataIndex={['priceItem', 'price']}
                    title="单价"
                    valueType="text"
                    renderFormItem={(dom, entity, form) => {
                      // const [_, { record }] = args;
                      // console.log(
                      //   dom?.entity?.record?.priceItem?.price,
                      //   entity?.priceItem?.price,
                      //   entity,
                      //   dom,
                      //   'recordrecord',
                      // );
                      return (
                        <Flex justify="flex-end" style={{ width: '100%' }}>
                          {askFixed(dom?.entity?.priceItem?.price ?? 0, 2)}
                          {/* {dom?.entity?.record?.priceItem?.price} */}
                        </Flex>
                      );
                    }}
                    preview
                    width={100}
                    fieldProps={{
                      readOnly: true,
                    }}
                  />
                  <VSTableColumn
                    dataIndex={['examCalculation']}
                    title="计算"
                    valueType="select"
                    initialValue={'ACCUMULATION'}
                    dataSource={[
                      { label: '多项目取高', value: 'TAKE_HIGH' },
                      { label: '累加', value: 'ACCUMULATION' },
                      { label: '单项目合计', value: 'SINGLE_TOTAL' },
                    ]}
                    fieldProps={{}}
                  />
                  <VSTableColumn
                    dataIndex={['examAdditionalIndicator']}
                    title="加收"
                    valueType="check"
                    initialValue={false}
                    // width={100}
                    width={labelNumberWidth(5)}
                    fieldProps={{}}
                    ellipsis={{
                      showTitle: true,
                    }}
                  />

                  <VSTableColumn
                    dataIndex={['examReportType']}
                    title="归类"
                    valueType="select"
                    dataSource={[
                      { label: '图文', value: '1' },
                      { label: '胶片', value: '2' },
                      { label: '云影像', value: '3' },
                    ]}
                    fieldProps={{}}
                  />

                  <VSTableColumn
                    dataIndex={['disease']}
                    title="病种"
                    valueType="select"
                    dataSource={[
                      { label: '普通', value: 'COMMON' },
                      { label: '肿瘤', value: 'TUMOUR' },
                    ]}
                    fieldProps={{}}
                  />

                  <VSTableColumn
                    dataIndex={['singleChargeIndicator']}
                    title="单次收费"
                    valueType="check"
                    initialValue={false}
                    // width={80}
                    width={labelNumberWidth(7)}
                    ellipsis={{
                      showTitle: true,
                    }}
                    fieldProps={{}}
                  />
                  <VSTableColumn
                    dataIndex={['cardinality']}
                    title="基数"
                    valueType="digit"
                    formItemProps={(...[, record]) => {
                      const examCalculation = getNewState()?.find(
                        (item) => item?.id == record?.entity?.id,
                      )?.examCalculation;
                      if (
                        ['TAKE_HIGH', 'SINGLE_TOTAL']?.includes?.(
                          examCalculation,
                        )
                      ) {
                        return {
                          rules: [
                            {
                              required: true,
                            },
                          ],
                        };
                      }
                    }}
                  />
                  <VSTableColumn
                    dataIndex={['cappingNumber']}
                    title="封顶数"
                    valueType="digit"
                  />
                  <VSTableColumn
                    dataIndex={['addCoefficient']}
                    title="加收系数"
                    valueType="digit"
                  />
                  <VSTableColumn
                    dataIndex={['__vs__table__edit']}
                    title="操作"
                    editable={false}
                    width={70}
                    valueType="custom"
                    render={remove}
                  />
                </VSTable>
              </Flex>
            </Flex>
          </div>
        </div>
      </DragLine.Container>
      <div
        className="aspirin-table-footer-button"
        style={{
          justifyContent: 'flex-end',
          // paddingTop: 24,
          paddingTop: 5,
        }}
      >
        <ButtonAndKeyDown
          onClick={onScreen}
          methodKey="altKey"
          ordinaryKey="R"
          type="primary"
          ghost
        >
          清屏
        </ButtonAndKeyDown>
        <Button
          onClick={onAudit}
          type="primary"
          ghost
          disabled={
            value?.auditIndicator || value.stopIndicator || props?.disabled
          }
        >
          审核
        </Button>
        <ButtonAndKeyDown
          onClick={async () => {
            const save =
              await vsf.refs.PriceTable.getEditForm()?.validateFieldsReturnFormatValue();
            if (save) {
              onSubmit?.();
            }
          }}
          methodKey="altKey"
          ordinaryKey="S"
          disabled={value?.stopIndicator || props?.disabled}
          type="primary"
        >
          保存
        </ButtonAndKeyDown>
      </div>
    </Fragment>
  );
};

export default TableRender;
