/* eslint-disable simple-import-sort/imports */
import { Register } from './../../module/cpoe/medical/editor/core/register/Register';
import Integer from '@pages/Index/manage/drug/integerRule';
import Dispenses from '@pages/Index/manage/drug/pharmacyDispensesMedicine';

import Center from '@/pages/Index/center';
import CenterCpoe from '@/pages/Index/cpoe/center';
import PatientList from '@/pages/Index/cpoe/center';
import CommonMedicines from '@/pages/Index/cpoe/center/main/common_medicines';
import DischargeMedicationExecution from '@/pages/Index/cpoe/center/main/discharge_medication_execution';
import MedicalOrderExecute from '@/pages/Index/cpoe/center/main/medical_order_execute';
import Deploy from '@/pages/Index/cpoe/center/main/medical_order_execute/deploy';
import {
  AgreedPartPackage,
  Defend,
} from '@/pages/Index/cpoe/center/main/medical_order_template';
import MedicalOrdersShow from '@/pages/Index/cpoe/center/main/medical_orders_show';
import OrderCheck from '@/pages/Index/cpoe/center/main/order_check';
import OrderExecute from '@/pages/Index/cpoe/center/main/order_execute';
// import Local from '@/pages/Index/manage/local/table';
// import outpatient from '@/pages/Index/manage/dictionary/outpatient';
import Index from '@/pages/Index/cpoe/index';
import BatchTriage from '@/pages/Index/emergency_treatment/batch_triage';
import PatientAdministrantion from '@/pages/Index/emergency_treatment/patient_administration';
import PatientTransfer from '@/pages/Index/emergency_treatment/patient_transfer';
import PreviewTriage from '@/pages/Index/emergency_treatment/preview_triage';
import InSettlementRecordEnquiry from '@/pages/Index/financial_medical_insurance/in_settlement_record_enquiry';
import ListPrint from '@/pages/Index/financial_medical_insurance/list_print';
import SpecialChargeListScheme from '@/pages/Index/financial_medical_insurance/maintenance_charge_list_scheme';
import SpecialChargeProjectScheme from '@/pages/Index/financial_medical_insurance/maintenance_charge_project_scheme';
import SpecialChargeScheme from '@/pages/Index/financial_medical_insurance/maintenance_charge_scheme';
import AvancePayment from '@/pages/Index/manage/advance_payment';
import BusinessApplication from '@/pages/Index/manage/application/business';
import StandardApplication from '@/pages/Index/manage/application/standard';
import bedCard from '@/pages/Index/manage/bed_card';
import BedLevel from '@/pages/Index/manage/bed_manage/bed_level';
import BedMaintenance from '@/pages/Index/manage/bed_manage/bed_maintenance';
import Charge from '@/pages/Index/manage/charge';
import CodeTableType from '@/pages/Index/manage/code_uphold/CodeTableType';
import Department from '@/pages/Index/manage/department';
import StaffSignatureList from '@/pages/Index/manage/department/userSignature';
import DoctorInformation from '@/pages/Index/manage/doctor_information';
import doctorStationBedCard from '@/pages/Index/manage/doctor_station_bed_card';
import DoctorStationOutHospital from '@/pages/Index/manage/doctor_station_out_hospital';
import MedicalCertificate from '@/pages/Index/manage/Medical_certificate';
import DoctorStationTransfer from '@/pages/Index/manage/doctor_station_transfer';
import ApplicationForReturningHerbalMedicineInTheWard from '@/pages/Index/manage/drug/application_for_returning_herbal_medicine_in _the ward';
import ApplicationForWesternMedicineRefundInWard from '@/pages/Index/manage/drug/application_for_western_medicine_refund _in _Ward';
import Belt from '@/pages/Index/manage/drug/beltProtection';
import CentreMedicineDispensing from '@/pages/Index/manage/drug/centre_medicine_dispensing';
import DeptDrugApply from '@/pages/Index/manage/drug/dept_drug_apply';
import DrugApply from '@/pages/Index/manage/drug/drug_apply';
import DragConfirmation from '@/pages/Index/manage/drug/drug_confirmation';
import ChineseConfim from '@/pages/Index/manage/drug/drug_confirmation_chinese';
import DrugSpecificationStorage from '@/pages/Index/manage/drug/drug_specification_storage';
import DrugStockSupplyManage from '@/pages/Index/manage/drug/drug_stock_supply_manage';
import DrugBeInHospitalDispensing from '@/pages/Index/manage/drug/DrugBeInHospitalDispensing';
import DrugDictionary from '@/pages/Index/manage/drug/drugDictionary/drugDictionary';
import DrugExport from '@/pages/Index/manage/drug/drugImportAndExport/export';
import DrugImport from '@/pages/Index/manage/drug/drugImportAndExport/import';
import DrugStockSplit from '@/pages/Index/manage/drug/DrugInventoryManage/drugStockSplit';
import DrugStocktaking from '@/pages/Index/manage/drug/DrugInventoryManage/drugStocktaking';
import DrugStorageProfile from '@/pages/Index/manage/drug/DrugInventoryManage/drugStorageProfile';
import DrugPriceAdjust from '@/pages/Index/manage/drug/DrugPriceAdjust';
import DrugReceiveAndCheckList from '@/pages/Index/manage/drug/DrugReception';
import PharmacyCustom from '@/pages/Index/manage/drug/drugStorage/customizeImportExportManage';
import DrugMaintenance from '@/pages/Index/manage/drug/drugStorage/drugMaintenanceRelationship';
import StorageDictionary from '@/pages/Index/manage/drug/drugStorage/drugStorageDictionary';
import DrugSubStorageManage from '@/pages/Index/manage/drug/drugStorage/drugSubStorageManage';
import DrugStoragePurchasePlanCreate from '@/pages/Index/manage/drug/DrugStoragePurchasePlanCreate';
import Firm from '@/pages/Index/manage/drug/firmManage/firm';
import SupplierTable from '@/pages/Index/manage/drug/firmManage/supplier/table';
import HerbPrescriptionDispense from '@/pages/Index/manage/drug/HerbPrescriptionDispense';
import InpatientPrescriptionDispensing from '@/pages/Index/manage/drug/inpatient-prescription-dispensing';
import InpDrugRefundPerform from '@/pages/Index/manage/drug/InpDrugRefundPerform';
import LeaveHospitalMedicine from '@/pages/Index/manage/drug/leave-hospital-medicine';
import {
  PrescriptionReturnMedicationDrug,
  PrescriptionReturnMedicationHerb,
} from '@/pages/Index/manage/drug/prescription_return_medication';
import RefundDrugApply from '@/pages/Index/manage/drug/refund_drug_apply';
import WindowDictionaryMaintenance from '@/pages/Index/manage/drug/windowDictionaryMaintenance';
import ExecutionRegistrationOfSpecialDrugsForRefinedAndToxicSubstances from '@/pages/Index/manage/execution_registration';
import BillingAudit from '@/pages/Index/manage/finance/BillingAudit';
import ClinicItem from '@/pages/Index/manage/finance/clinicItem';
import Exam from '@/pages/Index/manage/finance/examItem';
import LabItem from '@/pages/Index/manage/finance/labItem';
import pathologyItem from '@/pages/Index/manage/finance/pathologyItem';
import Price from '@/pages/Index/manage/finance/priceItem';
import Review from '@/pages/Index/manage/finance/priceItem/review';
import PricePattern from '@/pages/Index/manage/finance/pricePattern';
import Specimen from '@/pages/Index/manage/finance/specimen';
import Treat from '@/pages/Index/manage/finance/treatItem';
import cancelHospitalizationSettlement from '@/pages/Index/manage/financial/cancel-hospitalization-settlement';
import hospitalizationSettlement from '@/pages/Index/manage/financial/hospitalization-settlement';
import Function from '@/pages/Index/manage/function';
import Institution from '@/pages/Index/manage/institution';
import MaintenanceComplaint from '@/pages/Index/manage/maintenance_complaint';
import InpMedicalTechnologyCharge from '@/pages/Index/manage/MedicalTechnology/InpMedicalTechnologyCharge';
import MedicalTechnologyChargeTemplate from '@/pages/Index/manage/MedicalTechnology/MedicalTechnologyChargeTemplate';
import OutpMedicalTechnologyCharge from '@/pages/Index/manage/MedicalTechnology/OutpMedicalTechnologyCharge';
import SupplementRefundBilling from '@/pages/Index/manage/nurse/SupplementRefundBilling';
import NurseAdmission from '@/pages/Index/manage/nurse_admission';
import NurseCancelOperation from '@/pages/Index/manage/nurse_cancel_operation';
import NurseStationBedsideCardPrinting from '@/pages/Index/manage/nurse_station_bed_side_card_printing';
import NurseStationOutHospital from '@/pages/Index/manage/nurse_station_out_hospital';
import nurseStationTransfer from '@/pages/Index/manage/nurse_station_transfer';
import OperationDictionary from '@/pages/Index/manage/operation_dictionary';
import OutpatientRefunds from '@/pages/Index/manage/out_patient_expenses/outpatientRefunds';
import ClinicRegisterSchedule from '@/pages/Index/manage/out_patient_number_schedule/ClinicRegisterSchedule';
import TypeProtect from '@/pages/Index/manage/out_patient_number_schedule/dictionary';
import NumberType from '@/pages/Index/manage/out_patient_number_schedule/numberType';
import ApplicationParameter from '@/pages/Index/manage/parameter/application/table';
import SystemParameter from '@/pages/Index/manage/parameter/system/table';
import PatientGroup from '@/pages/Index/manage/patient_group/table';
import PatientMain from '@/pages/Index/manage/patient_main_manager/patient_main';
import PatientMainSearch from '@/pages/Index/manage/patient_main_manager/patient_main_search';
import PatientMainMerge from '@/pages/Index/manage/patient_main_manager/patient_merge';
import PatientMainMergeLog from '@/pages/Index/manage/patient_main_manager/patient_merge_log';
import PatientMainUpdateLog from '@/pages/Index/manage/patient_main_manager/patient_update_log';
import ReceiptInquiry from '@/pages/Index/manage/receipt_inquiry';
import Appointmentregistration from '@/pages/Index/manage/register/AppointmentRegistration';
import registration from '@/pages/Index/manage/register/outpatientRegistration';
import backIngNumber from '@/pages/Index/manage/register/OutpatientWithdrawal';
import Check_check_rollback from '@/pages/Index/manage/register/Check_check_rollback';
import RegistrationForCollection from '@/pages/Index/manage/register/RegistrationForCollection';
import registerInformation from '@/pages/Index/manage/register_information';
import ReviewOfInpatientMedicationOrders from '@/pages/Index/manage/review_of_inpatient_medication_orders/index';
import Staff from '@/pages/Index/manage/staff';
import Stopwatch from '@/pages/Index/manage/stop_watch';
import WardDayFeeReview from '@/pages/Index/manage/ward_day_fee_review';
import SignSelection from '@/pages/Index/sign_selection';
import ApplicationInpHerbRefundPerform from '@/pages/Index/manage/drug/ApplicationInpHerbRefundPerform';

const routes = [
  // 费用审核
  {
    path: '/index/billing-audit',
    component: BillingAudit,
    exact: true,
  },
  // 住院医技科室计价
  {
    path: '/index/inp-medical-technology-charge',
    component: InpMedicalTechnologyCharge,
    exact: true,
  },
  // 医技科室计价费用授权及模板维护
  {
    path: '/index/medical-technology-charge-template',
    component: MedicalTechnologyChargeTemplate,
    exact: true,
  },
  // 窗口字典维护
  {
    path: '/index/window-dictionary-maintenance',
    component: WindowDictionaryMaintenance,
    exact: true,
  },
  // 门急诊医技科室计价
  {
    path: '/index/outp-medical-technology-charge',
    component: OutpMedicalTechnologyCharge,
    exact: true,
  },
  // 住院中药发药
  {
    path: '/index/herb-prescription-dispense',
    component: HerbPrescriptionDispense,
    exact: true,
  },
  // 护士补退费
  {
    path: '/index/supplement-refund-billing',
    exact: true,
    component: SupplementRefundBilling,
  },
  // 药房采购计划(西药)
  {
    path: '/index/drug-storage-purchase-plan-create',
    exact: true,
    component: DrugStoragePurchasePlanCreate,
  },
  // 医嘱校对
  {
    path: '/index/cpoe/order-check',
    exact: true,
    component: OrderCheck,
  },
  // 医嘱执行
  {
    path: '/index/cpoe/order-execute',
    exact: true,
    component: OrderExecute,
  },
  // 病区护士入科
  {
    path: '/index/nurse-admission',
    exact: true,
    component: NurseAdmission,
  },
  // 医生站床卡
  {
    path: '/index/doctor-station-bed-card',
    exact: true,
    component: doctorStationBedCard,
  },
  // 护士站床卡
  {
    path: '/index/bed-card',
    exact: true,
    component: bedCard,
  },
  // 入院准备中心-信息登记
  {
    path: '/index/register-information',
    exact: true,
    component: registerInformation,
  },
  // 患者队列
  {
    path: '/index/cpoe/patient-list',
    exact: true,
    component: PatientList,
  },
  {
    // cpoe 主页
    path: '/index/cpoe/center',
    exact: true,
    component: CenterCpoe,
  },
  {
    // cpoe 概览页
    path: '/index/cpoe/index',
    exact: true,
    component: Index,
  },
  {
    // 首页
    path: '/index/doctor-information',
    exact: true,
    component: DoctorInformation,
  },
  {
    // 首页
    path: '/index/center',
    exact: true,
    component: Center,
  },
  {
    // 系统参数
    path: '/index/system-parameter',
    exact: true,
    component: SystemParameter,
  },
  {
    // 应用参数
    path: '/index/application-parameter',
    exact: true,
    component: ApplicationParameter,
  },
  {
    // 科室设置
    path: '/index/department',
    exact: true,
    component: Department,
  },
  {
    // 医疗机构
    path: '/index/institution',
    exact: true,
    component: Institution,
  },
  {
    // 主诊组
    path: '/index/patient-group',
    exact: true,
    component: PatientGroup,
  },
  {
    path: '/index/staff-signature',
    exact: true,
    component: StaffSignatureList,
  },
  {
    // 功能模块
    path: '/index/function',
    exact: true,
    component: Function,
  },
  {
    // 人员字典
    path: '/index/staff',
    exact: true,
    component: Staff,
  },

  {
    // 标准工作站
    path: '/index/standard-application',
    exact: true,
    component: StandardApplication,
  },
  {
    // 业务工作站
    path: '/index/business-application',
    exact: true,
    component: BusinessApplication,
  },
  {
    // 价表管理
    path: '/index/price',
    component: Price,
    exact: true,
  },
  {
    // 价表管理
    path: '/index/number',
    component: NumberType,
    exact: true,
  },
  {
    //价表复核
    path: '/index/price-review',
    component: Review,
  },
  {
    // 检查项目
    path: '/index/exam-item',
    component: Exam,
  },
  {
    // 病理项目
    path: '/index/pathology-item',
    component: pathologyItem,
  },
  {
    //治疗项目
    path: '/index/treat-item',
    component: Treat,
  },
  {
    //标本字典维护
    path: '/index/specimen',
    component: Specimen,
  },
  {
    //标本字典维护
    path: '/index/lab-item',
    component: LabItem,
  },
  {
    //临床诊疗项目维护
    path: '/index/clinic-item',
    component: ClinicItem,
  },
  {
    //临床诊疗项目维护
    path: '/index/price-pattern',
    component: PricePattern,
  },
  {
    // 号类维护证件分类
    path: '/index/number-protect',
    component: TypeProtect,
    exact: true,
  },
  {
    // 号类维护证件分类
    path: '/index/number-type',
    component: ClinicRegisterSchedule,
    exact: true,
  },
  {
    // 号类维护证件分类
    path: '/index/sign-selection',
    component: SignSelection,
    exact: true,
  },
  {
    // 厂家字典维护
    path: '/index/firmManage-firm',
    component: Firm,
    exact: true,
  },
  {
    // 供应商字典维护
    path: '/index/firmManage-supplier',
    component: SupplierTable,
    exact: true,
  },
  {
    // 药品字典维护
    path: '/index/firmManage-drug',
    component: DrugDictionary,
    exact: true,
  },

  {
    // 药品盘点分类字典
    path: '/index/drugInventoryManage-stocktaking',
    component: DrugStocktaking,
    exact: true,
  },

  {
    // 药品库存定义
    path: '/index/drugInventoryManage-profile',
    component: DrugStorageProfile,
    exact: true,
  },
  {
    // 药品库存拆分
    path: '/index/drugInventoryManage-split',
    component: DrugStockSplit,
    exact: true,
  },
  {
    // 药品供应标识维护
    path: '/index/drugInventoryManage-supply',
    component: DrugStockSupplyManage,
    exact: true,
  },
  {
    // 药品规格管理
    path: '/index/drugInventoryManage-specification',
    component: DrugSpecificationStorage,
    exact: true,
  },
  {
    // 子库房维护
    path: '/index/drugStorage-drugSubStorageManage',
    component: DrugSubStorageManage,
    exact: true,
  },
  {
    // 门诊挂号
    path: '/index/outpatient-registration',
    component: registration,
    exact: true,
  },
  {
    // 门诊预约挂号
    path: '/index/appointment-registration',
    component: Appointmentregistration,
    exact: true,
  },
  {
    // 门诊退号
    path: '/index/utpatient-withdrawal',
    component: backIngNumber,
    exact: true,
  },
  {
    // 托收挂号
    path: '/index/registrationForCollection',
    component: RegistrationForCollection,
    exact: true,
  },
  {
    // 门急诊检查检验回退
    path: '/index/Check-check-rollback',
    component: Check_check_rollback,
    exact: true,
  },
  //药库药房关系对照
  {
    path: '/index/maintenance-relationship',
    component: DrugMaintenance,
    exact: true,
  },
  {
    // 药品入库管理
    path: '/index/drug-import',
    component: DrugImport,
    exact: true,
  },
  {
    // 药品出库管理
    path: '/index/drug-export',
    component: DrugExport,
  },
  //药房出入库类型字典配置
  {
    path: '/index/storage-dictionary',
    component: StorageDictionary,
    exact: true,
  },
  //药房出入库自定义关系配置
  {
    path: '/index/pharmacy-custom',
    component: PharmacyCustom,
    exact: true,
  },
  {
    path: '/index/patient-main-add',
    component: PatientMain,
    exact: true,
  },
  {
    path: '/index/patient-main-search',
    component: PatientMainSearch,
    exact: true,
  },
  // 主索引合并
  {
    path: '/index/patient-main-merge',
    component: PatientMainMerge,
    exact: true,
  },
  // 主索引合并日志
  {
    path: '/index/patient-main-merge-log',
    component: PatientMainMergeLog,
    exact: true,
  },
  // 主索引修改日志
  {
    path: '/index/patient-main-update-log',
    component: PatientMainUpdateLog,
    exact: true,
  },
  {
    path: '/index/cpoe/medical-order-template',
    component: Defend,
    exact: true,
  },
  {
    path: '/index/cpoe/medical-order-agreed-party-package',
    component: AgreedPartPackage,
    exact: true,
  },
  {
    path: '/index/cpoe/medical-order-common',
    component: CommonMedicines,
    exact: true,
  },
  //取整规则配置
  {
    path: '/index/integer-rule',
    component: Integer,
    exact: true,
  },
  //西药房发药
  {
    path: '/index/belt-protection',
    component: Belt,
    exact: true,
  },
  // 药品库存申请
  {
    path: '/index/drug-apply',
    component: DrugApply,
    exact: true,
  },
  {
    path: '/index/dept-drug-apply',
    component: DeptDrugApply,
    exact: true,
  },
  {
    path: '/index/dispenses-medicine',
    component: Dispenses,
    exact: true,
  },
  // 中药房发药
  {
    path: '/index/centre-dispenses-medicine',
    component: CentreMedicineDispensing,
    exact: true,
  },
  //门急诊收费
  {
    path: '/index/emergency-charge',
    component: Charge,
    exact: true,
  },

  //门诊预交金
  {
    path: '/index/avance-payment',
    component: AvancePayment,
    exact: true,
  },
  //码表字典
  {
    path: '/index/stopwatch',
    component: Stopwatch,
    exact: true,
  },
  {
    path: '/index/code-table-type',
    component: CodeTableType,
    exact: true,
  },
  //住院医生站转科
  {
    path: '/index/doctor-station-transfer',
    component: DoctorStationTransfer,
    exact: true,
  },
  // 住院医生站出院
  {
    path: '/index/doctor-station-out-hospital',
    component: DoctorStationOutHospital,
    exact: true,
  },
  {
    // 医学证明
    path: '/index/medical-certificate',
    component: MedicalCertificate,
    exact: true,
  },
  // 病房护士站转科
  {
    path: '/index/nurse-station-transfer',
    component: nurseStationTransfer,
    exact: true,
  },
  // 病房护士站出院
  {
    path: '/index/nurse-station-out-hospital',
    component: NurseStationOutHospital,
    exact: true,
  },
  // 取消入出转
  {
    path: '/index/nurse-cancel-operation',
    component: NurseCancelOperation,
    exact: true,
  },
  // 病房护士站床头卡打印
  {
    path: '/index/nurse-station-bedside-card-printing',
    component: NurseStationBedsideCardPrinting,
    exact: true,
  },
  //门急诊退费
  {
    path: '/index/outpatient-refunds',
    component: OutpatientRefunds,
    exact: true,
  },
  //门急诊收费
  {
    path: '/index/receipt-inquiry',
    component: ReceiptInquiry,
    exact: true,
  },
  {
    path: '/index/cpoe/medical-orders-show',
    component: MedicalOrdersShow,
    exact: true,
  },
  {
    path: '/index/cpoe/medical-order-execute',
    component: MedicalOrderExecute,
    exact: true,
  },
  // 门诊退药申请（西药）
  {
    path: '/index/refund-drug-apply',
    component: RefundDrugApply,
  },
  {
    path: '/index/cpoe/discharge-medication-execution',
    component: DischargeMedicationExecution,
    exact: true,
  },
  //日结算
  {
    path: '/index/day-fee-review',
    component: WardDayFeeReview,
    exact: true,
  },
  //西药发药确认
  {
    path: '/index/drug-confirmation',
    component: DragConfirmation,
    exact: true,
  },
  //医嘱执行单
  {
    path: '/index/cpoe/system-execution-order-maintenance',
    component: Deploy,
    exact: true,
  },
  //中药发药确认
  {
    path: '/index/drug-confirmation-chinese',
    component: ChineseConfim,
    exact: true,
  },
  //中药发药确认
  {
    path: '/index/drug-reception',
    component: DrugReceiveAndCheckList,
    exact: true,
  },
  {
    path: '/index/review-of-inpatient-medication-orders',
    component: ReviewOfInpatientMedicationOrders,
    exact: true,
  },
  // 精毒特殊药品执行等级
  {
    path: '/index/execution-registration-of-special-drugs-for-refined-and-toxic-substances',
    component: ExecutionRegistrationOfSpecialDrugsForRefinedAndToxicSubstances,
    exact: true,
  },
  //住院处方发药
  {
    path: '/index/inpatient-prescription-dispensing',
    component: InpatientPrescriptionDispensing,
    exact: true,
  },
  //出院带药发药
  {
    path: '/index/leave-hospital-medicine',
    component: LeaveHospitalMedicine,
    exact: true,
  },
  {
    path: '/index/medication-prescription-for-inpatient-medication-placement',
    component: DrugBeInHospitalDispensing,
  },
  //住院结算
  {
    path: '/index/hospitalization-settlement',
    component: hospitalizationSettlement,
    exact: true,
  },
  //住院结算
  {
    path: '/index/cancel-hospitalization-settlement',
    component: cancelHospitalizationSettlement,
    exact: true,
  },
  {
    path: '/index/prescription-return-medication-drug',
    component: PrescriptionReturnMedicationDrug,
    exact: true,
  },
  {
    path: '/index/prescription-return-medication-herb',
    component: PrescriptionReturnMedicationHerb,
    exact: true,
  },

  //
  // 主诉维护
  {
    path: '/index/maintenance-complaint',
    component: MaintenanceComplaint,
    exact: true,
  },
  {
    // 病区西药退药申请
    path: '/index/application-for-western-medicine-refund-in-ward',
    component: ApplicationForWesternMedicineRefundInWard,
    exact: true,
  },
  // 病区草药退药申请
  {
    path: '/index/application-for-returning-herbal-medicine-in-the-ward',
    component: ApplicationForReturningHerbalMedicineInTheWard,
    exact: true,
  },
  //调价审核

  {
    path: '/index/drug-priceAdjust',
    component: DrugPriceAdjust,
    exact: true,
  },
  // 预检分诊
  {
    path: '/index/preview-triage',
    component: PreviewTriage,
    exact: true,
  },
  // 突发事件批量分诊
  {
    path: '/index/batch_triage',
    component: BatchTriage,
    exact: true,
  },
  // 急诊患者接收
  {
    path: '/index/patient_administrantion',
    component: PatientAdministrantion,
    exact: true,
  },
  // 急诊患者转出
  {
    path: '/index/patient_transfer',
    component: PatientTransfer,
    exact: true,
  },
  //手术字典
  {
    path: '/index/operation_dictionary',
    component: OperationDictionary,
    exact: true,
  },
  // 床位管理-床位等级价表对照
  {
    path: '/index/bed_level',
    component: BedLevel,
    exact: true,
  },
  // 床位管理-全院床位维护

  {
    path: '/index/bed_maintenance',
    component: BedMaintenance,
    exact: true,
  },
  // 住院费用结算-日清单打印
  {
    path: '/index/list_print',
    component: ListPrint,
    exact: true,
  },
  // 住院费用结算-住院结算记录查询

  {
    path: '/index/in_settlement_record_enquiry',
    component: InSettlementRecordEnquiry,
    exact: true,
  },
  // 西药退药确认（病区药房）
  {
    path: '/index/inp_drug_refund_perform',
    component: InpDrugRefundPerform,
    exact: true,
  },
  // 特殊收费方案维护-收费方案维护
  {
    path: '/index/special-charge-scheme/maintenance_charge_scheme',
    component: SpecialChargeScheme,
    exact: true,
  },
  // 特殊收费方案维护-收费方案项目维护
  {
    path: '/index/special-charge-scheme/maintenance_charge_project_scheme',
    component: SpecialChargeProjectScheme,
    exact: true,
  },
  // 特殊收费方案维护-收费方案名单维护
  {
    path: '/index/special-charge-scheme/maintenance_charge_list_scheme',
    component: SpecialChargeListScheme,
    exact: true,
  },
  // 病区草药退药确认
  {
    path: '/index/application-inpHerb-refund-perform',
    component: ApplicationInpHerbRefundPerform,
    exact: true,
  },
];

export default routes;
