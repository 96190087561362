import React, { useState } from 'react';
import CertificateTitle from './component/certificateTitle';
import CertificateContent from './component/certificateContent';
import CertificateFooter from './component/certificateFooter';
import { useGetResetInitState } from '@/pages/Index/components/func/hooks';
import { Button, message, Modal } from '@vs/vsf-kit';
import CertificateModel from './component/certificateModel';

import '@/style/adaptation.less';
import './index.less';

let data = [
  {
    id: 1,
    name: '内容1',
  },
  {
    id: 2,
    name: '内容2',
  },
  {
    id: 3,
    name: '内容3',
  },
  {
    id: 4,
    name: '内容4',
  },
  {
    id: 5,
    name: '内容5',
  },
  {
    id: 6,
    name: '内容6',
  },
  {
    id: 7,
    name: '内容6',
  },
  {
    id: 8,
    name: '内容6',
  },
  {
    id: 9,
    name: '内容6',
  },
  {
    id: 10,
    name: '内容6',
  },
  {
    id: 11,
    name: '内容6',
  },
  {
    id: 12,
    name: '内容6',
  },
  {
    id: 13,
    name: '内容6',
  },
  {
    id: 14,
    name: '内容6',
  },
  {
    id: 15,
    name: '内容6',
  },
  {
    id: 16,
    name: '内容6',
  },
  {
    id: 17,
    name: '内容6',
  },
];

export default function index() {
  const [active, setActive] = useState();
  const [timer, setTimer] = useState();

  const [dataStore, , getDataStore, , , setDataStore] = useGetResetInitState({
    initialState: [],
    data: [],
    id: {
      stopIndicator: true,
      auditIndicator: true,
    },
    dataSource: [],
  });
  const [certificate, setCertificate] = useState();
  const [medicalFlag, setMedicalFlag] = useState(false);

  // 查询
  const inquire = async () => {
    const inpValue = vsf?.refs?.listPrintForm?.getFieldValue();
    console.log(inpValue, '查询条件');
    // if (!!inpValue?.orderDateRangeIn) {
    //   if (!!inpValue?.patientBed) {
    //     const res =
    //       await vsf.services?.MedicalCertificationRecordController_getClinicRecordWithCertification_1b3b3c?.(
    //         { qto: qtoParams },
    //       );
    //     getDataStore(data);
    //   } else {
    //     message.error('请选择就诊科室');
    //   }
    // } else {
    //   message.error('请选择就诊时间');
    // }
  };

  // 新增
  const add = async () => {
    await vsf?.services?.MedicalCertificationRecordController_getClinicRecordWithCertification_1b3b3c?.(
      {
        qto: {
          from: params?.pagination?.from ?? 0,
          size: params?.pagination?.size ?? 20,
          ...params?.search,
          ...params?.extra,
          ...(params ?? {}),
        },
      },
    );
    vsf?.refs?.medicalCertificate?.setFieldsValue(dataStore.id);
    console.log(dataStore.id, '选中');
    setMedicalFlag(true);
  };
  // 打印
  const print = () => {
    // console.log('打印');
    message.info('todo');
  };
  // 关闭
  const cancel = () => {
    vsf.closeModal();
  };

  // 一键复制
  const onCopy = () => {
    console.log('一键复制');
  };

  // 弹框确认
  const onOk = async () => {
    // setMedicalFlag(false);
    vsf?.refs?.medicalCertificate?.setFieldsValue(dataStore.id);
    await vsf?.services?.MedicalCertificationRecordController_createCertificationRecord_17d142?.(
      { btoParam: params },
    );
  };
  // 弹框取消
  const onCancel = () => {
    setMedicalFlag(false);
  };

  return (
    <div className="certificate">
      <CertificateTitle
        timer={timer}
        setTimer={setTimer}
        inquire={inquire}
        setCertificate={setCertificate}
      />
      <CertificateContent
        onChange={(value) => {
          setActive(value);
        }}
        dataStore={dataStore}
        setDataStore={setDataStore}
      />
      <CertificateFooter cancel={cancel} print={print} add={add} />
      <Modal
        id="modal"
        open={medicalFlag}
        title="医学证明"
        className="width1100"
        footer={
          <div>
            <Button type="primary" onClick={onCopy}>
              一键复制
            </Button>
            <Button type="primary" onClick={onOk}>
              确定
            </Button>
            <Button onClick={onCancel}>取消</Button>
          </div>
        }
        defaultOpen={false}
      >
        <CertificateModel />
      </Modal>
    </div>
  );
}
