import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Modal,
  Section,
  Slider,
  Space,
  Tabs,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { confirm, openModal } from '../details/calculate';
const Index = (props, ref) => {
  const [open, setOpen] = useState(false);
  const handelData = () => {
    try {
      const res = vsf.stores.dbenums.enums.PRE_EXAM_ITEM_DICT?.filter((i) => {
        // 过滤preExamScoreTypeCode为01，且未停用

        return (
          i?.extensionJson &&
          JSON.parse(i?.extensionJson)?.preExamScoreTypeCode == '01' &&
          !i?.deprecated
        );
      })?.map((item) => {
        return {
          ...item,
          children:
            item?.children?.length > 0
              ? item?.children
                  ?.filter((childrenItem) => {
                    // 过滤未停用
                    return !childrenItem?.deprecated;
                  })
                  ?.map((childrenItem) => {
                    //   拼接分数
                    return {
                      ...childrenItem,
                      itemScroe: childrenItem?.extensionJson
                        ? JSON.parse(childrenItem?.extensionJson)?.score
                        : null,
                      label: childrenItem?.extensionJson
                        ? JSON.parse(childrenItem?.extensionJson)?.score +
                          '\u00A0' +
                          '\u00A0' +
                          childrenItem?.label
                        : childrenItem?.label,
                      labelName: item?.pinyin + item?.value,
                    };
                  })
                  .sort((a: any, b: any) => {
                    return b?.itemScroe - a?.itemScroe;
                  })
              : [],
        };
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (open) {
      openModal(
        vsf.refs?.preExamMainForm.getFieldsValue(),
        (combinedObject) => {
          vsf.refs?.gcsModal?.setFieldsValue({
            ...combinedObject,
          });
        },
      );
    }
  }, [open]);

  const handelOpen = (v = false, value?) => {
    setOpen(v);
  };

  useImperativeHandle(ref, () => ({
    handelOpen,
  }));

  return (
    <div>
      <Modal
        centered
        width={600}
        bodyStyle={{
          maxHeight: 720,
          overflowY: 'scroll',
        }}
        open={open}
        okText="确认 "
        cancelText="取消 "
        title={<div style={{ color: '#3276E8' }}>GCS评分</div>}
        onCancel={() => {
          handelOpen(false);
        }}
        footer={
          <div>
            <Button
              type="primary"
              ghost
              onClick={() => {
                handelOpen(false);
              }}
            >
              取消
            </Button>
            <Button
              type="primary"
              onClick={() => {
                handelData()?.forEach((i, index) => {
                  vsf.refs.gcsModal.setFieldsValue({
                    [i?.code]: i?.children?.[0],
                  });
                });
              }}
            >
              正常
            </Button>
            <Button
              type="primary"
              onClick={() => {
                const formValue = vsf.refs?.gcsModal?.getFieldsValue();
                const _data = handelData()
                  ?.map((i) => {
                    return i?.children;
                  })
                  .flat();

                confirm(
                  formValue,
                  vsf.refs?.preExamMainForm?.getFieldsValue()
                    ?.erpPreExamRecordDetailWithVitalsignDictionaryList,
                  (
                    res,
                    tempErpPreExamRecordDetailWithVitalsignDictionaryList,
                  ) => {
                    vsf.refs?.preExamMainForm?.setFieldsValue({
                      gcsScore: res,
                      erpPreExamRecordDetailWithVitalsignDictionaryList:
                        tempErpPreExamRecordDetailWithVitalsignDictionaryList,
                    });
                    handelOpen(false);
                  },
                  _data,
                );
              }}
            >
              确定
            </Button>
          </div>
        }
      >
        <VSForm id="gcsModal" labelAlign="left" colon={false}>
          <VSFormLayout columnCount={1} labelWidth={100}>
            {handelData()?.map((item, index) => {
              return (
                <VSFormItem
                  key={index}
                  name={[item?.code]}
                  valueType="radio"
                  label={
                    <div className="fall_risk_form_label icon18">
                      {item?.label}
                    </div>
                  }
                  fieldNames={{
                    label: 'label',
                    value: 'value',
                  }}
                  fieldProps={{
                    style: { display: 'flex', flexDirection: 'column' },
                  }}
                  initialValue={item?.children?.[0]}
                  dataSource={item?.children ?? []}
                  dataSourceRender={(dom, data) => {
                    return (
                      <div
                        className="marginBottom14 icon18"
                        style={{
                          display: 'flex',
                          color:
                            item?.children?.[0]?.code == data?.code
                              ? 'red'
                              : '',
                        }}
                      >
                        {dom}
                      </div>
                    );
                  }}
                />
              );
            })}
          </VSFormLayout>
        </VSForm>
      </Modal>
    </div>
  );
};
export default forwardRef(Index);
// GCS code=19 vsf.stores.dbenums.enums.PRE_EXAM_ITEM_DICT
