import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Divider,
  Select,
  VSForm,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { useCallback, useRef } from 'react';

import { isMinScreen } from '@/config';
import { Flex } from '@/pages/Index/components';
import { useOnChangeValue } from '@/pages/Index/components/func/hooks';
import { getScrollY } from '@/utils';

const onSpliced = (input) => {
  const _ = window._ ?? {};
  const output: any = [];
  output.push(
    ...(_.get(input, 'data.result')?.map((d, i) => {
      const obj: any = {};
      obj.id = _.get(d, 'id');
      obj.inpVisitId = _.get(d, 'inpVisitId');
      obj.bedLabel = _.get(d, 'bed.bedLabel');
      obj.name = _.get(d, 'patient.name');
      obj.displayId = _.get(d, 'patient.displayId');
      obj.haveRefundApply = _.get(d, 'haveRefundApply');
      obj.haveStopOrderPlan = _.get(d, 'haveStopOrderPlan');
      return obj;
    }) ?? []),
  );
  return output;
};

const Search = (props) => {
  const [search] = useOnChangeValue('', (value: any) => {
    patient.current = value?.id;
    return value?.id;
  });
  const [select] = useOnChangeValue<any>({});
  const table = useRef<any>(null);
  const patient = useRef('');
  const onFetch = useCallback(async () => {
    const res =
      await vsf?.services?.PatientInHosptialController_queryPagedWardPatientList_e6e95f?.(
        {
          qto: {
            // from: params?.pagination?.from ?? 0,
            // size: params?.pagination?.size ?? 20,
            patientId: patient?.current,
          },
        },
      );
    return {
      data: onSpliced?.(res),
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Flex vertical>
      <Flex gap={10}>
        <Flex flex={1} gap={10} align="center">
          <span>患者</span>
          <Flex flex={1}>
            <Select
              width="100%"
              showSearch
              {...search}
              allowClear
              dataSource={async (value) => {
                const res =
                  await vsf?.services?.PatientInHosptialController_queryScrollWardPatientList_346269?.(
                    {
                      qto: {
                        ...value,
                      },
                    },
                  );
                return (res?.data?.result ?? res?.data ?? [])?.map((item) => ({
                  ...item,
                  id: item?.patient?.id,
                  name: item?.bed?.bedLabel + '-' + item?.patient?.name,
                }));
              }}
              fieldNames={{ label: 'name', value: 'id' }}
              searchKey="searchText"
            />
          </Flex>
        </Flex>
        <Button
          type="primary"
          onClick={() => {
            table?.current?.reload();
          }}
        >
          查询
        </Button>
      </Flex>
      <Divider
        style={{
          background: 'var(--blue)',
        }}
      />
      <VSPromiseTable
        onFetch={onFetch}
        className="aspirin-table-no-background-box"
        rowClassName={(record) => {
          return record?.id === select?.value?.id
            ? 'aspirin-row-selected-box'
            : 'aspirin-row-select-box';
        }}
        pagination={false}
        scroll={{
          x: 328,
          y: isMinScreen ? 400 : 550,
        }}
        onRow={(record) => ({
          onClick: () => {
            if (record?.id === select?.value?.id) {
              select?.onChange?.();
              props?.onChange?.();
              return;
            }
            select?.onChange?.(record);
            props?.onChange?.(record);
          },
        })}
        ref={table}
      >
        <VSTableColumn
          width={86}
          dataIndex={['bedLabel']}
          title="床号"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          width={126}
          dataIndex={['name']}
          title="姓名"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          // width={106}
          dataIndex={['displayId']}
          title="患者ID"
          valueType="text"
          fieldProps={{}}
        />
      </VSPromiseTable>
    </Flex>
  );
};

export default definePage(Search);
