import vsf from '@vs/vsf-boot';
import {
  Col,
  compose,
  Form,
  InputProps,
  Row,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import { useGetState, useRequest } from 'ahooks';
import { Table } from 'antd';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import Loading from '@/module/Loading';

import ComplexSelect from '../complex_select';
import { useInputEnter } from '../dosage/utils';

/**
 * DrugSelect
 */
const DrugSelect = forwardRef((props: any, ref) => {
  const {
    value,
    onFieldEnter,
    onKeyUp,
    onChange,
    record,
    subStorageId,
    ...rest
  } = props;
  const fieldEnterProps = useInputEnter({ onFieldEnter, onKeyUp });
  console.log(record);
  const form = Form?.useFormInstance();
  const key = record?.id;

  /**
   * 获取已选批号，进行过滤处理
   */
  const otherSelect =
    vsf?.refs?.ExportRecord?.getFieldValue('drugExportDetailBtoList')?.map(
      (item) => item?.stockId,
    ) ?? [];
  const {
    data: dataSource,
    loading,
    run: getDataSource,
  } = useRequest(
    async (inputCode) => {
      const res =
        await vsf.services?.DrugStorageController_queryStockForExportByName_513ee9(
          {
            inputCode,
            subStorageId,
          },
        );
      return res.data
        ?.filter((item) => {
          for (const stock of item?.stockList) {
            if (!otherSelect.includes(stock?.id)) {
              return !otherSelect.includes(stock?.id);
            } else {
              continue;
            }
          }
        })
        ?.map((item, index) => {
          return {
            ...item,
            key: index,
            drugSpecification: item?.specification,
            packageUnit: item.unit,
            stockList: item?.stockList?.filter(
              (item) => !otherSelect?.includes(item?.id),
            ),
            firm: {
              id: item.firmId,
              firmName: item.firmName,
            },
          };
        });
    },
    {
      manual: true,
      debounceWait: 500,
    },
  );

  const columns = [
    {
      title: '药品名称',
      dataIndex: ['productName'],
      key: 'product_name',
    },
    {
      title: '包装规格',
      dataIndex: ['packageSpecification'],
      key: 'package_specification',
    },
    {
      title: '生产厂家',
      dataIndex: ['firmName'],
      key: 'firm_name',
    },
  ];

  const table = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    ...table?.current,
  }));

  return (
    <ComplexSelect
      hideSelectIcon
      mode="radio"
      showLabel="productName"
      value={value}
      ref={table}
      onChange={(value) => {
        form?.resetFields([
          [key, 'retailPrice'],
          [key, 'expirationDate'],
          [key, 'batchNumber'],
          [key, 'purchasePrice'],
          [key, 'amountPerPackage'],
          [key, 'drugStockLocation'],
          [key, 'amount'],
          [key, 'purchaseAmount'],
          [key, 'stockId'],
        ]);
        form.setFieldValue(key, {
          ...form?.getFieldValue(key),
          ...value,
        });
        if (value?.stockList?.length) {
          form.setFieldValue(
            [key, 'packageUnit'],
            value?.stockList?.at(-1)?.packageUnit,
          );
        }
        onChange({
          label: value.productName,
          value: value.productId,
        });
      }}
      onSearch={(inputCode) => {
        if (inputCode?.trim()) {
          getDataSource(inputCode);
        }
      }}
      dropdownMatchSelectWidth={false}
      dropdownStyle={{
        width: '46.66rem',
      }}
      listHeight={1000}
      {...rest}
      {...fieldEnterProps}
      placeholder="请选择药品"
    >
      <Table
        rowClassName="aspirin-table-row-box"
        columns={columns}
        pagination={false}
        size="middle"
        loading={{
          indicator: (
            <Row
              justify="center"
              style={{
                left: '40%',
              }}
            >
              <Col span={24}>
                <Loading />
              </Col>
            </Row>
          ),
          spinning: loading,
        }}
        scroll={{
          y: '26.66rem',
        }}
        dataSource={dataSource}
      />
    </ComplexSelect>
  );
});

DrugSelect.displayName = 'drugSelect';

export default compose(
  withRef(),
  withField<any>({
    name: 'drugSelect',
    handleEnter: true,
  }),
  withPreview<InputProps & { value: { unit: string; value: string | number } }>(
    {
      renderPreview: (props) => {
        const { value } = props;
        return <>{value}</>;
      },
    },
  ),
)(DrugSelect) as typeof DrugSelect;
