import {
  Button,
  Column,
  Columns,
  message,
  Modal,
  Popover,
  RadioGroup,
  Section,
  Select,
  Tag,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
  VSForm,
  Input,
} from '@vs/vsf-kit';
import cn from 'classnames';
import dayjs from 'dayjs';

import Loading from '@/module/Loading';
import vsf, { definePage } from '@vs/vsf-boot';

import './index.less';

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import IconCpoe from '@/module/cpoe/Icon';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import Block from '@/pages/Index/components/block_no_padding';
import { getScrollY, labelColWidth } from '@/utils';
import storage from '@/utils/storage';
import HeaderInfo from '@/pages/Index/components/head_info';
import {
  convertGetDischargePatientInfoVoByInpVisitIdRes2DischargePatientInfoForm,
  convertGetWaitDischargePatientVoListByWardIdRes2WaitDischargePatientList,
  convertDischargePatientInfoForm2DischargeNoticeSettleReq,
} from './convert';
import ModalDetail from './modalDetail';

const Index = (props) => {
  const { routes } = props;
  const { common } = props?.stores;
  const { currentApplication } = common;
  const [loading, setLoading] = useState(false);
  // 当前选中患者
  const [currentInpVisitId, setCurrentInpVisitId] = useState();
  // 当前选中info
  const [currentInpInfo, setCurrentInpVisitInfo] = useState();
  // 弹窗内容
  const [modalContent, setModalContent] = useState();
  const [modalProps, setModalProps] = useState();
  //  通知结算成功
  const [initkey, setInitkey] = useState(0);

  const modalRef = useRef();

  useEffect(() => {
    if (!currentInpVisitId) return setLoading(false);
    getDischargePatientInfoVoByInpVisitId();
  }, [currentInpVisitId]);
  // routes?.query?.inpVisitId
  // 列表
  const getListWaitInpPreTransferList = useCallback(async () => {
    // setCurrentInpVisitId(routes?.query?.inpVisitId);
    const res =
      await vsf.services?.ClinicInpVisitController_getWaitDischargePatientVoListByWardId_d7e976?.(
        {
          wardId: currentApplication?.department?.id,
        },
      );
    const _res =
      convertGetWaitDischargePatientVoListByWardIdRes2WaitDischargePatientList(
        res,
      );
    const _todyOut = _res?.filter((item) => {
      return item?.preDischargeStatus === 'PRE_CHARGE_TODAY';
    });
    const _out = _res?.filter((item) => {
      return item?.preDischargeStatus === 'PRE_DISCHARGE';
    });
    const _other = _res?.filter((item) => {
      return !item?.preDischargeStatus;
    });
    const _data = [..._todyOut, ..._out, ..._other];

    if (_res?.length > 0) {
      // 床卡跳转
      if (routes?.query?.inpVisitId) {
        const res = _data?.find((item) => {
          return item?.inpVisitId == routes?.query?.inpVisitId;
        });
        setCurrentInpVisitId(routes?.query?.inpVisitId);
        setCurrentInpVisitInfo(res);
      } else {
        setCurrentInpVisitId(_data?.[0]?.id);
        setCurrentInpVisitInfo(_data?.[0]);
      }
    }

    return {
      data: _data,
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, [initkey, currentApplication?.department?.id, routes?.query?.inpVisitId]);
  // form
  const getDischargePatientInfoVoByInpVisitId = async () => {
    // setLoading(true);
    const res =
      await vsf.services?.ClinicInpVisitController_getDischargePatientInfoVoByInpVisitId_504d06?.(
        {
          inpVisitId: currentInpVisitId,
        },
      );
    const _response =
      convertGetDischargePatientInfoVoByInpVisitIdRes2DischargePatientInfoForm(
        res,
      );
    // setLoading(false);
    vsf?.refs?.vSFormBedPatientInpDetail?.setFieldsValue(_response);
  };
  // 保存
  const save = async () => {
    // console.log(vsf?.refs?.vSFormBedPatientInpDetail?.getFieldsValue());

    const res =
      await vsf.services?.ClinicInpAdtController_dischargeNoticeSettle_d9b5af?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          ...convertDischargePatientInfoForm2DischargeNoticeSettleReq(
            vsf?.refs?.vSFormBedPatientInpDetail?.getFieldsValue(),
          ),
        },
      );
    if (res?.code == 200) {
      setCurrentInpVisitId(null);
      setCurrentInpVisitInfo(null);
      setInitkey(initkey + 1);
      getListWaitInpPreTransferList();
      message.success('通知结算成功');
    }
  };
  // 清屏
  const handelClear = () => {
    getDischargePatientInfoVoByInpVisitId();
  };
  // 责任护士/质控护士
  const getPagedByUserStaffQto = async (v) => {
    console.log(v);
    const searchParams = {};
    // searchParams.staffDepartmentIdIs = detail?.departmentId;
    // searchParams.jobClassCodeIs = 'A2'; // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.UserStaffController_getPagedByUserStaffQto_c82b82?.({
        // TODO: 请检查以下这部分传参是否符合需求
        qto: {
          from: 0,
          size: 200,
          jobClassCodeIs: 'A2',
          // ...v,
          // inputCodeIs: v?.staffName,
          ...v,
        },
      }); // TODO: 你可能需要整理返回数据格式

    return res?.data?.result;
  };
  // 未计费医嘱一览
  const unbilledMedicalOrdersGlanceProps = {
    title: '未计费医嘱一览',
    width: 1142,
    bodyStyle: { minHeight: 400, maxHeight: 600, overflowY: 'scroll' },
  };
  const renderUnbilledMedicalOrdersGlance = () => {
    return <div>未计费医嘱一览</div>;
  };
  // 未执行检查一览
  const notPerformExamProps = {
    title: '未执行检查一览',
    width: 1242,
    bodyStyle: { minHeight: 400, maxHeight: 600, overflowY: 'scroll' },
  };
  const notPerformExam = () => {
    return <div>未执行检查一览</div>;
  };
  const renderIcon1 = (v) => {
    const value = vsf?.refs?.vSFormBedPatientInpDetail?.getFieldValue(v);
    const handelClick = () => {
      switch (v) {
        // case 'notChargeLab':
        //   return {
        //     renderContent: renderUnbilledMedicalOrdersGlance,
        //     renderProps: unbilledMedicalOrdersGlanceProps,
        //   };
        // 未执行检查
        case 'notPerformExam':
          return {
            renderContent: notPerformExam,
            renderProps: notPerformExamProps,
          };
        default:
          return '';
      }
    };
    return (
      value &&
      value > 0 && (
        <Icon
          onClick={() => {
            const res = handelClick();
            setModalContent(res?.renderContent);
            setModalProps(res?.renderProps);
            modalRef?.current?.handeOpen(true);
          }}
          style={{ cursor: 'pointer' }}
          type="icon-a-jingshihong"
        />
      )
    );
  };
  const renderIcon2 = (v) => {
    const value = vsf?.refs?.vSFormBedPatientInpDetail?.getFieldValue(v);

    return (
      value &&
      value > 0 && (
        <Icon style={{ cursor: 'pointer' }} type="icon-a-gaoweicheng" />
      )
    );
  };

  // console.log(currentInpInfo?.preDischargeStatus, '7412_currentInpInfo');

  return (
    <div className="nurse_station_out_hospital">
      <div className="nurse_station_out_hospital_table">
        <div className="nurse_station_out_hospital_detail_content_form_title">
          <div className="nurse_station_out_hospital_detail_content_form_title_tag"></div>
          患者信息
        </div>
        <div className="nurse_station_out_hospital_table_content">
          <VSPromiseTable
            vsid="90797"
            id="waitInpPreTransferApplyList"
            className="table_overflow_278
            aspirin-table-header-background-color
           aspirin-table-row-hover-background-color
           aspirin-table-nopadding
           aspirin-table-body-empty-transparent-background-color
           "
            rowClassName={(v) => {
              return v?.id == currentInpVisitId
                ? 'aspirin-table-row-select-background-color'
                : '';
            }}
            pagination={false}
            onFetch={getListWaitInpPreTransferList}
            scroll={{ y: 0 }}
            onRow={(record) => {
              return {
                onClick: () => {
                  setCurrentInpVisitId(record?.id);
                  setCurrentInpVisitInfo(record);
                },
              };
            }}
          >
            <VSTableColumn
              dataIndex={['preDischargeStatus']}
              title=""
              valueType="select"
              dataSource={[
                { label: '待出院', value: 'PRE_DISCHARGE' },
                { label: '今日待出', value: 'PRE_CHARGE_TODAY' },
              ]}
              fieldProps={{}}
              render={(dom, value) => {
                return value?.preDischargeStatus ? (
                  <div
                    style={{
                      textAlign: 'center',
                      lineHeight: '24px',
                      width:
                        value?.preDischargeStatus === 'PRE_DISCHARGE' ? 58 : 76,
                      height: 24,
                      color: '#3276E8',
                      background: '#d4e6f9',
                      borderRadius: '2px',
                    }}
                  >
                    {dom}
                  </div>
                ) : (
                  ''
                );
              }}
              width={80}
            />
            <VSTableColumn
              dataIndex={['bedLabel']}
              title="床号"
              valueType="text"
              fieldProps={{}}
              ellipsis
            />
            <VSTableColumn
              dataIndex={['name']}
              title="姓名"
              valueType="text"
              ellipsis
              formItemProps={{
                rules: [
                  {
                    message: '姓名长度不合法',
                    type: 'string',
                    min: 0,
                    max: 60,
                  },
                ],
              }}
              fieldProps={{}}
            />

            <VSTableColumn
              dataIndex={['displayId']}
              title="患者ID"
              valueType="text"
              ellipsis
              fieldProps={{}}
            />
          </VSPromiseTable>
        </div>
      </div>
      {
        //   loading ? (
        //   <div className="nurse_station_out_hospital_detail">
        //     <Loading />
        //   </div>
        // ) :
        currentInpVisitId ? (
          <div className="nurse_station_out_hospital_detail">
            {/* <div className="nurse_station_out_hospital_detail_search">
          <div className="nurse_station_out_hospital_detail_search_item">
            <span className="nurse_station_out_hospital_detail_search_item_label">
              床号
            </span>
            <div className="nurse_station_out_hospital_detail_search_item_input">
              <Select width={240} showSearch={true} />
            </div>
          </div>
          <div className="nurse_station_out_hospital_detail_search_item">
            <span className="nurse_station_out_hospital_detail_search_item_label">
              患者ID
            </span>
            <div className="nurse_station_out_hospital_detail_search_item_input">
              <Input width={240} />
            </div>
          </div>
        </div> */}
            <div className="nurse_station_out_hospital_detail_content hideScroll">
              <VSForm
                id="vSFormBedPatientInpDetail"
                labelAlign="left"
                labelWrap
              >
                <div
                  // onClick={() => {
                  //   setModalContent(renderUnbilledMedicalOrdersGlance);
                  //   setModalProps(unbilledMedicalOrdersGlanceProps);
                  //   modalRef?.current?.handeOpen(true);
                  // }}
                  className="nurse_station_out_hospital_detail_content_form_title"
                >
                  <VSFormItem
                    name={['id']}
                    label="主键"
                    valueType="text"
                    fieldProps={{}}
                    style={{ display: 'none' }}
                  />
                  <div className="nurse_station_out_hospital_detail_content_form_title_tag"></div>
                  患者信息
                </div>
                <div style={{ paddingLeft: 16 }}>
                  <VSFormLayout
                    key="0"
                    columnCount={3}
                    // labelCol={labelColWidth[10]}
                    labelWidth={120}
                  >
                    <VSFormItem
                      name={['displayId']}
                      label="患者ID"
                      readonly
                      valueType="text"
                      rules={[
                        {
                          message: '用于显示的id长度不合法',
                          type: 'string',
                          min: 0,
                          max: 40,
                        },
                      ]}
                      fieldProps={{}}
                    />

                    <VSFormItem
                      name={['bedLabel']}
                      label="床号"
                      readonly
                      valueType="text"
                      fieldProps={{}}
                    />

                    <VSFormItem
                      name={['name']}
                      label="姓名"
                      readonly
                      valueType="text"
                      rules={[
                        {
                          message: '姓名长度不合法',
                          type: 'string',
                          min: 0,
                          max: 60,
                        },
                      ]}
                      fieldProps={{}}
                    />

                    <VSFormItem
                      name={['gender']}
                      label="性别"
                      readonly
                      valueType="select"
                      fieldProps={{}}
                      dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
                    />

                    <VSFormItem
                      name={['age']}
                      label="年龄"
                      readonly
                      valueType="text"
                      fieldProps={{}}
                    />

                    <VSFormItem
                      name={['birthAddress']}
                      label="出生地"
                      readonly
                      valueType="address"
                      fieldProps={{}}
                    />
                    <VSFormItem
                      name={['identityCode']}
                      label="人员类别"
                      readonly
                      valueType="select"
                      fieldProps={{}}
                      dataSource={vsf.stores.dbenums?.enums?.IDENTITY_DICT}
                    />

                    <VSFormItem
                      name={['defaultChargeType']}
                      label="费别"
                      readonly
                      valueType="select"
                      fieldProps={{}}
                      dataSource={vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT}
                    />

                    <VSFormItem
                      name={['contactName']}
                      label="联系人"
                      readonly
                      valueType="text"
                      rules={[
                        {
                          message: '联系人姓名长度不合法',
                          type: 'string',
                          min: 0,
                          max: 60,
                        },
                      ]}
                      fieldProps={{}}
                    />

                    <VSFormItem
                      name={['phoneNumber']}
                      label="联系人电话"
                      readonly
                      valueType="text"
                      rules={[
                        {
                          message: '联系人的联系电话长度不合法',
                          type: 'string',
                          min: 0,
                          max: 40,
                        },
                      ]}
                      fieldProps={{}}
                    />
                  </VSFormLayout>
                </div>
                <div className="nurse_station_out_hospital_detail_content_form_title">
                  <div className="nurse_station_out_hospital_detail_content_form_title_tag"></div>
                  在科信息
                </div>
                <div style={{ paddingLeft: 16 }}>
                  <VSFormLayout
                    key="10"
                    columnCount={3}
                    // labelCol={labelColWidth[6]}
                    labelWidth={120}
                  >
                    <VSFormItem
                      name={['departmentName']}
                      label="所属科室"
                      readonly
                      valueType="text"
                      rules={[
                        {
                          message: '科室名称长度不合法',
                          type: 'string',
                          min: 0,
                          max: 40,
                        },
                      ]}
                      fieldProps={{}}
                    />

                    <VSFormItem
                      name={['admissionDateTime']}
                      label="入院时间"
                      readonly
                      valueType="text"
                      fieldProps={{}}
                      convertValue={(v) => {
                        if (!v) return;
                        return dayjs(v).format('YYYY-MM-DD HH:mm');
                      }}
                    />

                    <VSFormItem
                      name={['admissionWardDateTime']}
                      label="入科时间"
                      readonly
                      valueType="text"
                      fieldProps={{}}
                      convertValue={(v) => {
                        if (!v) return;
                        return dayjs(v).format('YYYY-MM-DD HH:mm');
                      }}
                    />

                    <VSFormItem
                      name={['patientCondition']}
                      label="病情"
                      readonly
                      valueType="select"
                      fieldProps={{}}
                      dataSource={
                        vsf.stores.dbenums?.enums?.PATIENT_STATUS_DICT
                      }
                    />

                    <VSFormItem
                      name={['nursingClass']}
                      label="护理等级"
                      readonly
                      valueType="select"
                      fieldProps={{}}
                      dataSource={vsf.stores.dbenums?.enums?.NURSING_CLASS_DICT}
                    />
                    {/* todo */}
                    {/* <VSFormItem
                  name={['admissionWardDateTime']}
                  label="少手术时间"
                  readonly
                  valueType="date"
                  fieldProps={{}}
                /> */}
                  </VSFormLayout>
                  <VSFormLayout
                    key="20"
                    columnCount={1}
                    // labelCol={labelColWidth[6]}
                    labelWidth={120}
                  >
                    <VSFormItem
                      name={['diagnosisDescription']}
                      label="诊断"
                      readonly
                      valueType="text"
                      rules={[
                        {
                          message: '数据格式错误',
                          type: 'string',
                          min: 0,
                          max: 200,
                        },
                      ]}
                      fieldProps={{}}
                    />
                  </VSFormLayout>
                  <VSFormLayout
                    key="4"
                    columnCount={3}
                    // labelCol={labelColWidth[6]}
                    labelWidth={120}
                  >
                    <VSFormItem
                      name={['doctorInCharge', 'staffName']}
                      label="经治医生"
                      readonly
                      valueType="text"
                      fieldProps={{}}
                    />
                    <VSFormItem
                      name={['nurseInCharge']}
                      label="责任护士"
                      valueType="select"
                      dataSource={getPagedByUserStaffQto}
                      searchKey="inputCodeLike"
                      fieldProps={{
                        className: 'width240',
                        showSearch: true,
                        fieldNames: { label: 'staffName', value: 'id' },
                      }}
                    />

                    <VSFormItem
                      name={['controlQualityNurse']}
                      label="质控护士"
                      valueType="select"
                      dataSource={getPagedByUserStaffQto}
                      searchKey="inputCodeLike"
                      fieldProps={{
                        className: 'width240',
                        showSearch: true,
                        fieldNames: { label: 'staffName', value: 'id' },
                      }}
                    />
                  </VSFormLayout>
                </div>
                <div className="nurse_station_out_hospital_detail_content_form_title">
                  <div className="nurse_station_out_hospital_detail_content_form_title_tag"></div>
                  出院校对信息
                </div>
                <div
                  className="nurse_station_out_hospital_detail_content_form_outhosipital_check"
                  style={{ paddingLeft: 16 }}
                >
                  <VSFormLayout key="2" columnCount={3} labelWidth={170}>
                    <VSFormItem
                      name={['prePaymentAmount']}
                      label="预交金(元)"
                      readonly
                      valueType="digit"
                      fieldProps={{}}
                      convertValue={(v) => {
                        return v?.toFixed(2);
                      }}
                    />

                    <VSFormItem
                      name={['totalCharge']}
                      label="累计费用(元)"
                      readonly
                      valueType="digit"
                      fieldProps={{}}
                      convertValue={(v) => {
                        return v?.toFixed(2);
                      }}
                    />
                    {/*
                <VSFormItem
                  name={['']}
                  label="结算状态"
                  readonly
                  valueType="text"
                  fieldProps={{}}
                  initialValue="未结算"
                /> */}
                    <VSFormItem
                      name={['notChargeLab']}
                      label="未计费检验"
                      readonly
                      valueType="digit"
                      fieldProps={{}}
                      addonAfter={renderIcon1('notChargeLab')}
                      // addonAfter={() => {
                      //   console.log(111);
                      // }}
                    />

                    <VSFormItem
                      name={['notPerformExam']}
                      label="未执行检查"
                      readonly
                      valueType="digit"
                      fieldProps={{}}
                      addonAfter={renderIcon1('notPerformExam')}
                    />

                    <VSFormItem
                      name={['notSubmitOrder']}
                      label="未校对医嘱"
                      readonly
                      valueType="digit"
                      fieldProps={{}}
                      addonAfter={renderIcon1('notSubmitOrder')}
                    />

                    <VSFormItem
                      name={['notChargeOperation']}
                      label="未计费麻醉/手术"
                      readonly
                      valueType="digit"
                      fieldProps={{}}
                      addonAfter={renderIcon1('notChargeOperation')}
                    />
                    {/*  */}
                    <VSFormItem
                      name={['notChargeTreat']}
                      label="未计费临时治疗医嘱"
                      readonly
                      valueType="digit"
                      fieldProps={{}}
                      addonAfter={renderIcon1('notChargeTreat')}
                    />

                    <VSFormItem
                      name={['notPerformOrder']}
                      label="未执行医嘱"
                      readonly
                      valueType="digit"
                      fieldProps={{}}
                      addonAfter={renderIcon1('notPerformOrder')}
                    />
                    <VSFormItem
                      name={['notStopOrder']}
                      label="未停医嘱(条)"
                      readonly
                      valueType="digit"
                      fieldProps={{}}
                      addonAfter={renderIcon1('notStopOrder')}
                    />
                    <VSFormItem
                      name={['notOperateDrugPrescription']}
                      label="未发药处方(张)"
                      readonly
                      valueType="digit"
                      fieldProps={{}}
                      addonAfter={renderIcon1('notOperateDrugPrescription')}
                    />
                    <VSFormItem
                      name={['notDispenseOrder']}
                      label="未发药医嘱(条)"
                      readonly
                      valueType="digit"
                      fieldProps={{}}
                      addonAfter={renderIcon1('notDispenseOrder')}
                    />
                    <VSFormItem
                      name={['notHandleReturnOrder']}
                      label="未处理回退医嘱"
                      readonly
                      valueType="digit"
                      fieldProps={{}}
                      addonAfter={renderIcon2('notHandleReturnOrder')}
                    />
                    <VSFormItem
                      name={['notReturnDrugPrescription']}
                      label="未退药处方"
                      readonly
                      valueType="digit"
                      fieldProps={{}}
                      addonAfter={renderIcon2('notReturnDrugPrescription')}
                    />

                    <VSFormItem
                      name={['notReturnDrugOrder']}
                      label="未退药医嘱"
                      readonly
                      valueType="digit"
                      fieldProps={{}}
                      addonAfter={renderIcon2('notReturnDrugOrder')}
                    />

                    <VSFormItem
                      name={['dischargeDrug']}
                      label="出院带药"
                      readonly
                      valueType="digit"
                      fieldProps={{}}
                      addonAfter={renderIcon2('dischargeDrug')}
                    />
                  </VSFormLayout>
                </div>
                <div style={{ paddingLeft: 16 }}>
                  <div
                    style={{ borderTop: '1px solid #D6D8D9', paddingTop: 10 }}
                  >
                    <VSFormLayout key="3" columnCount={3} labelWidth={170}>
                      <VSFormItem
                        name={['dischargeDateExpected']}
                        label="出院时间"
                        readonly
                        valueType="text"
                        fieldProps={{}}
                        convertValue={(v) => {
                          if (!v) return;
                          return dayjs(v).format('YYYY-MM-DD HH:mm');
                        }}
                      />
                      <VSFormItem
                        name={['dischargeWay']}
                        label="出院方式"
                        valueType="select"
                        readonly
                        fieldProps={{
                          showSearch: true,
                        }}
                        dataSource={
                          vsf.stores.dbenums?.enums?.DISCHARGE_DISPOSITION_DICT
                        }
                      />
                    </VSFormLayout>
                  </div>
                </div>
              </VSForm>
            </div>
            <div className="nurse_station_out_hospital_detail_bottom">
              <Button
                onClick={handelClear}
                type="primary"
                ghost
                style={{ marginLeft: 16 }}
                className="width80"
              >
                清屏
              </Button>
              {currentInpInfo?.preDischargeStatus && (
                <Button
                  onClick={save}
                  style={{ marginLeft: 16 }}
                  type="primary"
                  // className="width96"
                >
                  通知结算
                </Button>
              )}
            </div>
          </div>
        ) : (
          <Empty />
        )
      }
      <ModalDetail
        ref={modalRef}
        modalProps={modalProps}
        content={modalContent}
      ></ModalDetail>
    </div>
  );
};

export default definePage(Index);
// 出院
