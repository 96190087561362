import { message } from '@vs/vsf-kit';

/**
 * convertGetExpenseListRes2InpExpenseList
 * @param {any} input
 * @returns {any}
 */
export const convertGetExpenseListRes2InpExpenseList = (input) => {
  const _ = window._ ?? {};
  const output = [];
  output.push(
    ...(_.get(input, 'data')?.map((d, i) => {
      const obj = {};
      obj.tmpId = i;
      obj.inpVisitId = _.get(d, `inpBillingDetailVoList.[0].inpVisitId`);
      obj.startDate = _.get(d, `inpBillingDetailVoList.[0].startDate`);
      obj.endDate = _.get(d, `inpBillingDetailVoList.[0].endDate`);
      obj.patientId = _.get(d, `inpBillingDetailVoList.[0].patientId`);
      obj.itemId = _.get(d, `inpBillingDetailVoList.[0].itemId`);
      obj.itemName = _.get(d, `inpBillingDetailVoList.[0].itemName`);
      obj.amount = _.get(d, `inpBillingDetailVoList.[0].amount`);
      obj.unit = _.get(d, `inpBillingDetailVoList.[0].unit`);
      obj.price = _.get(d, `inpBillingDetailVoList.[0].price`);
      obj.cost = _.get(d, `inpBillingDetailVoList.[0].cost`);
      obj.orderDate = _.get(d, `inpBillingDetailVoList.[0].orderDate`);
      obj.totalSelfPay = _.get(d, `inpBillingDetailVoList.[0].totalSelfPay`);
      return obj;
    }) ?? []),
  );
  return output;
};

/**
 * convertQueryPatientsAwaitingDischargeRes2InpWaitSettlePatientListRo
 * @param {any} input
 * @returns {any}
 */
export const convertQueryPatientsAwaitingDischargeRes2InpWaitSettlePatientListRo =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj = {};
        obj.name = _.get(d, 'inpVisit.inpVisitExtensionPatientInfoDto.name');
        obj.displayId = _.get(d, 'inpVisit.patient.displayId');
        obj.bedLabel = _.get(
          d,
          'inpVisit.patientInHospitalBaseDto.bed.bedLabel',
        );
        obj.totalCost = _.get(
          d,
          'inpVisit.patientBillingRecordBaseDto.totalCost',
        );
        obj.prePaymentAmount = _.get(
          d,
          'inpVisit.patientBillingRecordBaseDto.prePaymentAmount',
        );
        obj.selfPaymentTotalCost = _.get(
          d,
          'inpVisit.patientBillingRecordBaseDto.selfPaymentTotalCost',
        );
        obj.prePaymentBalance = _.get(d, 'prePaymentBalance');
        obj.dischargeDateTime = _.get(d, 'inpVisit.dischargeDateTime');
        obj.departmentName = _.get(
          d,
          'inpVisit.patientInHospitalBaseDto.ward.departmentName',
        );
        obj.chargeType = _.get(
          d,
          'inpVisit.inpVisitExtensionBillingInfoDto.chargeType',
        );
        obj.identityCode = _.get(
          d,
          'inpVisit.inpVisitExtensionBillingInfoDto.identityCode',
        );
        obj.admissionDateTime = _.get(d, 'inpVisit.admissionDateTime');
        obj.inpVisitId = _.get(d, 'inpVisit.id');
        return obj;
      }) ?? []),
    );
    return output;
  };

/**
 * convertGetInpBillingDetailByVisitIdRes2InpSettleBillingDetailList
 * @param {any} input
 * @returns {any}
 */

export const convertGetInpBillingDetailByVisitIdRes2InpSettleBillingDetailList =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ..._.get(input, 'data')?.map((d, i) => {
        const obj = {};
        obj.id = _.get(d, 'id') ?? 'create' + Math.random();
        obj.itemName = _.get(d, 'itemName');
        obj.price = _.get(d, 'price');
        obj.departmentName = _.get(d, 'performDepartment.departmentName');
        obj.cost = _.get(d, 'cost');
        obj.amount = _.get(d, 'amount');
        obj.orderDate = _.get(d, 'orderDate');
        obj.insuranceCode = _.get(d, 'insuranceCode');
        obj.operateDate = _.get(d, 'operateDate');
        obj.staffName = _.get(d, 'operator.staffName');
        return obj;
      }),
      // .filter((item) => item?.id))
    );
    return output;
  };

/**
 * convertGetPatientInpVisitInfoRes2InpSettlePatientInfo
 * @param {any} input
 * @returns {any}
 */
const convertGetPatientInpVisitInfoRes2InpSettlePatientInfo = (input, i) => {
  const _ = window._ ?? {};
  return [
    ..._.get(input, 'data')?.map((ele) => {
      const output = {};
      output.inpVisitId = _.get(ele, `id`);
      output.displayId = _.get(ele, `patient.displayId`);
      output.name = _.get(ele, `inpVisitExtensionPatientInfoDto.name`);
      output.chargeType = _.get(
        ele,
        `inpVisitExtensionBillingInfoDto.chargeType`,
      );
      output.identityCode = _.get(
        ele,
        `inpVisitExtensionBillingInfoDto.identityCode`,
      );
      output.patientId = _.get(ele, `patient.id`);
      output.admissionDateTime = _.get(ele, `admissionDateTime`);
      output.dischargeDateTime = _.get(ele, `dischargeDateTime`);
      output.prePaymentAmount = _.get(
        ele,
        `patientBillingRecordBaseDto.prePaymentAmount`,
      );
      output.totalCost = _.get(ele, `patientBillingRecordBaseDto.totalCost`);
      output.totalCharge = _.get(
        ele,
        `patientBillingRecordBaseDto.totalCharge`,
      );
      output.selfPaymentTotalCost = _.get(
        ele,
        `patientBillingRecordBaseDto.selfPaymentTotalCost`,
      );
      output.bedLabel = _.get(ele, `patientInHospitalBaseDto.bed.bedLabel`);
      output.diagnosisDescription = _.get(
        ele,
        `patientInHospitalBaseDto.diagnosisRecord.diagnosisDescription`,
      );
      output.departmentName = _.get(
        ele,
        `patientInHospitalBaseDto.ward.departmentName`,
      );
      output.patientId = _.get(ele, `patient.id`);
      output.inpVisitExtensionBillingInfoDto = _.get(
        ele,
        `inpVisitExtensionBillingInfoDto`,
      );
      return output;
    }),
  ];
};

/**
 * convertGetExpenseListRes2InpExpenseGroupList
 * @param {any} input
 * @returns {any}
 */
const convertGetExpenseListRes2InpExpenseGroupList = (input) => {
  const _ = window._ ?? {};
  const output = [];
  output.push(
    ...(_.get(input, 'data')?.map((d, i) => {
      const obj = {};
      obj.inpReceiptClass = _.get(d, 'inpReceiptClass');
      obj.subTotal = _.get(d, 'subTotal');
      obj.inpBillingDetailVoList = _.get(d, 'inpBillingDetailVoList');
      obj.id = i;
      return obj;
    }) ?? []),
  );
  return output;
};
export const getFormList = async (params) => {
  if (params?.inpVisitId || params?.displayId) {
    const res =
      await vsf?.services?.ClinicInpVisitController_getPatientInpVisitInfoForSettle_eebc78?.(
        {
          inpVisitId: params?.inpVisitId,
          patientId: params?.patientId,
          displayId: params?.displayId,
        },
      );
    if (params?.inpVisitId) {
      const output =
        convertGetPatientInpVisitInfoRes2InpSettlePatientInfo?.(res);
      return output;
    }

    if (res?.data?.length) {
      const output =
        convertGetPatientInpVisitInfoRes2InpSettlePatientInfo?.(res);
      return output;
    } else {
      message?.error('未查询到患者未结算的住院记录，请核对后重试');
      return res?.data;
    }
  }
};

export const prePayment = async (params) => {
  const res = await vsf?.services?.InpSettleController_preSettle_5135f4?.({
    patientId: params?.patientId,
    inpVisitId: params?.inpVisitId,
    newbornIds: params?.newbornIds,
    creditIndicator: params?.creditIndicator,
  });
  return res?.data;
};
export const paymentSubmit = async (params) => {
  const res = await vsf?.services?.InpSettleController_settle_f5a339?.({
    inpSettleEo: params,
  });
  return res;
};

//挂账记录查询
export const chargeRecordQuery = async (params) => {
  const res =
    await vsf?.services?.InpBillingDetailController_getExpenseList_7847bb?.({
      inpBillingDetailSimpleQto: {
        ...params,
      },
    });
  const output = convertGetExpenseListRes2InpExpenseGroupList?.(res);
  const arr = [];
  output?.map((item) => {
    arr.push(item);
    item?.inpBillingDetailVoList?.map((obj) => {
      arr.push(obj);
    });
  });
  return { data: arr ?? [], total: output?.length ?? 0 };
};

/**
 * convertGetSimpleMedicalRecordByIdRes2InpSettleMedicalRecord
 * @param {any} input
 * @returns {any}
 */
const convertGetSimpleMedicalRecordByIdRes2InpSettleMedicalRecord = (input) => {
  const _ = window._ ?? {};
  const output = {}; // TODO 请确认下行字段是否符合逻辑
  output.displayId = _.get(input, 'data.inpVisitVo.patient.displayId'); // TODO 请确认下行字段是否符合逻辑
  output.name = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionPatientInfoDto.name',
  ); // TODO 请确认下行字段是否符合逻辑
  output.gender = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionPatientInfoDto.gender',
  ); // TODO 请确认下行字段是否符合逻辑
  output.age = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionPatientInfoDto.age',
  ); // TODO 请确认下行字段是否符合逻辑
  output.birthday = _.get(input, 'data.inpVisitVo.patient.birthday'); // TODO 请确认下行字段是否符合逻辑
  output.birthAddress = Object.values(
    _.get(input, 'data.inpVisitVo.patient.birthAddress'),
  )?.join(''); // TODO 没有匹配到合适字段 output.idNumber = null; // String 身份证号
  // TODO 请确认下行字段是否符合逻辑
  output.citizenship = _.get(
    input,
    'data.inpVisitVo.patient.patientProfileDto.citizenship',
  ); // TODO 请确认下行字段是否符合逻辑
  output.nation = _.get(
    input,
    'data.inpVisitVo.patient.patientProfileDto.nation',
  ); // TODO 请确认下行字段是否符合逻辑
  output.job = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionPatientInfoDto.job',
  ); // TODO 请确认下行字段是否符合逻辑
  output.marriageStatus = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionPatientInfoDto.marriageStatus',
  ); // TODO 请确认下行字段是否符合逻辑
  output.phoneNumber = _.get(input, 'data.inpVisitVo.patient.phoneNumber'); // TODO 请确认下行字段是否符合逻辑
  output.cellphoneNumber = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionPatientInfoDto.cellphoneNumber',
  ); // TODO 请确认下行字段是否符合逻辑
  output.chargeType = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBillingInfoDto.chargeType',
  ); // TODO 请确认下行字段是否符合逻辑
  output.identityCode = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBillingInfoDto.identityCode',
  ); // TODO 请确认下行字段是否符合逻辑
  output.nativeAddress = Object.values(
    _.get(input, 'data.inpVisitVo.patient.patientProfileDto.nativeAddress'),
  )?.join(''); // TODO 请确认下行字段是否符合逻辑
  output.currentAddress = Object.values(
    _.get(input, 'data.inpVisitVo.patient.patientProfileDto.currentAddress'),
  )?.join(''); // TODO 请确认下行字段是否符合逻辑
  output.registeredResidenceAddress = Object.values(
    _.get(
      input,
      'data.inpVisitVo.patient.patientProfileDto.registeredResidenceAddress',
    ),
  )?.join(''); // TODO 请确认下行字段是否符合逻辑
  output.securityType = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBillingInfoDto.securityType',
  ); // TODO 请确认下行字段是否符合逻辑
  output.securityNumber = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBillingInfoDto.securityNumber',
  ); // TODO 请确认下行字段是否符合逻辑
  output.unitInContract = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionPatientInfoDto.unitInContract',
  ); // TODO 请确认下行字段是否符合逻辑
  output.workUnitPhoneNumber = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionPatientInfoDto.workUnitPhoneNumber',
  ); // TODO 没有匹配到合适字段 output.medicalType = null; // String 医疗类别
  // TODO 请确认下行字段是否符合逻辑
  output.insuranceDisease = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionBillingInfoDto.insuranceDisease',
  ); // TODO 请确认下行字段是否符合逻辑
  output.workUnit = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionPatientInfoDto.workUnit',
  ); // TODO 请确认下行字段是否符合逻辑
  output.workStatus = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionPatientInfoDto.workStatus',
  ); // TODO 请确认下行字段是否符合逻辑
  output.workUnitAddress = Object.values(
    _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionPatientInfoDto.workUnitAddress',
    ),
  )?.join(''); // TODO 请确认下行字段是否符合逻辑
  output.workUnitPostcode = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionPatientInfoDto.workUnitPostcode',
  ); // TODO 请确认下行字段是否符合逻辑
  output.contactPersonName = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionPatientInfoDto.contactPersonName',
  ); // TODO 请确认下行字段是否符合逻辑
  output.contactPersonRelationship = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionPatientInfoDto.contactPersonRelationship',
  ); // TODO 请确认下行字段是否符合逻辑
  output.contactPersonPhone = _.get(
    input,
    'data.inpVisitVo.inpVisitExtensionPatientInfoDto.contactPersonPhone',
  ); // TODO 请确认下行字段是否符合逻辑
  output.contactPersonAddress = Object.values(
    _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionPatientInfoDto.contactPersonAddress',
    ),
  )?.join(''); // TODO 请确认下行字段是否符合逻辑
  output.patientClass = _.get(input, 'data.inpVisitVo.patientClass'); // TODO 请确认下行字段是否符合逻辑
  output.patientAdmissionCondition = _.get(
    input,
    'data.inpVisitVo.patientAdmissionCondition',
  ); // TODO 请确认下行字段是否符合逻辑
  output.admissionPurpose = _.get(input, 'data.inpVisitVo.admissionPurpose'); // TODO 请确认下行字段是否符合逻辑
  output.admissionDateTime = _.get(input, 'data.inpVisitVo.admissionDateTime'); // TODO 请确认下行字段是否符合逻辑
  output.wardDepartmentName = _.get(
    input,
    'data.inpVisitVo.patientInHospitalBedDto.ward.departmentName',
  ); // TODO 请确认下行字段是否符合逻辑
  output.admissionDepartmentName = _.get(
    input,
    'data.inpVisitVo.admissionToDepartment.departmentName',
  ); // TODO 请确认下行字段是否符合逻辑
  output.encounterDoctorName = _.get(
    input,
    'data.inpVisitVo.admissionApply.outpEncounter.encounterDoctor.staffName',
  ); // TODO 请确认下行字段是否符合逻辑
  output.visitStartDate = _.get(
    input,
    'data.inpVisitVo.admissionApply.outpEncounter.visitStartDate',
  );
  output.dischargeDiagnosisDescription = _.get(
    input,
    'data.dischargeDiagnosisDescription',
  );
  return output;
};
export const getSimpleMedicalRecordById = async (params) => {
  console.log(params);
  if (!params?.inpVisitId) return { displayId: 123 };
  const res =
    await vsf?.services?.ClinicInpVisitController_getSimpleMedicalRecordById_466f96?.(
      { inpVisitId: params?.inpVisitId },
    ); // TODO: 请确认入参来源及出参用法
  const output =
    convertGetSimpleMedicalRecordByIdRes2InpSettleMedicalRecord?.(res);
  return output;
};
