import vsf, { definePage } from '@vs/vsf-boot';
import {
  VSTableColumn,
  VSPromiseTable,
  VSTableAddition,
  VSTableToolbar,
  Button,
  Modal,
  Tabs,
  message,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';
import ClinicRegisterTypePriceItemTable from './ClinicRegisterTypePriceItemTable';
import './index.less';
import Icon from '@/module/Icon';
import { getScrollY, getScrollYRem } from '@/utils';
import Tip from '@/pages/Index/components/func/Tip';
import { useMount, useRequest } from 'ahooks';
import { isMinScreen } from '@/config';
import classnames from 'classnames';

const ClinicRegisterTypeTable = (props) => {
  const [clinicRegisterType, setClinicRegisterType] = useState();
  const onSave = async (params) => {
    const data = vsf.refs.clinicRegisterTypeTable.getValues();
    const formatData = data.map((d) => {
      const dict = { ...d, branchInstitutionId: d.branchInstitution?.id };
      if (('' + d.id)?.startsWith('create_')) {
        const { id, ...rest } = dict;
        return rest;
      } else {
        return dict;
      }
    });

    //TODO需要优化
    let isNull = formatData
      .map((item) => {
        if (
          !(
            item?.serialNumber ||
            item?.clinicType ||
            item?.clinicTypeClass ||
            item?.branchInstitution?.institutionName
          )
        ) {
          return true;
        } else {
          return false;
        }
      })
      .includes(true);

    if (isNull) {
      Modal.confirm({
        title: '项目未填写',
      });
      return;
    }
    const res =
      await vsf?.services?.OutpRegisterDefineController_addUpdateClinicRegisterType_9b25f7?.(
        { btoParamList: formatData, extApiParam: {} },
      );
    vsf.refs.clinicRegisterTypeTable.reload();
    message.success('添加成功');
    return res?.data;
  };
  const onFetch = useCallback(async (params) => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllByClinicRegisterTypeDictionaryQto_fb0472?.(
        {
          qto: {
            // from: params.pagination?.from ?? 0,
            // size: params.pagination?.size ?? 20,
            orderList: params.orderList, // 排序结果
            ...params.search, // 搜索结果
            ...params.filters, // 过滤器结果
            ...(params?.extra ?? {}), // 传入的额外参数
          },
        },
      );
    if (res?.data) {
      const result = res?.data?.sort(function (a, b) {
        return a.serialNumber - b.serialNumber;
      });
      return result?.map((e) => ({
        ...e,
        costs: (e?.costs).toFixed(2),
      }));
    } else {
      return res.data;
    }
  }, []);

  const onRemove = async (params) => {
    if (('' + params.id)?.indexOf('create_') >= 0) return true;
    const res =
      await vsf?.services?.OutpRegisterDefineController_deleteClinicRegisterType_dc5486?.(
        { btoParam: params, extApiParam: params?.extApiParam },
      );
    vsf.refs.clinicRegisterTypeTable.reload();
    res?.code === 200 && message.success('删除成功');
    return res?.code === 200;
  };

  return (
    <div className="layout-mycontainter">
      <div className="layout-mycontainter-item-top">
        <VSPromiseTable
          isMinScreen
          className={classnames(
            'table_overflow_560 aspirin-table aspirin-table-search-padding-no',
          )}
          editable={{
            editType: 'single',
            onCanDelete: () => null,
            editText: <Icon type="icon-bianji" size={24} />,
            extraOperations: [
              {
                children: <Icon type="icon-shanchu1" size={24} />,
                order: 1,
                onClick: (event, data) => {
                  Tip({
                    content: `是否删除{【${data?.clinicType}】}`,
                    onOk: () => {
                      onRemove(data);
                    },
                  });
                },
              },
            ],
          }}
          onFetch={onFetch}
          // value={data}
          onRemove={onRemove}
          onRecord={() => ({ id: 'create_' + Math.random() })}
          id="clinicRegisterTypeTable"
          rowClick={{
            onRowClick: (_value) => {
              setClinicRegisterType({ ..._value });
            },
          }}
          vsid="50510"
          pagination={false}
          scroll={{
            y: 0,
            // y: getScrollY(600),
          }}
        >
          <VSTableColumn
            dataIndex={['serialNumber']}
            title="排序号"
            valueType="digit"
            formItemProps={{
              rules: [
                {
                  message: '排序号的值不合法',
                  type: 'number',
                  min: 0,
                  max: null,
                  required: true,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['clinicType']}
            title="号类名称"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: '数据格式错误',
                  type: 'string',
                  min: 0,
                  max: 12,
                  required: true,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['clinicTypeClass']}
            title="号类类别"
            valueType="select"
            dataSource={[
              { label: '普通号', value: 'COMMON' },
              { label: '专家号', value: 'SPECIALITY' },
            ]}
            formItemProps={{
              rules: [{ message: '该项为必选项', required: true }],
            }}
            fieldProps={{
              showSearch: true,
            }}
          />

          <VSTableColumn
            dataIndex={['costs']}
            title="合计"
            editable={false}
            valueType="digit"
            formItemProps={{
              rules: [{ message: '该项为必选项', required: true }],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['id']}
            title="主键"
            hideInTable={true}
            valueType="digit"
            formItemProps={{
              rules: [{ message: '该项为必选项', required: true }],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['branchInstitution']}
            title="院区"
            valueType="select"
            fieldProps={{
              dataSource: async (params) => {
                const res =
                  await vsf?.services?.MedicalInstitutionDtoController_getCurrentInstitution_b22926?.(
                    {},
                  );
                return res?.data?.branchInstitutionList;
              },
              fieldNames: { label: 'institutionName', value: 'id' },
              showSearch: true,
            }}
          />
          <VSTableColumn
            dataIndex={['scheduleGeneratePeriod']}
            title="排班生成周期"
            valueType="digit"
            formItemProps={{
              rules: [
                {
                  required: true,
                  message: '排班生成周期的值不合法',
                  type: 'number',
                  min: 0,
                  max: null,
                },
              ],
            }}
            fieldProps={{}}
            render={(_, value) => {
              return `${value?.scheduleGeneratePeriod || 0}天`;
            }}
          />
          <VSTableToolbar
            title={
              <div className="all-save">
                <div>医疗机构详细信息</div>
              </div>
            }
            // style={{ paddingLeft: '20px' }}
          >
            <VSTableAddition type="dashed" children="添加" position="top" />
            <Button type="primary" children="保存" onClick={onSave} />
          </VSTableToolbar>
        </VSPromiseTable>
      </div>
      <div className="layout-mycontainter-item-bottom">
        <ClinicRegisterTypePriceItemTable value={clinicRegisterType} />
      </div>
    </div>
  );
};
export default definePage(ClinicRegisterTypeTable);
