import React, { useEffect } from 'react';
import { labelColWidth } from '@/utils';
import { VSFormLayout, VSFormItem, VSForm, Select } from '@vs/vsf-kit';
import './index.less';
import '@/style/adaptation.less';

export default function model() {
  useEffect(() => {
    vsf?.refs?.medicalCertificate?.setFieldsValue({ name: '张三里斯' });
  }, []);
  return (
    <div className="model widthAutoFill">
      <div className="model_title widthAutoFill icon18">
        宜兴市人民医院医学证明书
      </div>
      <VSForm vsid="17359" labelAlign="left" id="medicalCertificate">
        <VSFormLayout key="1" columnCount={16} labelCol={labelColWidth[8]}>
          <VSFormItem
            label="姓名"
            name={['name']}
            className="width140"
            valueType="text"
            fieldProps={{
              width: '100%',
              precision: 1,
            }}
            readonly={true}
          />
          <VSFormItem
            label="性别"
            name={['name']}
            className="width140"
            valueType="text"
            fieldProps={{
              width: '100%',
              precision: 1,
            }}
            readonly={true}
          />
          <VSFormItem
            label="年龄"
            name={['name']}
            className="width140"
            valueType="text"
            fieldProps={{
              width: '100%',
              precision: 1,
              placeholder: '',
            }}
            readonly={true}
          />
          <VSFormItem
            label="籍贯"
            name={['name']}
            className="width280"
            valueType="text"
            fieldProps={{
              width: '100%',
              precision: 2,
              placeholder: '',
            }}
            readonly={true}
          />
          <VSFormItem
            label="工作单位或地址"
            name={['name']}
            className="width240"
            valueType="text"
            fieldProps={{
              width: '100%',
              precision: 2,
              placeholder: '',
            }}
            readonly={true}
          />
        </VSFormLayout>
        <VSFormLayout key="2" columnCount={2} labelCol={labelColWidth[4]}>
          <VSFormItem
            label="初步诊断"
            name={['name']}
            className="width470"
            valueType="select"
            fieldProps={{
              precision: 2,
              placeholder: '',
              showArrow: false,
            }}
          />
          <VSFormItem
            label="门诊/住院号"
            name={['name']}
            className="width180"
            valueType="text"
            fieldProps={{
              precision: 2,
              placeholder: '',
            }}
            readonly={true}
          />
        </VSFormLayout>
        <VSFormLayout key="3" columnCount={1} labelCol={labelColWidth[1]}>
          <VSFormItem
            label="休息"
            name={['name']}
            className="width200"
            valueType="text"
            fieldProps={{
              precision: 2,
              placeholder: '请输入数字',
              suffix: (
                <>天</>
                // <Select
                //   style={{ width: 20 }}
                //   // onChange={}
                //   suffixIcon={false}
                //   options={[
                //     {
                //       value: '天',
                //       label: '天',
                //     },
                //     {
                //       value: '周',
                //       label: '周',
                //     },
                //     {
                //       value: '月',
                //       label: '月',
                //     },
                //     {
                //       value: '年',
                //       label: '年',
                //     },
                //   ]}
                // />
              ),
            }}
          />
        </VSFormLayout>
        <div>处理意见:(建议)</div>
        <VSFormLayout key="4">
          <VSFormItem
            label=""
            name={['name']}
            className="widthAutoFill"
            valueType="textarea"
            fieldProps={{
              precision: 2,
              placeholder: '',
            }}
          />
        </VSFormLayout>
        <VSFormLayout key="5" columnCount={5} labelCol={labelColWidth[5]}>
          <VSFormItem
            label="公章"
            name={['name']}
            className="width700"
            valueType="input"
            fieldProps={{
              disabled: true,
              precision: 2,
              placeholder: '',
            }}
            readonly={true}
          />
          <VSFormItem
            label=""
            name={['name']}
            className="width80"
            valueType="input"
            fieldProps={{
              disabled: true,
              precision: 2,
              placeholder: '',
            }}
            readonly={true}
          />
          <VSFormItem
            label="医生"
            name={['name']}
            className="width80"
            valueType="input"
            fieldProps={{
              disabled: true,
              precision: 2,
              placeholder: '',
            }}
            readonly={true}
          />
          <VSFormItem
            label=""
            name={['name']}
            className="width80"
            valueType="input"
            fieldProps={{
              disabled: true,
              precision: 2,
              placeholder: '',
            }}
            readonly={true}
          />
        </VSFormLayout>
      </VSForm>
      <div className="model_footer widthAutoFill icon14">
        未盖医疗专用章或涂改者无效
      </div>
    </div>
  );
}
