import vsf, { definePage } from '@vs/vsf-boot';
import React, { useEffect, useState } from 'react';
import './index.less';
import MenuDetails from './MenuDetails';
import TableDetails from './TableDetails';
import Drawer from './Drawer';
const InpDrugRefundPerform = (props) => {
  // 菜单
  const [current, setCurrent] = useState('mail');
  // 切换菜单
  const onChanegMenu = (value) => setCurrent(value?.key);
  // 浏览器缩放列表宽度/高度
  const [Scroll, setScroll] = useState();
  const resize = () => setScroll(Math?.random?.());
  useEffect(() => {
    window?.addEventListener?.('resize', resize);
    return () => window?.removeEventListener('resize', resize);
  }, []);

  const [openDrawer, setOpenDrawer] = useState(false),
    [wardInfos, setWardInfos] = useState();
  return (
    <div id="InpDrugRefundPerform">
      <MenuDetails {...{ current, onChanegMenu }} />
      <TableDetails {...{ current, wardInfos, setCurrent }} />
      <Drawer {...{ openDrawer, setOpenDrawer, wardInfos, setWardInfos }} />
    </div>
  );
};
export default definePage(InpDrugRefundPerform);
