import {
  Section,
  Todo,
  Tree,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
  VSTable,
  VSPromiseTable,
  VSTableColumn,
  VSTableAddition,
  Modal,
} from '@vs/vsf-kit';
import { useGetState } from 'ahooks';
import React, { useState, useEffect, useCallback } from 'react';

import askSearch from '@/components/CodeTableSelect/askRomanAlphabet';
import Icon from '@/module/Icon';
import vsf, { definePage } from '@vs/vsf-boot';

const EditableTable = (props) => {
  const { application, value } = props;
  const [roleList, setRoleList] = useState([]);
  const [selectedRows, setSelectedRows, getSelectedRows] = useGetState();

  const handleGetRoleList = useCallback(async () => {
    const res =
      await vsf?.services?.PrivilegeController_getAllRoleListOfStandardApplication_ce4076?.(
        {
          bizApplicationId: application?.id,
        },
      );
    if (res?.code === 200) {
      setRoleList(res?.data);
    }
  }, [application]);

  const onFetch = useCallback(async (params) => {
    const res =
      await vsf?.services?.PrivilegeController_searchCandidateStaff_e2616c?.({
        // departmentId: params?.departmentId,
        // name: params?.name,
        applicationId: application?.id,
        ...params?.search,
      });
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  useEffect(() => {
    handleGetRoleList();
  }, [handleGetRoleList]);

  const filterFormData = (data, need = false) => {
    if (need) {
      const selectedData = getSelectedRows() ?? [];
      const filterData = data?.filter((item) =>
        selectedData?.find((sItem) => sItem?.id === item?.id),
      );
      return convertData(filterData);
    } else {
      return convertData(data);
    }
  };

  const convertData = (formData) => {
    const res = formData?.map((item) => {
      return {
        staffId: item?.id,
        applicationId: application?.id,
        staffVsRoleBtoList: (item?.staffVsRoleWithRoleDtoList?.role?.id || [])
          ?.map((rItem) => {
            const target = roleList?.find((v) => v?.id === rItem);
            if (target) {
              return {
                applicationId: application?.id,
                staffId: item?.id,
                roleId: rItem,
              };
            } else {
              return undefined;
            }
          })
          .filter((v) => v),
      };
    });
    return res;
  };

  return (
    <VSPromiseTable
      editable={{
        editType: 'single',
        onCanDelete: () => false,
        editText: <Icon type="icon-bianji" size={24} />,
        columnProps: {
          width: 100,
        },
      }}
      className="table_overflow_520"
      scroll={{
        y: 450,
      }}
      vsid="43096"
      onFetch={onFetch}
      rowSelection={{
        selectType: 'multiple',
        onSelectedRowsChange: (formData, keys) => {
          setSelectedRows(formData);
          props?.onChange(filterFormData(formData));
        },
        selectedRows: selectedRows,
      }}
      pagination={false}
      onValuesChange={(formData, record) => {
        props?.onChange(filterFormData(formData, true));
      }}
    >
      <VSTableColumn
        dataIndex={['id']}
        title="员工识别号"
        editable={false}
        valueType="digit"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['staffName']}
        title="姓名"
        editable={false}
        valueType="text"
        search
        order={-1}
        columnKey={'name'}
        formItemProps={{
          rules: [
            {
              message: 'staff_name长度不合法',
              type: 'string',
              min: 0,
              max: 60,
            },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['loginName']}
        title="登录名"
        editable={false}
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: 'login_name长度不合法',
              type: 'string',
              min: 0,
              max: 20,
            },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['staffDepartment', 'departmentName']}
        title="科室"
        editable={false}
        valueType="select"
        search
        order={1}
        columnKey={'departmentId'}
        formItemProps={{
          rules: [
            {
              message: '科室名称长度不合法',
              type: 'string',
              min: 0,
              max: 40,
            },
          ],
        }}
        dataSource={async (option) => {
          const res =
            await vsf?.services?.MedicalInstitutionDtoController_getCurrentInstitutionWithDepartment_58c06a?.();

          const _res = res?.data?.branchInstitutionList
            ?.map((item) => {
              return {
                ...item,
                departmentDtoList: item?.departmentDtoList?.map((i) => {
                  return {
                    ...i,
                    institutionName: item?.institutionName,
                    label: i?.departmentName,
                    value: i?.id,
                  };
                }),
              };
            })
            .map((i) => i?.departmentDtoList)
            .flat();
          const _data = option?.label
            ? _res?.filter((i) => askSearch(option.label, i, 'label'))
            : _res;
          return _data ?? [];
        }}
        fieldProps={{
          showSearch: true,
        }}
      />

      <VSTableColumn
        dataIndex={['staffVsRoleWithRoleDtoList', 'role', 'id']}
        title="用户权限"
        valueType="select"
        fieldProps={{
          mode: 'multiple',
        }}
        dataSource={roleList?.map((item) => ({
          label: item?.roleName,
          value: item?.id,
        }))}
      />
    </VSPromiseTable>
  );
};

const Index = React.memo(EditableTable, (prev, next) => {
  return true;
});

export default Index;
