import { ButtonAndKeyDown } from '@/pages/Index/components';
import { getPromiseHeight } from '@/utils';
import { definePage } from '@vs/vsf-boot';
import {
  message,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import React, { useCallback, useEffect, useState } from 'react';
import {
  getInpRefundApplyToPerform,
  performInpDrugRefund,
  queryScrollWardPatientList,
} from './services';
import InquireHospitalSelect from '../../MedicalTechnology/InquireHospitalSelect';
import { askFixed } from '@/pages/Index/components/func/ask';
import { add } from '@/utils/setDataUtil';
import Tip from '@/pages/Index/components/func/Tip';
const TableDetails = (props) => {
  const { current, wardInfos, setCurrent } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const [random, setRandom] = useState();
  // 按钮
  const onButton = (button) => {
    return (
      <div className="aspirin-table-footer-button aspirin-table-footer-button-flex-end">
        {button.map((item) => (
          <ButtonAndKeyDown key={item.text} {...item} type="primary">
            {item.text}
          </ButtonAndKeyDown>
        ))}
      </div>
    );
  };
  const common_handle = () => {
    current != 'mail' && setCurrent('mail');
    setSelectedRows([]);
    setRandom(Math?.random());
  };
  useEffect(() => common_handle(), [wardInfos?.wardId]);
  const isWithdrawalDis = !wardInfos?.wardId;
  const onButtonProps = {
    onWithdrawal: {
      ghost: false,
      disabled: isWithdrawalDis,
      text: '退药',
      methodKey: 'altKey',
      ordinaryKey: '',
      onClick: async () => {
        if (!selectedRows?.length) return message.error('请选择需要药品');
        Tip({
          content: '是否确认退药',
          onOk: async () => {
            const res = await performInpDrugRefund(
              selectedRows?.map?.((ele) => ele?.prescriptionWaitDispenseId),
            );

            if (res?.code != 200 && !res?.data) return;
            common_handle();
          },
        });
      },
    },
    onPrint: {
      ghost: true,
      disabled: true,
      text: '打印',
      methodKey: 'altKey',
      ordinaryKey: '',
      onClick: async () => {},
    },
  };
  return (
    <div className="TableDetails">
      <Components
        {...{
          ...props,
          current,
          isWithdrawalDis,
          selectedRows,
          setSelectedRows,
          random,
        }}
      />
      {onButton([onButtonProps?.onWithdrawal, onButtonProps?.onPrint])}
    </div>
  );
};
const Components = (props) => {
  const { current, wardInfos } = props;

  const [bedDataSource, setBedDataSource] = useState([]);
  const { run } = useRequest(
    async (params) => {
      const res = await queryScrollWardPatientList(params);
      RefundApplyToPerformRun({
        params,
        WardPatientList: res,
      });
    },
    {
      manual: true,
    },
  );
  const onHandleBedData = useCallback(
    (res, WardPatientList) => {
      const filters = res?.data?.map?.((ele) => ele?.displayId);
      !bedDataSource?.length &&
        setBedDataSource(
          WardPatientList?.filter?.((ele) =>
            filters?.includes(ele?.displayId),
          )?.map?.((ele) => ({
            ...ele,
            gender: res?.data?.find((f) => f?.displayId == ele?.displayId)
              ?.gender,
          })),
        );
    },
    [bedDataSource],
  );
  const {
    loading: RefundApplyToPerformLoading,
    data: RefundApplyToPerformData,
    run: RefundApplyToPerformRun,
  } = useRequest(
    async ({ params, WardPatientList }) => {
      if (!params?.wardId) return [];
      const res = await getInpRefundApplyToPerform(params);
      if (Array?.isArray(WardPatientList))
        onHandleBedData(res, WardPatientList);
      if (params?.displayId)
        return res?.data?.filter((ele) => ele?.displayId == params?.displayId);
      return res?.data;
    },
    { manual: true },
  );
  const onReset = () => {
    setBedDataSource([]);
  };
  useEffect(() => {
    onReset();
    vsf?.refs?.tableInpRefundApplyOrderToPerform?.setFieldValue(
      'wardInfo',
      wardInfos?.wardName,
    );
    run?.({
      wardId: wardInfos?.wardId,
    });
  }, [wardInfos, current, props?.random]);
  switch (current) {
    case 'mail':
      return (
        <Mail
          {...{
            ...props,
            bedDataSource,
            setBedDataSource,
            refundApplyProps: {
              data: RefundApplyToPerformData,
              loading: RefundApplyToPerformLoading,
              run: RefundApplyToPerformRun,
            },
          }}
        />
      );

    case 'app':
      return <App {...{ ...props }} />;
  }
};
// 病区处方退药
const Mail = (props) => {
  const { isWithdrawalDis, bedDataSource, refundApplyProps, wardInfos } = props;
  const onChangeBedInfo = useCallback(async (params) => {
    const value = {
      bedInfo: '',
      displayId: '',
    };
    const form = vsf?.refs?.tableInpRefundApplyOrderToPerform;
    if (params?.id) {
      value.bedInfo = `${params?.bedLabel} - ${params?.name}`;
      value.displayId = params?.displayId;
    }

    form?.setFieldsValue(value);
  });
  const onInquire = () => {
    props?.setSelectedRows([]);
    refundApplyProps?.run({
      params: {
        wardId: wardInfos?.wardId,
        ...vsf?.refs?.tableInpRefundApplyOrderToPerform?.getFieldsValue(),
      },
    });
  };
  return (
    <div id="Mail">
      <div className="InpRefundApplyOrderForm">
        <VSForm id="tableInpRefundApplyOrderToPerform">
          <VSFormLayout
            style={{ width: 'calc(100% - 5.86rem)', marginRight: '0.8rem' }}
            key="0"
            columnCount={4}
          >
            <VSFormItem
              name={['wardInfo']}
              label="病区"
              valueType="text"
              fieldProps={{
                disabled: true,
                fieldNames: { label: 'departmentName', value: 'id' },
                placeholder: '请选择病区',
                onChange: (params) => {},
              }}
            />
            <VSFormItem
              fieldProps={{
                disabled: isWithdrawalDis,
              }}
              name={['bedInfo']}
              label="床位"
              valueType="custom"
            >
              <InquireHospitalSelect
                {...{
                  onChange: onChangeBedInfo,
                  dataSource: bedDataSource,
                  placeholder: '请选择床位',
                  columns: [
                    {
                      title: '床号',
                      dataIndex: ['bedLabel'],
                      width: 56,
                    },
                    {
                      title: '住院号',
                      dataIndex: ['displayId'],
                      width: 56,
                    },
                    {
                      title: '姓名',
                      dataIndex: ['name'],
                      width: 56,
                    },
                    {
                      title: '性别',
                      dataIndex: ['gender'],
                      width: 56,
                    },
                  ],
                }}
              />
            </VSFormItem>
            <VSFormItem
              name={['displayId']}
              label="患者ID"
              valueType="text"
              rules={[]}
              fieldProps={{
                onPressEnter: () => {},
                disabled: isWithdrawalDis,
              }}
            />
            <VSFormItem
              initialValue={null}
              name={['dischargeDrugPrescriptionIs']}
              label="出院带药"
              valueType="selectCode"
              dataSource={[
                { label: '全部', value: null },
                { label: '是', value: true },
                { label: '否', value: false },
              ]}
              fieldProps={{
                placeholder: '请选择出院带药',
                onChange: (params) => {},
                disabled: isWithdrawalDis,
              }}
            />
          </VSFormLayout>

          <ButtonAndKeyDown
            children="查询"
            type="primary"
            methodKey=""
            ordinaryKey=""
            disabled={isWithdrawalDis}
            onClick={onInquire}
          />
        </VSForm>
      </div>
      <MailTable {...{ ...props }} />
    </div>
  );
};
const Common_Column = [
  {
    width: '19rem',
    dataIndex: ['orderText'],
    title: '医嘱内容',
    valueType: 'text',
    fieldProps: {},
  },
  {
    width: '19rem',
    dataIndex: ['firmName'],
    title: '厂家',
    valueType: 'text',
    formItemProps: {
      rules: [
        {
          message: '数据格式错误',
          type: 'string',
          min: 0,
          max: 100,
        },
      ],
    },
    fieldProps: {},
  },
  {
    width: '11.2rem',
    dataIndex: ['packageSpecification'],
    title: '规格',
    valueType: 'text',
    fieldProps: {},
  },
  {
    width: '4.53rem',
    dataIndex: ['amount'],
    title: '数量',
    valueType: 'text',
    fieldProps: {},
  },
];
const onColumns = (Column) => Column?.map((ele) => <VSTableColumn {...ele} />);
// 病区处方退药表格
const MailTable = (props) => {
  const { refundApplyProps, selectedRows, setSelectedRows } = props;
  const [yMaxHeight, setYMaxHeight] = useState('528');
  getPromiseHeight('Mail')?.then((res) => {
    setYMaxHeight(res - 166);
  });
  const [data, setData] = useState([]);
  const onHandleData = (params) => {
    if (!params?.length) return setData([]);
    const datas = [];
    params?.forEach?.((ele) => {
      const { orderList, ...args } = ele;
      orderList?.forEach((olo, i) => {
        if (i === 0) olo = { ...olo, parents: args, parent: true };
        datas?.push({ ...olo, parent_id: args?.id });
      });
    });
    setData(datas);
    setSelectedRows(datas);
  };
  useEffect(
    () => !refundApplyProps?.loading && onHandleData(refundApplyProps?.data),
    [refundApplyProps?.loading],
  );

  const onTableReal = vsf?.refs?.tableInpRefundApplyOrderToPerformTable;
  const [totalData, setTotalData] = useState({
    orderText: '合计：',
    charge: 0,
  });

  const onSetTotal = (value) =>
    setTotalData?.({
      ...totalData,
      charge: value,
    });

  const onComputeTotal = (data) => {
    if (!data?.length) return 0;
    return data?.reduce((crrent, next) => {
      return add(crrent, next?.charge, 2);
    }, 0);
  };
  useEffect(() => {
    onSetTotal(onComputeTotal(selectedRows));
  }, [selectedRows]);
  const Column = [
    {
      dataIndex: ['parents', 'bedLabel'],
      title: '床号',
      valueType: 'text',
      fieldProps: {},
      width: '4rem',
    },
    {
      dataIndex: ['parents', 'name'],
      title: '姓名',
      valueType: 'text',
      fieldProps: {},
      width: '6rem',
    },
    {
      dataIndex: ['parents', 'displayId'],
      title: '患者ID',
      valueType: 'text',
      fieldProps: {},
      width: '12rem',
    },
    ...Common_Column,

    {
      width: '4.53rem',
      dataIndex: ['administration'],
      title: '途径',
      valueType: 'select',
      fieldProps: {},
      dataSource: vsf.stores.dbenums.enums.ADMINISTRATION_DICT,
    },
    {
      width: '4.53rem',
      dataIndex: ['frequency'],
      title: '频次',
      valueType: 'select',
      fieldProps: {},
      dataSource: vsf.stores.dbenums.enums.PERFORM_FREQ_DICT,
    },
    {
      width: '4.53rem',
      dataIndex: ['packageUnit'],
      title: '单位',
      valueType: 'select',
      fieldProps: {},
      formItemProps: {},
      dataSource: vsf?.stores?.dbenums?.enums?.MEASURES_DICT,
    },
    {
      dataIndex: ['prescriptionAttribute'],
      title: '属性',
      valueType: 'text',
      fieldProps: {},
    },
    {
      width: '11.2rem',
      dataIndex: ['charge'],
      title: '金额',
      valueType: 'digit',
      fieldProps: {},
      render: (_, { charge }) => askFixed(charge, 2),
    },
  ];
  return (
    <div className="InpRefundApplyOrderTable">
      <VSTable
        className="common_table"
        editable={{ editType: 'none' }}
        rowSelection={{
          selectType: 'multiple',
          selectedRows: selectedRows,
          onSelect: (data, _, value) => {
            // 普通勾选
            if (!data?.parent) {
              if (value) return setSelectedRows([...selectedRows, data]);
              return setSelectedRows(
                selectedRows?.filter((ele) => ele?.id != data?.id),
              );
            }
            const TableData = onTableReal?.getValues();
            // 勾选下all
            if (value && data?.parent) {
              setSelectedRows([
                ...TableData?.filter(
                  (ele) => data?.parent_id == ele?.parent_id,
                ),
                ...selectedRows,
              ]);
              return;
            }
            // 清理勾选all；
            if (!value && data?.parent) {
              const filters = TableData?.filter(
                (ele) => ele?.parent_id == data?.parent_id,
              )?.map((ele) => ele?.id);
              setSelectedRows(
                selectedRows?.filter((ele) => !filters?.includes(ele?.id)),
              );
              return;
            }
          },
          onSelectAll: (data) => setSelectedRows(data),
        }}
        id="tableInpRefundApplyOrderToPerformTable"
        scroll={{ y: yMaxHeight }}
        value={data}
        loading={refundApplyProps?.loading}
        pagination={false}
        bordered
      >
        {/* {onColumns(Column)} */}
        {Column?.map((ele) => (
          <VSTableColumn {...ele} />
        ))}
      </VSTable>
      <VSTable
        rowSelection={{ selectType: 'multiple' }}
        className="common_table hide_thead"
        id="tableInpRefundApplyOrderToPerformTableFooter"
        value={[totalData]}
        pagination={false}
        bordered
        showHeader={false}
      >
        {onColumns(Column)}
      </VSTable>
    </div>
  );
};
// 退药汇总
const App = (props) => {
  const { selectedRows } = props;
  const [yMaxHeight, setYMaxHeight] = useState('629');
  getPromiseHeight('App')?.then((res) => {
    setYMaxHeight(res - 56);
  });
  const onHandleData = useCallback(
    (data) => {
      if (!data.length) return [];
      const orderTextList = data?.reduce((Current, next) => {
        Current[next?.drugPriceItemId] = next?.orderText;
        return Current;
      }, {});
      let datas = [];
      Object?.keys(orderTextList)?.forEach((ele) => {
        const arr = data?.filter((d) => d?.drugPriceItemId == ele);
        datas?.push({
          id: ele,
          ...arr[0],
          amount: arr?.reduce((Current, Next) => {
            return Current + Next?.amount;
          }, 0),
          number: arr?.length,
        });
      });
      return datas;
    },
    [selectedRows],
  );
  const [data, setData] = useState([]);
  useEffect(() => setData(onHandleData(selectedRows)), [selectedRows]);
  const Column = [
    ...Common_Column,
    {
      width: '4.53rem',
      dataIndex: ['administration'],
      title: '途径',
      valueType: 'select',
      fieldProps: {},
      dataSource: vsf.stores.dbenums.enums.ADMINISTRATION_DICT,
    },
    {
      width: '4.53rem',
      dataIndex: ['frequency'],
      title: '频次',
      valueType: 'select',
      fieldProps: {},
      dataSource: vsf.stores.dbenums.enums.PERFORM_FREQ_DICT,
    },
    {
      width: '4.53rem',
      dataIndex: ['packageUnit'],
      title: '单位',
      valueType: 'select',
      fieldProps: {},
      formItemProps: {},
      dataSource: vsf?.stores?.dbenums?.enums?.MEASURES_DICT,
    },
    {
      width: '11.2rem',
      dataIndex: ['number'],
      title: '医嘱条数',
      valueType: 'text',
      fieldProps: {},
    },
  ];
  return (
    <div id="App">
      <div className="InpRefundApplyOrderTable">
        <VSTable
          className="common_table"
          id="tableInpRefundApplyListToPerformTable"
          scroll={{ y: yMaxHeight, x: '66.66rem' }}
          value={data}
          pagination={false}
          bordered
        >
          <VSTableColumn
            width="4.13rem"
            dataIndex={['index']}
            title="编号"
            valueType="text"
            fieldProps={{}}
            render={(_, __, index) => index + 1}
          />
          {onColumns(Column)}
        </VSTable>
      </div>
    </div>
  );
};
export default definePage(TableDetails);
