/* eslint-disable no-case-declarations */
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Alert,
  Button,
  List,
  message,
  Popover,
  Select,
  Tooltip,
  VSTable,
  VSTableColumn,
  VSTableEditable,
  VSTableInstance,
} from '@vs/vsf-kit';
import { useEventListener, useGetState, useMount } from 'ahooks';
import classnames from 'classnames';
import dayjs from 'dayjs';
import moment from 'moment';
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { InputUnit } from '@/components';
import { cdssShow } from '@/mock';
import Block from '@/module/Block';
import { getUUID } from '@/module/cpoe/medical/editor/utils';
import Icon from '@/module/Icon';
import Determine from '@/pages/Index/components/determine';
import {
  askFixed,
  askIsInteger,
  askSearch,
} from '@/pages/Index/components/func/ask';
import { StateValueType } from '@/pages/Index/components/func/ask/askGroupMedicalOrders';
import { useOnChangeValue } from '@/pages/Index/components/func/hooks';
import Tip from '@/pages/Index/components/func/Tip';
import onReload from '@/pages/Index/components/order_table/onReload';
import { numberAndNegative } from '@/pattern';
import { getScrollY, removeObjectFalsy } from '@/utils';
import { add } from '@/utils/setDataUtil';

import { DosageUnit } from '../../../dosage';
import Flex from '../../../flex';
import Fetch from '../../../order_table/fetch';
import { UsageUnit } from '../../../usage';
import { AuxiliaryRefs, BeIn } from '..';
import Auxiliary from '../assist/auxiliary';
import Header, { HeaderRefs } from '../assist/header';
import askGroupMedicalOrders from './askGroupMedicalOrders';
import { convertCdssOrderOutTable } from './convert';
import {
  continuousOrderClassDataSource,
  dataSourceHerb,
  drugBillingAttributeDataSource,
  drugUseDescriptionDataSource,
  orderClassDataSource,
  orderStatusDataSource,
  orderStatusDefaultValue,
  repeatIndicatorDataSource,
  requiredRule,
  tableDataIndexList,
} from './datasource';
import { RenderDistribute, RenderUseDescription } from './field';
import render from './render';
export type TableRefs = VSTableInstance<any, 'text'> | null;

export interface Ref {
  onOpen: (value?: never) => void;
}

interface BeInHospitalProps {
  auxiliary: {
    current: AuxiliaryRefs | null;
  };
  header: {
    current: HeaderRefs | null;
  };
  prefixCls?: string;
  children?: React.ReactNode;
  // filterParams: any;
  operation?: any;
  footer: {
    current: {
      group(arg0: boolean): unknown;
      click: (data) => void;
    };
  };
}

export interface Refs {
  setValue: (value) => never;
  getValue: () => never;
  onSliced: (dataSource) => any[];
  onGroup: (value?: any) => any[];
  cancelGroupDisabled: (value) => boolean;
  onCancelGroup: () => any[];
  countablePreview: (value: any) => void;
  onSave: () => Promise<boolean>;
  onStart: (value: any) => Promise<boolean>;
}

export type Value = SaveOrderEoClinicRecordOrderPersistEo &
  Omit<StateValueType, 'id'> & {
    uuid: string;
    groupIndex: number[];
    groupName: string;
    group: never;
  };

/**
 * 住院版医嘱表格
 */
const BeInHospital: any = forwardRef((props: BeInHospitalProps, ref) => {
  const { auxiliary, header, footer, operation } = props;
  const {
    common: { currentApplication },
    cdss: { cdssFocus },
    user: { inVisitInfo, currentPatient },
  } = vsf?.stores;
  const [value, setValue, getValue] = useGetState<any[]>([]);
  const id = useMemo(() => getUUID(), []);
  const [select] = useOnChangeValue<any>({});
  const tableRef = useRef<TableRefs>(null);

  const search = useRef<string[]>([]);

  const edit = useRef<string | number | null>(null);

  /**
   * 成组类型数据是否预览判断，组内第一项为编辑态，非组内第一项为预览态
   * @param param
   * @returns
   */
  const groupPreview: any = (index) => {
    const value = tableRef?.current?.getRowValue(index);
    return (
      value?.drugOrder?.groupSubNumber && value?.drugOrder?.groupSubNumber !== 1
    );
  };

  /**
   * 医嘱数据是否预览判断，可数类型数据为编辑态，不可数类型数据为预览态
   * @param param
   * @returns
   */
  const countablePreview: any = (data) => {
    const previewClassList = [
      { label: '治疗', value: 'TREAT' },
      { label: '手术', value: 'OPERATION' },
      { label: '麻醉', value: 'ANESTHESIA' },
      { label: '护理', value: 'NURSING' },
      { label: '膳食', value: 'MEAL' },
      { label: '营养', value: 'NUTRITION' },
      { label: '会诊', value: 'CONSULTATION' },
      { label: '用血', value: 'BLOOD' },
      { label: '其他', value: 'OTHER' },
    ];
    const value = previewClassList
      ?.map((item) => item?.value)
      .includes(data?.orderClass);
    return value || data?.isOther;
  };

  const repeatValidDaysRequire = (data) => {
    if (data?.repeatIndicator === false) {
      return true;
    } else {
      return data?.orderText?.prescriptionIndicator || data?.dischargeDrug;
    }
  };

  /**
   * 住院医嘱先选长/临才能编辑
   * @param data
   * @returns
   */
  const repeatIndicatorPreview = (data) => {
    return [null, void 0].includes(data?.repeatIndicator);
  };
  /**
   * 皮试的主医嘱编辑限制
   * @param data
   * @returns
   */
  const skinTestPreview = (record) => {
    return (
      record?._skinTest ||
      record?.parentRelatedUuid ||
      getValue()
        ?.map((item) => item?.parentRelatedUuid)
        ?.includes(record?.uuid)
    );
  };

  /**
   * 成组医嘱的长临不可编辑
   * @param record
   * @returns
   */
  const groupRepeatIndicatorPreview = (record) => {
    const groupName = getValue?.()?.find(
      (item) => item?.id === record?.id,
    )?.groupName;
    return groupName;
  };
  /**
   * 当长/临下拉选择“临时”且药品勾选处方，该控件可编辑；未勾选处方则不可编辑 选择“长期”时，可编辑"
   * @param record
   * @param index
   * @returns {Boolean}
   */
  const frequencyPreview = (record, index): boolean => {
    return groupPreview(index) || record?.repeatIndicator === false
      ? typeof record?.drugOrder?.prescriptionIndicator === 'boolean'
        ? !record?.drugOrder?.prescriptionIndicator
        : !record?.drugOrder?.prescriptionId
      : !record?.repeatIndicator || repeatIndicatorPreview(record);
  };

  /**
   *  根据途径判断是否必填
   */
  const administrationAmountPreview = (record) => {
    /**
     * @const showDripSpeedIndicator
     */
    const showDripSpeedIndicator =
      vsf.stores.dbenums.enums.ADMINISTRATION_DICT?.map((item) => ({
        ...item,
        ...JSON?.parse(item?.extensionJson || '{}'),
      }))?.find(
        (item) => item?.value == (record as any)?.administration,
      )?.showDripSpeedIndicator;
    return !showDripSpeedIndicator;
  };

  const group = useRef(
    askGroupMedicalOrders({
      max: 5,
      order: ['DRUG'],
      eliminates: [
        // ['startPerformDateTime'],
        ['administration'], // 途径
        ['dripSpeed'], //滴速
        ['frequency'], //频次
        ['useDescription'], //用法
        ['repeatValidDays'], //有效期
        ['dischargeDrug'], //出院带药
        ['drugBillingAttribute'], //摆药方式
        ['distribute'], //配置
      ],
      tip({ onOk, onCancel, contrast_index = [] }) {
        const Columns = vsf.refs?.inTable?.getColumns(),
          contrast_title: string[] = [];
        let i = 0;
        while (contrast_index?.length > i) {
          const dataIndex = contrast_index?.[i];
          contrast_title?.push(
            `【${
              Columns?.find((ele) => ele?.dataIndex?.[0] == dataIndex?.[0])
                ?.title
            }】`,
          );
          i++;
        }
        Tip({
          // content: '当前医嘱与上一条医嘱不一致，是否成组',
          content: `当前医嘱${contrast_title?.join(
            '与',
          )}与上一条医嘱不一致，是否确认成组`,
          onOk,
          onCancel,
        });
      },
      disables(one, two) {
        if (one?.repeatIndicator !== two?.repeatIndicator) {
          message?.warning('药品长临属性不同，无法成组');
        }
        const onePrescriptionIndicator =
          one?.dischargeDrug === true
            ? true
            : typeof one?.drugOrder?.prescriptionIndicator === 'boolean'
            ? one?.drugOrder?.prescriptionIndicator
              ? true
              : null
            : null;

        const twoPrescriptionIndicator =
          two?.dischargeDrug === true
            ? true
            : typeof two?.drugOrder?.prescriptionIndicator === 'boolean'
            ? two?.drugOrder?.prescriptionIndicator
              ? true
              : null
            : null;

        if (onePrescriptionIndicator !== twoPrescriptionIndicator) {
          message?.warning('处方和医嘱不能成组');
        }
        return [
          one?.repeatIndicator === two?.repeatIndicator,
          onePrescriptionIndicator === twoPrescriptionIndicator,
        ];
      },
    }),
  );

  const searchRef = useRef();

  const fetch = useMemo(() => {
    return {
      onDrugFetch: async (params: any) => {
        // console.log(params, 'params1');

        if (params?.searchValue?.trim() === '.') {
          return {
            data: [],
            total: 0,
          };
        }
        const { storageId, storageList, favoriteType } = params;
        let storageValue = [storageId];
        if (!storageId) {
          storageValue = storageList?.map((item) => item?.id);
        }
        if (!storageList || storageList?.length === 0) {
          return {
            data: [],
            total: 0,
          };
        } else {
          const searchParams = {
            from: params.pagination?.from ?? 0,
            inputCodeLike: params?.searchValue,
          };
          if (params?.searchValue !== searchRef.current) {
            searchParams.from = 0;
          }
          searchRef.current = params?.searchValue;
          const extraParams = removeObjectFalsy({
            departmentIdIs:
              favoriteType === 'DEPARTMENT'
                ? currentApplication?.department?.id
                : undefined,
          });
          const res =
            await vsf?.services?.DrugDrugController_queryInpOrderDrugList_d5d0a5?.(
              {
                qto: {
                  size: params.pagination?.size ?? 20,
                  orderList: params.orderList, // 排序结果
                  ...params.search, // 搜索结果
                  ...params.filters, // 过滤器结果
                  storageIdIn: storageValue,
                  isDoctorFavoriteIs: favoriteType === 'PERSONAL',
                  isDepartmentFavoriteIs: favoriteType === 'DEPARTMENT',
                  // inputCodeLike: params?.searchValue,
                  doctorIdIs: vsf?.stores?.user?.staffInfo?.id,
                  repeatIndicatorIs: params?.repeatIndicatorIs,
                  // repeatIndicatorIs
                  ...extraParams,
                  ...searchParams,
                },
              },
            );
          return {
            data: res?.data?.result ?? [],
            total: res?.data?.count ?? 0,
          };
        }
      },
      onDrugDetailsFetch: async (value) => {
        const drugItemId = value?.drugItemId;
        if (drugItemId) {
          const res: any =
            await vsf?.services?.ClinicRecordOrderDrugController_getOrderDrugAndValid_31540a?.(
              {
                drugItemId: drugItemId,
                patientId: 1001,
                departmentId: 193,
                staffId: vsf?.stores?.user?.staffInfo?.id,
              },
            );
          return res?.data;
        }
        return [];
      },
    };
  }, [currentApplication?.department?.id]);

  const onDrug = async (isOther?: boolean) => {
    const record = {
      id: 'create' + Math.random(),
      uuid: getUUID(),
      orderStatus: 'OPEN',
      is_one: true,
      isOther,
      startPerformDateTime: dayjs().format('YYYY/MM/DD HH:mm:ss')?.valueOf(),
    };
    const save = await onSave();
    if (save) {
      tableRef?.current?.add(record as any)?.then(() => {
        return onStart(record?.id);
      });
    }
  };

  const onStart = useCallback((id) => {
    const start = tableRef?.current?.startEdit(id);
    edit.current = id;
    footer?.current?.group(!start);
    return start;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // 未填写字段时，跳到需要填空的第一个
  const validateFieldsReturnFormatValueScrollTo = useCallback(async () => {
    const tableReal = vsf.refs?.inTable,
      element = document
        ?.querySelector('#inTable')
        ?.querySelector('.ant-table-body');
    tableReal
      ?.getEditForm()
      ?.validateFieldsReturnFormatValue()
      .then((res) => console.log(res))
      .catch((err) => {
        const Columns = tableReal?.getColumns(),
          empty_first = err?.errorFields?.[0]?.name?.[1],
          empty_first_index = Columns?.findIndex(
            (ele) => empty_first == ele?.dataIndex?.[0],
          );
        element?.scrollTo(
          Number(
            Columns?.slice(0, empty_first_index)?.reduce((Current, next) => {
              return add(Current, next?.width, 0);
            }, 0) /
              2 -
              40,
          ),
          element?.scrollHeight,
        );
      });
    return await tableReal?.getEditForm()?.validateFieldsReturnFormatValue();
  }, []);
  const onSave = useCallback(async () => {
    await validateFieldsReturnFormatValueScrollTo();
    const save = await tableRef?.current?.saveEdit();
    footer?.current?.group(Boolean(save));
    if (save) {
      edit.current = null;
    }
    return save;
  }, [footer, validateFieldsReturnFormatValueScrollTo]);

  const getGroup = useCallback(async (data) => {
    const value = tableRef?.current?.getEditForm()?.getFieldsValue(true)?.[
      edit?.current ?? ''
    ];

    const __info__ = data ?? {
      id: edit?.current,
      ...value,
    };

    const drugItemId =
      __info__?.drugOrder?.drugItemId ?? __info__?.orderText?.drugItemId;
    let res: any = {};
    if (drugItemId) {
      res =
        await vsf?.services?.ClinicRecordOrderDrugController_getOrderDrugAndValid_31540a?.(
          {
            drugItemId,
            patientId: 1001,
            departmentId: 193,
            staffId: vsf?.stores?.user?.staffInfo?.id,
          },
        );
    }
    const init = res?.data
      ? {
          amountPerPackage: res?.data?.amountPerPackage ?? 0,
          dosagePerUnit: res?.data?.dosagePerUnit ?? 0,
          roundingType: res?.data?.roundingType ?? 'BY_AMOUNT',
        }
      : {};
    return {
      ...__info__,
      ...init,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 横向滚动医嘱正文贴边固定
   */
  const table = document.querySelector(`[data-id="${id}"] .ant-table-body`);
  useEventListener(
    'scroll',
    ({ target }) => {
      const th =
        document?.querySelectorAll(
          `[data-id="${id}"] .aspirin-table-order-cell`,
        ) ?? [];
      if (target?.scrollLeft >= 90) {
        for (const item of th) {
          item?.classList?.add('aspirin-table-order-cell-left');
        }
      } else {
        for (const item of th) {
          item?.classList?.remove('aspirin-table-order-cell-left');
        }
      }
    },
    {
      target: table,
    },
  );

  /**
   * 成组
   * @param data
   */
  const onGroup = useCallback(
    async (data?: any) => {
      const form = tableRef?.current?.getEditForm();
      const key = edit?.current;
      const save = await form?.validateFields([
        [key, 'orderText'],
        [key, 'usage'],
        [key, 'dosage'],
      ]);
      if (save) {
        const value = await getGroup(data);
        const [or] = await group.current?.onGroup(value, getValue());
        if (or) {
          const slice = group.current?.onSliced(or);
          const edit = slice?.find((item) => item?.id == key);
          form?.setFieldValue(
            [key ?? '', 'totalAmount'],
            edit?.drugOrder?.totalAmount,
          );
          setValue(slice);
          new Promise((resolve, reject) => {
            const save = tableRef?.current?.cancelEdit();
            save ? resolve(save) : reject(save);
          }).then(() => {
            form?.resetFields();
          });
        }
      }
    },
    [getGroup, getValue, setValue],
  );

  /**
   * 取消成组
   */
  const onCancelGroup = (value) => {
    const [, data = []] =
      group.current.onCancelGroup(getValue(), value ?? select?.value) || [];
    for (const item of data) {
      tableRef?.current?.update(item);
    }
  };

  /**
   * 插入一条数据
   */
  const onInsert = useCallback(
    async (_info?: any) => {
      if (await onSave?.()) {
        const list = getValue() as any;
        const index = list?.findIndex((item) => item?.id === _info?.id);
        const newly = list[index ?? ''];
        const data = {
          id: 'create' + Math.random(),
          uuid: getUUID(),
          orderStatus: 'OPEN',
          is_one: true,
          /**
           * 长/临类型需要和插入的一致
           */
          repeatIndicator: newly?.repeatIndicator,
          /**
           * 记录这条数据是不是插入的
           */
          insert: true,
          startPerformDateTime: dayjs(
            dayjs(_info?.startPerformDateTime).valueOf() - 36000,
          ).format('YYYY/MM/DD HH:mm:ss'),
        };
        /**
         * 判断如果插入组内直接成组
         */
        if (
          newly?.drugOrder?.groupSubNumber &&
          newly?.drugOrder?.groupSubNumber !== 1
        ) {
          const value = list?.toSpliced(index, 0, {
            ...data,
            orderClass: 'DRUG',
          });
          const [and] = await group.current?.intoGroups(
            await getGroup(data),
            value,
            'splice',
          );
          if (and) {
            setValue(group.current?.onSliced(and));
            setTimeout(() => {
              onStart(data?.id);
            }, 200);
          }
        } else {
          setValue(list?.toSpliced(index, 0, data));
          setTimeout(() => {
            onStart(data?.id);
          }, 200);
        }
      }
    },
    [getGroup, getValue, onSave, onStart, setValue],
  );

  /**
   * 表格行单/双击操作
   */
  const onTableRowOperation = {
    click: async (data) => {
      if (cdssShow) {
        const _save = await onSave();

        if (!_save) return;
        const _record = convertCdssOrderOutTable(
          [{ ...data }],
          { inpVisitId: inVisitInfo?.inpVisitId },
          currentPatient.patientId,
        );

        cdssFocus(
          {
            patientId: currentPatient.patientId,
            inpVisitId: inVisitInfo?.inpVisitId,
          },
          data?.orderStatus == 'OPEN'
            ? { ..._record?.[0], orderStatus: null }
            : _record?.[0],
        );
      }
      select?.onChange(data);
      footer.current.click?.(data);
    },
    doubleClick: (target) => {
      const {
        examOrder,
        herbOrder,
        labOrder,
        orderClass,
        operationOrder,
        pathologyOrder,
        orderStatus,
      } = target || {};
      if (!['SAVE', 'OPEN', 'AUDIT_FAIL'].includes(target?.orderStatus)) {
        message?.warning('只有开立和保存可以修改');
        return;
      }
      if (
        target?.parentRelatedUuid ||
        (target?._skinTest?.[0] !== target?.id && target?._skinTest)
      ) {
        message?.warning('皮试带药不可编辑');
        return;
      }
      const METHOD = {
        DRUG: async () => {
          onSave()?.then(() => tableRef?.current?.startEdit(target?.id));
        },
        HERB: () => {
          auxiliary?.current?.onHerb({
            ...herbOrder,
            performDepartment: target?.performDepartment,
            uuid: target?.uuid,
          });
        },
        LAB: () => {
          auxiliary?.current?.onLab({
            ...labOrder,
            allPrice: target?.allPrice,
            performDepartment: target?.performDepartment,
            uuid: target?.uuid,
          });
        },
        EXAM: () => {
          auxiliary?.current?.onExam({
            uuid: target?.uuid,
            ...examOrder,
            patientSpecialInfo: target?.patientSpecialInfo,
            performDepartment: target?.performDepartment,
            orderDiagnosisIdList: target?.orderDiagnosisIdList,
            memo: target?.memo,
          });
        },
        OPERATION: () => {
          auxiliary?.current?.onOperation({
            uuid: target?.uuid,
            ...operationOrder,
            orderDiagnosisIdList: target?.orderDiagnosisIdList,
            patientSpecialInfo: target?.patientSpecialInfo,
          });
        },
        PATHOLOGY: () => {
          auxiliary?.current?.onPathology({
            uuid: target?.uuid,
            ...pathologyOrder,
            performDepartment: target?.performDepartment,
            orderDiagnosisIdList: target?.orderDiagnosisIdList,
          });
        },
      };
      if (METHOD?.[orderClass]) {
        METHOD?.[orderClass]?.();
      } else {
        onSave()?.then(() => onStart?.(target?.id));
      }
    },
  };

  useImperativeHandle(ref, () => ({
    onDrug,
    setValue: (data) => {
      setValue(data);
    },
    getValue,
    onGroup,
    onOther: () => onDrug?.(true),
    ...tableRef.current,
    onSliced: group.current?.onSliced,
    onStart,
    onSave,
    onCancelGroup,
    tableRef: document.querySelector(`[data-id="${id}"] .ant-table-body`),
    countablePreview,
    id: () => edit.current,
  }));

  const handleExtraSubmit = useContext(BeIn);

  const filterParams = header.current?.getState();
  const filterValue = useMemo(() => {
    return value?.filter((item) => {
      if (filterParams?.orderDeadline === 2) {
        return (
          !filterParams?.orderFilterType ||
          filterParams?.orderFilterType === item?.orderClass
        );
      } else if (filterParams?.orderDeadline === 1) {
        return (
          (!filterParams?.orderFilterType ||
            filterParams?.orderFilterType === item?.orderClass) &&
          item?.repeatIndicator
        );
      } else {
        return (
          (!filterParams?.orderFilterType ||
            filterParams?.orderFilterType === item?.orderClass) &&
          !item?.repeatIndicator
        );
      }
    });
  }, [filterParams?.orderDeadline, filterParams?.orderFilterType, value]);

  const editable = useMemo(() => {
    const ret: VSTableEditable<any> = {
      editType: 'single',
      columnProps: {
        fixed: 'right',
        width: 50,
        hideInTable: true,
      },
      onCanEdit: (target) => !target?.parentRelatedUuid,
      switchMode: {
        onColumnFinish: async () => true,
        onRowFinish: async () => true,
      },
    };
    return ret;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearRowData = async (form, record) => {
    const clearValues: any = {};
    tableDataIndexList?.forEach((item) => {
      if (typeof item === 'object') {
        clearValues[item?.key] = item?.defaultValue;
      } else {
        clearValues[item] = undefined;
      }
    });
    await form?.setFieldsValue({
      [record?.id]: clearValues,
    });
  };

  const renderValueUnit = (v: any) => {
    // eslint-disable-next-line no-prototype-builtins
    if (v?.hasOwnProperty('value')) {
      return `${v?.value ?? ''}${v?.unit ?? ''}`;
    } else {
      return v ?? '';
    }
  };

  const style = useMemo(
    () => ({
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    }),
    [],
  );

  const { renderOrderText, rowClassName, renderRepeatValidDays } = useMemo(
    () =>
      render({
        select: select,
        getValue,
      }),
    [getValue, select],
  );

  return (
    <VSTable
      value={filterValue}
      pagination={false}
      editable={editable}
      data-id={id}
      scroll={{
        y: getScrollY(500),
        x: 3400,
      }}
      onRow={(record) => ({
        onDoubleClick: () => {
          onTableRowOperation?.doubleClick?.(record);
        },
        onClick: async () => {
          onTableRowOperation?.click(record);
        },
      })}
      ref={tableRef}
      rowClassName={rowClassName}
      bordered
      onChange={(value) => {
        setValue(value);
      }}
      onRecord={async () => {
        const record = {
          id: 'create' + Math.random(),
          uuid: getUUID(),
          orderStatus: 'OPEN',
          is_one: true,
          isOther: false,
          startPerformDateTime: dayjs()
            .format('YYYY/MM/DD HH:mm:ss')
            ?.valueOf(),
        };
        edit.current = record?.id;
        return record;
      }}
      className="table_overflow_530 aspirin-table aspirin-table-nopadding aspirin-table-order aspirin-row-select-hover"
      id="inTable"
    >
      <VSTableColumn
        title="状态"
        valueType="select"
        dataIndex={['orderStatus']}
        dataSource={orderStatusDataSource}
        fieldProps={{
          defaultValue: orderStatusDefaultValue,
        }}
        preview
        width={100}
      />
      <VSTableColumn
        title="类别"
        dataIndex={['orderClass']}
        valueType="select"
        dataSource={orderClassDataSource}
        preview
        width={45}
      />
      <VSTableColumn
        title="开始时间"
        dataIndex={['startPerformDateTime']}
        valueType="aspirinDateTimePicker"
        fieldProps={{
          format: 'YYYY/MM/DD HH:mm:ss',
          previewFormat: 'MM/DD HH:mm',
          datePickerProps: {
            disabledTime: () => {
              const currentHour = moment().hour();
              const currentMinute = moment().minute();
              return {
                disabledHours: () => {
                  const disabledHours: any = [];
                  for (let i = 0; i < 24; i++) {
                    if (i < currentHour - 6 || i > currentHour + 6) {
                      disabledHours.push(i);
                    }
                  }
                  return disabledHours;
                },
                disabledMinutes: (selectedHour) => {
                  if (selectedHour === currentHour - 6) {
                    return Array.from({ length: currentMinute }, (v, k) => k);
                  }
                  if (selectedHour === currentHour + 6) {
                    return Array.from({ length: 60 }, (v, k) => k).slice(
                      currentMinute + 1,
                    );
                  }
                  return [];
                },
              };
            },
          },
          valueType: 'timestamp',
        }}
        width={100}
        render={(...[, record]) => {
          const { startPerformDateTime } = record || {};
          return (
            <Flex justify="center">
              {startPerformDateTime
                ? dayjs(startPerformDateTime).format('MM/DD HH:mm')
                : ''}
            </Flex>
          );
        }}
        preview={(...[, record]) => {
          return groupRepeatIndicatorPreview(record) || skinTestPreview(record);
        }}
      />
      <VSTableColumn
        title="长/临"
        dataIndex={['repeatIndicator']}
        valueType="repeatIndicatorSelect"
        dataSource={repeatIndicatorDataSource}
        width={90}
        formItemProps={requiredRule}
        fieldProps={(record) => {
          return {
            /** 修改类型清空已填项 */
            async onChange(repeatIndicator) {
              const form: any = tableRef?.current?.getEditForm() || {};
              if (record?.orderClass === 'DRUG') {
                await form?.resetFields([
                  ['dosage'],
                  ['usage'],
                  ['administration'],
                  ['dripSpeed'],
                  ['frequency'],
                  ['useDescription'],
                  ['repeatValidDays'],
                  ['totalAmount'],
                  ['drugBillingAttribute'],
                ]);
                await clearRowData(form, record);
                /**
                 * @type {DRUG and OTHER}
                 * 当长/临下拉选择“临时”时且未勾选处方，频次默认“ONCE”（通过在码表上增加扩展字段标记哪个频次是once
                 * 当选择“长期”时或选则“临时”且勾选处方，如果所选药品有绑定的默认的频次（DRUG_TRADE_USAGE.FREQUENCY不为空），且该频次是范围值内，则默认该频次
                 */
                const frequency =
                  vsf.stores.dbenums.enums.PERFORM_FREQ_DICT?.map((item) => {
                    return {
                      ...item,
                      ...JSON?.parse(item?.extensionJson ?? '{}'),
                    };
                  })?.find(({ frequency }) => frequency === 'once');
                await form?.setFieldsValue({
                  [record?.id]: {
                    repeatIndicator,
                    ...(repeatIndicator
                      ? {}
                      : {
                          frequency,
                        }),
                  },
                });
              } else {
                form?.resetFields([
                  [record?.id, 'frequency'],
                  [record?.id, 'repeatValidDays'],
                ]);
              }
              setTimeout(() => {
                form.getFieldInstance([record?.id, 'orderText'])?.focus();
              }, 100);
            },
            record,
          };
        }}
        render={(...[, record]) => {
          return (
            <Flex justify="center">
              {
                repeatIndicatorDataSource?.find(
                  (item) => item?.value === !!record?.repeatIndicator,
                )?.label
              }
            </Flex>
          );
        }}
        preview={(...[, record]) => {
          return groupRepeatIndicatorPreview(record) || skinTestPreview(record);
        }}
      />
      <VSTableColumn
        title="医嘱"
        dataIndex={['orderText']}
        valueType="dictionary"
        render={renderOrderText}
        fieldProps={(...args) => {
          const [record] = args;
          const recode = value?.find((item) => item?.id === record?.id);
          return {
            isCoder: true,
            width: '100%',
            handleExtraSubmit,
            onDrugFetch: fetch?.onDrugFetch,
            onDrugDetailsFetch: fetch?.onDrugDetailsFetch,
            isOther: countablePreview(record) || record?.isOther,
            hospitalType: 'in',
            repeatIndicator: record?.repeatIndicator,
            startPerformDateTime: record?.startPerformDateTime,
            className: recode?.groupName,
            dataSource: getValue(),
            record: recode,
            getDisabled: group?.current?.disabled,
            onChange: async (orderText) => {
              //临时医嘱的频次默认为once且不允许修改
              const frequency = vsf.stores.dbenums.enums.PERFORM_FREQ_DICT?.map(
                (item) => {
                  return {
                    ...item,
                    ...JSON?.parse(item?.extensionJson ?? '{}'),
                  };
                },
              )?.find(({ frequency }) => frequency === 'once');

              const form: any = tableRef?.current?.getEditForm() || {};
              const key = record?.id;
              const { id, detailInfo, ...rest } = orderText || {};

              form?.resetFields(
                [
                  ['dosage'],
                  ['usage'],
                  ['administration'],
                  ['dripSpeed'],
                  ['frequency'],
                  ['useDescription'],
                  ['repeatValidDays'],
                  ['totalAmount'],
                  ['drugBillingAttribute'],
                ]?.map((item) => [key, ...item]),
              );

              const DischargeMedication = await Fetch.getPatientPreDischarge(
                vsf?.stores?.user?.inVisitInfo?.inpVisitId,
              );

              const orderClass = rest?.orderClass ?? detailInfo?.orderClass;
              switch (orderClass) {
                case 'DRUG':
                  {
                    // eslint-disable-next-line no-case-declarations
                    const {
                      drugEo: {
                        groupNumber,
                        groupSubNumber,
                        useDescription,
                        ...drug
                      },
                      source,
                    } = detailInfo || {};
                    const int = askFixed(
                      (drug?.dosage ?? 0) / (detailInfo?.dosagePerUnit ?? 0),
                      2,
                    );
                    let newDrug = drug;
                    let extraObj = {};
                    if (typeof rest?.prescriptionIndicator === 'boolean') {
                      extraObj = {
                        prescriptionIndicator: rest?.prescriptionIndicator,
                      };
                      const { prescriptionIndicator, ...restDrug } = drug;
                      newDrug = restDrug;
                    }
                    const drugOrder = {
                      ...(newDrug ?? {}),
                      drugBillingAttribute:
                        source == 'IN_HOSPITAL' ? 'NORMAL' : source,
                      unitPrice: detailInfo?.retailPrice, // 存疑
                      dosage: {
                        value: drug?.dosage,
                        unit: drug?.dosageUnit ?? detailInfo?.unit,
                      },
                      usage: {
                        value: Number(int),
                        unit: drug?.usageUnit,
                      },
                      // frequency,
                      toxicCode: detailInfo?.drugEo?.toxicCode,
                      totalAmount: {
                        unit:
                          vsf?.stores?.dbenums?.enums?.MEASURES_DICT?.find(
                            (item) => item?.value == drug?.packageUnit,
                          )?.label ?? drug?.packageUnit,
                        value: record?.repeatIndicator
                          ? 0
                          : rest?.prescriptionIndicator
                          ? 0
                          : frequency?.frequencyCount,
                      },
                      skinTestContinueUseIndicator:
                        rest?.skinTestContinueUseIndicator,
                      skinTestContinueReason: rest?.skinTestContinueReason,
                      ...extraObj,
                      ...(record?.repeatIndicator === false
                        ? {
                            ...(rest?.prescriptionIndicator
                              ? {
                                  prescriptionIndicator:
                                    rest?.prescriptionIndicator,
                                  frequency: undefined,
                                }
                              : {
                                  frequency,
                                }),
                          }
                        : {
                            frequency,
                          }),
                      // frequency,
                      amountPerPackage: detailInfo?.amountPerPackage,
                      dosagePerUnit: detailInfo?.dosagePerUnit,
                    };
                    if (Object?.keys?.(drugOrder).includes('id'))
                      delete drugOrder?.id;
                    form.setFieldsValue({
                      [key]: {
                        orderClass: 'DRUG',
                        drugOrder,
                        ...drugOrder,
                        // 患者已有出院通知且开立处方时，默认勾选；否则不勾选. DischargeMedication有无出院通知 rest?.prescriptionIndicator 是否是处方
                        // 非处方不勾选
                        dischargeDrug: rest?.prescriptionIndicator
                          ? DischargeMedication && rest?.prescriptionIndicator
                            ? true
                            : false
                          : null,
                        drugPriceItemId: drug?.drugPriceItemId,
                        performDepartmentId: rest?.storageDepartmentId,
                        performDepartment: {
                          departmentName: rest?.storageName,
                          id: rest?.storageDepartmentId,
                        },
                        uuid: record?.uuid ?? getUUID(),
                      },
                    });
                  }
                  break;
                case 'TREAT':
                case 'OTHER': {
                  const data = {
                    orderClass,
                    orderText: rest?.clinicItemName,
                    disposalOrder: {
                      itemId: rest?.clinicItemId,
                      itemName: rest?.clinicItemName,
                    },
                  };
                  form?.setFieldsValue({
                    [key]: data,
                  });
                  const index = value?.findIndex(
                    (item) => item?.id === record?.id,
                  );
                  const is = frequencyPreview(record, index);
                  if (!is) {
                    table?.scrollTo(500, table.scrollHeight);
                    form.getFieldInstance([record?.id, 'frequency'])?.focus?.();
                  }
                }
              }
            },
          };
        }}
        width={286}
        preview={(...[, record]) => {
          return skinTestPreview(record) || repeatIndicatorPreview(record);
        }}
        className="aspirin-table-order-cell"
        formItemProps={{
          rules: [
            {
              required: true,
            },
          ],
        }}
      />
      <VSTableColumn
        title="剂量"
        dataIndex={['dosage']}
        valueType="dosage"
        width={100}
        formItemProps={{
          rules: [
            {
              validator: (...[, value]) => {
                const target = value?.value ?? value;
                return target
                  ? target <= 0
                    ? Promise.reject?.('剂量不能小于0')
                    : Promise?.resolve()
                  : Promise?.reject('剂量不能为空');
              },
            },
          ],
        }}
        fieldProps={(record) => {
          return {
            record: value?.find((item) => item?.id === record?.id),
            allowClear: true,
            dataSource: value,
            id: String(record?.id),
            path: {
              performDays: ['performDays'],
              totalAmount: ['totalAmount'],
              frequency: ['frequency'],
              usage: ['usage'],
            },
          };
        }}
        render={(...[, record]) => <DosageUnit value={record?.dosage} />}
        preview={(...[, record]) =>
          countablePreview(record) || repeatIndicatorPreview(record)
        }
      />
      <VSTableColumn
        title="用量"
        dataIndex={['usage']}
        valueType="usage"
        width={70}
        formItemProps={{
          rules: [
            {
              validator: (...[, value]) => {
                const target = value?.value ?? value;
                return target
                  ? target <= 0
                    ? Promise.reject?.('用量不能小于0')
                    : Promise?.resolve()
                  : Promise?.reject('用量不能为空');
              },
            },
          ],
        }}
        fieldProps={(record) => {
          return {
            dataSource: value,
            record: value?.find((item) => item?.id === record?.id),
            allowClear: true,
            path: {
              dosage: ['dosage'],
            },
            id: String(record?.id),
          };
        }}
        render={(...[, record]) => <UsageUnit value={record?.usage} />}
        preview={(...[, record, index]) =>
          countablePreview(record) || repeatIndicatorPreview(record)
        }
      />
      <VSTableColumn
        dataIndex={['administration']}
        title="途径"
        valueType="administrationSelect"
        preview={(...[, record, index]) =>
          groupPreview(index) ||
          countablePreview(record) ||
          repeatIndicatorPreview(record)
        }
        fieldProps={(record) => {
          return {
            record,
            allowClear: false,
            showSearch: true,
            placeholder: '请选择途径',
            onInputKeyDown: (event) => {
              search.current = [search?.current?.at(-1) ?? '', event?.key];
              const [one, two] = search?.current;
              if (one === '/' && two === 'Enter') {
                onGroup(void 0);
              }
            },
            onChange: () => {
              setTimeout(() => {
                const form: any = tableRef?.current?.getEditForm() || {};
                const key = record?.id;
                form?.setFieldValue(key, {
                  ...form?.getFieldValue(key),
                  dripSpeed: null,
                  administrationAmount: null,
                });
              }, 100);
            },
          };
        }}
        formItemProps={{
          rules: [
            {
              required: true,
            },
          ],
        }}
        width={100}
      />
      <VSTableColumn
        dataIndex={['dripSpeed']}
        title="滴速"
        valueType="text"
        preview={(...[, record, index]) =>
          groupPreview(index) ||
          countablePreview(record) ||
          repeatIndicatorPreview(record) ||
          administrationAmountPreview(record)
        }
        width={120}
        render={(...[, record]) => {
          return record?.dripSpeed ? `${record?.dripSpeed}滴/分` : '';
        }}
        fieldProps={{
          addonAfter: '滴/分',
          allowClear: false,
          onFocus: (event) => {
            event?.target?.select();
          },
        }}
        formItemProps={{
          rules: [
            {
              validator: (...[, value]) => {
                return value <= 0
                  ? Promise.reject('滴速不能小于0')
                  : Promise.resolve();
              },
            },
          ],
        }}
      />
      <VSTableColumn
        dataIndex={['frequency']}
        title="频次"
        valueType="frequencySelect"
        preview={(...[, record, index]) => {
          return frequencyPreview(record, index);
        }}
        width={100}
        // dataSource={(option) => {
        //   const value = vsf.stores.dbenums.enums.PERFORM_FREQ_DICT?.map(
        //     (item) => {
        //       return {
        //         ...item,
        //         ...JSON?.parse(item?.extensionJson ?? '{}'),
        //       };
        //     },
        //   );
        //   const state = option?.frequency
        //     ? value?.filter((item) =>
        //         askSearch(option?.frequency, item, 'frequency'),
        //       )
        //     : value;
        //   return state;
        // }}
        render={(...[, record]) => {
          return record?.frequency?.frequency ?? record?.frequency?.label ?? '';
        }}
        fieldNames={{
          label: 'frequency',
          value: 'frequency',
        }}
        fieldProps={(record) => {
          return {
            allowClear: false,
            placeholder: '请选择频次',
            showSearch: true,
            record,
          };
        }}
        formItemProps={{
          rules: [
            {
              required: true,
            },
          ],
        }}
      />

      <VSTableColumn
        dataIndex={['repeatValidDays']}
        title="有效期"
        valueType="text"
        preview={(...[, record, index]) => {
          return (
            groupPreview(index) ||
            countablePreview(record) ||
            repeatIndicatorPreview(record) ||
            frequencyPreview(record, index)
          );
        }}
        fieldProps={{
          allowClear: false,
          addonAfter: '天',
          onFocus: (event) => {
            event?.target?.select();
          },
        }}
        width={100}
        render={renderRepeatValidDays}
        formItemProps={(...args) => {
          const [form, other] = args;
          const data = form.getFieldValue([other?.rowKey?.[0] as string]);
          const isNotRequired = continuousOrderClassDataSource?.find(
            (item) => item?.value === data?.orderClass,
          );
          if (isNotRequired) {
            return {};
          } else {
            return {
              rules: [
                {
                  required: true,
                },
                { pattern: numberAndNegative, message: '请输入正整数' },
              ],
            };
          }
          // else if (repeatValidDaysRequire(data)) {
          //   return {
          //     rules: [
          //       {
          //         required: true,
          //       },
          //       { pattern: numberAndNegative, message: '请输入正整数' },
          //     ],
          //   };
          // }
        }}
      />

      <VSTableColumn
        dataIndex={['drugBillingAttribute']}
        title="摆药方式"
        valueType="codeTableSelect"
        fieldProps={{
          allowClear: true,
          showSearch: true,
          onFocus: () => {
            table?.scrollTo(700, table.scrollHeight);
            // form.getFieldInstance([record?.id, 'frequency'])?.focus?.();
          },
        }}
        preview={(...[, record, index]) =>
          repeatIndicatorPreview(record) ||
          groupPreview(index) ||
          countablePreview(record)
        }
        width={100}
        formItemProps={{
          rules: [
            {
              required: true,
            },
          ],
        }}
        dataSource={drugBillingAttributeDataSource}
      />
      <VSTableColumn
        dataIndex={['useDescription']}
        title="用法"
        valueType="descriptionSelect"
        fieldProps={(record) => {
          return {
            allowClear: true,
            showSearch: true,
            onChange: (v) => {
              console.log(v, 'ppopop用法修改');

              setTimeout(() => {
                const form: any = tableRef?.current?.getEditForm() || {};
                const key = record?.id;
                form?.setFieldValue(key, {
                  ...form?.getFieldValue(key),
                  deliveryDepartment: null,
                });
              }, 100);
            },
          };
        }}
        width={100}
        preview={(...[, record, index]) =>
          repeatIndicatorPreview(record) ||
          countablePreview(record) ||
          groupPreview(index)
        }
        render={(...[, record]) => {
          return <RenderUseDescription value={record?.useDescription} />;
        }}
        formItemProps={(form, recode) => {
          const record = form?.getFieldValue([recode?.rowKey]);
          /**
           * @const useDescription
           */
          // console.log(form, recode, 'form, recode');

          const useDescription =
            vsf.stores.dbenums.enums.ADMINISTRATION_DICT?.map((item) => ({
              ...item,
              ...JSON?.parse(item?.extensionJson || '{}'),
            }))?.find(
              (item) => item?.value == (record as any)?.administration,
            )?.useRequiredIndicator;
          return useDescription
            ? {
                rules: [
                  {
                    required: true,
                  },
                ],
              }
            : {};
        }}
      />
      <VSTableColumn
        dataIndex={['distribute']}
        title="配置"
        valueType="select"
        dataSource={vsf?.stores?.dbenums?.enums?.DRUG_DISTRIBUTION_DICT}
        fieldProps={{
          allowClear: true,
        }}
        width={100}
        preview={(...[, record, index]) => {
          return (
            repeatIndicatorPreview(record) ||
            (record?.prescriptionIndicator && !record?.repeatIndicator) ||
            groupPreview(index) ||
            record?.orderClass !== 'DRUG'
          );
        }}
        render={(...[, record]) => {
          return <RenderDistribute value={record?.distribute} />;
        }}
        // formItemProps={{
        //   rules: [
        //     {
        //       required: true,
        //     },
        //   ],
        // }}
      />
      <VSTableColumn
        dataIndex={['deliveryDepartment']}
        title="配送地点"
        valueType="deliveryDepartmentSelect"
        width={100}
        preview={(...[, , index]) => groupPreview?.(index)}
        fieldProps={(record) => {
          return {
            record,
          };
        }}
        formItemProps={(form, record) => {
          const list = vsf.stores.dbenums.enums.DRUG_USING_DESCRIPTION_DICT;
          const recode = list
            ?.map((item) => ({
              ...item,
              ...JSON.parse?.(item?.extensionJson ?? '{}'),
            }))
            ?.find?.((item) => item?.value === record?.entity?.useDescription);

          return recode?.deliveryIndicator
            ? {
                rules: [
                  {
                    required: true,
                  },
                ],
              }
            : {};
        }}
      />

      <VSTableColumn
        dataIndex={['dischargeDrug']}
        title="出院带药"
        valueType="check"
        preview={(...[, record, index]) =>
          groupPreview?.(index) || !record?.prescriptionIndicator
        }
        width={70}
        // render={(...[, record]) => (
        //   <Flex justify="center">
        //     {typeof record?.dischargeDrug === 'boolean'
        //       ? record?.dischargeDrug
        //         ? '是'
        //         : '否'
        //       : record?.dischargeDrug === null
        //       ? '否'
        //       : ''}
        //   </Flex>
        // )}
        // initialValue={true}
        render={(dom, value) => {
          return value?.dischargeDrug == null ? '' : dom;
        }}
        fieldProps={(row) => {
          return {
            style,
            // // 开立非处方时不可勾选“出院带药”
            // disabled: !row?.prescriptionIndicator,
          };
        }}
      />
      <VSTableColumn
        dataIndex={['memo']}
        title="备注"
        valueType="text"
        width={100}
        preview={(...[, record, index]) =>
          repeatIndicatorPreview(record) || groupPreview(index)
        }
        fieldProps={{
          onKeyDown: (event) => {
            if (event?.key === 'Tab') event?.preventDefault();
          },
          onFocus: (event) => {
            event?.target?.select();
          },
        }}
      />

      <VSTableColumn
        dataIndex={['totalAmount']}
        title="数量"
        valueType="custom"
        fieldProps={{
          allowClear: true,
          width: '100%',
        }}
        width={70}
        preview={(...[, record]) =>
          !countablePreview(record) || repeatIndicatorPreview(record)
        }
        render={(...[, record]) => {
          const { orderClass } = record || {};
          if (orderClass === 'DRUG') {
            return record?.drugOrder?.prescriptionIndicator ||
              record?.drugOrder?.prescriptionId ? (
              <UsageUnit value={record?.totalAmount} />
            ) : (
              ''
            );
          } else {
            return renderValueUnit(record?.totalAmount);
          }
        }}
        renderFormItem={(schema, record, form) => {
          const { orderClass, drugOrder, totalAmount } = record?.record || {};
          if (orderClass === 'DRUG') {
            return drugOrder?.prescriptionIndicator || drugOrder?.prescriptionId
              ? renderValueUnit(totalAmount)
              : '';
          } else {
            return (
              <InputUnit
                onChange={schema.originProps.onChange}
                style={{ width: '100%' }}
              />
            );
          }
        }}
        shouldCellUpdate={undefined}
        formItemProps={{
          rules: [
            {
              required: true,
            },
          ],
        }}
      />
      <VSTableColumn
        dataIndex={['duration']}
        title="持续时间"
        valueType="inputUnitDynamic"
        width={120}
        fieldNames={{
          label: 'staffName',
          value: 'id',
        }}
        fieldProps={{
          dataSource: [
            {
              label: '日',
              value: '日',
            },
            {
              label: '时',
              value: '时',
            },
            {
              label: '分',
              value: '分',
            },
          ],
        }}
        preview={(...[, record, index]) =>
          !continuousOrderClassDataSource?.find(
            (item) => item?.value === record?.orderClass,
          ) ||
          repeatIndicatorPreview(record) ||
          !countablePreview(record)
        }
        render={(...arr) => {
          const [, record] = arr;
          const { disposalOrder } = record || {};

          return disposalOrder?.duration
            ? `${disposalOrder?.duration}${disposalOrder?.durationUnit}`
            : '';
        }}
      />

      <VSTableColumn
        dataIndex={['doctor']}
        title="开单医师"
        valueType="text"
        width={100}
        preview
        fieldNames={{
          label: 'staffName',
          value: 'id',
        }}
        render={(...[, record]) => {
          const { doctor } = record;
          return doctor?.staffName ?? vsf?.stores?.user?.staffInfo?.staffName;
        }}
        renderFormItem={(...[, { record }]) => {
          const { doctor } = record as any;
          return doctor?.staffName ?? vsf?.stores?.user?.staffInfo?.staffName;
        }}
      />

      <VSTableColumn
        dataIndex={['stopOrderDateTime']}
        title="停止时间"
        valueType="date"
        width={170}
        preview
        render={(...arr) => {
          const [, record] = arr;
          const { stopOrderDateTime } = record || {};

          return stopOrderDateTime
            ? dayjs(stopOrderDateTime).format('YYYY-MM-DD HH:mm:ss')
            : '';
        }}
      />

      <VSTableColumn
        dataIndex={['stopOrderDoctor']}
        title="停止医师"
        valueType="select"
        width={100}
        preview
        fieldNames={{
          label: 'staffName',
          value: 'id',
        }}
      />

      <VSTableColumn
        dataIndex={['performDepartment']}
        title="执行科室"
        valueType="performDepartment"
        fieldProps={(...args) => {
          const [record] = args;
          return {
            record,
          };
        }}
        width={170}
        render={(...arr) => {
          const [, record] = arr;
          return record?.performDepartment?.departmentName ?? '';
        }}
        preview={(...[, record]) =>
          !countablePreview(record) || repeatIndicatorPreview(record)
        }
        formItemProps={{
          rules: [
            {
              required: true,
            },
          ],
        }}
      />

      <VSTableColumn
        dataIndex={['allPrice']}
        title="总价"
        valueType="text"
        fieldProps={(...args) => {
          const [record] = args;
          return {
            record,
          };
        }}
        width={100}
        preview
        render={(...[, record]) => {
          const price = askFixed(record?.allPrice ?? 0, 2);
          return Number?.isNaN(price) || !record?.allPrice ? '' : price + '元';
        }}
      />
      <VSTableColumn
        dataIndex={['submitDateTime']}
        title="开单时间"
        valueType="date"
        width={140}
        preview
        fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss', style }}
      />
      <VSTableColumn
        dataIndex={['scheduleDateTime']}
        title="预约时间"
        valueType="date"
        width={170}
        preview
        fieldProps={{ format: 'YYYY-MM-DD' }}
        render={(...[, record]) => {
          const { examOrder, operationOrder } = record || {};
          return examOrder?.scheduleDateTime
            ? dayjs(examOrder?.scheduleDateTime).format('YYYY-MM-DD')
            : operationOrder?.scheduleDateTime
            ? dayjs(operationOrder?.scheduleDateTime).format('YYYY-MM-DD')
            : '';
        }}
      />
      <VSTableColumn
        dataIndex={['edit']}
        title="操作"
        valueType="custom"
        renderFormItem={(...[, record]) => {
          return (
            <Button
              type="text"
              onClick={async () => {
                if (!record?.record?.is_one) {
                  tableRef?.current?.cancelEdit();
                } else {
                  if (record?.record?._skin) {
                    const tableData: any[] = getValue?.() ?? [];
                    tableRef?.current?.remove(
                      tableData?.filter((item) =>
                        record?.record?._skinTest?.includes(item?.id),
                      ),
                    );
                  } else if (record?.record?.drugOrder?.groupSubNumber) {
                    /**
                     * 针对插入成组的行, 需要纠正 groupSubNumber
                     */
                    const array = group?.current?.onSliced(
                      getValue().filter(
                        (item) => item?.id !== record?.record?.id,
                      ),
                    );
                    const list = await group.current?.onCancelInsert(
                      array,
                      getValue().filter(
                        (item, index) =>
                          record?.record?.groupIndex?.includes(index) &&
                          record?.record?.id !== item?.id,
                      ),
                    );
                    setValue(list);
                  } else {
                    tableRef?.current?.remove(record?.record);
                  }
                }
              }}
            >
              取消
            </Button>
          );
        }}
        render={(...[, record]) => {
          const { orderStatus, stopOrderDateTime, stopOrderDoctor } =
            record || {};
          return (
            <div className="aspirin-table-operation-container">
              <Determine
                condition={
                  orderStatus === 'PERFORM' &&
                  !!stopOrderDateTime &&
                  !!stopOrderDoctor
                }
              >
                <Tooltip placement="top" title="取消停止">
                  <Icon
                    type="icon-qiyong"
                    size={20}
                    onClick={() => operation?.stop(record)}
                  />
                </Tooltip>
              </Determine>
              <Determine
                condition={
                  orderStatus === 'AUDIT_FAIL' &&
                  [''].includes(record?.groupName)
                }
              >
                <Tooltip placement="top" title="作废">
                  <Icon
                    type="icon-zuofei"
                    size={20}
                    onClick={() => operation?.cancel(record)}
                  />
                </Tooltip>
                <Tooltip placement="top" title="撤销">
                  <Icon
                    type="icon-chexiao"
                    size={20}
                    onClick={() => operation?.undo(record)}
                  />
                </Tooltip>
              </Determine>
              <Determine
                condition={
                  ['OPEN']?.includes(record?.orderStatus) &&
                  !record?.parentRelatedUuid &&
                  (record?.groupIndex?.length === 5
                    ? record?.groupName === 'aspirin-order-text-group-first'
                    : true)
                }
              >
                <Tooltip placement="top" title="插入">
                  <Icon
                    type="icon-xiangshangcharu"
                    size={20}
                    onClick={() => onInsert(record)}
                  />
                </Tooltip>
              </Determine>
              <Determine
                condition={
                  // !record?.parentRelatedUuid ||
                  // ['', 'AUDIT_FAIL'].includes(record?.orderStatus)
                  ((['SAVE', 'OPEN']?.includes(record?.orderStatus) &&
                    !record?.parentRelatedUuid) ||
                    (record?.orderStatus === 'AUDIT_FAIL' &&
                      record?.removableIndicator)) &&
                  !record?.parentRelatedUuid
                }
              >
                <Tooltip placement="top" title="删除">
                  <Icon
                    type="icon-wudishanchu"
                    size={20}
                    onClick={() => operation?.delete(record)}
                  />
                </Tooltip>
              </Determine>
            </div>
          );
        }}
        fixed="right"
        width={110}
        shouldCellUpdate={undefined}
      />
    </VSTable>
  );
});

BeInHospital.displayName = 'BeInHospital';
BeInHospital.Header = Header;
BeInHospital.Auxiliary = Auxiliary;
export default BeInHospital;
