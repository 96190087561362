import './index.less';
import './convert';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Section,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Icon from '@/module/cpoe/Icon';
import { positive, positiveMessage } from '@/pattern';
import { getScrollY, labelNumberWidth } from '@/utils';
import { calculateAge, labelColWidth } from '@/utils/index';
import { add } from '@/utils/setDataUtil';
import storage from '@/utils/storage';

import {
  convertCreateChargeSolutionForm2CreateChargeSolutionReq,
  convertGetChargeSolutionDetailByIdRes2EditChargeSolutionControlledForm,
  convertGetChargeSolutionListRes2ChargeSolutionListRo,
} from './convert';
import Edit from './edit';

const Index = () => {
  // table
  const onFetch_t7212 = useCallback(async (params) => {
    const res =
      await vsf?.services?.ChargeSolutionDtoController_getChargeSolutionList_114af0?.(
        {
          qto: {
            from: params?.pagination?.from ?? 0,
            size: params?.pagination?.size ?? 20,
            ...params?.search,
            ...params?.extra,
            ...(params ?? {}),
          },
        },
      ); // TODO: 请确认入参来源及出参用法
    const output = convertGetChargeSolutionListRes2ChargeSolutionListRo?.(res);
    return {
      data: output ?? [],
      total: output?.length ?? 0,
    };
  }, []);
  // 编辑
  // onUpdate={async (event) => {
  //   const res =
  //     await vsf?.services?.ChargeSolutionDtoController_getChargeSolutionDetailById_41f387?.(
  //       { chargeSolutionId: chargeSolutionId },
  //     ); // TODO: 请确认入参来源及出参用法
  //   const output =
  //     convertGetChargeSolutionDetailByIdRes2EditChargeSolutionControlledForm?.(
  //       res,
  //     );
  //   return output;
  // }}

  return (
    <div className="special_charge_scheme">
      <VSPromiseTable
        editable={{
          editType: 'single',
          editText: <Icon type="icon-Frame-21" className="icon18" />,
          deleteText: <Icon type="icon-shanchuwudi" className="icon18" />,
          columnProps: {
            width: 90,
          },
        }}
        id="chargeSolutionList"
        pagination={false}
        className="
            aspirin-table-header-background-color
           aspirin-table-row-hover-background-color
           aspirin-table-nopadding
           aspirin-table-body-empty-transparent-background-color
           "
        rowClick={{
          onRowClick: (_value) => {},
        }}
        searchConfig={{
          labelAlign: 'left',
          // labelWidth: '20%',
        }}
        scroll={{
          x: 1000,
          // y: getScrollY(350),
          y: labelNumberWidth(32),
        }}
        onFetch={onFetch_t7212}
        onRemove={async (params) => {
          const res =
            await vsf?.services?.ChargeSolutionBOController_deleteChargeSolutionById_423c88?.(
              { chargeSolutionId: params?.id },
            );
          if (res?.code == 200) {
            message.success('收费方案删除成功');
          }
          return res?.code === 200;
        }}
        updateLinkPage={{
          linkPage: () => <Edit />,
          modalProps: {
            title: `编辑收费方案`,
            className: 'aspirin-linkpage width1100 height650',
            // width: editModalWidth,
            centered: true,
            bodyStyle: {
              overflowY: 'scroll',
              overflowX: 'hidden',
              height: 520,
            },
          },
          onLinkPageValidate: async (params) => {
            console.log(params, 'params');

            try {
              const ret =
                await vsf.refs.createChargeSolutionForm?.validateFieldsReturnFormatValue();
              return ret;
            } catch (e) {
              console.log(e);

              return false;
            }
          },
        }}
        onUpdate={async (values) => {
          // TODO: 请确认入参来源及出参用法
          let output =
            convertCreateChargeSolutionForm2CreateChargeSolutionReq?.(values);

          if (output?.btoParam?.judgeWay == 'PATIENT_BASE_INFO') {
            // chargeSolutionJudgeConditionBtoList
            output = {
              ...output,
              btoParam: {
                ...output.btoParam,
                chargeSolutionJudgeConditionBtoList:
                  output.btoParam?.chargeSolutionJudgeConditionBtoList
                    ?.filter((i) => i?.judgeConditionType)
                    ?.map(({ id, ..._v }) => {
                      return { ..._v };
                    }),
              },
            };
          } else if (output?.btoParam?.judgeWay == 'CHARGE_TYPE') {
            // judgeConditionList
            output = {
              ...output,
              btoParam: {
                ...output.btoParam,
                chargeSolutionJudgeConditionBtoList:
                  output.btoParam?.judgeConditionList
                    ?.filter(
                      (i) =>
                        i?.limitChargeType && i?.limitIdentityType?.length > 0,
                    )
                    ?.map((i) => {
                      return i?.limitIdentityType?.map((item) => {
                        return {
                          limitChargeType: i?.limitChargeType,
                          limitIdentityType: item?.code ?? item,
                        };
                      });
                    })
                    .flat(),
              },
            };
            console.log(output, 'outputoutput');
          }
          const res =
            await vsf?.services?.ChargeSolutionBOController_updateChargeSolution_691afa?.(
              output,
            );

          if (res?.code == 200) {
            message.success('收费方案编辑成功');
          }
          vsf?.refs?.chargeSolutionList?.reload();
          return values;
        }}
        addLinkPage={{
          linkPage: () => <Edit />,
          modalProps: {
            title: `新增收费方案`,
            className: 'aspirin-linkpage width1100',

            // width: editModalWidth,
            centered: true,
            bodyStyle: {
              overflowY: 'scroll',
              overflowX: 'hidden',
              height: 520,
            },
          },
          onLinkPageValidate: async (params) => {
            try {
              const ret =
                await vsf.refs.createChargeSolutionForm?.validateFieldsReturnFormatValue();
              return ret;
            } catch (e) {
              return false;
            }
          },
        }}
        onAdd={async (params) => {
          let output =
            convertCreateChargeSolutionForm2CreateChargeSolutionReq?.(params);

          if (output?.btoParam?.judgeWay == 'PATIENT_BASE_INFO') {
            // chargeSolutionJudgeConditionBtoList
            output = {
              ...output,
              btoParam: {
                ...output.btoParam,
                chargeSolutionJudgeConditionBtoList:
                  output.btoParam?.chargeSolutionJudgeConditionBtoList?.map(
                    ({ id, ..._v }) => {
                      return { ..._v };
                    },
                  ),
              },
            };
          } else if (output?.btoParam?.judgeWay == 'CHARGE_TYPE') {
            // judgeConditionList
            output = {
              ...output,
              btoParam: {
                ...output.btoParam,
                chargeSolutionJudgeConditionBtoList:
                  output.btoParam?.judgeConditionList
                    ?.filter(
                      (i) =>
                        i?.limitChargeType && i?.limitIdentityType?.length > 0,
                    )
                    ?.map((i) => {
                      return i?.limitIdentityType?.map((item) => {
                        return {
                          limitChargeType: i?.limitChargeType,
                          limitIdentityType: item?.code ?? item,
                        };
                      });
                    })
                    .flat(),
              },
            };
            console.log(output, 'outputoutput');
          }

          const res =
            await vsf?.services?.ChargeSolutionBOController_createChargeSolution_27fbf3?.(
              output,
            );
          if (res?.code == 200) {
            message.success('收费方案新增成功');
          }
          vsf?.refs?.chargeSolutionList?.reload();
          return res?.data;
        }}
      >
        <VSTableColumn
          dataIndex={['chargeSolutionNameLike']}
          title="收费方案名称"
          valueType="text"
          search
          order={0}
          columnKey={'chargeSolutionNameLike'}
          hideInTable
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['chargeSolutionCodeLike']}
          title="方案ID"
          valueType="text"
          search
          order={0}
          columnKey={'chargeSolutionCodeLike'}
          hideInTable
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['chargeSolutionCode']}
          title="收费方案ID"
          valueType="text"
          fieldProps={{}}
          // width={100}
        />

        <VSTableColumn
          dataIndex={['chargeSolutionName']}
          title="方案名称"
          valueType="text"
          fieldProps={{}}
          width={150}
          ellipsis
        />

        <VSTableColumn
          dataIndex={['startDate']}
          title="启用日期"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['stopDate']}
          title="停用日期"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['createStaffName']}
          title="创建人"
          valueType="text"
          fieldProps={{}}
          width={120}
          ellipsis
        />

        <VSTableColumn
          dataIndex={['createdAt']}
          title="创建日期"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['lastModifyStaffName']}
          title="最后修改人"
          valueType="text"
          fieldProps={{}}
          width={120}
          ellipsis
        />

        <VSTableColumn
          dataIndex={['updatedAt']}
          title="最后修改日期"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['solutionMemo']}
          title="方案说明"
          valueType="text"
          fieldProps={{}}
          width={150}
          ellipsis
        />
        <VSTableToolbar
          className="ant-pro-table-list-toolbar-padding"
          // title="收费方案"
        >
          <VSTableAddition children="添加" />
        </VSTableToolbar>
      </VSPromiseTable>
    </div>
  );
};

export default definePage(Index);
// 特殊收费方案维护
