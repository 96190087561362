import vsf, { defineStore } from '@vs/vsf-boot';

import storage from '@/utils/storage';

import user from './user';

export enum tip {
  applicationList,
  currentApplication,
  systemParameter,
  currentFunctionList,
  leftFunctionList,
  signSelection,
  departmentList,
  medicalType,
  patientList,
  currentPatient,
  treatInfo,
  doctorOrder,
  hospitalType,
  localParameter,
  settings,
  user,
  screenSize,
  getDoctorOrder,
  accountInfo,
  treatPatientInfo,
  outVisitInfo,
  inVisitInfo,
  outVisitTreatInfo,
  staffInfo,
  patientNumber,
  wardId,
  inVisitTreatInfo,
  orderPatientList,
  currentOrderPatient,
  rest,
  patientLabelConfig,
  erVisitTreatInfo,
  erVisitInfo,
  outRegisterInfo,
}

const common = defineStore({
  /**
   * 业务工作站列表 (layout 右上角切换数据源)
   */
  applicationList: storage?.get('applicationList') ?? [],
  /**
   * 当前业务工作站信息
   */
  currentApplication: storage.get('currentApplication') ?? null,
  /**
   * 应用参数
   */
  localParameter: storage?.get('localParameter') ?? null,
  /**
   * 系统参数
   */
  systemParameter: storage.get('systemParameter') ?? null,
  /**
   * 当前业务工作站所包含的所有功能模块列表 (一级菜单列表)
   */
  currentFunctionList: [] as any[],
  /**
   * 当前业务工作站所包含的左侧功能模块列表 (包含自启动和临时点击产生)
   */
  leftFunctionList: [] as any[],
  // 妇产科code
  obstericsDeptCode: '',
  // 获取所有业务工作站列表
  getApplicationList: async (loginId) => {
    // const res =
    //   await vsf?.services?.ApplicationDtoController_getApplication_fbf818({
    //     template: false,
    //   });
    // console.log(res);
    const res =
      await vsf?.services?.PrivilegeController_getAuthorizedApplicationList_3e355f(
        {
          loginId,
        },
      );
    common.setApplicationList(res?.data);
    // if (
    //   !res?.data
    //     ?.map((item) => item?.id)
    //     .includes(storage?.get('currentApplication')?.id)
    // ) {
    //   vsf?.navigateTo('/login');
    // }
    return res?.data;
  },

  // 获取当前业务工作站对应的功能列表
  getFunctionWithApplication: async (id) => {
    const res =
      await vsf?.services?.ApplicationFunctionGroupDtoController_getFunctionGroupByApplicationId_ddcfcd(
        {
          applicationId: id,
        },
      );
    common.setCurrentFunctionList(
      res?.data
        ?.map((item) => ({
          ...item,
          applicationFunctionVsGroupWithFunctionDtoList:
            item?.applicationFunctionVsGroupWithFunctionDtoList?.filter?.(
              (item) => !['DISPLAY', null]?.includes(item?.showType),
            ),
        }))
        ?.filter(
          (item) => item?.applicationFunctionVsGroupWithFunctionDtoList?.length,
        ),
    );
    common.setAutoLoadFunctionList(res?.data);
    return res?.data;
  },

  // 获取当前业务工作站对应标准工作站所有自启动的功能模块列表
  setAutoLoadFunctionList: (target) => {
    common.leftFunctionList = common?.onSpliced?.(target);
  },

  onSpliced: (data) => {
    const target = data?.reduce((accumulator, currentValue) => {
      return accumulator?.concat(
        currentValue?.applicationFunctionVsGroupWithFunctionDtoList
          ?.filter((aItem) => aItem?.showType === 'PAGE')
          ?.map((item) => item?.function ?? {}),
      );
    }, []);
    return target;
  },
  setObstericsDeptCode: (value) => {
    common.obstericsDeptCode = value;
    storage.set('obstericsDeptCode', value);
  },
  getSystemParameter: async () => {
    const res =
      await vsf?.services?.SystemParameterConfigDtoController_getAllBySystemParameterQto_4551c8(
        {
          qto: {},
        },
      );
    const _res = res?.data?.find((i) => i?.name == 'OBSTERICS_DEPT_CODE');

    common.setObstericsDeptCode(_res?.systemValue);
    return res?.data;
  },
  getLocalParameter: async () => {
    const res =
      await vsf?.services?.LocalParameterConfigDtoController_getAllByLocalParameterQto_f27ac9(
        {
          qto: {},
        },
      );
    common.setLocalParameter(res?.data);
    const values =
      await vsf?.services?.ApplicationParameterController_getLocalParameterValues_df5905(
        {
          names: ['CLINIC_TYPE', 'TREAT_AREA_CODE'],
        },
      );

    user?.setHospitalType(
      values?.data?.[0]?.value?.toLowerCase() === 'inp'
        ? 'in'
        : values?.data?.[0]?.value?.toLowerCase() === 'outp'
        ? 'out'
        : values?.data?.[0]?.value?.toLowerCase() === 'erp'
        ? 'er'
        : 'out',
    );
    return res?.data;
  },

  // 移除左侧功能模块
  removeFunction: (data) => {
    common.leftFunctionList = (common.leftFunctionList ?? [])?.filter(
      (item) => item?.id !== data?.id,
    );
  },

  // 新增左侧功能模块
  addFunction: (data) => {
    const target = (common.leftFunctionList ?? [])?.find(
      (item) => item?.id === data?.id,
    );
    if (!target) {
      common.leftFunctionList = [...common.leftFunctionList, data];
    }
  },

  setApplicationList: (list) => {
    common.applicationList = list;
    storage?.set('applicationList', list);
  },

  setCurrentFunctionList: (list) => {
    common.currentFunctionList = list;
  },

  setCurrentApplication: async (value) => {
    common.currentApplication = value;
    const fun = common?.onSpliced(
      await common?.getFunctionWithApplication?.(value?.templateApplicationId),
    );
    // console.log(fun, 'pppppppp');
    if (fun?.length) {
      vsf?.history?.push((fun?.[0] as any)?.source ?? '');
    } else {
      vsf?.history?.push('/index/center');
    }
    common.clearData();
    storage.set('currentApplication', value);
  },
  setSystemParameter: (value) => {
    common.systemParameter = value;
    storage.set('systemParameter', value);
  },
  setLocalParameter: (value) => {
    common.localParameter = value;
    storage.set('localParameter', value);
  },
  // 获取当前时间时间
  getServerTime: async () => {
    const res =
      await vsf?.services?.BaseCommonController_getServerTime_371274();
    if (res?.code == 200 && res?.data) {
      return res?.data;
    } else {
      return +new Date();
    }
  },
  // 切换工作站清空数据
  clearData: () => {
    user.currentPatient = null;
    user.inVisitInfo = null;
    user.outRegisterInfo = null;
    user.inVisitTreatInfo = null;
    user.outVisitInfo = null;
    user.outVisitTreatInfo = null;
    storage.set('outVisitInfo', null);
    storage.set('outRegisterInfo', null);
    storage.set('outVisitTreatInfo', null);
    storage.set('inVisitTreatInfo', null);
    storage.set('inVisitInfo', null);
  },
});

export default common;
