import {
  nameCardList,
  bedsideCardList,
  accompanyingWristband,
  convertVSPromiseTableBedPrintPreviewCard2UpdatePatientInHospitalListReq,
  convertQueryPagefallByClinicBedWithPatientQtoRes2VSPromiseTableBedPrintPreviewCard,
  convertQueryPagefallByClinicBedWithPatientQtoRes2VSPromiseTableBedPrintPreviewListRo,
  convertVSPromiseTableBedPrintPreviewListQro2QueryPagefallByClinicBedWithPatientQtoReq,
  convertQueryPagefallByClinicBedWithPatientQtoRes2VSPromiseTableBedPrintPreviewInpEscortRegisterManage,
} from './convert';
import {
  RadioGroup,
  VSPromiseTable,
  VSTableColumn,
  Tabs,
  TabPane,
  Switch,
  Button,
  message,
  Checkbox,
  Input,
  Select,
  Modal,
  Form,
  VSForm,
  VSFormItem,
  VSFormLayout,
  Image,
} from '@vs/vsf-kit';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import { QRCodeCanvas } from 'qrcode.react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import { getScrollY, labelNumberWidth } from '@/utils';
import { handleSex } from '@/utils/index';
import Block from '@/pages/Index/components/block_no_padding';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import Loading from '@/module/Loading';
import SearchContainer from '@/module/Search';
import storage from '@/utils/storage';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import vsf, { definePage } from '@vs/vsf-boot';

import './index.less';
import { isMinScreen } from '@/config';

const Index = (props) => {
  const { common } = props?.stores;
  const { currentApplication } = common;
  // 打印状态
  const [status, setStatus] = useState('all');
  // 已选择的行
  const [selectRows, setSelectRows] = useState();
  const [switchValue, setSwitchValue] = useState(false);
  // 条目总数
  const [totalNumber, setTotalNumber] = useState();
  // 打印预览list
  const [previewList, setPreviewList] = useState({ data: [], count: 0 });
  // 陪护人列表 operationType:false显示编辑 true显示保存
  const [tab, setTab] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getTotalNumber();
  }, []);
  useEffect(() => {
    // if (tab === 'card') return;
    getPrintPreviewList(
      vsf?.refs?.vSPromiseTableBedPrintPreviewList?.getSelectedRowValues(),
    );
  }, [tab]);

  // 全部/打印/未打印
  const handelChangeStatus = (v) => {
    setStatus(v);
  };
  // 获取条目总数
  const getTotalNumber = async () => {
    const reqParamSearch =
      convertVSPromiseTableBedPrintPreviewListQro2QueryPagefallByClinicBedWithPatientQtoReq(
        {
          status: 'all',
          wardId: currentApplication?.department?.id,
        },
      );
    const res =
      await vsf.services?.ClinicBedController_queryPagefallByClinicBedWithPatientQto_98f903?.(
        {
          qto: {
            from: 0,
            size: 20,
            ...reqParamSearch,
          },
          ext: {},
        },
      );
    // setTotalNumber(res?.data?.count);
  };
  // 在床患者列表
  const getClinicBedWithPatient = useCallback(
    async (params) => {
      setSelectRows([]);
      setPreviewList({ data: [], count: 0 });
      const reqParamSearch =
        convertVSPromiseTableBedPrintPreviewListQro2QueryPagefallByClinicBedWithPatientQtoReq(
          {
            status: status,
            wardId: currentApplication?.department?.id,
          },
        );
      const res =
        await vsf.services?.ClinicBedController_queryPagefallByClinicBedWithPatientQto_98f903?.(
          {
            qto: {
              from: params.pagination?.from ?? 0,
              size: params.pagination?.size ?? 200,
              ...reqParamSearch,
            },
            ext: {},
          },
        ); // TODO: 你需要自己补充入参input来源及出参output用法
      const resData =
        convertQueryPagefallByClinicBedWithPatientQtoRes2VSPromiseTableBedPrintPreviewListRo(
          res,
        );

      if (status === 'all') {
        setTotalNumber(res?.data?.count);
      }
      return {
        data: resData ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [status],
  );
  // 查询打印预览
  const getPrintPreviewList = async (data) => {
    console.log(data);
    setLoading(true);
    const paramsIdIn = data?.map((item) => {
      return item?.bedId;
    });
    if (paramsIdIn?.length <= 0) {
      setPreviewList(null);
      return setLoading(false);
    }
    const res =
      await vsf.services?.ClinicBedController_queryPagefallByClinicBedWithPatientQto_98f903?.(
        {
          qto: {
            from: 0,
            size: 1000,
            idIn: paramsIdIn,
            wardId: currentApplication?.department?.id,
          },
          ext: {
            withInpEscortRegister:
              tab === 'accompanyingWristband' ? true : null,
          },
        },
      );
    const resultFormData =
      convertQueryPagefallByClinicBedWithPatientQtoRes2VSPromiseTableBedPrintPreviewCard(
        res,
      );
    const _resultFormData = resultFormData?.map((item) => {
      return {
        ...item,
        isChecked: item?.inpEscortRegisterList?.some((i) => {
          return !i?.id;
        })
          ? false
          : true,
        inpEscortRegisterList: item?.inpEscortRegisterList?.map((_item) => {
          return {
            ..._item,
            isChecked: _item?.id ? true : false,
            operationType: _item?.id ? false : true,
          };
        }),
      };
    });
    setLoading(false);
    setPreviewList({ data: _resultFormData ?? [], count: res?.data?.count });
  };
  // 陪护腕带批量打印
  const handelPrinter = async () => {
    if (tab === 'accompanyingWristband') {
      const _inpEscortRegisterList = previewList?.data
        ?.map((item) => {
          return item?.inpEscortRegisterList;
        })
        ?.reduce((acc, val) => acc.concat(val), [])
        ?.filter((i) => {
          return i?.isChecked;
        })
        ?.map((_i) => {
          return _i?.id;
        });
      const res =
        await vsf.services?.ClinicInpVisitRecordController_updateInpEscortRegisterPrintInfo_fa160a?.(
          { idList: _inpEscortRegisterList, extApiParam: {} },
        );
      if (res?.code == 200) {
        getPrintPreviewList(
          vsf?.refs?.vSPromiseTableBedPrintPreviewList?.getSelectedRowValues(),
        );
        message.info('打印成功');
      }
      return res?.data;
    } else {
      const reqParams =
        convertVSPromiseTableBedPrintPreviewCard2UpdatePatientInHospitalListReq(
          previewList?.data,
        );
      const res =
        await vsf.services?.ClinicInpVisitController_updatePatientInHospitalList_156cdf?.(
          { btoList: reqParams?.btoList },
        );
      if (res?.code == 200) {
        getPrintPreviewList(
          vsf?.refs?.vSPromiseTableBedPrintPreviewList?.getSelectedRowValues(),
        );
        message.info('打印成功');
      }
      return res?.data;
    }
  };
  // 重置
  const handelReset = () => {
    // 清空表格和预览
    vsf?.refs?.vSPromiseTableBedPrintPreviewList?.reload();
    setSelectRows([]);
    setPreviewList({ data: [], count: 0 });
  };
  // 陪护腕带全选按钮点击逻辑
  const handelAllSelect = (v, i) => {
    const _filter = v?.inpEscortRegisterList
      ?.filter((item) => {
        // 过滤为显示编辑的
        return !item?.operationType;
      })
      ?.some((item) => {
        // 显示编辑的有一项没有选中?
        return !item?.isChecked;
      });

    if (_filter) {
      // 全选 显示编辑的有一项没有选中(isChecked有一项为false),都变成选择;显示为保存的不选中;
      const _res1 = v?.inpEscortRegisterList?.map((item) => {
        return { ...item, isChecked: item?.operationType ? false : true };
      });
      const _previewList1 = previewList?.data?.map((preItem, preIndex) => {
        if (preIndex == i) {
          return { ...preItem, inpEscortRegisterList: _res1 };
        } else {
          return { ...preItem };
        }
      });
      setPreviewList({ ...previewList, data: _previewList1 });
    } else {
      // 显示编辑的已经都选中(isChecked都为true),
      const _res2 = v?.inpEscortRegisterList?.map((item) => {
        return { ...item, isChecked: false };
      });
      const _previewList2 = previewList?.data?.map((preItem, preIndex) => {
        if (preIndex == i) {
          return { ...preItem, inpEscortRegisterList: _res2 };
        } else {
          return { ...preItem };
        }
      });
      setPreviewList({ ...previewList, data: _previewList2 });
    }
  };
  // 陪护腕带保存陪护人
  const handelSave = async (
    item,
    accompanyPersonnelIndex,
    accompanyPersonnelItem,
    data,
  ) => {
    const response =
      await vsf?.services?.ClinicInpVisitRecordController_saveInpEscortRegister_01cd8c(
        {
          btoParam: {
            ...item,
            ...accompanyPersonnelItem,
            inpVisitId: item?.inpVisitId,
          },
          extApiParam: {},
        },
      );
    if (response?.code == 200) {
      getPrintPreviewList(
        vsf?.refs?.vSPromiseTableBedPrintPreviewList?.getSelectedRowValues(),
      );
      message.success('保存成功!');
    }
  };
  // 陪护腕带陪护人编辑/保存按钮切换切换
  const handelChangeBtn = async (
    item,
    index,
    accompanyPersonnelItem,
    accompanyPersonnelIndex,
  ) => {
    const _res = previewList?.data?.map((preItem, preIndex) => {
      if (preIndex == index) {
        return {
          ...preItem,
          inpEscortRegisterList: preItem?.inpEscortRegisterList?.map(
            (escorItem, escortIndex) => {
              if (escortIndex == accompanyPersonnelIndex) {
                return {
                  ...escorItem,
                  operationType: !escorItem?.operationType,
                  isChecked: accompanyPersonnelItem?.operationType,
                };
              } else {
                return { ...escorItem };
              }
            },
          ),
        };
      } else {
        return { ...preItem };
      }
    });
    if (!accompanyPersonnelItem?.operationType) {
      setPreviewList({ ...previewList, data: _res ?? [] });
    } else {
      if (!accompanyPersonnelItem?.operationType) return;
      const _validData = await vsf?.refs?.[
        `form_${item?.inpVisitId}_${accompanyPersonnelIndex}`
      ]?.validateFieldsReturnFormatValue();
      if (!_validData) return;
      handelSave(item, accompanyPersonnelIndex, accompanyPersonnelItem, _res);
    }
  };
  // 陪护腕带增加陪护人
  const handelAdd = (v, i) => {
    // 默认无法选中,操作显示为保存
    const _res = previewList?.data?.map((item, index) => {
      if (i == index) {
        return {
          ...item,
          inpEscortRegisterList: [
            ...item?.inpEscortRegisterList,
            {
              isChecked: false,
              operationType: true,
              escortName: '',
              gender: '',
              age: '',
              escortIdentityNumber: '',
              escortPhoneNumber: '',
            },
          ],
        };
      } else {
        return { ...item };
      }
    });
    setPreviewList({
      ...previewList,
      data: _res,
    });
  };
  // 陪护腕带删除陪护人
  const handelDelete = (
    v,
    i,
    accompanyPersonnelItem,
    accompanyPersonnelIndex,
  ) => {
    if (accompanyPersonnelItem?.id) {
      // 调接口删除
      Modal.confirm({
        title: '提示',
        content: '数据已保存,是否确认删除',
        centered: true,
        onOk: async () => {
          const _response =
            await vsf?.services?.ClinicInpVisitRecordController_deleteInpEscortRegister_831020(
              {
                btoParam: {
                  id: accompanyPersonnelItem?.id,
                },
                extApiParam: {},
              },
            );
          if (_response?.code == 200) {
            getPrintPreviewList(
              vsf?.refs?.vSPromiseTableBedPrintPreviewList?.getSelectedRowValues(),
            );
            message.success('删除成功!');
          }
        },
        onCancel() {},
        okText: '确定',
        cancelText: '取消',
      });
    } else {
      const _response = previewList?.data?.map((_v, _i) => {
        if (_i == i) {
          const _res = _v?.inpEscortRegisterList?.filter((item, index) => {
            return index !== accompanyPersonnelIndex;
          });
          return { ...v, inpEscortRegisterList: _res };
        } else {
          return { ..._v };
        }
      });
      setPreviewList({ ...previewList, data: _response });
    }
  };
  // 陪护腕带是否需要半选状态
  const judgeIndeterminate = (v) => {
    const _res = v?.inpEscortRegisterList?.filter((item) => {
      return item?.isChecked;
    });

    if (_res?.length < v?.inpEscortRegisterList?.length && _res?.length !== 0) {
      return true;
    } else {
      return false;
    }
  };
  // 陪护腕带全选按钮
  const handelValue = (v) => {
    return !v?.inpEscortRegisterList?.some((item) => {
      return !item?.isChecked;
    });
  };
  // 陪护腕带Input输入
  const handelInputChange = (
    item,
    index,
    inpEscortRegisterListItem,
    inpEscortRegisterListIndex,
    label,
    value,
  ) => {
    const _res = previewList?.data?.map((_item, _index) => {
      if (index == _index) {
        return {
          ...item,
          inpEscortRegisterList: _item?.inpEscortRegisterList?.map((v, i) => {
            if (i == inpEscortRegisterListIndex) {
              return { ...v, [label]: value };
            } else {
              return { ...v };
            }
          }),
        };
      } else {
        return { ..._item };
      }
    });
    setPreviewList({ ...previewList, data: _res });
  };
  // 在床患者列表
  const renderLeftTable = () => {
    return (
      <VSPromiseTable
        vsid="39133"
        id="vSPromiseTableBedPrintPreviewList"
        pagination={false}
        // className="
        //  aspirin-table-nopadding
        //  aspirin-table-header-background-color
        //  aspirin-table-empty-background-color
        //  aspirin-table-body-empty-transparent-background-color
        //  aspirin-table-row-select-background-color-transparent
        //  aspirin-table-row-hover-background-color-transparent"
        className="table_overflow_358
        aspirin-table-header-background-color
       aspirin-table-row-hover-background-color
       aspirin-table-nopadding
       aspirin-table-body-empty-transparent-background-color
       "
        rowClick={{
          rowClickType: 'select',
        }}
        rowKey={'patientDisplayId'}
        rowSelection={{
          selectType: 'multiple',
          selectedRows: selectRows,
          onSelectedRowsChange: (data) => {
            getPrintPreviewList(data);
          },
        }}
        rowClassName={(v, c) => {
          const _data = vsf?.refs?.vSPromiseTableBedPrintPreviewList
            ?.getSelectedRowValues()
            ?.map((item) => {
              return item?.patientDisplayId;
            });
          return _data?.includes(v?.patientDisplayId)
            ? 'aspirin-table-row-select-background-color'
            : '';
        }}
        scroll={{ y: 0 }}
        onFetch={getClinicBedWithPatient}
      >
        <VSTableColumn
          dataIndex={['bedLabel']}
          title="床号"
          editable={false}
          valueType="text"
          fieldProps={{}}
          width={labelNumberWidth(2)}
        />

        <VSTableColumn
          dataIndex={['patientName']}
          title="姓名"
          editable={false}
          valueType="text"
          fieldProps={{}}
          width={labelNumberWidth(5)}
        />

        <VSTableColumn
          dataIndex={['patientDisplayId']}
          title="患者ID"
          editable={false}
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            ],
          }}
          fieldProps={{}}
          width={labelNumberWidth(4)}
        />
      </VSPromiseTable>
    );
  };
  // 姓名小卡
  const renderNameCard = () => {
    return (
      <div className="NurseStationBedsideCardPrinting_right_tabcontent_content_renderNameCard">
        {previewList?.data?.map((previewItem, i) => {
          return (
            <div
              key={previewItem?.inpVisitId}
              className="NurseStationBedsideCardPrinting_right_tabcontent_content_renderNameCard_item"
            >
              {nameCardList?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="NurseStationBedsideCardPrinting_right_tabcontent_content_renderNameCard_item_row"
                  >
                    <div
                      className={
                        item?.value == 'admissionWardDateTime'
                          ? 'icon18 NurseStationBedsideCardPrinting_right_tabcontent_content_renderNameCard_item_row_label width96'
                          : 'icon18 NurseStationBedsideCardPrinting_right_tabcontent_content_renderNameCard_item_row_label'
                      }
                    >
                      {item?.label}：
                    </div>
                    <div className="icon18 NurseStationBedsideCardPrinting_right_tabcontent_content_renderNameCard_item_row_value">
                      {item?.value !== 'patientGender' &&
                        previewItem?.[item?.value]}
                      {item?.value === 'patientGender' &&
                        handleSex(
                          previewItem?.[item?.value],
                          vsf.stores.dbenums?.enums?.SEX_DICT,
                        )?.label}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };
  // 床头卡
  const renderBedsideCard = () => {
    return (
      <div className="NurseStationBedsideCardPrinting_right_tabcontent_content_renderBedsideCard">
        {previewList?.data?.map((previewItem, i) => {
          return (
            <div
              key={i}
              className=" NurseStationBedsideCardPrinting_right_tabcontent_content_renderBedsideCard_item"
            >
              <div className="NurseStationBedsideCardPrinting_right_tabcontent_content_renderBedsideCard_item_top">
                <div className="NurseStationBedsideCardPrinting_right_tabcontent_content_renderBedsideCard_item_top_qrcode">
                  <QRCodeCanvas
                    id="qrcode"
                    className="width60 height60"
                    value={JSON.stringify({
                      patientDisplayId: previewItem?.patientDisplayId,
                      bedLabel: previewItem?.bedLabel,
                      // patientName: toUtf8(previewItem?.patientName),
                    })}
                  />
                </div>
                <div className="NurseStationBedsideCardPrinting_right_tabcontent_content_renderBedsideCard_item_top_info">
                  <div className="NurseStationBedsideCardPrinting_right_tabcontent_content_renderBedsideCard_item_top_info_name">
                    {previewItem?.patientName}
                  </div>
                  <div className="icon20 NurseStationBedsideCardPrinting_right_tabcontent_content_renderBedsideCard_item_info_id">
                    {previewItem?.patientDisplayId}
                  </div>
                </div>
              </div>
              <div className="NurseStationBedsideCardPrinting_right_tabcontent_content_renderBedsideCard_item_bottom">
                {bedsideCardList?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="icon18 NurseStationBedsideCardPrinting_right_tabcontent_content_renderBedsideCard_item_bottom_item"
                      style={{
                        width:
                          item?.value === 'admissionWardDateTime' ? '100%' : '',
                      }}
                    >
                      <div
                        className={
                          item?.value === 'admissionWardDateTime'
                            ? 'icon18 NurseStationBedsideCardPrinting_right_tabcontent_content_renderBedsideCard_item_bottom_item_label width96'
                            : 'icon18 NurseStationBedsideCardPrinting_right_tabcontent_content_renderBedsideCard_item_bottom_item_label width60'
                        }
                      >
                        {item?.label}：
                      </div>
                      <div
                        style={{
                          width:
                            item?.value === 'admissionWardDateTime' ? 270 : 100,
                        }}
                        className="NurseStationBedsideCardPrinting_right_tabcontent_content_renderBedsideCard_item_bottom_item_value"
                      >
                        {item?.value !== 'patientGender' &&
                          previewItem?.[item?.value]}
                        {item?.value === 'patientGender' &&
                          handleSex(
                            previewItem?.[item?.value],
                            vsf.stores.dbenums?.enums?.SEX_DICT,
                          )?.label}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  // 陪护腕带
  const renderAccompanyingWristband = (previewList) => {
    return (
      <>
        {previewList?.data?.map((item, index) => {
          return (
            <div
              key={index}
              className="NurseStationBedsideCardPrinting_right_tabcontent_content_renderAccompanyingWristband"
            >
              <div className="NurseStationBedsideCardPrinting_right_tabcontent_content_renderAccompanyingWristband_header">
                <div className="NurseStationBedsideCardPrinting_right_tabcontent_content_renderAccompanyingWristband_header_check">
                  <Checkbox
                    defaultValue={item?.isChecked}
                    indeterminate={judgeIndeterminate(item)}
                    onChange={() => {
                      handelAllSelect(item, index);
                    }}
                    value={handelValue(item)}
                  ></Checkbox>
                </div>
                <div className="icon18 NurseStationBedsideCardPrinting_right_tabcontent_content_renderAccompanyingWristband_header_content">
                  <div className="NurseStationBedsideCardPrinting_right_tabcontent_content_renderAccompanyingWristband_header_content_item">
                    床号：{item?.bedLabel}
                  </div>
                  <div className="NurseStationBedsideCardPrinting_right_tabcontent_content_renderAccompanyingWristband_header_content_item">
                    姓名：{item?.patientName}
                  </div>
                  <div className="NurseStationBedsideCardPrinting_right_tabcontent_content_renderAccompanyingWristband_header_content_item">
                    患者ID：{item?.patientDisplayId}
                  </div>
                </div>
                <div className="NurseStationBedsideCardPrinting_right_tabcontent_content_renderAccompanyingWristband_header_operation">
                  <Button
                    // className="NurseStationBedsideCardPrinting_right_tabcontent_content_renderAccompanyingWristband_header_operation_btn"
                    type="primary"
                    onClick={() => {
                      handelAdd(item, index);
                    }}
                  >
                    {/* <div style={{ margin: '1px 2px 0 0' }}> */}
                    <Icon
                      style={{ color: '#fff' }}
                      type="icon-tianjia1"
                      size={14}
                    />
                    {/* </div> */}
                    增加陪护人
                  </Button>
                </div>
              </div>
              <div className="NurseStationBedsideCardPrinting_right_tabcontent_content_renderAccompanyingWristband_content">
                {item?.inpEscortRegisterList?.map(
                  (inpEscortRegisterListItem, inpEscortRegisterListIndex) => {
                    return (
                      <div
                        key={inpEscortRegisterListIndex}
                        className="NurseStationBedsideCardPrinting_right_tabcontent_content_renderAccompanyingWristband_content_item"
                        style={{
                          cursor: 'pointer',
                          background: inpEscortRegisterListItem?.operationType
                            ? '#F7FAFF'
                            : '',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (inpEscortRegisterListItem?.operationType) return;
                          const _res = previewList?.data?.map(
                            (changeItem, changeIndex) => {
                              if (changeIndex == index) {
                                return {
                                  ...changeItem,
                                  inpEscortRegisterList:
                                    changeItem?.inpEscortRegisterList?.map(
                                      (v, i) => {
                                        if (i == inpEscortRegisterListIndex) {
                                          return {
                                            ...v,
                                            isChecked: v?.id
                                              ? !v?.isChecked
                                              : false,
                                          };
                                        } else {
                                          return { ...v };
                                        }
                                      },
                                    ),
                                };
                              } else {
                                return { ...changeItem };
                              }
                            },
                          );
                          setPreviewList({ ...previewList, data: _res });
                        }}
                      >
                        <div className="NurseStationBedsideCardPrinting_right_tabcontent_content_renderAccompanyingWristband_content_item_check">
                          <Checkbox
                            disabled={inpEscortRegisterListItem?.operationType}
                            defaultValue={
                              inpEscortRegisterListItem?.isChecked ||
                              inpEscortRegisterListItem?.operationType
                            }
                            value={inpEscortRegisterListItem?.isChecked}
                            onChange={(e) => {
                              const _res = previewList?.data?.map(
                                (changeItem, changeIndex) => {
                                  if (changeIndex == index) {
                                    return {
                                      ...changeItem,
                                      inpEscortRegisterList:
                                        changeItem?.inpEscortRegisterList?.map(
                                          (v, i) => {
                                            if (
                                              i == inpEscortRegisterListIndex
                                            ) {
                                              return {
                                                ...v,
                                                isChecked: v?.id
                                                  ? !v?.isChecked
                                                  : false,
                                              };
                                            } else {
                                              return { ...v };
                                            }
                                          },
                                        ),
                                    };
                                  } else {
                                    return { ...changeItem };
                                  }
                                },
                              );
                              setPreviewList({ ...previewList, data: _res });
                            }}
                          ></Checkbox>
                        </div>
                        <div className="NurseStationBedsideCardPrinting_right_tabcontent_content_renderAccompanyingWristband_content_item_content">
                          <VSForm
                            labelAlign="left"
                            // initialValues={inpEscortRegisterListItem}
                            onFetch={async () => {
                              return inpEscortRegisterListItem;
                            }}
                            disabled={!inpEscortRegisterListItem?.operationType}
                            id={`form_${item?.inpVisitId}_${inpEscortRegisterListIndex}`}
                          >
                            <div className="NurseStationBedsideCardPrinting_right_tabcontent_content_renderAccompanyingWristband_content_item_content_info">
                              <VSFormLayout
                                labelWidth={isMinScreen ? 100 : 110}
                                key="0"
                                columnCount={3}
                              >
                                <VSFormItem
                                  name={['escortName']}
                                  label="陪护人姓名"
                                  valueType="text"
                                  fieldProps={{
                                    required: true,
                                  }}
                                  required
                                />
                                <VSFormItem
                                  name={['gender']}
                                  label="陪护人性别"
                                  valueType="select"
                                  fieldProps={{
                                    required: true,
                                    showSearch: true,
                                  }}
                                  dataSource={
                                    vsf.stores.dbenums?.enums?.SEX_DICT
                                  }
                                  required
                                />
                                <VSFormItem
                                  name={['age']}
                                  label="陪护人年龄"
                                  valueType="text"
                                  fieldProps={{
                                    required: true,
                                  }}
                                  required
                                />
                                <VSFormItem
                                  name={['escortIdentityNumber']}
                                  label="身份证号码"
                                  valueType="text"
                                  fieldProps={{
                                    required: true,
                                  }}
                                  required
                                />
                                <VSFormItem
                                  name={['escortPhoneNumber']}
                                  label="电话号码"
                                  valueType="text"
                                  fieldProps={{
                                    required: true,
                                  }}
                                  required
                                />
                                <VSFormItem
                                  name={['printTimes']}
                                  label="打印次数"
                                  valueType="text"
                                  fieldProps={{ disabled: true }}
                                />
                              </VSFormLayout>
                              {/* </div> */}
                            </div>
                          </VSForm>
                        </div>
                        <div className="icon8 NurseStationBedsideCardPrinting_right_tabcontent_content_renderAccompanyingWristband_content_item_operation">
                          <div
                            className="icon18 NurseStationBedsideCardPrinting_right_tabcontent_content_renderAccompanyingWristband_content_item_operation_edit"
                            onClick={(e) => {
                              e.stopPropagation();
                              const _changeValue =
                                vsf?.refs?.[
                                  `form_${item?.inpVisitId}_${inpEscortRegisterListIndex}`
                                ]?.getFieldsValue();
                              handelChangeBtn(
                                item,
                                index,
                                {
                                  ...inpEscortRegisterListItem,
                                  ..._changeValue,
                                },
                                inpEscortRegisterListIndex,
                              );
                            }}
                          >
                            <div>
                              {!inpEscortRegisterListItem?.operationType &&
                              inpEscortRegisterListItem?.id ? (
                                <Icon type="icon-wudibianji" />
                              ) : (
                                <Icon type="icon-a-Frame437" />
                              )}
                            </div>
                            {!inpEscortRegisterListItem?.operationType &&
                            inpEscortRegisterListItem?.id
                              ? '编辑'
                              : '保存'}
                          </div>
                          <div
                            className="icon18 NurseStationBedsideCardPrinting_right_tabcontent_content_renderAccompanyingWristband_content_item_operation_delete"
                            onClick={(e) => {
                              e.stopPropagation();
                              handelDelete(
                                item,
                                index,
                                inpEscortRegisterListItem,
                                inpEscortRegisterListIndex,
                              );
                            }}
                          >
                            <div>
                              <Icon type="icon-wudishanchu" />
                            </div>
                            删除
                          </div>
                        </div>
                      </div>
                    );
                  },
                )}
              </div>
            </div>
          );
        })}
      </>
    );
  };
  // bottom 重置/打印
  const renderRightBottom = () => {
    return (
      <div>
        <Button
          type="primary"
          ghost
          onClick={handelReset}
          className="marginLeft16"
        >
          重置
        </Button>
        {tab !== 'accompanyingWristband' && (
          <Button
            onClick={handelPrinter}
            type="primary"
            disabled={previewList?.count == 0}
            className="marginLeft16"
          >
            打印
          </Button>
        )}
        {tab === 'accompanyingWristband' && (
          <Button
            onClick={handelPrinter}
            type="primary"
            disabled={previewList?.count == 0}
            className="marginLeft16"
          >
            腕带打印
          </Button>
        )}
      </div>
    );
  };
  return (
    <div className="NurseStationBedsideCardPrinting">
      <div className="NurseStationBedsideCardPrinting_left">
        <div className="NurseStationBedsideCardPrinting_left_tab">
          <RadioGroup
            dataSource={[
              { label: '全部', value: 'all' },
              { label: '未打印', value: 'UN_PRINT' },
              { label: '已打印', value: 'PRINTED' },
            ]}
            onChange={handelChangeStatus}
            value={status}
            optionType="button"
          />
        </div>
        <div className="NurseStationBedsideCardPrinting_left_content">
          {renderLeftTable()}
        </div>
        <div className="NurseStationBedsideCardPrinting_left_bottom icon18">
          <div className="NurseStationBedsideCardPrinting_left_bottom_item">
            条目总数：{totalNumber ?? 0}
          </div>
          <div className="NurseStationBedsideCardPrinting_left_bottom_item">
            选中条目：{previewList?.count ?? 0}
          </div>
        </div>
      </div>
      <div className="NurseStationBedsideCardPrinting_right">
        <Tabs
          id="NurseStationBedsideCardPrintingTabs"
          className="
        aspirin-tab
         NurseStationBedsideCardPrinting_right_tab
        aspirin-history-tab-border"
          onChange={(v) => {
            setTab(v);
          }}
        >
          {/* switchValue ? '姓名小卡' : '床头卡' */}
          <TabPane tab="床头卡" key="card">
            <div className="NurseStationBedsideCardPrinting_right_tabcontent">
              <div className="NurseStationBedsideCardPrinting_right_tabcontent_change">
                <Switch
                  onChange={(v) => {
                    setSwitchValue(v);
                  }}
                ></Switch>
                <div className="icon18" style={{ marginLeft: 8 }}>
                  {switchValue ? '切换姓名小卡' : '切换床头卡'}
                </div>
              </div>
              {(previewList || previewList?.count > 0) && !loading && (
                <div className="NurseStationBedsideCardPrinting_right_tabcontent_content">
                  {switchValue ? renderBedsideCard() : renderNameCard()}
                </div>
              )}
              {(!previewList || previewList?.count <= 0) && !loading && (
                <div className="NurseStationBedsideCardPrinting_right_tabcontent_empty">
                  <Empty />
                </div>
              )}
              {loading && <Loading size="normal" />}
              <div className="NurseStationBedsideCardPrinting_right_tabcontent_bottom">
                {renderRightBottom()}
              </div>
            </div>
          </TabPane>
          <TabPane tab="陪护腕带" key="accompanyingWristband">
            <div className="NurseStationBedsideCardPrinting_right_tabcontent">
              {(previewList || previewList?.count > 0) && !loading && (
                <div className="NurseStationBedsideCardPrinting_right_tabcontent_content">
                  {renderAccompanyingWristband(previewList)}
                </div>
              )}
              {(!previewList || previewList?.count <= 0) && !loading && (
                <div className="NurseStationBedsideCardPrinting_right_tabcontent_empty">
                  <Empty />
                </div>
              )}
              {loading && <Loading size="normal" />}
              <div className="NurseStationBedsideCardPrinting_right_tabcontent_bottom">
                {renderRightBottom()}
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default definePage(Index);
