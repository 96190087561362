import React from 'react';
import { Button, Input } from '@vs/vsf-kit';

export default function certificateFooter(props) {
  const { add, print, cancel } = props;
  return (
    <div className="certificate_footer">
      <Button type="primary" shape="default" size="medium" onClick={add}>
        新增
      </Button>
      <Button type="primary" shape="default" size="medium" onClick={print}>
        打印
      </Button>
      <Button type="primary" shape="default" size="medium" onClick={cancel}>
        关闭
      </Button>
    </div>
  );
}
