import NewDrawer from '@/pages/Index/components/new_drawer';
import Tip from '@/pages/Index/components/func/Tip';
import vsf, { definePage } from '@vs/vsf-boot';
import { VSPromiseTable, VSTableColumn, Button, message } from '@vs/vsf-kit';
import React, { useCallback, useState, useContext } from 'react';
import { DrugExportContext } from '.';
const DrugAccounting = (props) => {
  const [open, setOpen] = useState(false);
  const [select, setSelect] = useState({});

  const onFetch = useCallback(async (params) => {
    const res =
      await vsf.services?.DrugStorageImportExportController_getAllByDrugApplyMasterForExportQto_1cc884?.(
        {
          qto: {
            orderList: params.orderList,
            ...params.search,
            ...params.filters,
            ...(params?.extra ?? {}),
            ...(params ?? {}),
          },
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const onSelect = (value) => {
    setOpen(false);
    props?.onChange?.(value);
  };

  const onReload = () => {
    vsf.refs?.DrugExportInvoice?.reload();
  };

  /**
   * 作废
   */
  const onInvalid = useCallback(async () => {
    Tip({
      content: `确定作废流水号为:{ ${select?.applyDepartment?.departmentName} }请领单据?`,
      onOk: async () => {
        const res =
          await vsf.services?.DrugStorageImportExportController_invalidApplyMaster_d46bc9?.(
            {
              id: select?.id,
            },
          );
        if (res.code === 200) {
          message.success('作废成功');
          onReload?.();
          setSelect([]);
        }
      },
    });
  }, [select]);

  const footer = () => {
    const onHide = () => {
      setOpen(false);
    };
    return (
      <div className="aspirin-table-footer-button">
        <Button type="primary" onClick={onReload}>
          刷新
        </Button>
        <Button type="primary" onClick={onHide}>
          隐藏
        </Button>
        <Button
          type="primary"
          onClick={onInvalid}
          disabled={!select?.id}
          danger
        >
          作废单据
        </Button>
      </div>
    );
  };

  const available = useContext(DrugExportContext);
  return (
    <NewDrawer
      open={open}
      width="40%"
      onOpen={() => setOpen(!open)}
      title="药品请领单"
      buttonTitle="药品请领单"
      backgroundColor="var(--blue)"
      footer={footer()}
      isOther
      buttonStyle={{
        top: '50%',
      }}
      style={{
        top: 104,
      }}
      drift="98px"
    >
      <VSPromiseTable
        id="DrugExportInvoice"
        vsid="67051"
        onFetch={onFetch}
        onRow={(node) => {
          return {
            onClick() {
              setSelect(node);
            },
            onDoubleClick() {
              onSelect(node);
            },
          };
        }}
        pagination={false}
        rowClassName={(node) => {
          return node?.id === select?.id
            ? 'aspirin-vs-table-row-box aspirin-row-selected-box'
            : 'aspirin-vs-table-row-box aspirin-row-select-box';
        }}
      >
        <VSTableColumn
          dataIndex={['applyDepartment', 'id']}
          title="主键"
          valueType="digit"
          fieldProps={{}}
          hideInTable
        />

        <VSTableColumn
          dataIndex={['applyDepartment', 'departmentName']}
          title="请领单位"
          valueType="text"
          className="aspirin-table-td-blue-box"
          formItemProps={{
            rules: [
              {
                message: '科室名称长度不合法',
                type: 'string',
                min: 0,
                max: 40,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['applyType']}
          title="出库类型"
          valueType="select"
          // fieldNames={
          //   {
          //     // label: 'name',
          //     // value: 'code',
          //   }
          // }
          // render={(...[, record]) => available?.find((item) => record?.applyType === item?.code)?.name ?? record?.applyType }
          fieldProps={{}}
          dataSource={vsf.stores.dbenums?.enums?.DRUG_IMPEXP_CLASS_DICT}
        />

        <VSTableColumn
          dataIndex={['planDate']}
          title="请领日期"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['applyStaff', 'staffName']}
          title="申请人"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: 'staff_name长度不合法',
                type: 'string',
                min: 0,
                max: 60,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['memo']}
          title="备注"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 40 },
            ],
          }}
          fieldProps={{}}
        />
      </VSPromiseTable>
    </NewDrawer>
  );
};
export default definePage(DrugAccounting);
