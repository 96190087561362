import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Column,
  Columns,
  Image,
  // Empty,
  Input,
  message,
  Popover,
  RadioGroup,
  Section,
  Select,
  Tag,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import cn from 'classnames';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { appendTreeLevel, buildTree } from '@/components/TreeMenu/utils';
import { formatDate } from '@/module/cpoe/medical/editor/utils/date';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import Loading from '@/module/Loading';
import SearchContainer from '@/module/Search';
import Block from '@/pages/Index/components/block_no_padding';
import TableRender from '@/pages/Index/components/func/TableRender';
import Tip from '@/pages/Index/components/func/Tip';
import { getScrollY } from '@/utils';
import storage from '@/utils/storage';

import BedCard from './content/bedCardList';
import {
  convertGet,
  convertSearchPrarms,
  patientDetailConditionIcon,
  renderDrugResistance,
} from './convert';
import DetailModal from './detailModal/index';

let tempSearchBed;

const explainIcon = [
  { label: '皮试阳性', icon: 'icon-yangxing', width: '50%' },
  { label: '过敏史', icon: 'icon-guomin', width: '50%' },
  { label: '病危', icon: 'icon-bingwei1', width: '50%' },
  { label: '病重', icon: 'icon-bingzhong', width: '50%' },
  { label: '隔离', icon: 'icon-geli', width: '100%' },
  { label: '检查、检验报告未出', icon: 'icon-weidayin', width: '100%' },
  { label: '护理路径', icon: 'icon-hulilujing', width: '50%' },
  { label: '耐药标识', icon: 'icon-naiyaobiaoshi', width: '50%' },
];

const Index = (props) => {
  const { onSelect, stores, dropdownOpen } = props;
  const {
    common: { currentApplication },
  } = stores;
  // 床卡
  const [cardList, setCardList] = useState<any>([]);
  // 单击击列表 id
  const [currentSelectCarBed, setCurrentSelectCarBed] = useState<any>(1);
  //卡片/列表
  const [radioGroupValue, setRadioGroup] = useState('detail');
  //患者类型筛选
  const [patientType, setPatientType] = useState('INP');
  // const [patientType, setPatientType] = useState('CONSULTATION');
  // 授权类型
  const [authorizationType, setAuthorizationType] = useState<any>([]);
  // 所属科室：我的、本科、本组（差枚举值）
  const [belongDepartment, setBelongDepartment] = useState('CURRENTDEPARTMENT');
  // 本科id
  const [currentDepartment, setCurrentDepartment] = useState<any>();
  // 本组-医生所在主诊组id
  const [currentGroupId, setCurrentGroupId] = useState<any>();
  // 病区
  const [wardIdIs, setWardIdIs] = useState<any>();
  // 收索姓名 id 床号查询
  const [searchBed, setSearchBed] = useState<any>();
  // 病情相关
  const [diseaseCorrelation, setDiseaseCorrelation] = useState<any>();
  // 统计在科和空床
  const [total, setTotal] = useState<any>([]);
  // 病区首项
  const [wardFirst, setWardFirst] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  // 患者详情弹窗
  const modalRef = useRef<any>();

  // const currentApplication = storage.get('currentApplication');

  useEffect(() => {
    getInDepartmentEmptyBed();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    console.log(patientType, dropdownOpen, 'pp1');

    if (patientType === 'INP' && dropdownOpen) {
      getPagedByDepartmentCode();
      getPagedByDepartmentCode1();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientType]);

  useEffect(() => {
    getCardList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    patientType,
    wardIdIs,
    belongDepartment,
    radioGroupValue,
    currentDepartment,
    currentGroupId,
    diseaseCorrelation,
    authorizationType,
    searchBed,
  ]);

  useEffect(() => {
    if (belongDepartment !== 'CURRENTGROUP') return;
    // belongDepartment
    queryMineMedicalGroup();
  }, [belongDepartment]);
  // 详卡列表
  const getCardList = async () => {
    setLoading(true);
    if (patientType === 'INP') {
      if (!wardIdIs) {
        return setLoading(false);
      }
    } else if (patientType !== 'INP') {
      if (!currentApplication?.department?.id) {
        return setLoading(false);
      }
    }

    const _convertSearchPrarms = convertSearchPrarms({
      // 患者类型
      patientTypeIn: [patientType],
      departmentIdIs: currentApplication?.department?.id,
      // 病区 currentApplication?.department?.id
      wardIdIs: patientType === 'INP' ? wardIdIs : null,
      // 授权类型
      patientAuthorityTypeIn:
        authorizationType?.length > 0 ? authorizationType : null,
      // 患者id
      patientIdIs: searchBed,
      // 患者姓名
      patientNameLike: searchBed,
      // displayId
      patientDisplayIdIs: searchBed,
      // 床号
      bedLabelIs: searchBed,
      // 所属-本组-本科-
      medicalGroupIdIs:
        belongDepartment === 'CURRENTGROUP'
          ? currentGroupId
          : belongDepartment === 'CURRENTDEPARTMENT'
          ? currentDepartment
          : null,
      // 所属-我的-医师id
      // 医生id,从登录取,写死
      attendingDoctorIdIs: belongDepartment === 'MINE' ? 1603 : undefined,
      // 医生id,从登录取,写死
      inChargeDoctorIdIs: belongDepartment === 'MINE' ? 1603 : undefined,
    });
    const res =
      await vsf.services?.ClinicBedController_queryPagefallByClinicBedWithPatientForDoctorQto_d563e0?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          qto: {
            from: 0,
            size: 20,
            // orderList: params.orderList,
            ..._convertSearchPrarms,
          },
          ext: {
            statisticsCodes:
              diseaseCorrelation?.length > 0 ? diseaseCorrelation : null,
          },
        },
      ); // TODO: 你需要自己补充入参input来源及出参output用法
    setLoading(false);
    if (res?.code === 200) {
      const customResult = convertGet(res); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
      const _customResult = customResult.map((item) => {
        return { ...item, isShowDetail: false };
      });
      const arr: any = [];
      _customResult?.forEach((item) => {
        if (arr?.find((v) => v.value === item?.wardId)) {
          arr?.[arr?.findIndex((v) => v.value === item?.wardId)]?.content?.push(
            {
              ...item,
            },
          );
        } else {
          arr?.push({
            title: item?.wardName,
            value: item?.wardId,
            content: [{ ...item }],
          });
        }
      });

      if (radioGroupValue === 'detail') {
        console.log(patientType, 'patientTypepatientTypepatientType');

        if (patientType !== 'INP') {
          // 其他
          setCardList(arr);
        } else {
          // 在科
          setCardList(_customResult ?? []);
        }
      } else if (radioGroupValue === 'list') {
        setCardList(_customResult ?? []);
      }
      return {
        data: _customResult ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    }
  };
  // 病区
  const getPagedByDepartmentCode = async (v?) => {
    if (!currentApplication?.department?.id) return;
    const res =
      await vsf.services?.DepartmentDtoController_getPagedByDepartmentIdAndSearchCode_4ce058?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          ...v,
          // departmentId: currentApplication?.department?.id,
          // 写死,其他科室查不到病区117
          departmentId: currentApplication?.department?.id,
        },
      ); // TODO: 你可能需要整理返回数据格式
    // setWardFirst(res?.data?.result?.[0] ?? {});

    return res?.data?.result;
  };
  // 设置病区默认值
  const getPagedByDepartmentCode1 = async (v?) => {
    if (!currentApplication?.department?.id) return;
    const res =
      await vsf.services?.DepartmentDtoController_getPagedByDepartmentIdAndSearchCode_4ce058?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          ...v,
          // departmentId: currentApplication?.department?.id,
          // 写死,其他科室查不到病区117
          departmentId: currentApplication?.department?.id,
        },
      ); // TODO: 你可能需要整理返回数据格式
    setWardIdIs(res?.data?.result?.[0]?.id ?? '');
    setWardFirst(res?.data?.result?.[0] ?? {});
    return res?.data?.result;
  };

  // 本科
  const getQueryAllByMedicalGroup = async () => {
    const res =
      await vsf.services?.UserGroupController_queryAllByMedicalGroup_169563?.({
        // TODO: 请检查以下这部分传参是否符合需求
        eo: {
          // departmentId: 117,
          departmentId: currentApplication?.department?.id,
          stopIndicator: false,
        },
      }); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    return res?.data?.result;
  };
  // 本组-当前医生所在主诊组
  const queryMineMedicalGroup = async () => {
    const res =
      await vsf.services?.UserGroupController_queryAllByMedicalGroup_169563?.({
        // TODO: 请检查以下这部分传参是否符合需求
        eo: {
          from: 0,
          size: 20,
          // 写死
          // departmentId: selectedPatientGroup?.id,
          departmentId: 117,
          // 写死
          staffName: '华佗',
          stopIndicator: false,
        },
      }); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
    setCurrentGroupId(res?.data?.result?.[0]?.id);
  };
  // 我的-当前医生id

  // 查在科和空床
  const getInDepartmentEmptyBed = async () => {
    const _res =
      await vsf.services?.ClinicBedController_queryBedPatientStatistics_3cd3df?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          qto: {
            from: 0,
            size: 200,
            patientDepartmentIdIs: currentApplication?.department?.id,
          },
          statisticsCodes: ['DEPARTMENT', 'EMPTY_BED'],
        },
      );
    setTotal(_res?.data?.result);
  };
  // 病情相关
  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color="blue"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        // style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };
  // 码表转换
  const handelStopwatch = (value, data) => {
    const _res = data?.filter((item) => {
      return item?.value == value;
    });
    return _res?.[0]?.label;
  };

  // 患者详情modal
  const handelViewDetail = (detail) => {
    console.log(detail);
    modalRef?.current?.handelModal(true, detail);
    return;
  };

  const renderIcon = (value) => {
    return (
      <div style={{ display: 'flex' }}>
        {patientDetailConditionIcon?.map((item: any, index) => {
          if (item?.type === 'arr') {
            // 耐药
            if (item?.isShow === 'drugResistanceType') {
              return value?.[item?.content]?.length === 1 ? (
                <Popover
                  key={item?.isShow}
                  content={value?.[item?.content]?.join(' | ')}
                >
                  <Icon
                    className="doctor_stationbed_card_list_content_table_VSPromiseTable_icon"
                    size={16}
                    type={renderDrugResistance(value?.[item?.content]?.[0])}
                  />
                </Popover>
              ) : value?.[item?.isShow]?.length > 1 ? (
                <Popover
                  key={item?.isShow}
                  content={value?.[item?.content]?.join(' | ')}
                >
                  <Icon
                    className="doctor_stationbed_card_list_content_table_VSPromiseTable_icon"
                    size={16}
                    type={item?.icon}
                  />
                </Popover>
              ) : (
                ''
              );
            } else if (item?.isShow === 'patientAllergyIndicator') {
              // 过敏
              return value?.[item?.isShow] ? (
                <Popover
                  key={item?.isShow}
                  content={value?.[item?.content].join(' ； ')}
                >
                  <Icon
                    className="doctor_stationbed_card_list_content_table_VSPromiseTable_icon"
                    size={16}
                    type={item?.icon}
                  />
                </Popover>
              ) : (
                ''
              );
            } else {
              return value?.[item?.isShow] ? (
                <Popover
                  key={item?.isShow}
                  content={value?.[item?.content]?.join(' | ')}
                >
                  <Icon
                    className="doctor_stationbed_card_list_content_table_VSPromiseTable_icon"
                    size={16}
                    type={item?.icon}
                  />
                </Popover>
              ) : (
                ''
              );
            }
          } else if (item?.type === 'string') {
            return value?.[item?.isShow] ? (
              <Popover key={item?.isShow} content={item?.content}>
                <Icon
                  className="doctor_stationbed_card_list_content_table_VSPromiseTable_icon"
                  size={16}
                  type={item?.icon}
                />
              </Popover>
            ) : (
              ''
            );
          }
          // if (item?.isShow === 'crisisIndicator') {
          //   if (value?.[item?.isShow]) {
          //     return (
          //       <Icon
          //         key={index}
          //         className="doctor_stationbed_card_list_content_table_VSPromiseTable_icon"
          //         size={16}
          //         type={item?.label}
          //       />
          //     );
          //   } else if (value?.[item?.isShowZhong as string]) {
          //     return (
          //       <Icon
          //         key={index}
          //         className="doctor_stationbed_card_list_content_table_VSPromiseTable_icon"
          //         size={16}
          //         type={item?.labelZhong as string}
          //       />
          //     );
          //   }
          // } else if (item?.isShow === 'isolationIndicator') {
          //   return value?.[item?.isShow] ? (
          //     <Popover
          //       key="index"
          //       content={handelStopwatch(
          //         value?.[item?.value],
          //         vsf.stores.dbenums?.enums?.ISOLATION_TYPE_DICT,
          //       )}
          //     >
          //       <Icon
          //         className="doctor_stationbed_card_list_content_table_VSPromiseTable_icon"
          //         size={16}
          //         type={item?.label}
          //         style={{
          //           display: value?.[item?.isShow] ? 'block' : 'none',
          //         }}
          //       />
          //     </Popover>
          //   ) : (
          //     ''
          //   );
          // } else if (item?.isShow === 'drugResistanceName') {
          //   if (value?.drugResistanceName?.length === 1) {
          //     console.log(1);
          //   } else if (value?.drugResistanceName?.length === 0) console.log(2);
          // } else {
          //   return item?.value ? (
          //     value?.[item?.isShow] ? (
          //       <Popover key="index" content={value?.[item?.value]}>
          //         <Icon
          //           className="doctor_stationbed_card_list_content_table_VSPromiseTable_icon"
          //           size={16}
          //           type={item?.label}
          //         />
          //       </Popover>
          //     ) : (
          //       ''
          //     )
          //   ) : value?.[item?.isShow] ? (
          //     <Icon
          //       className="doctor_stationbed_card_list_content_table_VSPromiseTable_icon"
          //       size={16}
          //       type={item?.label}
          //     />
          //   ) : (
          //     ''
          //   );
          // }
        })}
      </div>
    );
  };

  const renderExplain = () => {
    return (
      <div className="doctor_stationbed_card_search_input_right_item_explain">
        <div className="doctor_stationbed_card_search_input_right_item_explain_title">
          状态条护理等级
        </div>
        <div className="doctor_stationbed_card_search_input_right_item_explain_leave">
          <div className="doctor_stationbed_card_search_input_right_item_explain_leave_item">
            <div
              style={{ backgroundColor: '#E34A4E' }}
              className="doctor_stationbed_card_search_input_right_item_explain_leave_item_div"
            ></div>
            特级
          </div>
          <div className="doctor_stationbed_card_search_input_right_item_explain_leave_item">
            <div
              style={{ backgroundColor: '#ff7d45' }}
              className="doctor_stationbed_card_search_input_right_item_explain_leave_item_div"
            ></div>
            一级
          </div>
          <div className="doctor_stationbed_card_search_input_right_item_explain_leave_item">
            <div
              style={{ backgroundColor: '#FFD900' }}
              className="doctor_stationbed_card_search_input_right_item_explain_leave_item_div"
            ></div>
            二级
          </div>
          <div className="doctor_stationbed_card_search_input_right_item_explain_leave_item">
            <div
              style={{ backgroundColor: '#AEB0B3' }}
              className="doctor_stationbed_card_search_input_right_item_explain_leave_item_div"
            ></div>
            三级
          </div>
        </div>
        <div className="doctor_stationbed_card_search_input_right_item_explain_title">
          图标信息
        </div>
        <div className="doctor_stationbed_card_search_input_right_item_explain_icon">
          {explainIcon?.map((item, index) => {
            return (
              <div
                key={index}
                className="doctor_stationbed_card_search_input_right_item_explain_icon_item"
                style={{ width: item?.width }}
              >
                <div className="doctor_stationbed_card_search_input_right_item_explain_icon_item_icon">
                  <Icon type={item?.icon} size={16} />
                </div>
                <div>{item?.label}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderSearchItem = (type) => {
    switch (type) {
      case 'INP':
        return (
          <>
            <div className="doctor_stationbed_card_search_input_left_item">
              病区
              <span className="doctor_stationbed_card_search_input_left_item_select">
                <Select
                  // width={220}
                  className="width220"
                  dataSource={getPagedByDepartmentCode}
                  showSearch={true}
                  fieldNames={{ label: 'departmentName', value: 'id' }}
                  // allowClear
                  value={wardFirst}
                  onChange={(v) => {
                    setDiseaseCorrelation([]);
                    setSearchBed(null);
                    setWardIdIs(v?.id);
                    setWardFirst(v);
                  }}
                ></Select>
              </span>
            </div>
            <div className="doctor_stationbed_card_search_input_left_item">
              所属
              <span className="doctor_stationbed_card_search_input_left_item_select">
                <Select
                  // width={140}
                  className="width140"
                  dataSource={[
                    { label: '本科', value: 'CURRENTDEPARTMENT' },
                    { label: '我的', value: 'MINE' },
                    { label: '本组', value: 'CURRENTGROUP' },
                  ]}
                  showSearch={true}
                  defaultValue="CURRENTDEPARTMENT"
                  onChange={(v) => {
                    setDiseaseCorrelation([]);
                    setCurrentDepartment(null);
                    setSearchBed(null);
                    setBelongDepartment(v);
                  }}
                ></Select>
                {belongDepartment === 'CURRENTDEPARTMENT' && (
                  <Select
                    // width={140}
                    className="width140"
                    dataSource={getQueryAllByMedicalGroup}
                    fieldNames={{ label: 'groupName', value: 'id' }}
                    showSearch={true}
                    allowClear
                    onChange={(v) => {
                      setDiseaseCorrelation([]);
                      setSearchBed(null);
                      setCurrentDepartment(v?.id);
                    }}
                  ></Select>
                )}
              </span>
            </div>
          </>
        );
      case 'AUTHORITY':
        return (
          <>
            <div className="doctor_stationbed_card_search_input_left_item">
              授权类型
              <span
                className="doctor_stationbed_card_search_input_left_item_select"
                style={{ marginRight: 8 }}
              >
                <Select
                  // width={410}
                  className="width410"
                  mode="multiple"
                  dataSource={
                    vsf.stores.dbenums?.enums?.AUTHORIZATION_TYPE_DICT
                  }
                  tagRender={tagRender}
                  showSearch={true}
                  onChange={(v) => {
                    setAuthorizationType(v);
                  }}
                ></Select>
              </span>
              <span>
                {authorizationType?.length > 0 ? cardList?.length : 0}人
              </span>
            </div>
          </>
        );
    }
  };

  const renderSearch = () => {
    return (
      <div className="doctor_stationbed_card_search_input">
        <div className="doctor_stationbed_card_search_input_left">
          <div className="doctor_stationbed_card_search_input_left_item">
            <RadioGroup
              optionType="button"
              // buttonStyle="solid"
              defaultValue="detail"
              value={radioGroupValue}
              onChange={(v) => {
                setRadioGroup(v);
              }}
              dataSource={[
                { label: '床卡', value: 'detail' },
                { label: '列表', value: 'list' },
              ]}
            ></RadioGroup>
          </div>
          <div className="doctor_stationbed_card_search_input_left_item">
            患者类型
            <span className="doctor_stationbed_card_search_input_left_item_select">
              <Select
                // width={140}
                className="width140"
                dataSource={[
                  { label: '在科患者', value: 'INP' },
                  { label: '会诊患者', value: 'CONSULTATION' },
                  { label: '转科患者', value: 'TRANSFER' },
                  { label: '授权患者', value: 'AUTHORITY' },
                  { label: '预住院患者', value: 'PRE_INP' },
                  { label: '病历未提交', value: 'NOT_SUBMIT_MEDICAL_RECORD' },
                ]}
                showSearch={true}
                defaultValue="INP"
                onChange={(v) => {
                  setWardIdIs(null);
                  setAuthorizationType([]);
                  setAuthorizationType([]);
                  setCurrentDepartment(null);
                  setCurrentGroupId(null);
                  setSearchBed(null);
                  setPatientType(v);
                }}
              ></Select>
            </span>
          </div>
          {/* CONSULTATION、TRANSFER、AUTHORITY、NOT_SUBMIT_MEDICAL_RECORD */}
          {renderSearchItem(patientType)}
        </div>
      </div>
    );
  };
  const renderSearch1 = () => {
    return (
      <div className="doctor_stationbed_card_search_input">
        <div className="doctor_stationbed_card_search_input_left">
          <div className="doctor_stationbed_card_search_input_left_item">
            <Input
              bordered={false}
              style={{ backgroundColor: '#eceff1' }}
              // width={240}
              className="width240"
              prefix={<Icon type="icon-sousuo" style={{ color: '#85898D' }} />}
              placeholder="请输入患者ID、姓名、床号"
              onChange={(v) => {
                // setSearchBed(v?.target?.value);
                tempSearchBed = v?.target?.value;
              }}
              onKeyUp={(e: any) => {
                if (e.code === 'Enter') {
                  if (e?.target?.value?.length <= 0) return setSearchBed(null);
                  setDiseaseCorrelation([]);
                  setSearchBed(tempSearchBed);
                }
              }}
            />
          </div>
          {patientType === 'INP' && (
            <div className="doctor_stationbed_card_search_input_left_item">
              病情相关
              <span className="doctor_stationbed_card_search_input_left_item_select">
                <Select
                  mode="multiple"
                  tagRender={tagRender}
                  // style={{ minWidth: 410 }}
                  className="width410"
                  dataSource={[
                    { label: '明日手术', value: 'OPERATION_TOMORROW' },
                    { label: '明日出院', value: 'DISCHARGE_TOMORROW' },
                    { label: '病重', value: 'SERIOUS_INDICATOR' },
                    { label: '病危', value: 'CRISIS_INDICATOR' },
                  ]}
                  // fieldNames={{ label: 'label', value: 'value' }}
                  onChange={(v) => {
                    setDiseaseCorrelation(v);
                  }}
                  value={diseaseCorrelation}
                ></Select>
                <span className="doctor_stationbed_card_content_list_desc_number">
                  {diseaseCorrelation?.length > 0
                    ? `${cardList?.length ?? 0}人`
                    : '0人'}
                </span>
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderContent = () => {
    // 在科
    switch (radioGroupValue) {
      case 'detail':
        return !loading ? (
          cardList?.length > 0 ? (
            <div className="doctor_stationbed_card_content">
              {cardList?.length > 0 && (
                <div className="doctor_stationbed_card_content_list hideScroll">
                  {/* 其他 */}
                  {patientType !== 'INP' &&
                    cardList?.map((item) => {
                      console.log(item, cardList);

                      return (
                        <>
                          <div className="doctor_stationbed_card_content_list_title">
                            {item?.title}
                          </div>
                          <BedCard
                            handelViewDetail={handelViewDetail}
                            patientType={patientType}
                            onSelect={onSelect}
                            cardList={item?.content}
                            type={radioGroupValue}
                            renderIcon={renderIcon}
                          ></BedCard>
                        </>
                      );
                    })}
                  {/* 在科 */}
                  {patientType === 'INP' && (
                    <BedCard
                      handelViewDetail={handelViewDetail}
                      patientType={patientType}
                      onSelect={onSelect}
                      renderIcon={renderIcon}
                      cardList={cardList}
                      type={radioGroupValue}
                    ></BedCard>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div
              className="doctor_stationbed_card_content"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Empty />
            </div>
          )
        ) : (
          <div
            className="doctor_stationbed_card_content"
            // style={{
            //   display: 'flex',
            //   justifyContent: 'center',
            //   alignItems: 'center',
            // }}
          >
            <Loading size="normal" />
          </div>
        );
      case 'list':
        return (
          <div className="doctor_stationbed_card_list_content">
            <div className="doctor_stationbed_card_list_content_table">
              {/* 出院 patientType === 'NOT_SUBMIT_MEDICAL_RECORD' &&*/}
              {patientType === 'NOT_SUBMIT_MEDICAL_RECORD' && (
                <VSPromiseTable
                  className="
                  aspirin-table-empty-background-color
                   aspirin-table-row-hover-background-color
                   aspirin-table-nopadding
                   aspirin-table-header-background-color
                   doctor_stationbed_card_list_content_table_VSPromiseTable"
                  id="vSPromiseTableBedPatientOut"
                  pagination={false}
                  bordered
                  style={{ width: 0 }}
                  onFetch={() => {
                    return cardList;
                  }}
                  scroll={{ y: getScrollY(440), x: 1300 }}
                  rowClassName={(v) => {
                    return v?.id == currentSelectCarBed
                      ? 'aspirin-table-row-select-background-color'
                      : '';
                  }}
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        setCurrentSelectCarBed(record?.id);
                        console.log(record);
                      },
                      onDoubleClick: () => {
                        if (record?.bedStatus == 0 || record?.status == 4)
                          return message.info('当前床位无患者');
                        onSelect?.(record);
                        // if (
                        //   (record?.bedStatus == 0 &&
                        //     patientType === 'NOT_SUBMIT_MEDICAL_RECORD') ||
                        //   record?.bedStatus == 1
                        // ) {
                        //   onSelect?.(record);
                        // }
                      },
                    };
                  }}
                >
                  <VSTableColumn
                    dataIndex={['displayId']}
                    title="患者ID"
                    valueType="text"
                    fieldProps={{}}
                    width={100}
                    fixed="left"
                  />
                  <VSTableColumn
                    dataIndex={['expectedDischargeDate']}
                    title="出院时间"
                    valueType="date"
                    fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
                    width={120}
                  />
                  <VSTableColumn
                    dataIndex={['nursingClass']}
                    title="护理等级"
                    valueType="select"
                    fieldProps={{}}
                    dataSource={vsf.stores.dbenums?.enums?.NURSING_CLASS_DICT}
                    render={(dom, value) => {
                      if (value?.nursingClass == '0') {
                        return <Tag color="#df494d">特级</Tag>;
                      } else if (value?.nursingClass == '1') {
                        return <Tag color="#fe7d42">一级</Tag>;
                      } else if (value?.nursingClass == '2') {
                        return <Tag color="#fed800">二级</Tag>;
                      } else if (value?.nursingClass == '3') {
                        return <Tag color="#adb1b4">三级</Tag>;
                      } else {
                        return '';
                      }
                    }}
                    width={100}
                  />
                  <VSTableColumn
                    dataIndex={['patientName']}
                    title="姓名"
                    valueType="text"
                    fieldProps={{}}
                    width={100}
                  />
                  <VSTableColumn
                    dataIndex={['patientGender']}
                    title="性别"
                    valueType="select"
                    fieldProps={{}}
                    dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
                    width={80}
                  />
                  <VSTableColumn
                    dataIndex={['wardName']}
                    title="病区"
                    valueType="text"
                    fieldProps={{}}
                    width={100}
                  />
                  <VSTableColumn
                    dataIndex={['bedLabel']}
                    title="床号"
                    valueType="text"
                    fieldProps={{}}
                    width={100}
                  />
                  <VSTableColumn
                    dataIndex={['patientAge']}
                    title="年龄"
                    valueType="text"
                    fieldProps={{}}
                    width={80}
                  />

                  <VSTableColumn
                    dataIndex={['chargeType']}
                    title="费别"
                    valueType="select"
                    fieldProps={{}}
                    dataSource={vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT}
                    width={100}
                  />
                  <VSTableColumn
                    dataIndex={['admissionDays']}
                    title="入院天数/日"
                    valueType="text"
                    width={100}
                  />
                  <VSTableColumn
                    dataIndex={['admissionWardDays']}
                    title="入科天数/日"
                    valueType="text"
                    width={100}
                  />
                  <VSTableColumn
                    dataIndex={['afterOperationDays']}
                    title="术后天数"
                    valueType="digit"
                    fieldProps={{}}
                    width={100}
                  />
                  <VSTableColumn
                    dataIndex={['medicalGroupName']}
                    title="医疗组名称"
                    valueType="text"
                    fieldProps={{}}
                    width={120}
                  />
                  <VSTableColumn
                    dataIndex={['diagnosisDescription']}
                    title="诊断"
                    valueType="text"
                    width={300}
                    fieldProps={{}}
                  />
                  <VSTableColumn
                    dataIndex={['totalCost']}
                    title="已产生费用"
                    valueType="text"
                    fieldProps={{}}
                    width={120}
                  />
                  <VSTableColumn
                    dataIndex={['inChargeDoctorName']}
                    title="主管医生"
                    valueType="text"
                    fieldProps={{}}
                    width={100}
                  />
                  <VSTableColumn
                    dataIndex={['dischargeVisitType']}
                    title="类型"
                    valueType="select"
                    fieldProps={{}}
                    dataSource={vsf.stores.dbenums?.enums?.DISCHARGE_MODE_DICT}
                    width={100}
                  />
                </VSPromiseTable>
              )}
              {/* 在院 nurses_stationbed_card_list_content_table_VSPromiseTable */}
              {patientType !== 'NOT_SUBMIT_MEDICAL_RECORD' && (
                <VSPromiseTable
                  className="
                  aspirin-table-header-background-color
                  aspirin-table-row-hover-background-color
                     aspirin-table-nopadding
                     aspirin-table-empty-background-color
                     doctor_stationbed_card_list_content_table_VSPromiseTable
                    "
                  id="vSPromiseTableBedPatient"
                  pagination={false}
                  bordered
                  style={{ width: 0 }}
                  scroll={{
                    y: getScrollY(440),
                    x: 1222,
                  }}
                  onFetch={() => {
                    return cardList;
                  }}
                  rowClassName={(v) => {
                    return v?.id == currentSelectCarBed
                      ? 'aspirin-table-row-select-background-color'
                      : '';
                  }}
                  onRow={(record) => {
                    return {
                      onDoubleClick: () => {
                        if (
                          (record?.bedStatus == 0 &&
                            patientType === 'NOT_SUBMIT_MEDICAL_RECORD') ||
                          record?.bedStatus == 1
                        ) {
                          onSelect(record);
                        }
                      },
                      onClick: () => {
                        setCurrentSelectCarBed(record?.id);
                        console.log(record);
                      },
                    };
                  }}
                >
                  <VSTableColumn
                    dataIndex={['displayId']}
                    title="患者ID"
                    valueType="text"
                    fieldProps={{}}
                    width={100}
                    fixed="left"
                  />
                  <VSTableColumn
                    dataIndex={['bedLabel']}
                    title="床号"
                    valueType="text"
                    fieldProps={{}}
                    width={100}
                  />
                  <VSTableColumn
                    dataIndex={['crisisIndicatorIs']}
                    title="病情"
                    valueType="text"
                    // search
                    order={-6}
                    columnKey={'crisisIndicatorIs'}
                    fieldProps={{}}
                    render={(_, value) => {
                      return value?.crisisIndicatorIs ? (
                        <Icon size={16} type="icon-bingwei1" />
                      ) : value?.seriousIndicatorIs ? (
                        <Icon size={16} type="icon-bingzhong" />
                      ) : value?.bedStatus == '0' ? (
                        ''
                      ) : (
                        '一般'
                      );
                    }}
                    width={100}
                  />
                  <VSTableColumn
                    dataIndex={['skinTestPositiveIndicator']}
                    title=""
                    valueType="text"
                    fieldProps={{}}
                    width={220}
                    render={(_, value) => {
                      return renderIcon(value);
                    }}
                  />
                  <VSTableColumn
                    dataIndex={['nursingClass']}
                    title="护理等级"
                    valueType="select"
                    fieldProps={{}}
                    dataSource={vsf.stores.dbenums?.enums?.NURSING_CLASS_DICT}
                    render={(dom, value) => {
                      if (value?.nursingClass == '0') {
                        return <Tag color="#df494d">特级</Tag>;
                      } else if (value?.nursingClass == '1') {
                        return <Tag color="#fe7d42">一级</Tag>;
                      } else if (value?.nursingClass == '2') {
                        return <Tag color="#fed800">二级</Tag>;
                      } else if (value?.nursingClass == '3') {
                        return <Tag color="#adb1b4">三级</Tag>;
                      } else {
                        return '';
                      }
                    }}
                    width={100}
                  />
                  <VSTableColumn
                    dataIndex={['patientName']}
                    title="姓名"
                    valueType="text"
                    fieldProps={{}}
                    width={100}
                  />
                  <VSTableColumn
                    dataIndex={['patientGender']}
                    title="性别"
                    valueType="select"
                    fieldProps={{}}
                    dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
                    width={60}
                  />
                  <VSTableColumn
                    dataIndex={['wardName']}
                    title="病区"
                    valueType="text"
                    fieldProps={{}}
                    width={100}
                  />
                  <VSTableColumn
                    dataIndex={['patientAge']}
                    title="年龄"
                    valueType="text"
                    fieldProps={{}}
                    width={60}
                  />
                  <VSTableColumn
                    dataIndex={['chargeType']}
                    title="费别"
                    valueType="select"
                    fieldProps={{}}
                    dataSource={vsf.stores.dbenums?.enums?.CHARGE_TYPE_DICT}
                    width={100}
                  />
                  <VSTableColumn
                    dataIndex={['admissionDays']}
                    title="入院天数/日"
                    valueType="text"
                    width={100}
                  />

                  <VSTableColumn
                    dataIndex={['admissionWardDays']}
                    title="入科天数/日"
                    valueType="text"
                    width={100}
                  />
                  <VSTableColumn
                    dataIndex={['afterOperationDays']}
                    title="术后天数"
                    valueType="text"
                    width={100}
                    fieldProps={{}}
                  />

                  <VSTableColumn
                    dataIndex={['diagnosisDescription']}
                    title="诊断"
                    valueType="text"
                    width={300}
                    fieldProps={{}}
                  />
                  <VSTableColumn
                    dataIndex={['totalCost']}
                    title="已产生费用"
                    valueType="text"
                    fieldProps={{}}
                    width={120}
                  />
                </VSPromiseTable>
              )}
            </div>
          </div>
        );
      default:
        '';
    }
  };

  return (
    <div className="doctor_stationbed_card">
      <div className="doctor_stationbed_card_search">{renderSearch()}</div>
      <div className="doctor_stationbed_card_search1">{renderSearch1()}</div>
      {renderContent()}
      <div className="doctor_stationbed_card_bottom">
        <div className="doctor_stationbed_card_bottom_left">
          <Popover
            className="doctor_stationbed_card_search_input_right_item_popover"
            placement="bottomLeft"
            content={renderExplain()}
            color={'rgba(1, 16, 53, 0.5)'}
          >
            <Icon type="icon-bangzhu" />
            <span style={{ marginLeft: 6 }}> 图标说明</span>
          </Popover>
        </div>

        <div className="doctor_stationbed_card_bottom_right">
          {total?.map((item, index) => {
            return (
              <div
                key={index}
                className="doctor_stationbed_card_bottom_right_item"
              >
                {item?.name}:{item?.value} {item?.[0]?.unit}
              </div>
            );
          })}

          {/* <div className="doctor_stationbed_card_bottom_right_item">
            空床：
            {total?.filter((item) => {
              return item?.label === 'EMPTY_BED';
            })?.[0]?.count ?? 0}
            张
          </div> */}
          {radioGroupValue === 'list' && (
            <div className="doctor_stationbed_card_bottom_right_item">
              <Button
                type="primary"
                className="doctor_stationbed_card_bottom_right_item_btn"
              >
                授权
              </Button>
              <Button
                type="primary"
                className="doctor_stationbed_card_bottom_right_item_btn"
              >
                提交
              </Button>
            </div>
          )}
        </div>
      </div>
      {/* 患者详情modal */}
      <DetailModal
        ref={modalRef}
        // bedCardId={currentSelectCarBedList}
      ></DetailModal>
    </div>
  );
};

export default definePage(Index);
