import './index.less';

import vsf from '@vs/vsf-boot';
import {
  compose,
  Image,
  Input,
  InputProps,
  message,
  Select,
  SelectProps as AntSelectProps,
  useControllableState,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import React from 'react';
import styled from 'styled-components';

import CodeTableSelect from '../CodeTableSelect';
import readCard from './readCard.png';

const ReadCardDiv = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  color: #3276e8;
  cursor: pointer;
`;

type SelectProps = Omit<AntSelectProps, 'fieldNames'> & {
  /**
   * 默认值
   */
  defaultValue?: any;
  /**
   * 值
   */
  value?: any;
};

export type CertificateSearchProps = {
  /**
   * 选择框props
   */
  selectProps?: SelectProps;
  /**
   * 输入框props
   */
  inputProps?: InputProps;
  /**
   * 入参字段名称映射
   */
  requestParamsFieldNames?: {
    selectName: string;
    inputName: string;
  };
  /**
   * 默认值
   */
  defaultValue?: any;
  /**
   * 值
   */
  value?: any;
  /**
   * 值变化回调
   */
  onChange?: (value?: any) => void;

  onEnter?: (value: any) => void;
};

/**
 * CertificateSearch
 */
const CertificateSearch = (props: CertificateSearchProps) => {
  const {
    defaultValue,
    value: valueProp,
    requestParamsFieldNames = {
      selectName: 'identificationClassIn',
      inputName: 'identificationNumberIn',
    },
    selectProps = { width: 140 },
    inputProps,
    onChange,
    onEnter,
  } = props;
  const [value, setValue] = useControllableState({
    defaultValue,
    value: valueProp,
    onChange,
  });

  const handelSelectChange = (v) => {
    setValue({
      ...value,
      [requestParamsFieldNames.selectName]: v,
    });
    selectProps?.onChange?.(v);
  };

  const handelInputChange = (e) => {
    setValue({
      ...value,
      [requestParamsFieldNames.inputName]: e?.target?.value,
    });
    inputProps?.onChange?.(e);
  };

  /** 编写组件逻辑 */
  return (
    <div>
      <Input
        {...inputProps}
        addonBefore={
          <CodeTableSelect
            className="width140"
            {...selectProps}
            showSearch
            // style={{ width: '140px' }}
            dataSource={vsf.stores.dbenums?.enums?.IDENTIFICATION_CLASS_DICT}
            onChange={handelSelectChange}
          />
        }
        onKeyDown={(event: any) => {
          if (event?.code === 'Enter') {
            if (!value?.[requestParamsFieldNames.selectName]) {
              message?.error('请选择证件类型');
              return;
            }
            onEnter?.({
              [requestParamsFieldNames.selectName]:
                value?.[requestParamsFieldNames.selectName],
              [requestParamsFieldNames.inputName]: event?.target?.value,
            });
          }
        }}
        suffix={
          <ReadCardDiv
            onClick={() => {
              vsf.showToast('读卡中');
            }}
          >
            <div>
              <Image src={readCard} className="read_card" preview={false} />
            </div>
            <div>读卡</div>
          </ReadCardDiv>
        }
        onChange={handelInputChange}
      />
    </div>
  );
};
CertificateSearch.displayName = 'CertificateSearch';

export default compose(
  withField<string>({
    name: 'CertificateSearch',
  }),
  withPreview<CertificateSearchProps>({
    renderPreview: (props) => {
      const { value } = props;
      /** 返回预览模式下的dom */
      return <>预览值：{value}</>;
    },
  }),
)(CertificateSearch);
