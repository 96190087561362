import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  message,
  Modal,
  Tag,
  VSControlledForm,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { Select } from 'antd';
import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import { isMinScreen } from '@/config';
import Empty from '@/module/Empty';
import Block from '@/pages/Index/components/block_no_padding';
import { askIsInteger, askSearch } from '@/pages/Index/components/func/ask';
import { calculateAge, labelColWidth } from '@/utils/index';

const Index = (props) => {
  const { user, settings, common } = vsf?.stores ?? {};
  const { initKey, currentPatient, outVisitInfo } = user ?? {};
  const { currentApplication } = common ?? {};
  const { prefixCls, mainRef, currentKey } = props;
  const { screenSize } = settings ?? {};

  const [birthday, setBirthday] = useState();
  const [saveId, setSaveId] = useState<any>();
  // 科室id
  const [department, setDepartment] = useState<any>();
  // 院区id
  const [admissionHospitalId, setAdmissionHospitalId] = useState<any>();
  const [AdmissionInfo, setAdmissionInfo] = useState<any>();
  // 诊断列表
  const [diagnosisLists, setDiagnosisLists] = useState<any>([]);
  const _patientId = currentPatient?.patientId;

  const getAvailableByClinicInpAdmission = useCallback(async () => {
    if (!_patientId) return;
    const res =
      await vsf.services?.ClinicInpAdmissionController_getAvailableByClinicInpAdmissionQto_405065?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          qto: {
            patientIdIs: _patientId,
            outpVisitIdIs: outVisitInfo?.outpVisit?.id,
          },
        },
      ); // TODO: 你可能需要整理返回数据格式

    const {
      diagnosisList,
      applyType,
      examIndicator,
      patientAdmissionCondition,
      admissionCondition,
      admissionWay,
      applyDoctorId,
      branchInstitutionId,
      applyDate,
      admissionToDepartment,
      appointAdmissionDate,
      admissionToDepartmentId,
      branchInstitution,
      appointWard,
      medicalGroupLeader,
    } = res?.data?.inpAdmissionApplyVo;
    // appointWard: null,
    // admissionToDepartment: null,
    // medicalGroupLeader: null,
    const _inpAdmissionApplyVo = {
      ...res?.data?.inpAdmissionApplyVo,
      branchInstitution: {
        institutionName: branchInstitution?.institutionName
          ? branchInstitution?.institutionName
          : currentApplication?.department?.branchInstitution?.institutionName,
        id: branchInstitution?.id
          ? branchInstitution?.id
          : currentApplication?.department?.branchInstitution?.id,
      },
    };

    setAdmissionInfo({
      ...res?.data,
      inpAdmissionApplyVo: {
        ..._inpAdmissionApplyVo,
        branchInstitution: {
          institutionName: branchInstitution?.institutionName
            ? branchInstitution?.institutionName
            : currentApplication?.department?.branchInstitution
                ?.institutionName,
          id: branchInstitution?.id
            ? branchInstitution?.id
            : currentApplication?.department?.branchInstitution?.id,
        },
      },
    });
    setDepartment({
      value: admissionToDepartment?.id,
      key: admissionToDepartment?.departmentCode,
    });
    setAdmissionHospitalId({
      institutionName:
        branchInstitution?.institutionName ??
        currentApplication?.department?.branchInstitution?.institutionName,
      id:
        branchInstitution?.id ??
        currentApplication?.department?.branchInstitution?.id,
    });
    setBirthday(res?.data?.patientVo?.birthday);

    vsf.refs?.patientBaseInfo?.setFieldsValue({
      ...res?.data?.patientVo,
      birthday: res?.data?.patientVo?.birthday
        ? `${calculateAge(res?.data?.patientVo?.birthday)}岁`
        : '',
      escortName:
        res?.data?.inpAdmissionApplyVo?.inpAdmissionApplyPatientInfoDto
          ?.escortName,
      escortPhoneNumber:
        res?.data?.inpAdmissionApplyVo?.inpAdmissionApplyPatientInfoDto
          ?.escortPhoneNumber,
      relationship:
        res?.data?.inpAdmissionApplyVo?.inpAdmissionApplyPatientInfoDto
          ?.relationship,
    });
    vsf.refs?.inpAppoint?.setFieldsValue({
      ..._inpAdmissionApplyVo,
      applyType: applyType ? applyType : 'NORMAL',
      examIndicator: examIndicator ? examIndicator : false,
      patientAdmissionCondition: patientAdmissionCondition
        ? patientAdmissionCondition
        : '3',
      admissionCondition: admissionCondition ? admissionCondition : 'NORMAL',
      admissionWay: admissionWay ? admissionWay : '1',
      // 登录还没做，没有医师姓名
      applyDoctor: _inpAdmissionApplyVo?.applyDoctor?.staffName,
      // 默认值登录取
      branchInstitutionId: branchInstitutionId ? branchInstitutionId : 421,
      applyDate: applyDate ? applyDate : +new Date(),
      admissionToDepartmentId: Number(
        admissionToDepartmentId,
        // appointAdmissionDate:appointAdmissionDate
      ),
      appointAdmissionDate: new Date(appointAdmissionDate).getTime(),
      diagnosisList:
        diagnosisList && diagnosisList?.length > 0
          ? diagnosisList?.map((item) => {
              return { ...item?.diagnosis, id: item.id };
            })
          : [{ ...diagnosisLists?.[0], id: diagnosisLists?.[0]?.id }],
      admissionToDepartment: admissionToDepartment?.id
        ? admissionToDepartment
        : null,
      appointWard: appointWard?.id ? appointWard : null,
      medicalGroupLeader: medicalGroupLeader?.id ? medicalGroupLeader : null,
      // branchInstitution: {
      //   institutionName: branchInstitution?.institutionName
      //     ? branchInstitution?.institutionName
      //     : currentApplication?.department?.branchInstitution?.institutionName,
      // },
    });
    console.log(diagnosisLists, 'diagnosisListsdiagnosisLists');
  }, [
    _patientId,
    currentApplication,
    outVisitInfo?.outpVisit?.id,
    diagnosisLists,
  ]);
  // 诊断
  const getOutpDiagnosis = async (v?) => {
    const res =
      await vsf.services?.ClinicRecordController_getOutpDiagnosisBySearchLike_08f2cd?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          outpEncounterId: outVisitInfo?.id,
          searchLike: v?.searchLike,
        },
      );
    const _res = res?.data?.map((item) => {
      return { diagnosisName: item?.diagnosis?.diagnosisName, id: item?.id };
    });
    return _res ?? [];
  };
  const getDiagnosis = async () => {
    const res = await getOutpDiagnosis();
    setDiagnosisLists(res);
  };
  useEffect(() => {
    getDiagnosis();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // 预约手术名称
  const getOperationDictionary = async (v?) => {
    // 瀑布流
    const res =
      await vsf.services?.BaseCommonController_queryPageFallBySearchPageFallOperationDictionaryQto_ab3dd3?.(
        {
          qto: {
            from: 0,
            size: 200,
            ...v,
          },
          ext: {},
        },
      ); // TODO: 你可能需要整理返回数据格式
    const _res = res?.data?.result.map((item) => {
      return { label: item?.operationName, value: item?.id };
    });
    return _res ?? [];
  };

  // 收住院区
  const getAllByUpInstitution = async (v) => {
    const res =
      await vsf.services?.OrganizationController_getAllByUpInstitutionCodeAndSearchCode_257200?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          upInstitutionIdIs: currentApplication?.institutionId,
          ...v,
        },
      );
    return res?.data ?? [];
  };
  // 临床科室
  const getPaged = async (v?) => {
    if (!admissionHospitalId?.id) return [];
    const res =
      await vsf.services?.DepartmentDtoController_getPagedBySearchCode_32d6e0?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          ...v,
          nameLike: v?.nameLike ?? '',
          outpOrInp: '1',
          branchInstitutionId: admissionHospitalId?.id,
        },
      );
    return res?.data?.result ?? [];
  };
  //医疗组长
  const getGroupController = async (v) => {
    if (!department?.value) return [];
    const res =
      await vsf.services?.UserGroupController_queryAllByMedicalGroup_169563?.({
        // TODO: 请检查以下这部分传参是否符合需求
        eo: {
          // departmentId: department?.value,
          staffRole: '01',
          stopIndicator: false,
          ...v,
        },
      }); // TODO: 你可能需要整理返回数据格式
    const _res = res?.data?.result?.map((item) => {
      return item?.medicalGroupMemberIndexDtoList?.[0]?.staff;
    });
    const __res = _res.filter((item) => {
      return item?.id;
    });
    return __res ?? [];
  };
  // 护理单元
  const getPagedByDepartment = async (v) => {
    if (!department?.key) return [];
    const res =
      await vsf.services?.DepartmentDtoController_getPagedByDepartmentIdAndSearchCode_4ce058?.(
        {
          // TODO: 请检查以下这部分传参是否符合需求
          ...v,
          departmentId: department?.value,
        },
      );
    return res?.data?.result ?? [];
  };
  useEffect(() => {
    getAvailableByClinicInpAdmission();
  }, [getAvailableByClinicInpAdmission]);

  const handelSave = async () => {
    try {
      const ret2 = await vsf.refs.inpAppoint?.validateFieldsReturnFormatValue();
      const ret1 =
        await vsf.refs.patientBaseInfo?.validateFieldsReturnFormatValue();
      const res = vsf?.refs?.patientBaseInfo?.getFieldValue();
      const res1 = vsf?.refs?.inpAppoint?.getFieldValue();
      const { applyDoctor, ..._res1 } = res1;
      const response =
        await vsf.services?.ClinicInpAdmissionController_saveClinicInpAdmission_b04761?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            clinicInpAdmissionShowVo: {
              patientVo: {
                ...res,
                patientProfileDto: {
                  ...res?.patientProfileDto,
                  currentAddress: {
                    ...res?.patientProfileDto?.currentAddress,
                    county: res?.patientProfileDto?.currentAddress?.district
                      ? res?.patientProfileDto?.currentAddress?.district
                      : res?.patientProfileDto?.currentAddress?.county,
                  },
                  // marriageStatus:
                },
                birthAddress: {
                  ...res?.birthAddress,
                  county: res?.birthAddress?.district
                    ? res?.birthAddress?.district
                    : res?.birthAddress?.county,
                },
                birthday: birthday,
              },
              inpAdmissionApplyVo: {
                ..._res1,
                ...ret2,
                source: 'OUTP',
                inpAdmissionApplyPatientInfoDto: {
                  escortName: ret1?.escortName,
                  escortPhoneNumber: ret1?.escortPhoneNumber,
                  relationship: ret1?.relationship,
                },
                outpVisitId: outVisitInfo?.outpVisit?.id,
                outpEncounterId: outVisitInfo?.id,
                id: saveId ? saveId : res1?.id,
                applyDoctor: null,
              },
            },
          },
        );
      if (response?.code === 200) {
        setSaveId(response?.data?.[0]);
        message.success('保存成功!');
      }
    } catch (error) {
      console.error(error);
    }
  };
  //   okText: '确定',
  //   cancelText: '取消',
  //   onCancel() {},
  // });
  // };

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color="blue"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        // style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <div className={classNames('admission_application_form')}>
      <div className="admission_application_form_main">
        <div className="admission_application_form_main_left">
          <Block
            title="患者基础信息"
            headerStyle={{
              marginBottom: '12px',
            }}
          >
            <VSControlledForm
              //   onSubmit={async (params) => {

              id="patientBaseInfo"
              labelAlign="left"
            >
              <VSFormLayout
                key="0"
                labelCol={isMinScreen ? labelColWidth[10] : labelColWidth[6]}
                columnCount={1}
              >
                <VSFormItem
                  name={['displayId']}
                  label="患者ID"
                  fieldProps={{ disabled: true }}
                  valueType="text"
                  //   formItemProps={{}}
                />
                <VSFormItem
                  name={['id']}
                  label="ID"
                  fieldProps={{ disabled: true }}
                  valueType="text"
                  //   formItemProps={{}}
                />
                <VSFormItem
                  name={['name']}
                  label="姓名"
                  fieldProps={{ disabled: true }}
                  valueType="text"
                  rules={[
                    {
                      message: '姓名长度不合法',
                      type: 'string',
                      min: 0,
                      max: 60,
                    },
                  ]}
                />

                <VSFormItem
                  name={['gender']}
                  label="性别"
                  fieldProps={{ disabled: true }}
                  valueType="select"
                  dataSource={vsf.stores.dbenums.enums.SEX_DICT}
                  //   formItemProps={{}}
                />

                <VSFormItem
                  name={['birthday']}
                  label="年龄"
                  fieldProps={{ disabled: true }}
                  valueType="text"
                  //   formItemProps={{}}
                  // convertValue={(v) => {
                  //   return String(calculateAge(v));
                  // }}
                />

                <VSFormItem
                  name={['idNumber']}
                  label="身份证号"
                  fieldProps={{ disabled: true }}
                  valueType="text"
                  rules={[
                    {
                      message: '数据格式错误',
                      type: 'string',
                      pattern:
                        /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                    },
                  ]}
                  //   formItemProps={{}}
                />

                <VSFormItem
                  name={['defaultChargeType']}
                  label="费用类别"
                  fieldProps={{ disabled: true }}
                  valueType="select"
                  dataSource={vsf.stores.dbenums.enums.CHARGE_TYPE_DICT}
                  //   formItemProps={{}}
                />

                <VSFormItem
                  name={['patientProfileDto', 'marriageStatus']}
                  label="婚姻状况"
                  valueType="select"
                  dataSource={vsf.stores.dbenums.enums.MARITAL_STATUS_DICT}
                  fieldProps={{
                    showSearch: true,
                  }}

                  //   formItemProps={{}}
                />

                {/* <VSFormItem
                  name={['phoneNumber']}
                  label="联系电话"
                  valueType="text"
                  rules={[
                    {
                      message: '联系电话长度不合法',
                      type: 'string',
                      min: 0,
                      max: 40,
                    },
                  ]}
                  fieldProps={{}}
                /> */}
                <VSFormItem
                  name={['cellphone']}
                  label="手机号"
                  valueType="text"
                  rules={[
                    {
                      message: '数据格式错误',
                      type: 'string',
                      pattern: /^(?:\+?86)?1[3-9]\d{9}$/,
                    },
                  ]}
                  fieldProps={{}}
                />
                <VSFormItem
                  name={['patientProfileDto', 'currentAddress']}
                  label="现住址"
                  valueType="address"
                  fieldProps={{
                    cascaderStyle: {
                      width: '100%',
                    },
                  }}
                  convertValue={(v) => {
                    return {
                      ...v,
                      district: v?.district ? v?.district : v?.county,
                    };
                  }}
                />
                <VSFormItem
                  name={['patientProfileDto', 'currentAddress', 'detail']}
                  label="详细地址"
                  valueType="text"
                  fieldProps={{}}
                />
                <VSFormItem
                  name={['birthAddress']}
                  label="出生地"
                  valueType="address"
                  fieldProps={{
                    cascaderStyle: {
                      width: '100%',
                    },
                  }}
                  convertValue={(v) => {
                    return {
                      ...v,
                      district: v?.district ? v?.district : v?.county,
                    };
                  }}
                />
                <VSFormItem
                  name={['escortName']}
                  label="陪护姓名"
                  valueType="text"
                  rules={[
                    {
                      message: '数据格式错误',
                      type: 'string',
                      min: 0,
                      max: 60,
                    },
                  ]}
                  fieldProps={{}}
                />
                <VSFormItem
                  name={['escortPhoneNumber']}
                  label="陪护电话"
                  valueType="text"
                  rules={[
                    {
                      message: '数据格式错误',
                      type: 'string',
                      min: 0,
                      max: 60,
                    },
                  ]}
                  fieldProps={{}}
                />
                <VSFormItem
                  name={['relationship']}
                  label="与患者关系"
                  valueType="custom"
                  dataSource={vsf.stores.dbenums.enums.RELATIONSHIP_DICT}
                  fieldProps={{
                    showSearch: true,
                  }}
                >
                  <Select
                    options={vsf.stores.dbenums.enums.RELATIONSHIP_DICT}
                    showSearch
                    placeholder="请选择与患者关系"
                    filterOption={askSearch}
                  />
                </VSFormItem>
              </VSFormLayout>
            </VSControlledForm>
          </Block>
        </div>
        <div className="admission_application_form_main_right">
          <Block
            title="预约住院信息"
            headerStyle={{
              marginBottom: '12px',
            }}
          >
            <VSControlledForm id="inpAppoint" labelAlign="left">
              {/* <VSFormItem
                name={['source']}
                label="门诊"
                valueType="aspirinSelect"
                style={{ display: 'none' }}
                dataSource={}
              /> */}
              <VSFormLayout
                key="9"
                columnCount={1}
                labelCol={isMinScreen ? labelColWidth[14] : labelColWidth[10]}
              >
                <VSFormItem
                  name={['diagnosisList']}
                  label="门诊诊断"
                  valueType="select"
                  rules={[{ required: true }]}
                  fieldProps={{
                    showSearch: true,
                    mode: 'multiple',
                    allowClear: true,
                    tagRender: tagRender,
                  }}
                  searchKey="searchLike"
                  dataSource={getOutpDiagnosis}
                  fieldNames={{
                    label: 'diagnosisName',
                    value: 'id',
                  }}
                  initialValue={[{ id: diagnosisLists?.[0]?.id }]}
                  transform={(v) => {
                    const _id = v.map((item) => {
                      return item?.id;
                    });
                    const _list = v?.map((item) => {
                      return item?.diagnosis
                        ? { ...item?.diagnosis, id: item?.id }
                        : item;
                    });
                    return { diagnosisRecordIdList: _id, diagnosisList: _list };
                  }}
                />
              </VSFormLayout>
              <VSFormLayout
                key="10"
                columnCount={2}
                labelCol={isMinScreen ? labelColWidth[14] : labelColWidth[10]}
              >
                {/* 默认值从登录取 */}
                <VSFormItem
                  name={['branchInstitution']}
                  label="收住院区"
                  valueType="select"
                  rules={[{ required: true }]}
                  fieldProps={{
                    showSearch: true,
                    onClear: () => {
                      setAdmissionHospitalId(null);
                      setDepartment(null);
                      vsf.refs.inpAppoint.setFieldsValue({
                        appointWard: null,
                        admissionToDepartment: null,
                        medicalGroupLeader: null,
                      });
                    },
                    onSelect: () => {
                      // setAdmissionHospitalId(null);
                      setDepartment(null);
                      vsf.refs.inpAppoint.setFieldsValue({
                        appointWard: null,
                        admissionToDepartment: null,
                        medicalGroupLeader: null,
                      });
                    },
                  }}
                  searchKey="searchCode"
                  dataSource={getAllByUpInstitution}
                  fieldNames={{
                    label: 'institutionName',
                    value: 'id',
                  }}
                  transform={(v) => {
                    setAdmissionHospitalId({
                      institutionName: v?.institutionName,
                      id: v?.id,
                    });
                    return {
                      branchInstitution: {
                        ...v,
                      },
                    };
                  }}
                />
                <VSFormItem
                  name={['admissionToDepartment']}
                  label="临床科室"
                  valueType="select"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  searchKey="nameLike"
                  fieldNames={{
                    label: 'departmentName',
                    value: 'id',
                  }}
                  fieldProps={{
                    showSearch: true,
                    onClear: () => {
                      setDepartment(null);
                      vsf.refs.inpAppoint.setFieldsValue({
                        appointWard: null,
                        medicalGroupLeader: null,
                      });
                    },
                    // onChange: () => {
                    //   vsf.refs.inpAppoint.setFieldsValue({
                    //     appointWard: '',
                    //     admissionToDepartment: '',
                    //     medicalGroupLeader: '',
                    //   });
                    // },
                    onSelect: (v, p) => {
                      // setDepartment(v);
                      // setDepartment(null);
                      vsf?.refs?.inpAppoint?.setFieldsValue({
                        appointWard: null,
                        medicalGroupLeader: null,
                      });
                    },
                  }}
                  dataSource={getPaged}
                  transform={(v) => {
                    setDepartment({ value: v?.id, key: v?.departmentCode });
                    return {
                      admissionToDepartment: {
                        ...v,
                      },
                    };
                  }}
                />

                <VSFormItem
                  name={['medicalGroupLeader']}
                  label="医疗组长"
                  valueType="select"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  fieldProps={{
                    showSearch: true,
                  }}
                  fieldNames={{ label: 'staffName', value: 'id' }}
                  dataSource={getGroupController}
                  transform={(v) => {
                    return { medicalGroupLeader: { ...v } };
                  }}
                />
                <VSFormItem
                  name={['appointWard']}
                  label="护理单元"
                  valueType="select"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  searchKey="searchCode"
                  fieldProps={{
                    showSearch: true,
                  }}
                  fieldNames={{
                    label: 'departmentName',
                    value: 'id',
                  }}
                  dataSource={getPagedByDepartment}
                  transform={(v) => {
                    return {
                      appointWard: {
                        ...v,
                      },
                    };
                  }}
                />
                {/* <VSFormItem
                  name={['bedId']}
                  label="床号"
                  valueType="select"
                  fieldProps={{}}
                /> */}
                <VSFormItem
                  name={['inpAppointDto', 'bedClass']}
                  label="床位类型"
                  valueType="select"
                  dataSource={vsf.stores.dbenums.enums.BED_CLASS_DICT}
                  fieldProps={{ showSearch: true }}
                />
                <VSFormItem
                  name={['appointAdmissionDate']}
                  label="(预约)住院日期"
                  valueType="date"
                  rules={[
                    { required: true, message: '' },
                    {
                      validator: (rule, value, callback) => {
                        if (!value) {
                          callback('请选择(预约)住院日期');
                        } else {
                          callback();
                        }
                      },
                    },
                  ]}
                  fieldProps={{
                    // width: 317,
                    width: '100%',
                  }}
                  required={true}
                />
                <VSFormItem
                  name={['firstPrePayment']}
                  label="首期预交款"
                  valueType="text"
                  // initialValue={0}
                  // rules={[
                  //   () => {
                  //     return {
                  //       validator: async (rule, value, callback) => {
                  //         if (!value || value?.length === 0) return;
                  //         const regex = /^\d+(\.\d{1,2})?$/;
                  //         if (!regex.test(value)) {
                  //           callback('请输入一个非负数，最多有两位小数！');
                  //         } else {
                  //           callback();
                  //         }
                  //       },
                  //     };
                  //   },
                  // ]}
                  rules={[
                    { required: true, message: '' },
                    {
                      validator: (rule, value, callback) => {
                        if (!value || value?.length === 0)
                          return callback('请输入首期预交款');
                        const regex = /^\d+(\.\d{1,2})?$/;
                        if (!regex.test(value)) {
                          callback('请输入一个非负数，最多有两位小数');
                        } else {
                          callback();
                        }
                      },
                    },
                  ]}
                />
                <VSFormItem
                  name={['admissionCondition']}
                  label="入院情况"
                  valueType="radio"
                  initialValue="NORMAL"
                  dataSource={[
                    { label: '一般', value: 'NORMAL' },
                    { label: '急', value: 'EMERGENCY' },
                    { label: '危', value: 'CRISIS' },
                  ]}
                  fieldProps={{}}
                  rules={[{ required: true }]}
                />
                <VSFormItem
                  name={['patientAdmissionCondition']}
                  label="病情"
                  valueType="radio"
                  dataSource={vsf.stores.dbenums.enums.PAT_ADM_CONDITION_DICT}
                  fieldProps={{}}
                  initialValue="3"
                  rules={[{ required: true }]}
                />
                <VSFormItem
                  name={['applyType']}
                  label="病人类型"
                  valueType="radio"
                  initialValue="NORMAL"
                  dataSource={[
                    { label: '普通', value: 'NORMAL' },
                    { label: '预住院', value: 'PRE_IN_HOSPITAL' },
                    { label: '日间手术', value: 'DAY_SURGERY' },
                  ]}
                  rules={[{ required: true }]}
                  fieldProps={{}}
                />
              </VSFormLayout>
              <VSFormLayout
                key="11"
                columnCount={1}
                labelCol={isMinScreen ? labelColWidth[14] : labelColWidth[10]}
              >
                <VSFormItem
                  name={['admissionWay']}
                  label="入院途径"
                  valueType="radio"
                  dataSource={vsf.stores.dbenums.enums.PATIENT_CLASS_DICT}
                  fieldProps={{}}
                  rules={[{ required: true }]}
                  initialValue="1"
                />
                {/* <VSFormItem
                  name={['admissionStatus']}
                  label="入院状态"
                  valueType="select"
                  dataSource={[
                    { label: '已申请', value: 'APPLY' },
                    { label: '预约', value: 'APPOINT' },
                    { label: '取消预约', value: 'CANCEL_APPOINT' },
                    { label: '住院通知', value: 'INP_ADMISSION_NOTIFY' },
                    { label: '入院登记', value: 'INP_ADMISSION_REGISTER' },
                    { label: '取消入院', value: 'CANCEL_ADMISSION' },
                    {
                      label: '退出虚拟住院',
                      value: 'LEAVE_VIRTUAL_IN_HOSPITAL',
                    },
                  ]}
                  fieldProps={{}}
                /> */}
                {/* 少预约住院时长 todo*/}
              </VSFormLayout>
              <VSFormLayout
                key="12"
                columnCount={2}
                labelCol={isMinScreen ? labelColWidth[14] : labelColWidth[10]}
              >
                <VSFormItem
                  name={['admissionReason']}
                  label="入院目的"
                  valueType="select"
                  dataSource={vsf.stores.dbenums.enums.ADMISSION_CAUSE_DICT}
                  fieldProps={{
                    showSearch: true,
                  }}
                  rules={[{ required: true }]}
                />
              </VSFormLayout>
              <VSFormLayout
                key="13"
                columnCount={1}
                labelCol={isMinScreen ? labelColWidth[14] : labelColWidth[10]}
              >
                <VSFormItem
                  name={['operation', 'operationName']}
                  label="预约手术名称"
                  valueType="select"
                  // rules={[
                  //   {
                  //     message: '手术编码的值不合法',
                  //     type: 'number',
                  //     min: 0,
                  //     max: undefined,
                  //   },
                  // ]}
                  searchKey="searchInputLike"
                  dataSource={getOperationDictionary}
                  fieldProps={{ showSearch: true }}
                  fieldNames={{
                    label: 'label',
                    value: 'value',
                  }}
                  transform={(v) => {
                    return {
                      operation: {
                        id:
                          v?.value ??
                          AdmissionInfo?.inpAdmissionApplyVo?.operation?.id,
                        operationName:
                          v?.label ??
                          AdmissionInfo?.inpAdmissionApplyVo?.operation
                            ?.operationName,
                      },
                    };
                  }}
                />
              </VSFormLayout>

              <VSFormLayout
                key="15"
                columnCount={2}
                labelCol={isMinScreen ? labelColWidth[14] : labelColWidth[10]}
              >
                <VSFormItem
                  name={['appointOperationDate']}
                  label="预约手术日期"
                  valueType="date"
                  fieldProps={{ width: '100%' }}
                />
                <VSFormItem
                  name={['examIndicator']}
                  label="在中心做检查"
                  valueType="radio"
                  initialValue={false}
                  dataSource={[
                    { label: '是', value: true },
                    { label: '否', value: false },
                  ]}
                  fieldProps={{}}
                  rules={[{ required: true }]}
                />
              </VSFormLayout>
              <VSFormLayout
                key="16"
                columnCount={1}
                labelCol={isMinScreen ? labelColWidth[14] : labelColWidth[10]}
              >
                <VSFormItem
                  name={['virtualInHospitalType']}
                  label="虚拟住院类型"
                  valueType="select"
                  dataSource={vsf.stores.dbenums.enums.SIMULATION_TYPE_DICT}
                  fieldProps={{
                    showSearch: true,
                  }}
                />
              </VSFormLayout>
              <VSFormLayout
                key="17"
                columnCount={1}
                labelCol={isMinScreen ? labelColWidth[14] : labelColWidth[10]}
              >
                <VSFormItem
                  name={['memo']}
                  label="紧急处理"
                  valueType="textarea"
                  // rules={[
                  //   {
                  //     message: '数据格式错误',
                  //     type: 'string',
                  //     min: 0,
                  //     max: 200,
                  //   },
                  // ]}
                  fieldProps={{}}
                />
              </VSFormLayout>
              <VSFormLayout
                key="8"
                columnCount={2}
                labelCol={isMinScreen ? labelColWidth[14] : labelColWidth[10]}
              >
                <VSFormItem
                  name={['applyDoctor']}
                  label="申请医生ID"
                  valueType="text"
                  rules={
                    [
                      // {
                      //   message: '申请医生ID的值不合法',
                      //   min: 0,
                      //   max: 10000000000000,
                      // },
                      // { required: true },
                    ]
                  }
                  fieldProps={{
                    disabled: true,
                  }}
                />
                <VSFormItem
                  name={['applyDate']}
                  label="申请日期"
                  valueType="date"
                  fieldProps={{
                    width: '100%',
                    disabled: true,
                  }}
                  // rules={[{ required: true }]}
                />
              </VSFormLayout>
            </VSControlledForm>
          </Block>
        </div>
      </div>
      <div className="admission_application_form_bottom">
        <div
          className="admission_application_form_bottom_btn_item admission_application_form_bottom_btn_item_clear"
          onClick={() => {
            getAvailableByClinicInpAdmission();
          }}
        >
          清屏
        </div>
        <div
          className="admission_application_form_bottom_btn_item"
          onClick={handelSave}
        >
          保存
        </div>
        <div className="admission_application_form_bottom_btn_item">
          打印住院证
        </div>
      </div>
    </div>
  );
};

export default definePage(Index);

// 住院申请单
