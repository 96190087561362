import vsf, { definePage } from '@vs/vsf-boot';
import {
  VSTableColumn,
  VSPromiseTable,
  Button,
  VSTableAddition,
  VSTableToolbar,
  message,
  vsfKitGlobal,
} from '@vs/vsf-kit';
import Icon from '@/module/Icon';
import React, { useCallback, useEffect, useState } from 'react';
import Tip from '@/pages/Index/components/func/Tip';
import { useRequest } from 'ahooks';
import { isNumber } from 'lodash';

function RelationshipTable(props) {
  const { data: pharmacySelectData } = useRequest(async () => {
    const res =
      await vsf?.services?.DrugStorageController_getCandicateDrugPharmacyList_3ef044?.(
        {},
      );
    return res.data;
  });
  const [selectData, setSelectData] = useState();
  const onFetch = useCallback(
    async (params) => {
      if (props?.pharmacyDataSource?.id) {
        const res =
          await vsf?.services?.DrugStorageController_getDrugPharmacyListByDepartmentId_7b6a3b?.(
            { departmentId: props?.pharmacyDataSource?.id },
          );
        // res?.data?.map((item)=>{
        //   return {pharmacy:{id:item?.pharmacyId}}
        // })
        return res?.data;
      } else {
        // message.error('请选择药房')
      }
    },
    [props?.pharmacyDataSource?.id],
  );
  const onSave = async (params) => {
    const data = vsf.refs.RelationshipFormTable?.getValues();
    const formatData = data.map((d) => {
      const dict = {
        ...d,
        clinicRegisterTypeId: props.value?.id,
        priceItemId: d.priceItem?.id,
      };
      if (('' + d.id).startsWith('create_')) {
        const { id, ...rest } = dict;
        return rest;
      } else {
        return dict;
      }
    });

    const filter = formatData.map((item) => {
      if (!isNumber(item?.id)) {
        return { departmentId: item?.pharmacyId };
      } else {
        return { id: item?.id, pharmacyId: item?.pharmacyId };
      }
    });
    const bto = {
      storageDepartmentId: props?.pharmacyDataSource?.id,
      drugStorageVsPharmacyBtoList: filter,
    };
    const res =
      await vsf?.services?.DrugStorageController_createOrUpdateDrugPharmacyList_ae7231?.(
        { bto: bto },
      );
    vsf?.refs?.RelationshipFormTable?.reload();
    if (res?.code === 200) {
      message.success('保存成功');
    }
    return res?.data;
  };
  const onRemove = async (params) => {
    const data = vsf.refs.RelationshipFormTable?.getValues();
    const param = {
      id: props?.pharmacyDataSource?.id,
      drugStorageVsPharmacyBtoList: [{ id: params?.id }],
    };
    const res =
      await vsf?.services?.DrugStorageController_deleteDrugPharmacy_f42203?.({
        btoParam: param,
      });
    vsf?.refs?.RelationshipFormTable?.reload();
    if (res?.code === 200) {
      message.success('删除成功');
    }
  };
  const onFileIsNull = (param) => {
    const data = vsf.refs.RelationshipFormTable?.getValues();
    console.log(selectData);
    data.map((item) => {
      console.log(item?.pharmacy?.id);
      console.log(param);
      if (item?.pharmacy?.id !== param?.pharmacy?.departmentName?.id) {
        return false;
      }
    });
    return param;
  };
  const onAdd = async (params) => {
    console.log('-----');
    console.log(params);
    return params;
  };
  const onUpdate = (params) => {
    console.log('---');
    console.log(param);
  };
  return (
    <div>
      <VSPromiseTable
        className="aspirin-table"
        pagination={false}
        editable={{
          editType: 'single',
          editText: <Icon type="icon-bianji" size={24} />,
          onCanDelete: () => null,
          extraOperations: [
            {
              children: <Icon type="icon-shanchu1" size={24} />,
              order: 1,
              onClick: (event, data) => {
                console.log(data);
                console.log();
                Tip({
                  content: `是否删除{【${
                    pharmacySelectData?.find(
                      (item) => data?.pharmacyDepartmentId === item?.id,
                    )?.departmentName
                  }】}`,
                  onOk: () => {
                    onRemove(data);
                  },
                });
              },
            },
          ],
          onFieldChange: (key, value, allValue, form) => {
            console.log(allValue);
            form.setFieldValue(key, {
              pharmacyDepartmentId: allValue?.pharmacyDepartmentId?.id,
              pharmacyId: allValue?.pharmacyDepartmentId?.id,
            });
          },
        }}
        onFetch={onFetch}
        onRecord={() => ({ id: 'create_' + Math.random() })}
        id="RelationshipFormTable"
        onRemove={onRemove}
        onAdd={onAdd}
        onUpdate={onUpdate}
      >
        <VSTableColumn
          dataIndex={['id']}
          title="主键"
          hideInTable={true}
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['branchInstitutionId']}
          title="院区"
          hideInTable={true}
          valueType="digit"
          fieldProps={{}}
        />
        {/* <VSTableColumn
          dataIndex={['id']}
          title="id"
          hideInTable={true}
          valueType="digit"
          fieldProps={{}}
        /> */}
        <VSTableColumn
          dataIndex={['pharmacyDepartmentId']}
          title="药房名称"
          valueType="select"
          fieldProps={{
            dataSource: pharmacySelectData,
            fieldNames: { label: 'departmentName', value: 'id' },
          }}
        />
        <VSTableColumn
          dataIndex={['pharmacyId']}
          title="药房编码"
          valueType="text"
          fieldProps={{}}
        />
        <VSTableToolbar
          title="药房列表"
          style={{ paddingLeft: '20px', paddingRight: '20px' }}
        >
          <VSTableAddition type="dashed" children="添加" />
          <Button type="primary" children="保存" onClick={onSave} />
        </VSTableToolbar>
      </VSPromiseTable>
    </div>
  );
}

export default RelationshipTable;
