import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  Todo,
  VSTableToolbar,
  VSTableAddition,
} from '@vs/vsf-kit';
import React, { useCallback } from 'react';
import Icon from '@/module/Icon';
import { getScrollY } from '@/utils';

const DrugSubStorageManage = (props) => {
  const onFetch = useCallback(async (params) => {
    const res =
      await vsf?.services?.DrugStorageController_getSubDrugStorageListByDrugStorageId_f79b0b?.(
        {},
      );
    return res?.data;
  }, []);

  const handelAdd = async (params) => {
    try {
      const requestParam = {
        ...params,
        id: undefined,
      };
      const response =
        await vsf.services?.DrugStorageController_createOrUpdateSubDrugStorage_73ec27?.(
          {
            btoList: [requestParam],
          },
        );
      if (response && response.code === 200) {
        return params;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
  };

  const handelUpdate = async (params) => {
    console.log(params);
    try {
      const requestParam = {
        ...params,
      };
    const response=  await vsf.services?.DrugStorageController_createOrUpdateSubDrugStorage_73ec27?.(
        {
          btoList: [requestParam],
        },
      );
      if (response && response.code === 200) {
        return params;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
  };
  const handelRemove = async (params) => {
    console.log(params);
    const res =
      await vsf?.services?.DrugStorageController_deleteSubDrugStorage_9e49ce?.({
        bto: { id: params?.id },
      });
    return res?.code === 200;
  };

  return (
    <div>
      <VSPromiseTable
        vsid="83041"
        className="aspirin-table"
        editable={{
          editType: 'single',
          editText: <Icon type="icon-bianji" size={24} />,
          deleteText: <Icon type="icon-shanchu1" size={24} />,
        }}
        onRemove={handelRemove}
        onRecord={() => {
          return {
            id: Math.random(),
          };
        }}
        onAdd={handelAdd}
        onFetch={onFetch}
        onUpdate={handelUpdate}
        scroll={{
          y: getScrollY(280),
        }}
      >
        <VSTableColumn
          dataIndex={['id']}
          title="主键"
          hideInTable={true}
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['subStorageName']}
          title="子库房名称"
          valueType="text"
          formItemProps={
            {
              // rules: [
              //   { message: '数据格式错误', type: 'string', min: 0, max: 20 },
              // ],
            }
          }
          fieldProps={{}}
        />

        <VSTableToolbar
          title="子库房维护"
          style={{ boxSizing: 'border-box', padding: '0 24px' }}
        >
          <VSTableAddition children="添加" position="top" />
        </VSTableToolbar>
      </VSPromiseTable>
    </div>
  );
};
export default definePage(DrugSubStorageManage);
