import { createServices } from '@vs/vsf-boot';
export default createServices({
  MedicalCertificationRecordController_createCertificationRecord_17d142: {
    method: 'post',
    url: '/api/clinic-record/create-certification-record',
    parameterFomatter: (data?: {
      btoParam: CreateMedicalCertificationRecordBtoClinicRecordServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: MedicalCertificationRecordDetailVoClinicRecordEntranceWebVo,
    ) => data,
  },
  MedicalCertificationRecordController_getCertificationRecordDetail_6b5779: {
    method: 'post',
    url: '/api/clinic-record/get-certification-record-detail',
    parameterFomatter: (data?: { inpVisitId: number; outpVisitId: number }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: MedicalCertificationRecordDetailVoClinicRecordEntranceWebVo,
    ) => data,
  },
  MedicalCertificationRecordController_getClinicRecordWithCertification_1b3b3c:
    {
      method: 'post',
      url: '/api/clinic-record/get-clinic-record-with-certification',
      parameterFomatter: (data?: {
        qto: OutpVisitEncounterDiagnosisQtoClinicOutpVisitPersistQto;
      }) => data,
      responseFormatter: (
        _,
        __,
        data?: ClinicRecordCertificationVoClinicRecordEntranceWebVo[],
      ) => data,
    },
});
