import {
  Section,
  VSFormItem,
  VSControlledForm,
  VSDataProvider,
  VSFormFooterSubmit,
  VSFormFooterReset,
  VSFormFooter,
  VSFormLayout,
  Button,
  Divider,
  message,
} from '@vs/vsf-kit';
import React, { useState, useEffect } from 'react';

import Block from '@/module/Block';
import vsf, { definePage } from '@vs/vsf-boot';
import Tip from '../../components/func/Tip';

const InstitutionEdit = (props) => {
  const { value, onChange } = props;
  /**
   * institution
   * @type {MedicalInstitutionVoOrganizationEntranceWebVo}
   */
  const [institution, setInstitution] = useState();
  /**
   * getId
   * @param {any} data
   * @returns {any}
   */
  const getId = (data) => {
    return data?.id;
  };

  const handleSubmit = () => {
    vsf?.refs?.InstitutionForm?.submit();
  };

  const handleReset = () => {
    vsf?.refs?.InstitutionForm?.resetFields([
      'institutionCode',
      'insuranceCode',
      'institutionName',
      'institutionAlias',
      'institutionDescription',
      'institutionLevel',
      'chargeLevel',
      'website',
      'address',
      'stopIndicator',
    ]);
  };

  const handleDelete = async () => {
    if (props?.value?.children?.length > 0)
      return !props?.value?.upInstitutionId
        ? message.info('当前医疗机构下有院区，不可删除！')
        : message.info('当前院区有科室信息，不可删除！');
    console.log(value);
    Tip({
      content: `确定要删除 ${value?.institutionName}吗？`,
      onOk: async () => {
        const res =
          await vsf?.services?.MedicalInstitutionBOController_deleteInstitution_af4371?.(
            {
              btoParam: {
                id: props?.value?.id || institution?.id,
              },
              ext: {},
            },
          );
        onChange?.();
        setInstitution({});
        vsf?.refs?.InstitutionForm?.resetFields();
      },
    });
  };

  useEffect(() => {
    if (value && !value?.id) {
      if (Object.keys(value)?.length === 0) {
        vsf?.refs?.InstitutionForm?.resetFields();
      } else {
        setInstitution(value);
        vsf?.refs?.InstitutionForm?.resetFields();
        vsf?.refs?.InstitutionForm?.setFieldsValue(value);
      }
    }
  }, [value]);

  const isEdit = getId(props.value);

  return (
    <Block
      title={
        isEdit
          ? props?.value?.treeLevel === 0
            ? '编辑机构'
            : '编辑院区'
          : props?.value?.upInstitutionId
          ? '新增院区'
          : '新增医疗机构'
      }
      scroll={{ y: true }}
      footer={
        <div className="aspirin-form-footer">
          {(isEdit || institution?.id) && (
            <Button size="large" onClick={handleDelete} className="icon16">
              删除
            </Button>
          )}
          <Button size="large" onClick={handleReset} className="icon16">
            重置
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={handleSubmit}
            className="icon16"
          >
            保存
          </Button>
        </div>
      }
    >
      {isEdit && (
        <VSDataProvider
          service={
            vsf.services
              .MedicalInstitutionDtoController_getInstitutionById_5594f2
          }
          params={{
            id: props.value?.id,
          }}
          onDataLoaded={(_value) => {
            setInstitution({ ..._value });
          }}
        />
      )}
      <VSControlledForm
        id="InstitutionForm"
        value={institution}
        onSubmit={async (params) => {
          const funcName = params.id
            ? 'MedicalInstitutionBOController_updateInstitution_83b664'
            : 'MedicalInstitutionBOController_createInstitution_c79457';
          const res = await vsf?.services?.[funcName]?.({
            btoParam: { ...(params ?? {}) },
          });
          onChange?.();
          // setInstitution(res?.data);
          return res?.data; // 直接返回提交的数据
        }}
        label
        labelAlign="left"
        labelWrap
      >
        <VSFormItem
          name={['id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />
        <VSFormLayout columnCount={3} gutter={[120]} labelWidth={130}>
          <VSFormItem
            name={['upInstitutionId']}
            label="上级医疗机构编码"
            valueType="text"
            fieldProps={{
              disabled: true,
            }}
          />

          <VSFormItem
            name={['upInstitutionName']}
            label="上级医疗机构名称"
            valueType="text"
            fieldProps={{
              disabled: true,
            }}
          />

          <VSFormItem
            name={['institutionCode']}
            label="卫健委注册编码"
            valueType="text"
            rules={[
              {
                message: 'institution_code长度不合法',
                type: 'string',
                min: 0,
                max: 100,
              },
              // { required: true },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['insuranceCode']}
            label="医保注册编码"
            valueType="text"
            rules={[
              {
                message: 'insurance_code长度不合法',
                type: 'string',
                min: 0,
                max: 100,
              },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['institutionName']}
            label="医疗机构名称"
            valueType="text"
            rules={[
              {
                message: 'institution_name长度不合法',
                type: 'string',
                min: 0,
                max: 100,
              },
              { required: true },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['institutionAlias']}
            label="医疗机构简称"
            valueType="text"
            rules={[
              {
                message: 'institution_alias长度不合法',
                type: 'string',
                min: 0,
                max: 100,
              },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['institutionDescription']}
            label="医疗机构描述"
            valueType="text"
            rules={[
              {
                message: 'institution_desc长度不合法',
                type: 'string',
                min: 0,
                max: 4000,
              },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['institutionLevel']}
            label="医疗机构等级"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.INSTITUTION_LEVEL_DICT}
            fieldProps={{
              showSearch: true,
            }}
          />

          <VSFormItem
            name={['chargeLevel']}
            label="收费等级"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.CHARGE_LEVEL_DICT}
            fieldProps={{
              showSearch: true,
            }}
          />
        </VSFormLayout>
        <Divider />
        <VSFormLayout columnCount={3} gutter={[120]} labelWidth={130}>
          <VSFormItem
            name={['website']}
            label="网址"
            valueType="text"
            rules={[
              {
                message: 'website长度不合法',
                type: 'string',
                min: 0,
                max: 100,
              },
            ]}
            fieldProps={{}}
          />

          <VSFormItem
            name={['address']}
            label="地址"
            valueType="text"
            fieldProps={{}}
          />

          {/* <VSFormItem
            name={['leafFlag']}
            label="是否叶节点"
            valueType="select"
            dataSource={[
              { label: '是', value: true },
              { label: '否', value: false },
            ]}
            // rules={[{ required: true }]}
            fieldProps={{
              disabled: isEdit,
            }}
          /> */}

          <VSFormItem
            name={['stopIndicator']}
            label="停用"
            valueType="select"
            dataSource={[
              { label: '在用', value: false },
              { label: '停用', value: true },
            ]}
            fieldProps={{
              showSearch: true,
            }}
            // initialValue={false}
            rules={[{ required: true }]}
          />
        </VSFormLayout>
      </VSControlledForm>
    </Block>
  );
};
export default definePage(InstitutionEdit);
