import vsf from '@vs/vsf-boot';
import { Checkbox, message, VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import { useGetState, useKeyPress, useReactive, useResetState } from 'ahooks';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import Icon from '@/module/Icon';
import { askFixed } from '@/pages/Index/components/func/ask';

import { useFetch, useOnChangeValue } from '../func/hooks';

// import Icon from '@/module/Icon';
// import { askFixed } from '@/pages/Index/components/func/ask';

const mockDoctorId = 1603;

const maxIndex = 9;
const minIndex = 0;

interface DictionaryProps {
  /**
   * 药品接口
   * @returns 药品列表
   */
  onDrugFetch?: (params) => Promise<any[]>;
  onDrugDetailsFetch?: (params) => Promise<any>;
  onSelect?: (value) => void;
  // 是否显示库存
  showRepertory?: boolean;
  // 是否显示药房
  showStorageName?: boolean;
  searchValue?: string;
  storageId?: number;
  storageList?: any[];
  open?: boolean;
  favoriteType?: string;
  /**
   * 工作站类型 in: 住院 out: 门诊
   */
  hospitalType?: string;
  /**
   * 长期还是临时医嘱 true: 长期； false: 临时
   */
  repeatIndicator?: boolean;
}

const Index = (props: DictionaryProps) => {
  const {
    onSelect,
    open,
    showRepertory = true,
    showStorageName = true,
    storageId,
    storageList,
    searchValue,
    onDrugFetch,
    favoriteType,
    hospitalType,
    repeatIndicator,
  } = props;
  const tableRef = useRef<any>();
  const [index, setIndex, getIndex] = useGetState<number>(-1);

  const handleSelect = useCallback(
    (data) => {
      if (open) {
        const target = prescriptionIndicatorRef.current?.[data?.drugId];
        const value =
          typeof target === 'boolean'
            ? target
            : data?.prescriptionDefaultIndicator;
        const newObj = repeatIndicator
          ? {}
          : {
              prescriptionIndicator: value,
            };

        onSelect?.({
          ...data,
          ...newObj,
        });
      }
    },
    [open, onSelect, repeatIndicator],
  );

  const getCurrentData = useCallback(
    (index) => {
      const dataSource = tableRef?.current?.getValues();
      handleSelect(dataSource?.[index]);
    },
    [handleSelect],
  );

  useEffect(() => {
    const keyup = {
      ArrowDown: () => {
        if (index < maxIndex && open) {
          setIndex(getIndex() + 1);
        }
      },
      ArrowUp: () => {
        if (index > minIndex && open) {
          setIndex(getIndex() - 1);
        }
      },
      ArrowLeft: () => {
        if (open) {
          tableRef?.current?.gotoPreviousPage();
          setIndex(0);
        }
      },
      ArrowRight: () => {
        if (open) {
          tableRef?.current?.gotoNextPage();
          setIndex(0);
        }
      },
    };
    const up = (event) => {
      keyup?.[event?.key]?.();
    };
    document.addEventListener('keyup', up);
    return () => {
      document?.removeEventListener('keyup', up);
    };
  }, [getIndex, index, open, setIndex]);

  const int = Array?.from({ length: 10 })?.map((...[, index]) => index + '');

  useKeyPress(
    (event) => int?.includes(event?.key),
    (event) => {
      if (open) {
        const num = Number(event?.key);
        if (num == 0) {
          getCurrentData(9);
        } else if (num < 9) {
          getCurrentData(num - 1);
        } else {
          getCurrentData(0);
        }
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  useKeyPress(
    'enter',
    () => {
      if (open && index >= 0) {
        getCurrentData(getIndex());
      }
    },
    {
      events: ['keyup'],
      useCapture: true,
    },
  );

  const onFetch = useCallback<any>(
    (params) => {
      return (
        onDrugFetch?.({
          ...params,
          storageId,
          storageList,
          ...(searchValue ? {} : { favoriteType }),
          searchValue,
          repeatIndicatorIs: repeatIndicator,
        }) ?? []
      );
    },
    [
      onDrugFetch,
      searchValue,
      storageId,
      storageList,
      favoriteType,
      repeatIndicator,
    ],
  );

  useEffect(() => {
    tableRef?.current?.setCurrentPage(1);
  }, [searchValue]);

  const Price = ({ recode }) => {
    const [favorite] = useOnChangeValue(false);
    const onCollect = useFetch({
      fetch: async (params) => {
        const res =
          await vsf.services?.FavoriteOrderItemSettingController_settingFavoriteOrderItem_061ca6?.(
            {
              favoriteType: 'PERSONAL',
              itemClass: 'DRUG',
              itemId: params?.drugId,
            },
          );
        return res;
      },
      success: () => {
        // favorite?.onChange?.(true);
        setTimeout(() => {
          tableRef?.current?.reload();
        }, 500);
      },
      message: '收藏',
    });
    const onCancelCollect = useFetch({
      fetch: async (params) => {
        const res =
          await vsf.services?.FavoriteOrderItemSettingController_cancelFavoriteOrderItemByItemId_ccd6f0?.(
            {
              itemClass: 'DRUG',
              favoriteType: 'PERSONAL',
              itemId: params?.drugId,
            },
          );
        return res;
      },
      message: '取消收藏',
      success() {
        // favorite?.onChange?.(false);
        setTimeout(() => {
          tableRef?.current?.reload();
        }, 500);
      },
    });
    useEffect(() => {
      favorite?.onChange(recode?.isFavorite ?? true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recode?.isFavorite]);
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span>{askFixed(recode?.retailPrice, 2)}</span>
        {favorite?.value ? (
          <Icon
            size={24}
            style={{ color: '#ffa77e' }}
            type="icon-shoucang1"
            onClick={(event) => {
              event?.stopPropagation();
              onCancelCollect(recode);
            }}
          />
        ) : (
          <Icon
            type="icon-meiyoushoucang"
            size={24}
            style={{ color: 'rgb(174, 176, 179)' }}
            onClick={(event) => {
              event?.stopPropagation();
              onCollect(recode);
            }}
          />
        )}
      </div>
    );
  };

  const prescriptionIndicatorRef = useRef({});

  return (
    <div className="dictionary-table">
      <VSPromiseTable
        ref={tableRef}
        className="aspirin-table aspirin-table-nopadding"
        onFetch={onDrugFetch ? onFetch : () => []}
        pagination={{
          defaultPageSize: 10,
        }}
        size="small"
        rowClassName={(...[, rowIndex]) => {
          return index === rowIndex ? 'ant-table-row-selected' : '';
        }}
        rowSelection={{
          selectType: 'single',
          hideColumn: true,
          onSelect: (node, key, selected) => {
            handleSelect(node);
          },
          onSelectedRowsChange: (data, keys) => {
            handleSelect(data?.[0]);
          },
        }}
        rowClick={{
          rowClickType: 'select',
        }}
        scroll={{ x: '66.66rem' }}
      >
        <VSTableColumn
          valueType="index"
          render={(v: any) => {
            if (v?.props?.text === 9) {
              return (
                <div className="ant-pro-field-index-column top-three">0</div>
              );
            }
            return v;
          }}
          title="序号"
          width="3.33rem"
          preview
        />

        <VSTableColumn
          dataIndex={['drugProductName']}
          title="药品名称"
          valueType="text"
          fieldProps={{}}
          width="16.66rem"
          preview
          // render={(...[, record]) =>
          //   record?.drugProductName ?? record?.drugName
          // }
        />

        <VSTableColumn
          dataIndex={['packageSpecification']}
          title="规格"
          valueType="text"
          fieldProps={{}}
          width="12rem"
          preview
        />

        {hospitalType === 'in' && !repeatIndicator && (
          <VSTableColumn
            valueType="custom"
            dataIndex={['prescriptionIndicator']}
            title="处方"
            render={(...arr) => {
              const [, record] = arr;
              const {
                prescriptionDefaultIndicator,
                prescriptionEditableIndication,
                drugId,
              } = record;
              const defaultValue =
                prescriptionIndicatorRef.current?.[drugId] ??
                prescriptionDefaultIndicator;
              return (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Checkbox
                    defaultValue={defaultValue}
                    onChange={(v) => {
                      prescriptionIndicatorRef.current = {
                        ...prescriptionIndicatorRef?.current,
                        [drugId]: v,
                      };
                    }}
                    disabled={!prescriptionEditableIndication}
                  />
                </div>
              );
            }}
          />
        )}

        <VSTableColumn
          valueType="text"
          dataIndex={['desc']}
          title="说明书"
          render={() => '详情'}
          preview
        />

        <VSTableColumn
          dataIndex={['medicalInsuranceType']}
          title="医保类型"
          valueType="select"
          dataSource={[
            { label: '甲', value: 'ALPHA' },
            { label: '乙', value: 'BETA' },
            { label: '丙', value: 'GAMMA' },
          ]}
          fieldProps={{}}
          preview
        />

        {showRepertory && (
          <VSTableColumn
            dataIndex={['amount']}
            title="库存"
            valueType="digit"
            fieldProps={{}}
            preview
          />
        )}

        {showStorageName && (
          <VSTableColumn
            dataIndex={['storageName']}
            title="药房"
            valueType="text"
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 40 },
              ],
            }}
            fieldProps={{}}
            preview
          />
        )}

        <VSTableColumn
          dataIndex={['retailPrice']}
          title="价格"
          valueType="custom"
          fieldProps={{}}
          width="6.66rem"
          render={(...[, record]) => <Price recode={record} />}
          preview
        />
      </VSPromiseTable>
    </div>
  );
};

export default Index;
