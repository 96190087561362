import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  Todo,
  VSTable,
  VSTableToolbar,
  VSTableAddition,
  Tag,
  Divider,
  message,
} from '@vs/vsf-kit';
import React, { useCallback, useState, useEffect } from 'react';

import { getScrollY } from '@/utils';
import { ButtonAndKeyDown } from '@/pages/Index/components';
import { useFetch } from '@/pages/Index/components/func/hooks';
import Icon from '@/module/Icon';
import SearchContainer from '@/module/Search';
import Tip from '@/pages/Index/components/func/Tip';
import vsf, { definePage } from '@vs/vsf-boot';

import StaffEdit from './edit';

const StandardApplicationFuncitonEdit = (props) => {
  const { application } = props;
  const [addList, setAddList] = useState([]);
  const [roleList, setRoleList] = useState([]);

  /**
   * 转换
   * @param {object} value
   * @returns {object}
   */
  const valueToApplicationId = (value) => {
    if (!value) {
      return {};
    }
    return { applicationId: value?.id };
  };

  const handleGetRoleList = useCallback(async () => {
    if (!application?.id) return;
    const res =
      await vsf?.services?.PrivilegeController_getAllRoleListOfStandardApplication_ce4076?.(
        {
          bizApplicationId: application?.id,
        },
      );
    if (res?.code === 200) {
      setRoleList(res?.data);
    }
  }, [application]);

  useEffect(() => {
    handleGetRoleList();
  }, [handleGetRoleList]);

  const onFetch = useCallback(
    async (params) => {
      if (!params.extra?.applicationId)
        return {
          data: [],
          total: 0,
        };
      const res =
        await vsf?.services?.PrivilegeController_queryApplicationStaffWithRoleList_8aadd4?.(
          {
            // TODO: 请检查以下这部分传参是否符合需求
            qto: {
              from: params.pagination?.from ?? 0,
              size: params.pagination?.size ?? 20,
              orderList: params.orderList,
              ...params.search,
              ...params.filters,
              ...(params?.extra ?? {}),
              ...(params ?? {}),
              applicationIdIs: params.extra?.applicationId,
            },
          },
        ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
      const _res = res?.data?.map((i) => {
        return {
          ...i,
          staffVsRoleWithRoleDtoList: i?.staffVsRoleWithRoleDtoList?.map(
            (item) => {
              return {
                ...item,
                roleId: item?.role?.id,
                roleName: item?.role?.roleName,
              };
            },
          ),
        };
      });

      return {
        data: _res ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [application],
  );

  const addLinkPage = useCallback(
    () => <StaffEdit application={application} />,
    [application],
  );

  const onRemove = useFetch({
    fetch: async (data) => {
      const res =
        await vsf?.services?.PrivilegeController_deleteApplicationStaffWithRole_7de462?.(
          {
            bto: {
              id: data?.id,
            },
          },
        );
      return res;
    },
    success: () => {
      vsf?.refs?.BusinessTable?.reload?.();
    },
    message: {
      loading: '删除人员中',
      success: '删除人员成功',
      error: '删除人员失败',
    },
  });
  const onAdd = async (params, type = 'add') => {
    const res =
      await vsf?.services?.PrivilegeController_createOrUpdateApplicationStaffWithRole_0bd2f6?.(
        {
          btoList: params,
        },
      );
    if (res?.code == 200) {
      message.success(type == 'add' ? '新增人员成功' : '编辑人员成功');
      setTimeout(() => {
        vsf?.refs?.BusinessTable?.reload?.();
      }, 1000);
      return true;
    }
  };

  return (
    <VSPromiseTable
      scroll={{
        y: getScrollY(460),
      }}
      className="table_overflow_450 aspirin-table aspirin-table-search-padding-no aspirin-table-no-background-box"
      id="BusinessTable"
      pagination={false}
      editable={{
        editType: 'single',
        editText: <Icon type="icon-bianji" size={24} />,
        deleteText: <Icon type="icon-shanchu1" size={24} />,
        saveText: <Icon type="icon-a-Frame437" size={24} />,
        cancelText: <Icon type="icon-shanchu1" size={24} />,
        onCanDelete: () => false,
        onCanEdit: () => true,
        extraOperations: [
          {
            children: <Icon type="icon-shanchu1" size={24} />,
            order: 1,
            onClick: (event, data) => {
              Tip({
                content: `是否删除数据：{【${data.staff.staffName}】}`,
                onOk: () => {
                  onRemove(data);
                },
              });
            },
          },
        ],
      }}
      searchFormRender={(props, dom) => (
        <div className="aspirin-table-search-inline-no">
          <SearchContainer mode="inline" formPadding="0 170px 0 100px">
            {dom}
          </SearchContainer>
          <Divider />
        </div>
      )}
      searchConfig={{
        labelWidth: 100,
        labelAlign: 'left',
        collapsed: false,
        collapseRender: false,
        optionRender: (...arr) => {
          const [, , [other, search]] = arr;
          return (
            <SearchContainer.Header title="筛选">
              {[
                other,
                <ButtonAndKeyDown
                  methodKey="altKey"
                  ordinaryKey="Q"
                  {...search?.props}
                >
                  查询
                </ButtonAndKeyDown>,
              ]}
            </SearchContainer.Header>
          );
        },
      }}
      vsid="74364"
      onFetch={onFetch}
      addLinkPage={{
        linkPage: addLinkPage,
        modalProps: {
          title: '新增人员',
          className: 'aspirin-linkpage aspirin-linkpage-static-height',
          style: {
            // height: '80vh',
          },
          className: 'modalHeight700',
        },
        onLinkPageValidate: async () => {
          try {
            await vsf.refs.FunctionForm?.validateFieldsReturnFormatValue();
            return true;
          } catch (e) {
            return false;
          }
        },
        updateType: 'reload',
      }}
      onAdd={onAdd}
      onUpdate={async (v) => {
        const __params = {
          applicationId: props?.application?.id,
          staffId: v?.staff?.id,
          staffVsRoleBtoList: v?.staffVsRoleWithRoleDtoList?.map((item) => {
            return {
              applicationId: props?.application?.id,
              roleId: item?.roleId,
            };
          }),
        };
        const res = await onAdd([__params], 'updata');

        return res ? v : false;
      }}
      extraParams={valueToApplicationId(props?.application)}
    >
      <VSTableColumn
        dataIndex={['id']}
        title="主键"
        hideInTable={true}
        valueType="digit"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['staff', 'id']}
        title="员工识别号"
        editable={false}
        valueType="digit"
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['staff', 'staffName']}
        title="姓名"
        editable={false}
        valueType="text"
        search
        order={-5}
        columnKey={'staffNameLike'}
        formItemProps={{
          rules: [
            {
              message: 'staff_name长度不合法',
              type: 'string',
              min: 0,
              max: 60,
            },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['staff', 'loginName']}
        title="登录名"
        editable={false}
        valueType="text"
        search
        order={-6}
        columnKey={'loginNameLike'}
        formItemProps={{
          rules: [
            {
              message: 'login_name长度不合法',
              type: 'string',
              min: 0,
              max: 20,
            },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['staff', 'staffDepartment', 'departmentName']}
        title="科室"
        editable={false}
        valueType="text"
        formItemProps={{
          rules: [
            {
              message: '科室名称长度不合法',
              type: 'string',
              min: 0,
              max: 40,
            },
          ],
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        // dataIndex={['staffVsRoleWithRoleDtoList', 'role', 'roleName']}
        dataIndex={['staffVsRoleWithRoleDtoList']}
        title="角色名"
        // editable={false}
        fieldProps={{
          mode: 'multiple',
        }}
        fieldNames={{
          label: 'roleName',
          value: 'roleId',
        }}
        valueType="select"
        dataSource={roleList?.map((item) => ({
          roleName: item?.roleName,
          roleId: item?.id,
        }))}
        formItemProps={
          {
            // rules: [
            //   { message: '角色名长度不合法', type: 'string', min: 0, max: 32 },
            // ],
          }
        }
        // fieldProps={{}}
        render={(dom, record) => {
          const target = record?.staffVsRoleWithRoleDtoList ?? [];
          return target?.map((item) => {
            return <Tag color="blue">{item?.role?.roleName}</Tag>;
          });
        }}
      />

      <VSTableToolbar title="人员设置">
        <VSTableAddition children="添加" disabled={!application?.id} />
      </VSTableToolbar>
    </VSPromiseTable>
  );
};
export default definePage(StandardApplicationFuncitonEdit);
