/* eslint-disable react-hooks/exhaustive-deps */
import '../index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Section,
  Space,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import { Select } from 'antd';
import dayjs from 'dayjs';
import { number } from 'echarts';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import AspirinSelect from '@/components/AspirinSelect';
import { isMinScreen } from '@/config';
import Container from '@/module/Container';
import Empty from '@/module/Empty';
import Loading from '@/module/Loading';
import SearchContainer from '@/module/Search';
import FormTitle from '@/pages/Index/components/form_title';
import { askIsInteger, askSearch } from '@/pages/Index/components/func/ask';
import TableRender from '@/pages/Index/components/func/TableRender';
import { getScrollY, labelColWidth, labelNumberWidth } from '@/utils';

import {
  convertGetErpPreExamMainVoRes2PreExamMainForm,
  convertPreExamMainForm2SaveErpPreExamFuncFlowExecutorReq,
  convertPreExamMainForm2SaveErpPreExamFuncFlowExecutorReq1,
} from '../convert';
import ActionChief from '../modal/action_chief';
import CRAMSModal from '../modal/crams_modal';
import FallRiskModal from '../modal/fall_risk_modal';
import GCSModal from '../modal/gcs_modal copy';
import NRSModal from '../modal/nrs_modal';
import PEWSModal from '../modal/pews_modal';
import PreviousModal from '../modal/previous_history_modal';
import SecondTirage from '../modal/secondary_triage';
import { calculateMews, cheifClassify } from './calculate';
import PatientAssessment from './patient_assessment';

const Index = (props) => {
  const {
    currentRowId = {},
    clearScreen,
    init,
    searchId,
    type,
    handelSaveSuccess,
    handelReloadList,
  } = props;
  const [isRequired, setIsRequired] = useState(true);
  const [bodyTemperatureRequiredRef, setBodyTemperatureRequiredRef] =
    useState(true);
  const [diseaseLevelColor, setDiseaseLevelColor] = useState<any>();
  const [referenceDiseaseLevelIdColor, setReferenceDiseaseLevelIdColor] =
    useState<any>();
  const [loading, setLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  // 小儿患者
  // const [isChildren, setIsChildren] = useState(true);
  const isChildren = useRef<boolean>(false);
  // 分级list
  const [levelList, setLevelList] = useState<any>([]);
  // 事实际病情原因是否必填
  const [isRequiredDiseaseLevel, setIsRequiredDiseaseLevel] = useState(true);
  // 病人去向原因是否必填
  const [isRequiredTreatAreaCode, setIsRequiredTreatAreaCode] = useState(true);
  const nrsRef = useRef<any>();
  const cramsRef = useRef<any>();
  const actionCiefRef = useRef<any>();
  const pewsRef = useRef<any>();
  const gcsRef = useRef<any>();
  const fallRiskRef = useRef<any>();
  const previousRef = useRef<any>();
  const secondTriageRef = useRef<any>();
  const [detail, setDetail] = useState<any>();
  const requiredRef = useRef<any>();

  // const bodyTemperatureRequiredRef = useRef<any>();
  const _labelWidth = isMinScreen ? 100 : 128;

  useEffect(() => {
    getLevel1();
  }, []);

  const handelModalList = (detail, modallist) => {
    // 小儿显示11，非小孩显示03
    if (detail?.preExamPatientType == 'BABY') {
      return modallist?.filter((i) => i?.code != '03');
    } else {
      return modallist?.filter((i) => i?.code != '11');
    }
  };

  const handelData = (arr = [], size = 3) => {
    const _arr: any = [];
    const residue = arr?.length % 3;
    const length = residue == 0 ? arr?.length / 3 : arr?.length / 3 + 1;
    for (let i = 0; i < 3; i++) {
      const fieldArr: any = [];
      for (let j = 0; j < length; j++) {
        if (j * 3 + i <= arr.length - 1) {
          fieldArr.push(arr[j * 3 + i]);
        }
      }
      _arr.push(fieldArr);
    }

    return _arr;
  };

  useEffect(() => {
    getAllByChief();
    // cheifClassify()
  }, []);
  // 判定依据
  const getAllByChief = async () => {
    const res =
      await vsf.services.ComplaintJudgeDictionaryController_getAllByChiefComplaintJudgeDictionaryNewQto_989929(
        { qto: {} },
      );
    // console.log(res, 'ppppppppppppppppppppppppppppppppp0');

    if (res?.code == 200) {
      cheifClassify(res?.data ?? []);
      return res?.data ?? [];
    }
    return [];
  };

  const getDetail = useCallback(
    // isNewErp为true 二次分诊弹窗新增分诊-保存NEW_ERP；直接查询患者-保存NEW_ERP；
    // 二次分诊弹窗二次分诊, 取消分诊 CANCEL_ERP
    // 二次分诊弹窗二次分诊,保存 REPEAT_ERP
    async (isNewErp?, erpVisitId?) => {
      if (!currentRowId?.id && !erpVisitId) return;
      setLoading(true);
      setShowDetail(false);
      if (isNewErp) {
        setDetail(null);
        vsf.refs?.preExamMainForm?.setFieldsValue(null);
      }
      const res =
        erpVisitId || currentRowId?.type == 'doubleClick'
          ? await vsf?.services?.ClinicErpVisitController_getErpPreExamMainVo_da8a4e?.(
              {
                // 二次分诊弹窗二次分诊查询/双击列表查询
                erpVisitId: erpVisitId ?? currentRowId?.spacicalId,
              },
            )
          : await vsf?.services?.ClinicErpVisitController_getNewErpPreExamMainVoByDisplayId_adbc63?.(
              !isNewErp
                ? {
                    // 直接查询
                    displayId: currentRowId?.id,
                  }
                : {
                    // 二次分诊弹窗新增分诊查询
                    displayId: currentRowId?.id,
                    isNewErp: true,
                  },
            );

      console.log(res, '7375_获取患者数据');
      // const saveErpPreExamFunctionEnum = isNewErp?'NEW_ERP';
      const output = convertGetErpPreExamMainVoRes2PreExamMainForm?.(res);
      if (
        output?.preExamPatientType == 'BABY' ||
        output?.vitalsignStatus == 'WITHOUT_VITALSIGN' ||
        output?.vitalsignStatus == 'NOT_TPRBP'
      ) {
        setIsRequired(false);
      }
      setLoading(false);
      let flag = false;
      switch (output?.preExamPatientType) {
        // 普通患者
        case 'GENERAL':
          flag = false;
          break;
        // 小儿患者
        case 'BABY':
          flag = true;
          break;
        // 产科患者
        case 'CHILDBIRTH':
          flag = false;
          break;

        default:
          break;
      }
      isChildren.current = flag;
      if (res?.code == 200) {
        if (res?.data) {
          setShowDetail(true);
          // 患者评估list
          const list =
            output?.erpPreExamRecordDetailWithVitalsignDictionaryList;
          // 过滤脉搏15、体温05 06 07、呼吸08、收缩压10 12

          const hearRate = list?.filter((i) => i?.item == '15');
          const systolicPressure = list?.filter(
            (i) => i?.item == '10' || i?.item == '12',
          );
          const breath = list?.filter((i) => i?.item == '28');
          const bodyTemperature = list?.filter(
            (i) => i?.item == '05' || i?.item == '06' || i?.item == '07',
          );
          const _filter = calculateMews(
            hearRate,
            systolicPressure,
            breath,
            bodyTemperature,
          );
          const res1 = vsf.stores.dbenums.enums.PRE_EXAM_SCORE_TYPE?.sort(
            (a, b) => {
              return a?.order - b?.order;
            },
          )
            ?.filter(
              (i) =>
                i?.code != '01' &&
                i?.code != '11' &&
                i?.code != '12' &&
                i?.code != '02' &&
                i?.code != '03',
            )
            ?.map((i, index) => {
              // 返回得值对应PRE_EXAM_SCORE_TYPE码表的某项
              // 父子关系
              const __arr = (vsf.stores.dbenums.enums.PRE_EXAM_ITEM_DICT ?? [])
                ?.sort((a, b) => {
                  return a?.order - b?.order;
                })
                ?.filter((item) => {
                  return (
                    item?.extensionJson &&
                    JSON.parse(item?.extensionJson)?.preExamScoreTypeCode ==
                      i.code
                  );
                });

              return {
                // 显示得label/code
                label: i?.label,
                labelCode: i?.code,
                // 下拉选择得是和输入得值，（服务端返回得）
                item: null,
                itemLabel: null,
                itemValue: null,
                itemSecond: null,
                itemValueSecond: null,
                // 下拉数据
                children: __arr,
              };
            })
            ?.map((i) => {
              // 不为血压
              const _arr = list
                ?.filter(
                  (listItem) =>
                    listItem != '10' &&
                    listItem != '11' &&
                    listItem != '12' &&
                    listItem != '13' &&
                    listItem != '26',
                )
                ?.filter((listItem) => {
                  if (listItem?.item == i?.labelCode) {
                    return true;
                  } else {
                    const childrenItemList = (i?.children ?? [])?.filter(
                      (childrenItem) => childrenItem?.code == listItem?.item,
                    );
                    return childrenItemList?.length > 0;
                  }
                });
              // 血压
              const bloodPressureList = (list ?? [])?.filter(
                (listItem) =>
                  listItem?.item == '10' ||
                  listItem?.item == '11' ||
                  listItem?.item == '12' ||
                  listItem?.item == '13' ||
                  listItem?.item == '26',
              );

              if (i?.labelCode == '19') {
                if (bloodPressureList?.length == 0) {
                  return {
                    ...i,
                    itemLabel: '',
                  };
                } else if (bloodPressureList?.length == 1) {
                  return {
                    ...i,
                    itemLabel: '拒测',
                    item: '26',
                  };
                } else if (bloodPressureList?.length > 1) {
                  // 上62 10 11 下63 12 13

                  return {
                    ...i,
                    item: bloodPressureList?.find((i) => i?.item == '10')
                      ? '62'
                      : '63',
                    itemLabel: bloodPressureList?.find((i) => i?.item == '10')
                      ? '上肢'
                      : '下肢',
                    // itemValue:
                    //   bloodPressureList?.find((i) => i?.item == '10')
                    //     ?.itemValue ||
                    //   bloodPressureList?.find((i) => i?.item == '12')?.itemValue
                    //     ? 1
                    //     : null,
                    itemValue: {
                      value1:
                        bloodPressureList?.find((i) => i?.item == '10')?.item ??
                        bloodPressureList?.find((i) => i?.item == '12')?.item,
                      value2:
                        bloodPressureList?.find((i) => i?.item == '11')
                          ?.itemValue ??
                        bloodPressureList?.find((i) => i?.item == '13')
                          ?.itemValue,
                    },
                    // itemSecond:
                    //   bloodPressureList?.find((i) => i?.item == '10')?.item ??
                    //   bloodPressureList?.find((i) => i?.item == '12')?.item,
                    // itemValueSecond:
                    //   bloodPressureList?.find((i) => i?.item == '10')
                    //     ?.itemValue ??
                    //   bloodPressureList?.find((i) => i?.item == '12')
                    //     ?.itemValue,
                    // itemThird:
                    //   bloodPressureList?.find((i) => i?.item == '11')?.item ??
                    //   bloodPressureList?.find((i) => i?.item == '13')?.item,
                    // itemValueThird:
                    //   bloodPressureList?.find((i) => i?.item == '11')
                    //     ?.itemValue ??
                    //   bloodPressureList?.find((i) => i?.item == '13')
                    //     ?.itemValue,
                  };
                }
              } else {
                return {
                  ...i,
                  item: _arr?.[0]?.item,
                  itemLabel: i?.children?.find(
                    (i) => i?.code == _arr?.[0]?.item,
                  )?.label,
                  itemValue: _arr?.[0]?.itemValue,
                };
              }
            })
            ?.map((i) => {
              return {
                ...i,
                item:
                  i?.children?.length == 1
                    ? i?.children?.[0]?.code
                    : i?.children?.length == 0
                    ? i?.labelCode
                    : i?.item,
                itemLabel:
                  i?.children?.length == 1
                    ? i?.children?.[0]?.label
                    : i?.children?.length == 0
                    ? i?.label
                    : i?.itemLabel,
              };
            });

          // table
          const res2 = handelData(
            output?.gender == '1' ? res1?.filter((i) => i?.item != '16') : res1,
          );

          // 弹窗
          const modalList = vsf.stores.dbenums.enums.PRE_EXAM_ITEM_DICT?.sort(
            (a, b) => {
              return a?.order - b?.order;
            },
          )
            ?.filter((i) => {
              const iCode =
                i?.extensionJson &&
                JSON.parse(i?.extensionJson)?.preExamScoreTypeCode;
              return (
                iCode == '01' ||
                iCode == '11' ||
                iCode == '12' ||
                iCode == '02' ||
                iCode == '03'
              );
            })
            ?.map((obj) => {
              const index = list?.find((item) => item.item === obj.code);
              // console.log(index);
              if (index) {
                return {
                  ...obj,
                  ...index,
                };
              } else {
                return {
                  ...obj,
                };
              }
            })
            .filter((i) => i?.item);
          // 主诉list
          // console.log(res2, 'res2res2res21');

          const _patientChiefComplaintVsJudgeDictionaryDtoList = (
            output?.patientChiefComplaintVsJudgeDictionaryDtoList ?? []
          )?.map((i) => {
            return {
              ...i,
              secondId: i?.chiefComplaint?.id,
              secondName: i?.chiefComplaint?.chiefComplaintName,
              id: i?.chiefComplaintJudge?.id,
              chiefComplaintJudgeName:
                i?.chiefComplaintJudge?.chiefComplaintJudgeName,
              chiefComplaintVsJudgeId: i?.chiefComplaintVsJudgeId,
            };
          });
          const _obj = {
            ...output,
            arriveHospitalDateTime: output?.arriveHospitalDateTime
              ? output?.arriveHospitalDateTime
              : +new Date(),
            arriveHospitalType: output?.arriveHospitalType
              ? output?.arriveHospitalType
              : vsf.stores.dbenums.enums.ARRI_PATTERN_DICT?.[0],
            ohcaIndicator: output?.ohcaIndicator
              ? output?.ohcaIndicator
              : false,
            notPlanReturnRescueIndicator: output?.notPlanReturnRescueIndicator
              ? output?.notPlanReturnRescueIndicator
              : false,
            vitalsignStatus: output?.vitalsignStatus
              ? output?.vitalsignStatus
              : 'HAVE_VITALSIGN',
            preExamPatientType: output?.preExamPatientType
              ? output?.preExamPatientType
              : output?.birthday && dayjs().diff(output?.birthday, 'year') <= 6
              ? 'BABY'
              : 'GENERAL',
            nrsScore: output?.nrsScore ? output?.nrsScore : 0,
            mewsScore: _filter,
            gcsScore: output?.gcsScore
              ? output?.gcsScore
              : output?.vitalsignStatus == 'WITHOUT_VITALSIGN'
              ? 3
              : 15,
            diseaseLevel: {
              ...output?.diseaseLevel,
              treatArea: levelList?.find(
                (i) => i?.id == output?.referenceDiseaseLevelId?.value,
              )?.treatArea,
            },

            cramsScore: output?.cramsScore ? Number(output?.cramsScore) : '',
            patientChiefComplaintVsJudgeDictionaryDtoList:
              _patientChiefComplaintVsJudgeDictionaryDtoList,
            patientChiefComplaintVsJudgeDictionary:
              output?.patientChiefComplaintVsJudgeDictionaryDtoList
                ?.map(
                  (item) =>
                    (item.chiefComplaint?.chiefComplaintName ?? '*') +
                    ',' +
                    item?.chiefComplaintJudge?.chiefComplaintJudgeName,
                )
                .join(';'),
            erpPreExamRecordDetailWithVitalsignDictionaryList: modalList,
            tableList: res2,
            modalList: modalList?.map((i) => {
              if (i?.item == 19) {
                return {
                  ...i,
                  itemValue: i?.itemValue
                    ? i?.itemValue
                    : output?.vitalsignStatus == 'WITHOUT_VITALSIGN'
                    ? 1
                    : 4,
                };
              } else if (i?.item == 20) {
                return {
                  ...i,
                  itemValue: i?.itemValue
                    ? i?.itemValue
                    : output?.vitalsignStatus == 'WITHOUT_VITALSIGN'
                    ? 1
                    : 5,
                };
              } else if (i?.item == 21) {
                return {
                  ...i,
                  itemValue: i?.itemValue
                    ? i?.itemValue
                    : output?.vitalsignStatus == 'WITHOUT_VITALSIGN'
                    ? 1
                    : 6,
                };
              } else {
                return i;
              }
            }),
          };
          // 判断实际分级原因是否必填
          handelIsRequiredDiseaseLevel(_obj);
          // 病人去向原因是否必填
          handeldIseaseLevelChangeReason(_obj);

          setDetail(_obj);
          setReferenceDiseaseLevelIdColor(
            levelList?.find(
              (i) => i?.id == _obj?.referenceDiseaseLevelId?.value,
            )?.colorCode,
          );
          // 设置分级颜色
          setDiseaseLevelColor(
            levelList?.find((i) => i?.id == _obj?.diseaseLevel?.value)
              ?.colorCode,
          );
          vsf.refs?.preExamMainForm?.setFieldsValue(_obj);
        } else {
          secondTriageRef?.current?.handelOpen(true, currentRowId?.id);
        }
      } else {
        setDetail(null);
        vsf.refs?.preExamMainForm?.setFieldsValue(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentRowId?.spacicalId, currentRowId?.id, currentRowId?.init, init],
  );
  // 参考/实际分级（初始获取）
  const getLevel1 = async () => {
    const res =
      await vsf?.services?.DiseaseLevelDictionaryController_getAllByDiseaseLevelDictionaryQto_39584f?.(
        { qto: { from: 0, size: 20 } },
      );
    setLevelList(res?.data);
  };

  const handelColor = (v) => {
    setDiseaseLevelColor(v);
    setReferenceDiseaseLevelIdColor(v);
  };

  useEffect(() => {
    requiredRef.current = isRequired;
    // console.log(requiredRef.current, 'requiredRef.current ');
  }, [isRequired]);

  useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDetail, init]);

  const getLevel = async (params) => {
    const res =
      await vsf?.services?.DiseaseLevelDictionaryController_getAllByDiseaseLevelDictionaryQto_39584f?.(
        { qto: { from: 0, size: 20 } },
      );
    // console.log(res, 'res');
    const _res = res?.data?.map((i) => {
      return {
        ...i,
        label: i?.diseaseLevelName,
        value: i?.diseaseLevelCode,
      };
    });
    const output = params?.label
      ? _res?.filter((item) =>
          askSearch(params?.label, item, 'diseaseLevelName'),
        )
      : _res;
    return output;
  };
  // 刷新
  const handelClear = () => {
    vsf.refs.patientSearch.resetFields();
    clearScreen();
  };

  // 判断实际病情分级是否必填
  const handelIsRequiredDiseaseLevel = (formValue?) => {
    const _fromValue = formValue
      ? formValue
      : vsf.refs.preExamMainForm.getFieldsValue();
    const _diseaseLevel = _fromValue?.diseaseLevel;
    const _referenceDiseaseLevelId = _fromValue?.referenceDiseaseLevelId;
    if (
      (_referenceDiseaseLevelId?.value ?? _referenceDiseaseLevelId) ==
      (_diseaseLevel?.value ?? _diseaseLevel?.value)
    ) {
      setIsRequiredDiseaseLevel(false);
    } else {
      setIsRequiredDiseaseLevel(true);
    }
  };
  // 判断病人去向是否必填
  const handeldIseaseLevelChangeReason = (formValue?) => {
    const _fromValue = formValue
      ? formValue
      : vsf.refs.preExamMainForm.getFieldsValue();
    const _diseaseLevel = _fromValue?.diseaseLevel;
    const _treatAreaCode = _fromValue?.treatAreaCode;
    // console.log(_treatAreaCode, _diseaseLevel?.treatArea, 'ppooo');

    if (
      (_treatAreaCode?.value ?? _treatAreaCode) ==
      (_diseaseLevel?.treatArea ?? _diseaseLevel)
    ) {
      setIsRequiredTreatAreaCode(false);
    } else {
      setIsRequiredTreatAreaCode(true);
    }
  };
  // 主诉变化
  const handelActionChief = (params) => {
    console.log(params, '7375_params');
    handeldIseaseLevelChangeReason(params);
    handeldIseaseLevelChangeReason(params);
  };

  const readonly = true;

  // console.log(type, 'type');
  // console.log(isChildren, 'isChildren');

  return (
    <div className="detail_content">
      <div className="detail_content_body hideScroll">
        {!loading ? (
          currentRowId?.id && showDetail ? (
            <VSForm
              labelAlign="left"
              id="preExamMainForm"
              labelWrap
              onChange={(v) => {
                // console.log(v, '哈哈哈哈哈');
              }}
            >
              <VSFormItem
                name={['erpPreExamRecordDetailWithVitalsignDictionaryList']}
                label="erpPreExamRecordDetailWithVitalsignDictionaryList"
                valueType="text"
                hidden
              />

              <VSFormItem
                name={['patientId']}
                label="患者id"
                valueType="text"
                hidden
              />
              <VSFormItem name={['id']} label="id" valueType="text" hidden />
              <VSFormItem
                name={['patientChiefComplaintVsJudgeDictionaryDtoList']}
                label="主诉及判定依据"
                valueType="select"
                // rules={[
                //   {
                //     message: '参考病情分级的值不合法',
                //     type: 'number',
                //     min: 0,
                //     max: undefined,
                //   },
                // ]}
                fieldProps={{
                  onChange: (v) => {
                    // console.log(v, 'vv1v');
                  },
                }}
                hidden
              />
              <FormTitle
                className="icon16"
                style={{ padding: 0, background: 'transparent' }}
                bodyStytle={{ padding: '10px 0' }}
                title="基本信息"
                isNeedTag={true}
              >
                <VSFormLayout
                  key="1"
                  columnCount={3}
                  // labelCol={labelColWidth[6]}
                  labelWidth={_labelWidth}
                  gutter={50}
                >
                  <VSFormItem
                    name={['name']}
                    label="姓名"
                    valueType="text"
                    readonly={readonly}
                    rules={[
                      {
                        message: '姓名长度不合法',
                        type: 'string',
                        min: 0,
                        max: 60,
                      },
                    ]}
                    fieldProps={{}}
                  />

                  <VSFormItem
                    name={['gender']}
                    label="性别"
                    valueType="select"
                    dataSource={vsf.stores.dbenums.enums.SEX_DICT}
                    fieldProps={{}}
                    readonly={readonly}
                  />

                  <VSFormItem
                    name={['age']}
                    label="年龄"
                    valueType="text"
                    fieldProps={{}}
                    readonly={readonly}
                  />

                  <VSFormItem
                    name={['defaultChargeType']}
                    label="费别"
                    valueType="select"
                    dataSource={vsf.stores.dbenums.enums.CHARGE_TYPE_DICT}
                    fieldProps={{}}
                    readonly={readonly}
                  />

                  <VSFormItem
                    name={['cellphone']}
                    label="电话"
                    valueType="text"
                    // rules={[
                    //   {
                    //     message: '数据格式错误',
                    //     type: 'string',
                    //     pattern: /^(?:\+?86)?1[3-9]\d{9}$/,
                    //   },
                    // ]}
                    fieldProps={{}}
                    readonly={readonly}
                  />

                  <VSFormItem
                    name={['currentAddress']}
                    label="住址"
                    valueType="address"
                    fieldProps={{ showDetail: true }}
                    readonly={readonly}
                  />

                  <VSFormItem
                    name={['idNumber']}
                    label="身份证号"
                    valueType="text"
                    rules={[
                      {
                        message: '数据格式错误',
                        type: 'string',
                        pattern:
                          /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                      },
                    ]}
                    fieldProps={{}}
                    readonly={readonly}
                  />

                  <VSFormItem
                    name={['workUnit', 'address']}
                    valueType="address"
                    label="单位地址"
                    fieldProps={{
                      showDetail: true,
                    }}
                    readonly={readonly}
                  />
                </VSFormLayout>
                <VSFormLayout
                  key="2"
                  columnCount={2}
                  columnSpan={[16, 8]}
                  // labelCol={labelColWidth[6]}
                  labelWidth={_labelWidth}
                  gutter={50}
                >
                  <Space.Compact style={{ width: '100%' }}>
                    <VSFormItem
                      name={['arriveHospitalType']}
                      label="到院方式"
                      fieldNames={{ label: 'label', value: 'value' }}
                      labelCol={
                        isMinScreen ? labelColWidth[15] : labelColWidth[7.5]
                      }
                      valueType="codeTableSelect"
                      style={{
                        width: isMinScreen ? 366 : 314,
                      }}
                      required
                      initialValue={
                        vsf.stores.dbenums.enums.ARRI_PATTERN_DICT?.[0]
                      }
                      fieldProps={{
                        showSearch: true,
                        // onChange: (v) => {
                        //   console.log(v, 'vvvvvvvbtreatArea');
                        //   vsf.refs.preExamMainForm.setFieldsValue({
                        //     treatAreaCode: v?.treatArea,
                        //   });
                        //   handelIsRequiredDiseaseLevel();
                        //   handeldIseaseLevelChangeReason();
                        //   setDiseaseLevelColor(v?.colorCode);
                        // },
                      }}
                      dataSource={vsf.stores.dbenums.enums.ARRI_PATTERN_DICT}
                      rules={[{ required: true }]}
                    />
                    <VSFormItem
                      name={['arriveHospitalPatternMemo']}
                      label=""
                      style={{
                        width: 430,
                      }}
                      valueType="text"
                    />
                  </Space.Compact>
                  {/* <VSFormItem
                    name={['arriveHospital']}
                    label="到院方式"
                    valueType="selectAndText"
                    // dataSource={vsf.stores.dbenums.enums.ARRI_PATTERN_DICT}
                    // requestParamsFieldNames={{
                    //   selectName: 'arriveHospitalType',
                    //   inputName: 'arriveHospitalPatternMemo',
                    // }}
                    rules={[{ required: true }]}
                    fieldProps={{
                      selectProps: {
                        required: true,
                        allowClear: true,
                        dataSource: vsf.stores.dbenums.enums.ARRI_PATTERN_DICT,
                        defaultValue:
                          vsf.stores.dbenums.enums.ARRI_PATTERN_DICT?.[0],
                        className: 'widthAutoFill',
                      },
                      inputProps: {},
                      requestParamsFieldNames: {
                        selectName: 'arriveHospitalType',
                        inputName: 'arriveHospitalPatternMemo',
                      },
                    }}
                  /> */}

                  <VSFormItem
                    name={['ambulanceTypeCode']}
                    label="120类型"
                    valueType="codeTableSelect"
                    dataSource={vsf.stores.dbenums.enums.DRIVE_TYPE_DICT}
                    fieldProps={{
                      allowClear: true,
                      showSearch: true,
                      className: 'widthAutoFill',
                    }}
                  />
                </VSFormLayout>
                <VSFormLayout
                  key="3"
                  columnCount={3}
                  // labelCol={labelColWidth[6]}
                  labelWidth={_labelWidth}
                  gutter={50}
                >
                  <VSFormItem
                    name={['arriveHospitalDateTime']}
                    label="到院时间"
                    valueType="date"
                    fieldProps={{
                      showTime: true,
                      width: '100%',
                      className: 'widthAutoFill',
                      disabledDate: (current) => {
                        return current && current > dayjs().endOf('day');
                      },
                    }}
                    initialValue={+new Date()}
                    rules={[{ required: true }]}
                    // labelWidth={_labelWidth}
                  />
                  {/* 院外心博停止 */}
                  <VSFormItem
                    name={['ohcaIndicator']}
                    label="OHCA"
                    valueType="radioButton"
                    initialValue={false}
                    // fieldProps={{ value: false }}
                    rules={[{ required: true }]}
                    dataSource={[
                      { label: '是', value: true },
                      { label: '否', value: false },
                    ]}
                  />

                  <VSFormItem
                    name={['notPlanReturnRescueIndicator']}
                    label="非计划重返抢救室"
                    valueType="select"
                    dataSource={[
                      { label: '是', value: true },
                      { label: '否', value: false },
                    ]}
                    initialValue={false}
                    fieldProps={{
                      className: 'widthAutoFill',
                    }}
                    rules={[{ required: true }]}
                  />

                  <VSFormItem
                    name={['vitalsignStatus']}
                    label="体征状态"
                    valueType="radioButton"
                    dataSource={[
                      { label: '有生命迹象', value: 'HAVE_VITALSIGN' },
                      { label: '无生命迹象', value: 'WITHOUT_VITALSIGN' },
                      { label: '拒绝TPRBP', value: 'NOT_TPRBP' },
                    ]}
                    initialValue="HAVE_VITALSIGN"
                    fieldProps={{
                      onChange: (v) => {
                        const res = vsf.refs?.preExamMainForm?.getFieldsValue();
                        console.log(v, '7384_v');
                        if (v == 'WITHOUT_VITALSIGN' || v == 'NOT_TPRBP') {
                          vsf.refs?.preExamMainForm?.setFieldsValue({
                            treatArea: {
                              treatAreaCode: '01',
                            },
                            // gcsScore: 3,
                          });
                          setBodyTemperatureRequiredRef(false);
                          setIsRequired(false);
                        } else {
                          setBodyTemperatureRequiredRef(true);
                          if (res?.preExamPatientType == 'BABY') {
                            setIsRequired(false);
                          } else {
                            setIsRequired(true);
                          }
                        }
                      },
                    }}
                    rules={[{ required: true }]}
                  />

                  <VSFormItem
                    name={['preExamPatientType']}
                    label="患者类别"
                    valueType="radioButton"
                    dataSource={[
                      { label: '普通患者', value: 'GENERAL' },
                      { label: '小儿患者', value: 'BABY' },
                      { label: '产科患者', value: 'CHILDBIRTH' },
                    ]}
                    initialValue="GENERAL"
                    fieldProps={{
                      onChange: (v) => {
                        const res = vsf.refs?.preExamMainForm?.getFieldsValue();
                        if (v == 'BABY') {
                          // setIsChildren(true);
                          isChildren.current = true;
                          setIsRequired(false);
                        } else {
                          if (
                            res?.vitalsignStatus == 'WITHOUT_VITALSIGN' ||
                            res?.vitalsignStatus == 'NOT_TPRBP'
                          ) {
                            setIsRequired(false);
                          } else {
                            setIsRequired(true);
                          }

                          // setIsChildren(false);
                          isChildren.current = false;
                        }
                      },
                    }}
                    rules={[{ required: true }]}
                  />

                  <VSFormItem
                    name={['patientType']}
                    label="患者类型"
                    valueType="codeTableSelect"
                    dataSource={
                      vsf.stores.dbenums.enums.EMERGENCY_VISIT_PATIENT_TYPE
                    }
                    fieldProps={{
                      className: 'widthAutoFill',
                      showSearch: true,
                      allowClear: true,
                    }}
                  />

                  <VSFormItem
                    name={['payAfterClinicReason']}
                    label="医后付理由"
                    valueType="custom"
                    dataSource={
                      vsf.stores.dbenums?.enums?.ERP_PAY_AFTER_VISIT_REASON
                    }
                    // style={{ width: 320 }}
                    // fieldProps={{
                    //   width: labelWidth,
                    //   showSearch: true,
                    //   allowClear: true,
                    //   selectProps: {
                    //     allowClear: true,
                    //   },
                    //   addItemOptions: {
                    //     status: true,
                    //     mode: 'single',
                    //   },
                    // }}
                    fieldNames={{
                      label: 'label',
                      value: 'value',
                    }}
                    customComponent={(v: any) => {
                      return (
                        <AspirinSelect
                          dataSource={vsf.stores.dbenums?.enums?.ERP_PAY_AFTER_VISIT_REASON?.map(
                            (i) => {
                              return { ...i, value: i?.label };
                            },
                          )}
                          value={v?.value}
                          onChange={(value) => {
                            v?.onChange(value);
                          }}
                          autoCompleteProps={{
                            showSearch: true,
                            placeholder: '请选择或输入医后付理由',
                          }}
                          addItemOptions={{
                            status: true,
                            mode: 'single',
                          }}
                          selectProps={{
                            fieldNames: {
                              label: 'label',
                              value: 'value',
                            },
                            showSearch: true,
                            allowClear: true,
                          }}
                        />
                      );
                    }}
                  />

                  <VSFormItem
                    name={['epidemiologyHistory']}
                    label="流行病学史"
                    valueType="codeTableSelect"
                    dataSource={
                      vsf.stores.dbenums.enums.EPIDEMIOLOGY_HISTORY_DICT
                    }
                    fieldProps={{
                      className: 'widthAutoFill',
                      allowClear: true,
                      showSearch: true,
                    }}
                  />

                  <VSFormItem
                    name={['epidemiologyHistoryMemo']}
                    label="流行病学史备注"
                    valueType="text"
                    rules={[
                      {
                        message: '数据格式错误',
                        type: 'string',
                        min: 0,
                        max: 100,
                      },
                    ]}
                    fieldProps={{ className: 'widthAutoFill' }}
                  />
                </VSFormLayout>
              </FormTitle>
              <FormTitle
                className="icon16"
                style={{ padding: 0, background: 'transparent' }}
                bodyStytle={{ padding: '0 0 10px', overflowX: 'hidden' }}
                title="患者评估"
                isNeedTag={true}
                extra={
                  <div>
                    <Button size="small" type="primary">
                      体征采集
                    </Button>
                    <Button
                      size="small"
                      type="primary"
                      style={{ marginLeft: 20 }}
                      onClick={() => {
                        previousRef.current?.handelOpen(
                          true,
                          vsf.refs?.preExamMainForm.getFieldsValue(),
                        );
                      }}
                    >
                      既往史
                    </Button>
                  </div>
                }
              >
                {/* <VSFormItem
                  valueType="custom"
                  name={['tableList']}
                  fieldProps={{
                    onChange: (v) => {
                      console.log(v, 'change');
                    },
                  }}
                  customComponent={(_props) => {
                    return (
                      <PatientAssessment
                        fallRiskRef={fallRiskRef}
                        {..._props}
                      />
                    );
                  }}
                ></VSFormItem> */}
                <PatientAssessment
                  fallRiskRef={fallRiskRef}
                  list={detail?.tableList}
                  isRequired={isRequired}
                  bodyTemperatureRequired={bodyTemperatureRequiredRef}
                  handelColor={handelColor}
                />

                <div className="patient_score">
                  <VSFormLayout
                    columnCount={4}
                    gutter={isMinScreen ? 40 : 100}
                    labelCol={labelColWidth[4]}
                  >
                    {/* 格拉斯哥评分得分 */}
                    <VSFormItem
                      label="GCS"
                      name={['gcsScore']}
                      valueType="select"
                      fieldProps={{
                        open: false,
                        suffixIcon: null,
                        allowClear: false,
                        onClick: () => {
                          gcsRef.current?.handelOpen(
                            true,
                            vsf.refs?.preExamMainForm.getFieldsValue(),
                          );
                        },
                      }}
                      dataSource={[
                        { label: '3分(重度)', value: 3 },
                        { label: '4分(重度)', value: 4 },
                        { label: '5分(重度)', value: 5 },
                        { label: '6分(重度)', value: 6 },
                        { label: '7分(重度)', value: 7 },
                        { label: '8分(重度)', value: 8 },
                        { label: '9分(中度)', value: 9 },
                        { label: '10分(中度)', value: 10 },
                        { label: '11分(中度)', value: 11 },
                        { label: '12分(中度)', value: 12 },
                        { label: '13分(轻度)', value: 13 },
                        { label: '14分(轻度)', value: 14 },
                        { label: '15分(轻度)', value: 15 },
                      ]}
                      rules={[
                        {
                          required: isRequired,
                          message: '',
                        },
                      ]}
                    />
                    {/* 创伤评分得分 */}
                    <VSFormItem
                      label="CRAMS"
                      name={['cramsScore']}
                      valueType="select"
                      // rules={[
                      //   {
                      //     message: '数据格式错误',
                      //     type: 'string',
                      //     min: 0,
                      //     max: 2,
                      //   },
                      // ]}
                      dataSource={[
                        { label: '0分(极重伤)', value: 0 },
                        { label: '1分(极重伤)', value: 1 },
                        { label: '2分(极重伤)', value: 2 },
                        { label: '3分(极重伤)', value: 3 },
                        { label: '4分(极重伤)', value: 4 },
                        { label: '5分(极重伤)', value: 5 },
                        { label: '6分(极重伤)', value: 6 },
                        { label: '7分(重伤)', value: 7 },
                        { label: '8分(重伤)', value: 8 },
                        { label: '9分(轻伤)', value: 9 },
                        { label: '10分(轻伤)', value: 10 },
                      ]}
                      fieldProps={{
                        open: false,
                        suffixIcon: null,
                        allowClear: false,
                        onClick: () => {
                          cramsRef.current?.handelOpen(
                            true,
                            vsf.refs?.preExamMainForm.getFieldsValue(),
                          );
                        },
                      }}
                    />
                    {/* 患者疼痛评分得分 */}
                    <VSFormItem
                      label="NRS"
                      name={['nrsScore']}
                      valueType="select"
                      fieldProps={{
                        open: false,
                        suffixIcon: null,
                        allowClear: false,
                        onClick: () => {
                          nrsRef.current?.handelOpen(
                            true,
                            vsf.refs?.preExamMainForm.getFieldsValue(),
                          );
                        },
                      }}
                      initialValue={0}
                      dataSource={[
                        { label: '0分（无痛）', value: 0 },
                        { label: '1分（轻度疼痛）', value: 1 },
                        { label: '2分（轻度疼痛）', value: 2 },
                        { label: '3分（轻度疼痛）', value: 3 },
                        { label: '4分（中度疼痛）', value: 4 },
                        { label: '5分（中度疼痛）', value: 5 },
                        { label: '6分（中度疼痛）', value: 6 },
                        { label: '7分（重度疼痛）', value: 7 },
                        { label: '8分（重度疼痛）', value: 8 },
                        { label: '9分（重度疼痛）', value: 9 },
                        { label: '10分（重度疼痛）', value: 10 },
                      ]}
                      rules={[{ required: isRequired, message: '' }]}
                    />
                    {/* 患者类别选择小儿患者时不显示MEWS 少字段*/}

                    {/* 患者类别选择小儿患者时显示 少字段 */}

                    {isChildren.current ? (
                      <VSFormItem
                        name={['pewsScore']}
                        label={`PEWS`}
                        valueType="text"
                        fieldProps={{
                          onClick: (e) => {
                            pewsRef.current?.handelOpen(
                              true,
                              vsf.refs?.preExamMainForm.getFieldsValue(),
                            );
                          },
                          addonAfter: '分',
                          onChange: (e) => {
                            e.preventDefault();
                          },
                          onKeyDown: (e) => {
                            e.preventDefault();
                          },
                        }}
                      />
                    ) : (
                      <VSFormItem
                        name={['mewsScore']}
                        label={`MEWS`}
                        valueType="text"
                        fieldProps={{
                          disabled: true,
                          addonAfter: '分',
                        }}
                      />
                    )}
                  </VSFormLayout>
                </div>
              </FormTitle>
              {type != 'modal' && (
                <FormTitle
                  className="icon16"
                  style={{ padding: 0, background: 'transparent' }}
                  bodyStytle={{ padding: '10px 0' }}
                  title="患者分级安置"
                  isNeedTag={true}
                >
                  <VSFormLayout
                    key="3"
                    columnCount={1}
                    // labelCol={labelColWidth[6]}
                    // labelWidth={isMinScreen ? 126 : 148}
                    gutter={100}
                    labelWidth={labelNumberWidth(9)}
                    // gutter={50}
                  >
                    <VSFormItem
                      name={['patientChiefComplaintVsJudgeDictionary']}
                      label="主诉及判定依据"
                      valueType="text"
                      required={true}
                      // rules={[
                      //   {
                      //     message: '参考病情分级的值不合法',
                      //     type: 'number',
                      //     min: 0,
                      //     max: undefined,
                      //   },
                      // ]}

                      fieldProps={{
                        allowClear: false,
                        onClick: () => {
                          actionCiefRef.current?.handelOpen(
                            true,
                            vsf.refs?.preExamMainForm.getFieldsValue(),
                          );
                        },
                        onChange: (e) => {
                          e.preventDefault();
                        },
                        onKeyDown: (e) => {
                          e.preventDefault();
                        },
                      }}
                    />
                    <VSFormItem
                      name={['referenceDiseaseLevelId']}
                      className="disabled_select_color"
                      label="参考病情分级"
                      valueType="select"
                      hidden
                      fieldNames={{ label: 'label', value: 'value' }}
                      style={{ color: referenceDiseaseLevelIdColor }}
                      fieldProps={{
                        style: {
                          width: 200,
                          color: referenceDiseaseLevelIdColor,
                        },

                        showSearch: true,
                        disabled: true,
                      }}
                      dataSource={async (param) => {
                        const res = getLevel(param);
                        return res;
                      }}
                      required={true}
                    />
                    <VSFormItem
                      name={['referenceDiseaseLevelIds']}
                      className="disabled_select_color"
                      label="参考病情分级"
                      valueType="text"
                      fieldNames={{ label: 'label', value: 'value' }}
                      style={{ color: referenceDiseaseLevelIdColor }}
                      fieldProps={{
                        style: {
                          width: 200,
                          color: referenceDiseaseLevelIdColor,
                        },

                        disabled: true,
                      }}
                      readonly
                      dataSource={async (param) => {
                        const res = getLevel(param);
                        return res;
                      }}
                      required={true}
                    />

                    <VSFormLayout columnCount={1} labelWidth={120}>
                      <Space.Compact style={{ width: '100%' }}>
                        <VSFormItem
                          name={['diseaseLevel']}
                          label="实际病情分级"
                          fieldNames={{ label: 'label', value: 'value' }}
                          labelCol={
                            isMinScreen ? labelColWidth[19] : labelColWidth[8]
                          }
                          valueType="select"
                          style={{
                            width: 350,
                          }}
                          fieldProps={{
                            showSearch: true,
                            style: {
                              color: diseaseLevelColor,
                            },
                            onChange: (v) => {
                              // console.log(v, 'vvvvvvvbtreatArea');
                              console.log(v, '7375_v');
                              vsf.refs.preExamMainForm.setFieldsValue({
                                treatAreaCode: v?.treatArea,
                              });
                              handelIsRequiredDiseaseLevel();
                              handeldIseaseLevelChangeReason();
                              setDiseaseLevelColor(v?.colorCode);
                            },
                          }}
                          dataSource={async (param) => {
                            const res = getLevel(param);
                            return res;
                          }}
                          rules={[{ required: true }]}
                        />
                        <VSFormItem
                          name={['diseaseLevelChangeReason']}
                          label=""
                          valueType="custom"
                          dataSource={
                            vsf.stores.dbenums?.enums?.PRE_LEVEL_CHANGE_DICT
                          }
                          style={{ width: 470 }}
                          // required={isRequiredDiseaseLevel}
                          rules={[
                            {
                              required: isRequiredDiseaseLevel,
                              message: '请选择或输入病情分级变更原因',
                            },
                          ]}
                          customComponent={(v: any) => {
                            return (
                              <AspirinSelect
                                dataSource={vsf.stores.dbenums?.enums?.PRE_LEVEL_CHANGE_DICT?.map(
                                  (i) => {
                                    return { ...i, value: i?.label };
                                  },
                                )}
                                value={v?.value}
                                onChange={(value) => {
                                  v?.onChange(value);
                                }}
                                autoCompleteProps={{
                                  showSearch: true,
                                  placeholder: '请选择或输入病情分级变更原因',
                                }}
                                addItemOptions={{
                                  status: true,
                                  mode: 'single',
                                }}
                                selectProps={{
                                  fieldNames: {
                                    label: 'label',
                                    value: 'value',
                                  },
                                  showSearch: true,
                                  allowClear: true,
                                }}
                              />
                            );
                          }}
                        />
                      </Space.Compact>
                    </VSFormLayout>
                    {/* <VSFormLayout columnCount={1} labelWidth={120}> */}
                    <VSFormLayout
                      columnCount={1}
                      labelWidth={labelNumberWidth(9)}
                    >
                      <Space.Compact style={{ width: '100%' }}>
                        <VSFormItem
                          name={['treatAreaCode']}
                          label="病人去向"
                          fieldNames={{ label: 'label', value: 'value' }}
                          labelCol={
                            isMinScreen ? labelColWidth[19] : labelColWidth[8]
                          }
                          valueType="select"
                          style={{
                            width: 350,
                          }}
                          fieldProps={{
                            showSearch: true,

                            onChange: (v) => {
                              // console.log(v, 'vvvvvvvbtreatArea');
                              // 判断是否和实际分级的treatArea一致
                              // vsf.refs.preExamMainForm.setFieldsValue({
                              //   treatAreaCode: v?.treatArea,
                              // });
                              handeldIseaseLevelChangeReason();
                            },
                          }}
                          dataSource={vsf.stores.dbenums?.enums?.TREAT_AREA_DICT?.filter(
                            (i) => i?.code != '07',
                          )}
                          rules={[{ required: true }]}
                        />
                        <VSFormItem
                          name={['directionChangeReason']}
                          label=""
                          valueType="custom"
                          dataSource={
                            vsf.stores.dbenums?.enums?.PRE_LEVEL_CHANGE_DICT
                          }
                          style={{ width: 470 }}
                          // required={isRequiredDiseaseLevel}
                          rules={[
                            {
                              required: isRequiredTreatAreaCode,
                              message: '请选择或输入病人去向变更原因',
                            },
                          ]}
                          customComponent={(v: any) => {
                            return (
                              <AspirinSelect
                                dataSource={vsf.stores.dbenums?.enums?.PRE_LEVEL_CHANGE_DICT?.map(
                                  (i) => {
                                    return { ...i, value: i?.label };
                                  },
                                )}
                                value={v?.value}
                                onChange={(value) => {
                                  v?.onChange(value);
                                }}
                                autoCompleteProps={{
                                  showSearch: true,
                                  placeholder: '请选择或输入病情分级变更原因',
                                }}
                                addItemOptions={{
                                  status: true,
                                  mode: 'single',
                                }}
                                selectProps={{
                                  fieldNames: {
                                    label: 'label',
                                    value: 'value',
                                  },
                                  showSearch: true,
                                  allowClear: true,
                                }}
                              />
                            );
                          }}
                        />
                      </Space.Compact>
                    </VSFormLayout>
                    {/* <VSFormItem
                      name={['treatArea']}
                      label="病人去向"
                      valueType="selectAndText"
                      style={{
                        width: 800,
                      }}
                      fieldProps={{
                        isAspirinSelect: true,
                        requestParamsFieldNames: {
                          selectName: 'treatAreaCode',
                          inputName: 'directionChangeReason',
                        },
                        selectProps: {
                          width: 200,
                          dataSource:
                            vsf.stores.dbenums?.enums?.TREAT_AREA_DICT?.filter(
                              (i) => i?.code != '07',
                            ),
                        },
                        inputProps: {
                          dataSource:
                            vsf.stores.dbenums?.enums?.PRE_LEVEL_CHANGE_DICT?.map(
                              (i) => {
                                return {
                                  ...i,
                                  label: i?.label,
                                  value: i?.label,
                                };
                              },
                            ),
                          placeholder: '请选择或输入病人去向变更原因',
                        },
                      }}
                      rules={[{ required: true }]}
                    /> */}

                    <VSFormItem
                      name={['clinicRegisterDetailList']}
                      label=""
                      hidden
                      valueType="select"
                    />
                    <VSFormItem
                      name={['clinicDepartment']}
                      label="分科"
                      valueType="select"
                      fieldProps={{
                        showSearch: true,
                        disabled: !detail?.id ? false : true,
                        mode: !detail?.id ? 'multiple' : undefined,
                        onChange: (v) => {
                          const _clinicRegisterDetailList = !detail?.id
                            ? v?.map((i) => i?.id)
                            : [v?.id];
                          vsf?.refs?.preExamMainForm.setFieldsValue({
                            clinicRegisterDetailList: _clinicRegisterDetailList,
                          });
                        },
                      }}
                      fieldNames={{
                        label: 'label',
                        value: 'value',
                      }}
                      dataSource={async (param) => {
                        const res =
                          await vsf?.services?.OutpRegisterController_getTodayErpClinicRegister_c4b2c6?.(
                            {},
                          );
                        return res?.data?.map((i) => {
                          return {
                            ...i,
                            label:
                              i?.clinicRegister?.clinicRegisterDefine
                                .clinicLabel,
                            value: i?.clinicRegister?.id,
                          };
                        });
                        // return param?.label
                        //   ? res?.data
                        //       ?.map((i) => {
                        //         return {
                        //           ...i,
                        //           label:
                        //             i?.clinicRegister?.clinicRegisterDefine
                        //               .clinicLabel,
                        //           value: i?.clinicRegister?.id,
                        //           clinicLabelInputCode:
                        //             i?.clinicRegister?.clinicRegisterDefine
                        //               .inputCode,
                        //         };
                        //       })
                        //       ?.filter(
                        //         (item) =>
                        //           askSearch(param?.label, item, 'label') ||
                        //           askSearch(
                        //             param?.label,
                        //             item,
                        //             'clinicLabelInputCode',
                        //           ),
                        //       )
                        //   : res?.data?.map((i) => {
                        //       return {
                        //         ...i,
                        //         label:
                        //           i?.clinicRegister?.clinicRegisterDefine
                        //             .clinicLabel,
                        //         value: i?.clinicRegister?.id,
                        //       };
                        //     });
                      }}
                      rules={[{ required: true }]}
                    />
                  </VSFormLayout>
                </FormTitle>
              )}
            </VSForm>
          ) : (
            <Empty />
          )
        ) : (
          <Loading size="normal" />
        )}
        {/* 疼痛评估弹窗 */}
        <NRSModal ref={nrsRef} />
        {/* 格拉斯 */}
        <GCSModal ref={gcsRef} />
        {/* 创伤评分 */}
        <CRAMSModal ref={cramsRef} />
        {/* 儿童早期预警 */}
        <PEWSModal ref={pewsRef} />
        {/* 跌倒风险评估 */}
        <FallRiskModal ref={fallRiskRef} />
        {/* 主诉及判定依据 */}
        <ActionChief
          ref={actionCiefRef}
          onChange={handelActionChief}
          handelColor={handelColor}
        />
        {/* 既往史 */}
        <PreviousModal ref={previousRef} />
        {/* 二次分诊 secondTriageRef */}
        <SecondTirage ref={secondTriageRef} getDetail={getDetail} />
      </div>
      {type != 'modal' && (
        <div className="detail_content_bottom">
          <Button type="primary" className="detail_content_bottom_button">
            打印腕带
          </Button>
          <Button type="primary" className="detail_content_bottom_button">
            打印
          </Button>
          <Button
            type="primary"
            ghost
            className="detail_content_bottom_button"
            onClick={handelClear}
          >
            刷新
          </Button>
          <Button
            type="primary"
            ghost
            className="detail_content_bottom_button"
            onClick={async () => {
              const output =
                convertPreExamMainForm2SaveErpPreExamFuncFlowExecutorReq1?.({
                  id: vsf.refs?.preExamMainForm?.getFieldsValue()?.id,
                  patientId:
                    vsf.refs?.preExamMainForm?.getFieldsValue()?.patientId,
                });
              const res =
                await vsf?.services?.ClinicErpPreExamController_saveErpPreExamFuncFlowExecutor_a6c909?.(
                  {
                    ...output,
                  },
                );
              if (res?.code == 200) {
                message.success('取消成功');

                setDetail(null);
                // handelClear();
                clearScreen();
                setTimeout(() => {
                  handelReloadList?.();
                }, 500);
              }
            }}
          >
            取消分诊
          </Button>
          <Button
            type="primary"
            className="detail_content_bottom_button"
            onClick={async () => {
              // table校验
              const validate1 = vsf.refs?.patientAssessmentFirst
                ?.getEditForm?.()
                ?.validateFieldsReturnFormatValue?.();
              const validate2 = vsf.refs?.patientAssessmentSecond
                ?.getEditForm?.()
                ?.validateFieldsReturnFormatValue?.();
              const validate3 = vsf.refs?.patientAssessmentThird
                ?.getEditForm?.()
                ?.validateFieldsReturnFormatValue?.();
              // form校验
              const validate4 = vsf.refs.preExamMainForm.validateFields();
              // 获取form的值
              const res = vsf.refs?.preExamMainForm.getFieldsValue();

              // 评分弹窗的值
              const dictionaryModal =
                res?.erpPreExamRecordDetailWithVitalsignDictionaryList
                  ?.filter((element) => !Array.isArray(element))
                  .map((i) => {
                    return { item: i?.item, itemValue: i?.itemValue };
                  });
              // 体征table的值
              const dictionaryList1 =
                vsf.refs?.patientAssessmentFirst.getValues();
              const dictionaryList2 =
                vsf.refs?.patientAssessmentSecond.getValues();
              const dictionaryList3 =
                vsf.refs?.patientAssessmentThird.getValues();
              // 血压
              const blood: any = [
                ...dictionaryList1,
                ...dictionaryList2,
                ...dictionaryList3,
              ]?.filter((i) => i?.labelCode == '19');

              // table数据
              const dictionaryList: any = [
                ...dictionaryList1,
                ...dictionaryList2,
                ...dictionaryList3,
              ]
                ?.filter((i) => i?.labelCode != '19')
                ?.map((i) => {
                  return {
                    item:
                      i?.children?.length == 0
                        ? i?.labelCode
                        : i?.item?.code ?? i?.item?.value ?? i?.item,
                    itemValue: i?.itemValue,
                  };
                });
              // console.log(blood, 'bloodblood');

              blood?.[0]?.item?.code == '26' || blood?.[0]?.item == '26'
                ? dictionaryList.push({ item: '26' })
                : blood?.item?.code == '62' || blood?.item == '62'
                ? dictionaryList.push(
                    {
                      item: 10,
                      itemValue: blood?.[0]?.itemValue?.value1,
                    },
                    {
                      item: 11,
                      itemValue: blood?.[0]?.itemValue?.value2,
                    },
                  )
                : dictionaryList.push(
                    {
                      item: 12,
                      itemValue: blood?.[0]?.itemValue?.value1,
                    },
                    {
                      item: 13,
                      itemValue: blood?.[0]?.itemValue?.value2,
                    },
                  );
              // console.log(dictionaryList, 'ppp23');

              const selectTreatArea =
                vsf.stores.dbenums?.enums?.TREAT_AREA_DICT?.find((i) => {
                  return (
                    (res?.treatAreaCode?.value ?? res?.treatAreaCode) == i?.code
                  );
                });
              // console.log(selectTreatArea, 'selectTreatArea');

              // const _validateList =
              //   res?.preExamPatientType == 'BABY'
              //     ? [validate4]
              //     : [validate1, validate2, validate3, validate4];
              // console.log(_validateList);

              Promise.all([validate1, validate2, validate3, validate4]).then(
                (r) => {
                  const _params = {
                    ...res,
                    ...res?.treatArea,
                    // ...res?.diseaseLevel,
                    ...res?.arriveHospital,

                    treatAreaSortCode:
                      selectTreatArea &&
                      selectTreatArea?.extensionJson &&
                      JSON.parse(selectTreatArea?.extensionJson)
                        ?.treatAreaSortCode,
                    patientChiefComplaintVsJudgeDictionaryDtoList:
                      res?.patientChiefComplaintVsJudgeDictionaryDtoList?.map(
                        (i) => {
                          return {
                            chiefComplaintId: i?.secondId,
                            chiefComplaintJudgeId: i?.id,
                            chiefComplaintVsJudgeId: i?.chiefComplaintVsJudgeId,
                          };
                        },
                      ) ?? [],

                    erpPreExamRecordDetailWithVitalsignDictionaryList: [
                      ...dictionaryList,
                      ...dictionaryModal,
                    ]?.filter((i) => i?.item && i?.itemValue),
                  };
                  // console.log(
                  //   _params,
                  //   dictionaryList,
                  //   dictionaryModal,
                  //   '_params_params_params',
                  // );

                  const output =
                    convertPreExamMainForm2SaveErpPreExamFuncFlowExecutorReq?.({
                      ..._params,
                    });
                  console.log(output, '7475_output');
                  vsf?.services
                    ?.ClinicErpPreExamController_saveErpPreExamFuncFlowExecutor_a6c909?.(
                      {
                        saveErpVisitForPreExamBto:
                          output?.saveErpVisitForPreExamBto,
                        createErpPreExamRecordBto:
                          output?.createErpPreExamRecordBto,
                        createPatientChiefComplaintVsJudgeBtoList:
                          output?.createPatientChiefComplaintVsJudgeBtoList,
                        saveVitalsignMeasureRecordBto:
                          output?.saveVitalsignMeasureRecordBto,
                        createVitalsignRecordBtoList:
                          output?.createVitalsignRecordBtoList,
                        saveErpPreExamFuncFlowApiParam:
                          output?.saveErpPreExamFuncFlowApiParam,
                        erpPreExamRecordDetailWithVitalsignDictionaryList: [
                          ...dictionaryList,
                          ...dictionaryModal,
                        ]?.filter((i) => i?.item && i?.itemValue),
                      },
                    )
                    .then((response) => {
                      if (response?.code == 200) {
                        message.success('保存成功');
                        setTimeout(() => {
                          handelSaveSuccess();
                        }, 500);
                        setDetail(null);
                        clearScreen();
                      }
                    });
                },
              );
            }}
          >
            保存
          </Button>
        </div>
      )}
    </div>
  );
};
export default Index;
