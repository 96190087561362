import {
  VSTable,
  VSTableColumn,
  VSPromiseTable,
  InputNumber,
  Button,
  Row,
  Col,
  Space,
  Modal,
  Tooltip,
  ConfigProvider,
  Checkbox,
  message,
  DatePicker,
} from '@vs/vsf-kit';
import {
  useGetResetInitState,
  useOnChangeValue,
} from '@/pages/Index/components/func/hooks';
import {
  saveNurseCost,
  nurseCostStopBilling,
  cancelInpNurseCost,
} from './Service';
import { useRequest } from 'ahooks';
import locale from 'antd/es/date-picker/locale/zh_CN';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { getScrollY } from '@/utils';
import { user } from '@/stores';
import vsf, { definePage } from '@vs/vsf-boot';

import DetallTable from './DetallTable';
import ModalComTemplate from './modalComTemplate';
import '@/style/adaptation.less';

const WardNurseBillingOrderInfo = (props) => {
  const [value, setValue, getValue, resetValue] = useGetResetInitState([]);
  const { staffInfo } = user || {};
  const { selectBedId, selectId, nurseOrderformData, patientId } = props;
  console.log(selectId, 'selectIdselectId');
  const [stopTime, setStopTime] = useState();
  //打开详情
  const [openDetaill, setOpenDetaill] = useState(false);
  // 选择模板
  const [selectComtemplate, setSelectComtemplate] = useState();
  const openDetaillChange = (param) => {
    setOpenDetaill(param);
  };

  const [detailData, setDetailData] = useState();

  const [tableDatasorce, setTableDatasorce] = useState([]);

  const tableRef = useRef();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // 平铺数据源
  const flattenData = (data) => {
    const flatData = [];
    data.forEach((parent) => {
      flatData.push({
        ...parent,
        checkbox: (
          <Checkbox
            indeterminate={
              parent.inpOrderCostVoList.every((child) =>
                selectedRowKeys.includes(child.key),
              )
                ? false
                : parent.inpOrderCostVoList.some((child) =>
                    selectedRowKeys.includes(child.key),
                  )
            }
            checked={
              parent.inpOrderCostVoList &&
              parent.inpOrderCostVoList.every((child) =>
                selectedRowKeys.includes(child.key),
              )
            }
            onChange={() => handleParentCheckboxChange(parent)}
          />
        ),
      });

      parent?.inpOrderCostVoList?.forEach((child) => {
        flatData.push({
          ...child,
          checkbox: (
            <Checkbox
              checked={selectedRowKeys?.includes(child.key)}
              onChange={() => handleChildCheckboxChange(child)}
            />
          ),
        });
      });
    });
    return flatData;
  };

  //选择父节点应该做什么
  const handleParentCheckboxChange = (parent) => {
    const childKeys = parent.inpOrderCostVoList.map((child) => child.key);
    const allSelected = childKeys.every((key) => {
      return selectedRowKeys.includes(key);
    });
    let newSelectedRowKeys = [...selectedRowKeys];

    if (allSelected) {
      newSelectedRowKeys = newSelectedRowKeys.filter(
        (key) => !childKeys.includes(key),
      );
    } else {
      newSelectedRowKeys = [...newSelectedRowKeys, ...childKeys];
    }
    console.log(newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  //选择子节点应该做什么
  const handleChildCheckboxChange = (child) => {
    let newSelectedRowKeys = [...selectedRowKeys];
    if (newSelectedRowKeys.includes(child.key)) {
      // Unselect child
      newSelectedRowKeys = newSelectedRowKeys.filter(
        (key) => key !== child.key,
      );
    } else {
      // Select child
      newSelectedRowKeys.push(child.key);
    }

    setSelectedRowKeys(newSelectedRowKeys);
  };

  //选择所有节点应该做什么
  const onAllSelectChange = (param) => {
    if (param) {
      let allKey = [];
      data?.map((item) => {
        const childKeys = item?.inpOrderCostVoList?.map((obj) => {
          allKey?.push(obj?.key);
        });
        allKey?.push(item?.key);
      }),
        setSelectedRowKeys(allKey);
    } else {
      setSelectedRowKeys([]);
    }
  };

  const { data: queryTableData, run: queryTableDataById } = useRequest(
    async (param) => {
      if (selectBedId) {
        setSelectedRowKeys([]);
        setTableDatasorce([]);
        const res =
          await vsf.services?.NurseBillingController_getInpOrderCost_c86d04?.({
            inpVisitId: selectBedId.key,
            orderIdList: selectId ?? undefined,
            includeTodayCancel: false,
            ...nurseOrderformData,
            ...param,
          });

        let result = res?.data?.map((item, index) => {
          const inpOrderCostVoList = item?.inpOrderCostVoList?.map((obj, i) => {
            return { ...obj, key: `${index}-${i}` };
          });
          return { ...item, key: `${index}`, inpOrderCostVoList };
        });
        if (param?.isNoParam) {
          result = [];
        }
        return result ?? [];
      }
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    //医嘱带入
    if (nurseOrderformData?.medicalBring && !nurseOrderformData?.nurseBring) {
      queryTableDataById({ orderCostIndicator: true });
    }
    //短期
    else if (
      !nurseOrderformData?.medicalBring &&
      nurseOrderformData?.nurseBring
    ) {
      queryTableDataById({ orderCostIndicator: false });
    } else if (
      nurseOrderformData?.medicalBring &&
      nurseOrderformData?.nurseBring
    ) {
      queryTableDataById();
    } else {
      queryTableDataById({ isNoParam: true });
      // queryTableDataById({ isNoParam: true });
    }
  }, [selectBedId, selectId, nurseOrderformData]);

  useEffect(() => {
    if (queryTableData) {
      setTableDatasorce(queryTableData);
    }
  }, queryTableData);

  //保存
  const { data: savaData, runAsync: saveRun } = useRequest(saveNurseCost, {
    manual: true,
  });

  //停止计费
  const { data: stopData, runAsync: stopRun } = useRequest(
    nurseCostStopBilling,
    {
      manual: true,
    },
  );

  //作废接口
  const { data: cancelData, runAsync: cancelRun } = useRequest(
    cancelInpNurseCost,
    {
      manual: true,
    },
  );

  // 停止计费
  const [stopCharge, setStopCharge] = useState(false);

  const stopChargeChange = (param) => {
    setStopCharge(param);
  };

  //停止计费Modal
  const modalComStop = (openChange, stopCharge) => {
    const disabledDate = (current) => {
      return (
        (current && current < dayjs().startOf('day')) ||
        current > dayjs().add(1, 'days').endOf('day')
      );
    };
    return (
      <Modal
        title={<div className="modal-fee-revivw">停止计费</div>}
        open={stopCharge}
        onCancel={() => {
          openChange(false);
          setStopTime(undefined);
        }}
        width={768}
        bodyStyle={{
          padding: '12px 24px',
          backgroundColor: '#f4f5f7',
        }}
        okText={'确定'}
        cancelText={'取消'}
        onOk={() => {
          const selectIds = flattenData(queryTableData).filter(
            (item) =>
              item?.id &&
              selectedRowKeys?.includes(item?.key) &&
              item?.repeatIndicator &&
              item?.billingType !== '医嘱带入',
          );
          if (selectIds?.length === 0) {
            message.error('未选中需要作废选中的计价医嘱');
            return;
          }
          stopRun({
            nurseCostIdList: selectIds?.map((item) => item?.id),
            billingStopDate: stopTime,
          }).then((res) => {
            if (res?.code === 200) {
              message.success('停止计费成功');
              queryTableDataById();
              openChange(false);
            }
          });
        }}
      >
        <div className="modal-stop-charge-div">
          {queryTableData &&
            flattenData(queryTableData)
              .filter(
                (item) =>
                  item?.id &&
                  selectedRowKeys?.includes(item?.key) &&
                  item?.repeatIndicator &&
                  item?.billingType !== '医嘱带入',
              )
              ?.map((obj) => (
                <Row justify={'center'} style={{ textAlign: 'center' }}>
                  <Col span={12}>{obj?.priceItemName}</Col>
                  <Col span={12}>{obj?.priceItemSpecification}</Col>
                </Row>
              ))}
        </div>

        <DatePicker
          format="YYYY-MM-DD"
          placeholder="请选择日期"
          onChange={(v) => {
            setStopTime(v);
          }}
          disabledDate={disabledDate}
          locale={locale}
        />
      </Modal>
    );
  };

  const footer = () => {
    return (
      <Space size={4}>
        <Button onClick={cancelExpense}>作废</Button>
        <Button
          onClick={() => {
            // const
            if (selectedRowKeys?.length === 0) {
              message.error('未选中需要作废选中的计价医嘱!');
              return;
            }
            setStopTime(undefined);
            stopChargeChange(true);
          }}
        >
          停止计费
        </Button>
        <Button
          type="primary"
          disabled={!selectId}
          onClick={async (p) => {
            console.log(tableRef.current);

            const _id = `dayOrder${Math.random()}`;
            await tableRef.current?.add(
              {
                id: _id,
              },
              undefined,
              true,
            );
          }}
        >
          新增
        </Button>
        <Button
          disabled={!selectId}
          type="primary"
          onClick={() => setImportTemplate(true)}
        >
          模版导入
        </Button>
        <Button type="primary">医嘱违规检查</Button>
        <Button type="primary" onClick={saveAllAction}>
          保存
        </Button>
      </Space>
    );
  };

  // 作废费用
  const cancelExpense = () => {
    const selectIds = flattenData(queryTableData).filter(
      (item) => item?.id && selectedRowKeys?.includes(item?.key),
    );
    if (selectIds?.length === 0) {
      message.error('未选中需要作废选中的计价医嘱');
      return;
    }

    if (selectIds?.some((i) => i?.billingType == '医嘱带入'))
      return message?.error('有费用为医嘱带入费用,不可作废!');

    Modal.confirm({
      title: '是否作废选中的计价医嘱？',
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        const param = selectIds.map((item) => {
          //过滤医嘱带入
          return item?.id;
        });

        param?.map((item) => {
          cancelRun(item).then((res) => {
            if (res?.code === 200) {
              message.success('作废成功');
            } else {
              message?.error('有费用为医嘱带入费用,不可作废!');
            }
          });
        });

        queryTableDataById();
      },
    });
  };
  const handelTableSelect = (v) => {
    console.log(v, 'handelTableSelect');
    setSelectComtemplate(v);
  };
  //打开导入模板
  const [importTemplate, setImportTemplate] = useState(false);

  const ImportTemplateChange = (param) => {
    setImportTemplate(param);
  };

  //计费模板Modal
  const modalComTemplate = (openChange, importTemplate) => {
    // console.log(saveParam);
    return (
      <Modal
        title={<div className="modal-fee-revivw">计费模板</div>}
        open={importTemplate}
        footer={modalFooter()}
        onCancel={() => {
          openChange(false);
        }}
        width={1246}
      >
        <ModalComTemplate handelTableSelect={handelTableSelect} />
      </Modal>
    );
  };

  const modalFooter = () => {
    return (
      <Row>
        <Col
          span={12}
          style={{ textAlign: 'left' }}
          className="modal-fee-revivw-memo"
        >
          说明：明细中红色字体为价表已经停用的费用明细
        </Col>
        <Col span={12}>
          <Space>
            <Button onClick={() => ImportTemplateChange(false)}>取消</Button>
            <Button
              type="primary"
              onClick={() => {
                // selectComtemplate
                console.log(
                  selectComtemplate,
                  'selectComtemplateselectComtemplate',
                );
                ImportTemplateChange(false);
                setTimeout(() => {
                  message.success('导入成功!');
                  selectComtemplate?.forEach(async (i) => {
                    const { priceItemVo, pricePatternDetailVo } = i;
                    const { id, ..._priceItemVo } = priceItemVo;
                    const { id: ids, ..._pricePatternDetailVo } =
                      pricePatternDetailVo;
                    await tableRef.current?.add(
                      {
                        ...i,
                        ..._priceItemVo,
                        ..._pricePatternDetailVo,
                        priceItemName: _priceItemVo?.itemName,
                        priceItemSpecification: _priceItemVo?.itemSpecification,
                        priceItemClass: _priceItemVo?.billItemClass,
                        patientId: patientId,
                        orderId: selectId?.[0],
                        id: `dayOrder${Math.random()}`,
                        billingStartDateTime: +new Date(),
                      },
                      undefined,
                      true,
                    );
                  }, 100);
                });
              }}
            >
              导入
            </Button>
          </Space>
        </Col>
      </Row>
    );
  };

  const saveAllAction = async () => {
    const res = await tableRef.current?.saveEdit();
    if (!res) return;
    const formdata = tableRef.current?.getValues();
    const param = formdata?.map((item) => {
      return { ...item, inpVisitId: selectBedId.key };
    });
    formdata?.map((params) => {
      if (typeof params?.id === 'string') {
        const formData = {
          ...params,
          inpVisitId: selectBedId.key,
          patientId: patientId,
          amount: params?.amount,
          billingStartDateTime: params?.billingStartDateTime,
          billingStopDateTime: params?.billingStopDateTime,
          recordNurseId: params?.recordNurseId,
          recordDateTime: params?.recordDateTime,
          selfPaymentIndicator: params?.selfPaymentIndicator,
          orderDepartmentId: params?.orderDepartmentId,
          performDepartmentId: params?.performDepartmentId,
          orderStaffId: 201,
          recordNurseId: staffInfo?.id,
          memo: params?.memo,
          orderId: selectId?.[0],
          priceItemId: params?.itemId,
          priceItemName: params?.itemName,
        };
        delete formData.id;
        saveRun(formData).then((res) => {
          if (res.code === 200) {
            message.success('保存成功');
            queryTableDataById();
          }
        });
      }
    });
  };

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <div style={{ width: '100%', flex: '1' }}>
        <VSPromiseTable
          editable={{
            editType: 'single',
            columnProps: {
              width: 100,
            },
            onCanEdit: (v) => {
              if (typeof v.id === 'number' || !v.id?.startsWith('dayOrder')) {
                return false;
              } else {
                return true;
              }
            },
            onCanDelete: (v) => {
              if (typeof v.id === 'number' || !v.id?.startsWith('dayOrder')) {
                return false;
              } else {
                return true;
              }
            },
          }}
          vsid="81979"
          id="wardNurseBillingOrderMasterList"
          onFetch={() => flattenData(tableDatasorce)}
          pagination={false}
          ref={tableRef}
          className="aspirin-table aspirin-table-search-padding-no aspirin-table-no-background-box"
          rowClassName={(node) => {
            // cancelIndicator

            const className1 = node?.inpOrderCostVoList
              ? 'aspirin-table-row-background-box'
              : '';
            const className2 = node?.cancelIndicator ? 'row-text-color' : '';
            return [className1, className2];
          }}
          scroll={{ x: 600, y: getScrollY(480) }}
          bordered
          rowKey={'id'}
        >
          <VSTableColumn
            dataIndex={['id']}
            title="主键"
            valueType="digit"
            hideInTable
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['checkbox']}
            title={<Checkbox onChange={onAllSelectChange} />}
            valueType="text"
            width={30}
            render={(dom, val) => {
              return val?.checkbox;
            }}
            preview
          />
          <VSTableColumn
            dataIndex={['patientId']}
            title="计费详情"
            valueType="text"
            fieldProps={{}}
            width={100}
            onCell={(recode, index) => ({
              colSpan: recode?.inpOrderCostVoList ? 3 : 1,
            })}
            preview
            render={(_, v) => {
              if (v?.inpOrderCostVoList) {
                return (
                  <div className="text-overflow-omit">
                    <Tooltip title={v?.orderText}>{v?.orderText}</Tooltip>
                  </div>
                );
              } else {
                return (
                  !v?.inpOrderCostVoList && (
                    <div
                      className="modal-fee-revivw-detail"
                      style={{ color: v?.cancelIndicator ? '#b8b5b5' : '' }}
                      onClick={() => {
                        setOpenDetaill(true);
                        setDetailData(v);
                      }}
                    >
                      查看详情
                    </div>
                  )
                );
              }
            }}
          />
          <VSTableColumn
            dataIndex={['repeatIndicator']}
            title="长/临"
            valueType="select"
            fieldProps={
              {
                // disabled: true,
              }
            }
            width={80}
            initialValue={false}
            dataSource={[
              { label: '长期', value: true },
              { label: '临时', value: false },
            ]}
            render={(dom, value) => {
              if (value?.frequency) {
                return <div>{value?.frequency}</div>;
              } else {
                if (value?.administration) {
                  return (
                    vsf.stores.dbenums?.enums?.ADMINISTRATION_DICT?.find(
                      (item) => item?.value === value?.administration,
                    )?.label ?? ''
                  );
                }

                if (value?.repeatIndicator) {
                  return '长期';
                } else {
                  return '临时';
                }
              }
            }}
          />

          <VSTableColumn
            dataIndex={['billingType']}
            title="费用来源"
            valueType="text"
            initialValue={'护士录入'}
            width={100}
            fieldProps={{ disabled: true }}
            render={(dom, value) => {
              if (value?.orderDepartmentName) {
                return (
                  <div style={{ textAlign: 'left' }}>
                    {value?.orderDepartmentName}
                  </div>
                );
              } else {
                return dom;
              }
            }}
          />

          <VSTableColumn
            dataIndex={['priceItemClass']}
            title="费用分类"
            valueType="select"
            width={100}
            fieldProps={{
              disabled: true,
            }}
            dataSource={vsf?.stores?.dbenums?.enums?.BILL_ITEM_CLASS_DICT}
            render={(dom, value) => {
              if (value?.orderDoctorName) {
                return value?.orderDoctorName;
              } else {
                return dom;
              }
            }}
          />

          <VSTableColumn
            dataIndex={['priceItemName']}
            title="项目名称"
            valueType="projectSelect"
            width={250}
            fieldProps={{
              placeholder: '请选择',
              dataSource: getValue?.(),
            }}
            render={(dom, value) => {
              if (value?.startPerformDateTime) {
                return (
                  <div
                    className="text-overflow-omit"
                    style={{ width: '250px' }}
                  >
                    <Tooltip title={value?.startPerformDateTime}>
                      {value?.startPerformDateTime}
                    </Tooltip>
                  </div>
                );
              } else {
                return dom;
              }
            }}
          />
          <VSTableColumn
            dataIndex={['priceItemSpecification']}
            title="规格"
            valueType="text"
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 100 },
              ],
            }}
            fieldProps={
              {
                // disabled: true,
              }
            }
            width={100}
          />
          <VSTableColumn
            dataIndex={['brand']}
            title="品牌"
            valueType="text"
            width={200}
            fieldProps={
              {
                // disabled: true,
              }
            }
          />

          <VSTableColumn
            dataIndex={['amount']}
            title="一天数量"
            valueType="digit"
            fieldProps={{}}
            width={100}
            formItemProps={{
              rules: [
                {
                  validator(_, value) {
                    if ([undefined, null].includes(value)) {
                      return Promise.reject('请输入数量');
                    } else if (!Number.isInteger(value) || value < 1) {
                      return Promise.reject('数量必须是正整数');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ],
            }}
            render={(dom, { billingType }) =>
              billingType !== '医嘱带入' ? dom : ''
            }
          />
          <VSTableColumn
            dataIndex={['perMealAmount']}
            title="一顿数量"
            valueType="text"
            width={150}
            fieldProps={{ disabled: true }}
          />

          <VSTableColumn
            dataIndex={['unit']}
            title="单位"
            valueType="text"
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 20 },
              ],
            }}
            fieldProps={{
              disabled: true,
            }}
            width={100}
          />

          <VSTableColumn
            dataIndex={['price']}
            title="单价"
            valueType="digit"
            fieldProps={{
              disabled: true,
            }}
            width={100}
          />

          <VSTableColumn
            dataIndex={['selfPaymentIndicator']}
            title="强制自费标记"
            valueType="switch"
            initialValue={false}
            width={150}
            fieldProps={{}}
            render={(dom, value) => {
              if (value?.inpOrderCostVoList) {
                return '';
              } else {
                return dom;
              }
            }}
          />
          <VSTableColumn
            dataIndex={['billingStartDateTime']}
            title="开始计费时间"
            valueType="date"
            width={200}
            fieldProps={{ format: 'YYYY-MM-DD' }}
            formItemProps={{
              rules: [
                {
                  validator(_, value) {
                    if (dayjs(value).isBefore(dayjs(), 'day')) {
                      return Promise.reject('修改范围只大于等于当前时间');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ],
            }}
          />

          <VSTableColumn
            dataIndex={['billingStopDateTime']}
            title="停止计费时间"
            valueType="date"
            width={200}
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss', disabled: true }}
          />
          <VSTableColumn
            dataIndex={['createDate']}
            title="录入时间"
            valueType="date"
            width={200}
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss', disabled: true }}
          />
          <VSTableColumn
            dataIndex={['operatorName']}
            title="录入护士"
            valueType="text"
            width={150}
            fieldProps={{
              disabled: true,
            }}
            render={(dom, value) => {
              if (value?.billingType === '医嘱带入') {
                return '';
              } else {
                return dom;
              }
            }}
          />

          <VSTableColumn
            dataIndex={['insuranceCode']}
            title="医保编码"
            valueType="text"
            width={150}
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 50 },
              ],
            }}
            fieldProps={{
              disabled: true,
            }}
          />

          <VSTableColumn
            dataIndex={['orderId']}
            title="医嘱id"
            hideInTable
            valueType="digit"
            fieldProps={{}}
            width={100}
          />
          <VSTableColumn
            dataIndex={['patientId']}
            title="病人标识"
            valueType="digit"
            hideInTable={true}
            formItemProps={{
              rules: [
                {
                  message: '病人标识的值不合法',
                  type: 'number',
                  min: 0,
                  max: undefined,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['medicalInsuranceType']}
            title="医保等级"
            valueType="select"
            dataSource={[
              { label: '甲', value: 'ALPHA' },
              { label: '乙', value: 'BETA' },
              { label: '丙', value: 'GAMMA' },
            ]}
            width={150}
            fieldProps={{
              disabled: true,
            }}
          />
          <VSTableColumn
            dataIndex={['priceItemId']}
            title="项目编码"
            valueType="text"
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 20 },
              ],
            }}
            hideInTable
            fieldProps={{
              disabled: true,
            }}
            width={150}
          />
        </VSPromiseTable>
      </div>
      <div className="refunds-btn">
        <Row justify={'space-between'}>
          <Col span={12} className="icon18" style={{ color: '#E34A4E' }}>
            每次录入新项目后，请务必点“保存”
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            {footer()}
          </Col>
        </Row>
      </div>

      {modalComTemplate(ImportTemplateChange, importTemplate)}
      {modalComStop(stopChargeChange, stopCharge)}
      {
        <DetallTable
          openDetaill={openDetaill}
          openDetaillChange={openDetaillChange}
          detailData={detailData}
          selectBedId={selectBedId?.key}
        />
      }
    </div>
  );
};
export default definePage(WardNurseBillingOrderInfo);
