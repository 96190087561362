import vsf from '@vs/vsf-boot';
import { Button, Modal, VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import classNames from 'classnames';
import React, { useCallback } from 'react';

import { Flex } from '@/pages/Index/components';
import { askGroupMedicalOrders } from '@/pages/Index/components/func/ask';
import useSoften from '@/pages/Index/components/func/hooks/useSoften';
import { getScrollY } from '@/utils';

import Detail from './detail';
const convertGetPatientVisitHistoryDetailRes2VisitHistoryInpOrderRo = (
  input,
) => {
  const _ = window._ ?? {};
  const output: any = [];
  output.push(
    ...(_.get(input, 'data.patientVisitHistoryDetailOrderList')?.map((d, i) => {
      const obj: any = {};
      obj.createDateTime = _.get(d, 'orderVo.createDateTime');
      obj.stopOrderDateTime = _.get(d, 'orderVo.stopOrderDateTime');
      obj.repeatIndicator = _.get(d, 'orderVo.repeatIndicator');
      obj.orderClass = _.get(d, 'orderVo.orderClass');
      obj.orderText = _.get(d, 'orderVo.orderText');
      obj.dosage = _.get(d, 'dosage');
      obj.dosageUnit = _.get(d, 'dosageUnit');
      obj.frequency = _.get(d, 'frequency');
      obj.performDays = _.get(d, 'performDays');
      obj.administration = _.get(d, 'administration');
      obj.groupNumber = _.get(d, 'orderDrugVo.groupNumber');
      obj.groupSubNumber = _.get(d, 'orderDrugVo.groupSubNumber');
      obj.drugOrder = _.get(d, 'orderDrugVo');
      return obj;
    }) ?? []),
  );
  return output;
};

const InOrder = (props) => {
  const { id, visitRecordHistoryType, patientId } = props;

  // 处理成组
  const group = askGroupMedicalOrders({
    max: 5,
    order: ['DRUG'],
    eliminates: [
      ['drugOrder', 'administration'],
      ['drugOrder', 'dripSpeed'],
      ['drugOrder', 'frequency'],
      ['drugOrder', 'userDescription'],
      ['drugOrder', 'performDays'],
    ],
    tip({ onOk, onCancel }) {},
  });

  const onFetch = useCallback(async () => {
    const res =
      await vsf?.services?.VisitHistoryController_getPatientVisitHistoryDetail_d961fc?.(
        {
          eo: {
            id,
            visitRecordHistoryType,
            detailType: 'ORDER',
            patientId,
          },
        },
      );
    const _res =
      convertGetPatientVisitHistoryDetailRes2VisitHistoryInpOrderRo?.(res);
    const _data = group?.onSliced(_res);
    console.log(_data, _res, 'ppppppppppppppppop');

    return _data;
  }, [id, patientId, visitRecordHistoryType, group]);

  const patientJourney = async (value) => {
    const res =
      await vsf?.services?.PatientJourneyController_getPatientJourneyDetail_09a7d6?.(
        {
          ...value,
        },
      );
    return res;
  };

  const { ref, Soften } = useSoften();

  return (
    <Flex>
      <VSPromiseTable
        className="
         aspirin-table-header-background-color
        aspirin-table-row-hover-background-color
        aspirin-table-nopadding
        aspirin-table-body-empty-transparent-background-color
 "
        onFetch={onFetch}
        pagination={false}
        editable={{
          editType: 'multiple',
          columnProps: {
            hideInTable: true,
          },
        }}
        scroll={{
          y: getScrollY(530),
        }}
      >
        <VSTableColumn
          dataIndex={['createDateTime']}
          title="下达日期及时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          preview
        />

        <VSTableColumn
          dataIndex={['stopOrderDateTime']}
          title="停止医嘱待停时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          preview
        />

        <VSTableColumn
          dataIndex={['repeatIndicator']}
          title="长/临"
          valueType="repeatIndicatorSelect"
          fieldProps={{}}
          width={70}
          preview
        />

        <VSTableColumn
          dataIndex={['orderClass']}
          title="医嘱类别"
          valueType="select"
          dataSource={[
            { label: '西药', value: 'DRUG' },
            { label: '中药', value: 'HERB' },
            { label: '检验', value: 'LAB' },
            { label: '检查', value: 'EXAM' },
            { label: '治疗', value: 'TREAT' },
            { label: '手术', value: 'OPERATION' },
            { label: '麻醉', value: 'ANESTHESIA' },
            { label: '护理', value: 'NURSING' },
            { label: '膳食', value: 'MEAL' },
            { label: '营养', value: 'NUTRITION' },
            { label: '会诊', value: 'CONSULTATION' },
            { label: '用血', value: 'BLOOD' },
            { label: '其他', value: 'OTHER' },
            { label: '病理', value: 'PATHOLOGY' },
          ]}
          preview
          width={100}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['orderText']}
          title="医嘱正文"
          valueType="text"
          fieldProps={{}}
          preview
          width={150}
          className="aspirin-table-order-cell"
          render={(element, record) => {
            return (
              <div
                className={classNames('aspirin-order-text', record?.groupName)}
              >
                {record?.orderText}
              </div>
            );
          }}
        />

        <VSTableColumn
          dataIndex={['dosage']}
          title="剂量"
          valueType="digit"
          fieldProps={{}}
          preview
        />

        <VSTableColumn
          dataIndex={['dosageUnit']}
          title="剂量单位"
          valueType="text"
          fieldProps={{}}
          preview
        />

        <VSTableColumn
          dataIndex={['frequency']}
          title="频次"
          valueType="text"
          fieldProps={{}}
          preview
        />

        <VSTableColumn
          dataIndex={['performDays']}
          title="执行天数"
          valueType="digit"
          fieldProps={{}}
          preview
        />

        <VSTableColumn
          dataIndex={['administration']}
          title="途径"
          valueType="administrationSelect"
          fieldProps={{}}
          preview
        />
        <VSTableColumn
          dataIndex={['edit']}
          title="操作"
          valueType="custom"
          fieldProps={{}}
          render={(...[, record]) => {
            console.log(record);
            return (
              <Flex
                justify="center"
                style={{ color: 'var(--blue)', cursor: 'pointer' }}
              >
                <span
                  onClick={async () => {
                    const detail = await patientJourney({
                      orderId: record?.orderId,
                      icon: record?.orderClass,
                    });
                    (ref.current as any)?.set({
                      open: true,
                      detail: detail?.data,
                    });
                  }}
                >
                  查看详情
                </span>
              </Flex>
            );
          }}
          preview
        />
      </VSPromiseTable>
      <Soften ref={ref}>
        {(state) => {
          return (
            <Modal
              open={state?.open}
              title="医嘱详情"
              width="50%"
              onCancel={() => {
                (ref?.current as any)?.set({
                  open: false,
                });
              }}
              footer={
                <Flex justify="flex-end">
                  <Button
                    onClick={() => {
                      (ref?.current as any)?.set({
                        open: false,
                      });
                    }}
                  >
                    取消
                  </Button>
                </Flex>
              }
            >
              <Detail detail={state?.detail} />
            </Modal>
          );
        }}
      </Soften>
    </Flex>
  );
};

export default InOrder;
