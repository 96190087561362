import vsf, { definePage } from '@vs/vsf-boot';
import { Button } from '@vs/vsf-kit';
import React, { createContext, useEffect, useState, useCallback } from 'react';
import ExportTable from './table';
import DrugAccounting from './accounting';
import DrugInvoice from './invoice';
import Apply from './apply';
import Container from '@/module/Container';
import Screen from '@/pages/Index/components/screen';
import storage from '@/utils/storage';
import { useAvoidState } from '@/pages/Index/components/func/hooks';
import { useAsyncEffect } from 'ahooks';

export const DrugExportContext = createContext([]);

const DrugExport = (props) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState(null);
  const [state, setState] = useState([]);
  const [dataSource, setDataSource] = useAvoidState([]);

  /**
   * 接受药品请领单的数据
   * @param {*} value
   */
  const onChange = (value) => {
    setData(value);
    setShow(true);
  };

  /**
   * 接受申请出库药品的数据
   * @param {*} value
   */

  const onOk = (value) => {
    setState(value);
  };

  /**
   * 接受待记账单据的数据
   * @param {*} value
   */
  const onAccountingChange = (value) => {
    setState(value);
  };

  useEffect(() => {
    vsf?.stores?.common?.getSystemParameter();
  }, []);

  // 出库类型
  const getAvailable = useCallback(async () => {
    const res =
      await vsf?.services?.DrugImportRecordCreateController_getCandidateImportExportClzList_6a4edf(
        {
          isImport: false,
        },
      );
    // console.log(res.data, 'drug_res.data');
    setDataSource(res.data);
  }, []);

  useAsyncEffect(getAvailable, [getAvailable]);

  return (
    <DrugExportContext.Provider value={dataSource}>
      <DrugAccounting onChange={onAccountingChange} />
      <DrugInvoice onChange={onChange} />
      <Container
        Container
        layout={{
          items: [
            {
              block: 1,
              bgColor: 'var(--background)',
              // padding: '24px'
            },
          ],
        }}
      >
        <ExportTable value={state} />
      </Container>
      <Apply
        open={show}
        value={data}
        onCancel={() => setShow(false)}
        onOk={onOk}
      />
    </DrugExportContext.Provider>
  );
};

const DrugExportMain = (props) => {
  return (
    <Screen>
      <DrugExport {...props} />
    </Screen>
  );
};

export default DrugExportMain;
