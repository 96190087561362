import {
  compose,
  Input,
  InputProps,
  Select,
  SelectProps as AntSelectProps,
  Space,
  useControllableState,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import React from 'react';

import { askIsInteger, askSearch } from '@/pages/Index/components/func/ask';

import AspirinSelect from '../AspirinSelect';
import CodeTableSelect from '../CodeTableSelect';

export type SelectAndTextValue = {
  /** 自定义类型字段 */
  selectValue: string;
  inputValue: string;
};

type SelectProps = Omit<AntSelectProps, 'fieldNames'> & {
  /**
   * 默认值
   */
  defaultValue?: any;
  /**
   * 值
   */
  value?: any;
};

export type SelectAndTextProps = {
  /**
   * 默认值
   */
  defaultValue?: any;
  /**
   * 值
   */
  value?: any;
  /**
   * 值变化回调
   */
  onChange?: (value?: any) => void;
  /**
   * Input属性
   */
  inputProps: any;
  /**
   * Input属性
   */
  selectProps: SelectProps;
  /**
   * 第二个输入框，默认input,true为AspirinSelect
   */
  isAspirinSelect: boolean;
  /**
   * 入参字段名称映射
   */
  requestParamsFieldNames?: {
    selectName: string;
    inputName: string;
  };
};

/**
 * 下拉输入
 */
const SelectAndText = (props: SelectAndTextProps) => {
  const {
    defaultValue,
    value: valueProp,
    onChange,
    inputProps,
    selectProps,
    isAspirinSelect = false,
    requestParamsFieldNames = {
      selectName: 'label',
      inputName: 'value',
    },
  } = props;
  const [value, setValue] = useControllableState({
    defaultValue,
    value: valueProp,
    onChange,
  });
  console.log(value, 'valuevaluevaluevalue');

  const handelInputChange = (e) => {
    console.log(e, 'eee');

    setValue({
      ...value,
      [requestParamsFieldNames.inputName]: e?.target?.value ?? e,
    });
    inputProps?.onChange?.(e);
  };

  const handelSelectChange = (v) => {
    setValue({
      ...value,
      [requestParamsFieldNames.selectName]: v,
    });
    selectProps?.onChange?.(v);
  };
  console.log(selectProps, props);

  /** 编写组件逻辑 */
  return (
    <div className="select_and_text">
      {/* <Input
        style={{
          backgroundColor: '#fff',
          // borderTop: '1px solid #d9d9d9',
          // borderBottom: '1px solid #d9d9d9',
        }}
        {...inputProps}
        onChange={handelInputChange}
        addonBefore={
          <Select
            bordered
            width={200}
            {...selectProps}
            showSearch
            onChange={handelSelectChange}
          />
        }
      /> */}
      <Space.Compact style={{ width: '100%' }}>
        <CodeTableSelect
          bordered
          width="30%"
          {...selectProps}
          allowClear
          // dataSource={(value) => {
          //   const _dataSource: any = selectProps?.dataSource ?? [];
          //   return value?.label
          //     ? _dataSource?.filter(
          //         (item) =>
          //           askSearch(value?.label, item, 'label') ||
          //           askSearch(value?.label, item, 'pinyin'),
          //       )
          //     : _dataSource;
          // }}
          dataSource={selectProps?.dataSource}
          showSearch
          onChange={handelSelectChange}
          value={value?.[requestParamsFieldNames.selectName]}
        />
        {!isAspirinSelect ? (
          <Input
            width="70%"
            {...inputProps}
            onChange={handelInputChange}
            value={value?.[requestParamsFieldNames.inputName]}
            allowClear
          />
        ) : (
          <div style={{ width: '70%' }}>
            <AspirinSelect
              dataSource={inputProps?.dataSource}
              onChange={handelInputChange}
              value={value?.[requestParamsFieldNames.inputName]}
              autoCompleteProps={{
                showSearch: true,
                placeholder: inputProps?.placeholder ?? '请选择或输入',
              }}
              addItemOptions={{
                status: true,
                mode: 'single',
              }}
              selectProps={{
                fieldNames: { label: 'label', value: 'value' },
                showSearch: true,
                allowClear: true,
              }}
            />
          </div>
        )}
      </Space.Compact>
    </div>
  );
};
SelectAndText.displayName = 'SelectAndText';

export default compose(
  withField<SelectAndTextValue>({
    name: 'SelectAndText',
  }),
  withPreview<SelectAndTextProps>({
    renderPreview: (props) => {
      const { value } = props;
      /** 返回预览模式下的dom */
      return <>预览值：{value}</>;
    },
  }),
)(SelectAndText);
