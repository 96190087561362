import vsf from '@vs/vsf-boot';
import {
  Col,
  compose,
  Form,
  InputProps,
  Row,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import { Select, SelectProps, Table } from 'antd';
import React, { forwardRef, useRef } from 'react';

import Loading from '@/module/Loading';

import ComplexSelect from '../complex_select';
import { useInputEnter } from '../dosage/utils';
import { askSearch } from '../func/ask';

type NewSelectProps = Omit<SelectProps, 'options'> & {
  dataSource: any[];
  onFieldEnter: () => void;
  onKeyUp: () => void;
  onChange: (value) => void;
};
const SelectCode = forwardRef((props: NewSelectProps, ref) => {
  const { onFieldEnter, onKeyUp, fieldNames, dataSource, ...rest } = props;
  const fieldEnterProps = useInputEnter({ onFieldEnter, onKeyUp });
  const value = props?.value?.[props.fieldNames?.value ?? ''] ?? props?.value;
  return (
    <Select
      {...rest}
      fieldNames={fieldNames}
      value={value}
      options={dataSource as any}
      filterOption={(...arr) => askSearch(...arr, fieldNames?.label)}
      onChange={(value, option) => {
        if (props.fieldNames) {
          props?.onChange?.(option);
        } else {
          props?.onChange?.(value);
        }
      }}
      showSearch
      ref={ref as any}
      {...fieldEnterProps}
    />
  );
});

SelectCode.displayName = 'selectCode';

export default compose(
  withRef(),
  withField<any>({
    name: 'selectCode',
    handleEnter: true,
    shouldFieldUpdate: (newly: any, old: any) => {
      return true;
    },
  }),
  withPreview<any>({
    renderPreview: (props) => {
      const { value, fieldNames } = props;
      return (
        <>
          {props?.dataSource?.find(
            (item) =>
              item?.[fieldNames?.value ?? 'value'] ===
              (value?.[fieldNames?.value ?? 'value'] ?? value),
          )?.[fieldNames?.label ?? 'label'] ?? value}
        </>
      );
    },
  }),
)(SelectCode) as typeof SelectCode;
