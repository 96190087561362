import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSPromiseTable,
  VSTableColumn,
  Button,
  message,
} from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';
import NewDrawer from '@/pages/Index/components/new_drawer';
import Tip from '@/pages/Index/components/func/Tip';
import dayjs from 'dayjs';

const DrugAccounting = ({ selectTableParamAction, businessType }) => {
  const onHide = () => {
    setOpenDrawer(false);
  };

  const destroyPaper = (selectClickData) => {
    if (selectClickData) {
      Tip({
        content: `是否作废当前请领单据！`,
        onOk: () => {
          destroyPaperAction(selectClickData);
        },
      });
    }
  };

  const reload = () => {
    vsf?.refs?.accountingPaperTable?.reload();
  };

  const [openDrawer, setOpenDrawer] = useState(true);
  const [selectData, setSelectData] = useState();
  const [cancellationData, setCancellationData] = useState();
  const onFetch = useCallback(async () => {
    if (openDrawer && businessType) {
      const res =
        await vsf.services?.DrugStorageImportExportController_getDrugImportRecordToBeAccount_20f690(
          {},
        );
      return res?.data;
    }
  }, [openDrawer, businessType]);
  const destroyPaperAction = async (param) => {
    const res =
      await vsf.services?.DrugStorageImportExportController_invalidDrugImportRecord_d16f0f?.(
        {
          bto: {
            id: param?.id,
            accountantStatus: 2,
          },
        },
      );
    if (res?.code === 200) {
      vsf?.refs?.accountingPaperTable?.reload();
      message.success('已作废');
    }
  };

  return (
    <>
      <NewDrawer
        open={openDrawer}
        width="40%"
        onOpen={() => setOpenDrawer(!openDrawer)}
        title={<div style={{ color: '#3276E8' }}>待记账单据</div>}
        buttonTitle="待记账单据"
        backgroundColor="var(--blue)"
        footer={
          <div className="aspirin-table-footer-button">
            <Button type="primary" onClick={reload}>
              刷新
            </Button>
            <Button type="primary" onClick={onHide}>
              隐藏
            </Button>
            <Button
              type="primary"
              danger
              onClick={() => {
                destroyPaper(cancellationData);
              }}
              disabled={!cancellationData}
            >
              作废单据
            </Button>
          </div>
        }
        isOther
        drift="98px"
        style={{
          top: 104,
        }}
        // isOther={true}
      >
        <VSPromiseTable
          onFetch={onFetch}
          vsid="49245"
          id="accountingPaperTable"
          onRecord={() => ({ id: 'create_' + Math.random() })}
          pagination={false}
          editable={{
            editType: 'multiple',
            columnProps: {
              hideInTable: true,
            },
          }}
          onRow={(node) => {
            return {
              onClick() {
                // console.log(node);
                setCancellationData(node);
              },
              onDoubleClick() {
                let obj = node;
                obj.flagType = false;

                setSelectData(obj);
                selectTableParamAction(obj);
                onHide();
              },
            };
          }}
          rowClassName={(node) => {
            return node?.id === cancellationData?.id
              ? 'aspirin-vs-table-row-box aspirin-row-selected-box'
              : 'aspirin-vs-table-row-box aspirin-row-select-box';
          }}
        >
          <VSTableColumn
            dataIndex={['exportImportCode']}
            title="业务类型"
            valueType="select"
            editable={false}
            dataSource={vsf.stores.dbenums.enums.DRUG_IMPEXP_CLASS_DICT}
            fieldProps={{
              onChange: (c) => {
                console.log(c);
              },
            }}
            render={(_, val) => {
              return (
                businessType?.find(
                  (item) => item?.value === val?.exportImportCode,
                )?.label ?? ''
              );
            }}
          />

          <VSTableColumn
            dataIndex={['supplier', 'name']}
            title="供应单位"
            valueType="text"
            editable={false}
            formItemProps={{
              rules: [
                {
                  message: '科室名称长度不合法',
                  type: 'string',
                  min: 0,
                  max: 40,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['documentNumber']}
            title="流水号"
            valueType="text"
            editable={false}
            className="aspirin-table-td-blue-box"
            // formItemProps={{
            //   rules: [
            //     { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            //   ],
            // }}
            fieldProps={{}}
            // renderFormItem={(schema, recode, form) => {
            //   return (
            //    <div>{}</div>
            //   );
            // }}
          />

          <VSTableColumn
            dataIndex={['importDate']}
            title="入库日期"
            valueType="date"
            editable={false}
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          />

          <VSTableColumn
            dataIndex={['id']}
            title="入库单号"
            valueType="digit"
            editable={false}
            fieldProps={{}}
          />
        </VSPromiseTable>
      </NewDrawer>
    </>
  );
};
export default definePage(DrugAccounting);
