import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  Image,
  message,
  Select,
  VSFormItem,
  Modal,
  Drawer,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Block from '@/module/Block';
import Icon from '@/module/cpoe/Icon';
import readCard from '@/assets/icon/readCard.png';
import SearchContainer from '@/module/Search';
import vsf, { definePage } from '@vs/vsf-boot';

import Menu from '../patient_main/menu';
import PatientFullInfo from '../patient_main/patient_full_info';

import './index.less';
import { isMinScreen } from '@/config';

const PatientMainSearch = (props) => {
  const { showTable = true, handelmodal, patientId } = props;
  /**
   * convertVSPromiseTablePatientMainSearchQro2QueryPagedByPatientFullQtoReq
   * @param {any} input
   * @returns {any}
   */
  const [modalVisible, setModalVisible] = useState(false);
  const [fullInfo, setFullInfo] = useState();
  const [isIdNumber, setIsIdNumber] = useState(false);

  const insuranceTypeIsRef = useRef();
  const firstFetch = useRef(true);

  const { data: certificateData } = useRequest(async () => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllIdentificationClassDictionary_a2521d?.(
        {},
      );
    return res?.data;
  });
  useEffect(() => {
    if (!showTable) {
      handelRowClick({ id: patientId });
    }
  }, []);

  const convertVSFormPatientFullInfo2CreateOrUpdatePatientReq = (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.btoParam = {};
    output.btoParam.id = input?.id; // Long 主键
    output.btoParam.displayId = input?.displayId; // String 用于显示的id
    output.btoParam.name = input?.name; // String 姓名
    output.btoParam.gender = input?.gender; // String 生理性别
    output.btoParam.idNumber = input?.idNumber; // String 身份证号
    output.btoParam.insuranceType = input?.insuranceType; // String 医保类型
    output.btoParam.insuranceNumber = input?.insuranceNumber; // String 医保账号
    output.btoParam.insuranceCardNumber = input?.insuranceCardNumber; // String 医保卡号
    output.btoParam.cellphone = input?.cellphone; // String 手机号
    output.btoParam.defaultChargeType = input?.defaultChargeType; // String 默认费别
    output.btoParam.identityCode = input?.identityCode; // String 人员类别编码
    output.btoParam.birthday = input?.birthday; // Date 出生日期
    output.btoParam.birthAddress = {};
    output.btoParam.birthAddress.province = input?.birthAddress?.province; // String 省
    output.btoParam.birthAddress.city = input?.birthAddress?.city; // String 市
    output.btoParam.birthAddress.county = input?.birthAddress?.county; // String 区
    output.btoParam.birthAddress.detail = input?.birthAddress?.detail; // String 详细地址
    output.btoParam.birthAddress.code = input?.birthAddress?.code; // String 地区编码
    output.btoParam.birthAddress.postcode = input?.birthAddress?.postcode; // String 邮编
    //TODO output.btoParam.creator = input?.; // String 创建人
    //TODO output.btoParam.operator = input?.; // String 操作人
    output.btoParam.patientExpressAddressBtoList =
      input?.patientExpressAddressDtoList?.map((item) => ({
        ...item,
        patientId: fullInfo?.id,
        id:
          typeof item?.id === 'string' && item?.id?.includes('create_')
            ? undefined
            : item?.id,
        label: [{ name: item?.label }],
      })); // List<PatientExpressAddressBto> 患者收货信息列表
    output.btoParam.patientRelationshipBtoList =
      input?.patientRelationshipDtoList?.map((item) => ({
        ...item,
        id:
          typeof item?.id === 'string' && item?.id?.includes('create_')
            ? undefined
            : item?.id,
        patientId: fullInfo?.id,
      })); // List<PatientRelationshipBto> 患者亲属关系信息列表
    output.btoParam.patientIdentificationBtoList =
      input?.patientIdentificationDtoList?.map((item) => ({
        ...item,
        patientId: fullInfo?.id,
      })); // List<PatientIdentificationBto> 患者身份识别信息列表
    output.btoParam.patientProfileBto = {};
    output.btoParam.patientProfileBto.citizenship = input?.citizenship; // String 国籍
    output.btoParam.patientProfileBto.nation = input?.nation; // String 民族
    output.btoParam.patientProfileBto.marriageStatus = input?.marriageStatus; // String 婚姻状态
    output.btoParam.patientProfileBto.nativeAddress = {};
    output.btoParam.patientProfileBto.nativeAddress.province =
      input?.nativeAddress?.province; // String 省
    output.btoParam.patientProfileBto.nativeAddress.city =
      input?.nativeAddress?.city; // String 市
    output.btoParam.patientProfileBto.nativeAddress.county =
      input?.nativeAddress?.county; // String 区
    output.btoParam.patientProfileBto.nativeAddress.detail =
      input?.nativeAddress?.detail; // String 详细地址
    output.btoParam.patientProfileBto.nativeAddress.code =
      input?.nativeAddress?.code; // String 地区编码
    output.btoParam.patientProfileBto.nativeAddress.postcode =
      input?.nativeAddress?.postcode; // String 邮编
    output.btoParam.patientProfileBto.email = input?.email; // String 电子邮件
    output.btoParam.patientProfileBto.currentAddress = {};
    output.btoParam.patientProfileBto.currentAddress.province =
      input?.currentAddress?.province; // String 省
    output.btoParam.patientProfileBto.currentAddress.city =
      input?.currentAddress?.city; // String 市
    output.btoParam.patientProfileBto.currentAddress.county =
      input?.currentAddress?.county; // String 区
    output.btoParam.patientProfileBto.currentAddress.detail =
      input?.currentAddress?.detail; // String 详细地址
    output.btoParam.patientProfileBto.currentAddress.code =
      input?.currentAddress?.code; // String 地区编码
    output.btoParam.patientProfileBto.currentAddress.postcode =
      input?.currentAddress?.postcode; // String 邮编
    // output.btoParam.patientProfileBto.workUnit = {};
    // output.btoParam.patientProfileBto.workUnit.name = input?.; // String 单位名称
    // output.btoParam.patientProfileBto.workUnit.phoneNumber = input?.; // String 单位联系电话
    // output.btoParam.patientProfileBto.workUnit.address = {};
    // output.btoParam.patientProfileBto.workUnit.address.province = input?.; // String 省
    // output.btoParam.patientProfileBto.workUnit.address.city = input?.; // String 市
    // output.btoParam.patientProfileBto.workUnit.address.county = input?.; // String 区
    // output.btoParam.patientProfileBto.workUnit.address.detail = input?.; // String 详细地址
    // output.btoParam.patientProfileBto.workUnit.address.code = input?.; // String 地区编码
    // output.btoParam.patientProfileBto.workUnit.address.postcode = input?.; // String 邮编
    // output.btoParam.patientProfileBto.unitInContract = input?.; // String 合同单位
    output.btoParam.patientProfileBto.contact = {};
    output.btoParam.patientProfileBto.contact.name = input?.contanctName; // String 联系人姓名
    output.btoParam.patientProfileBto.contact.relationshipType =
      input?.contactRelationshipType; // String 联系人关系类型
    output.btoParam.patientProfileBto.contact.phoneNumber =
      input?.contactPhoneNumber; // String 联系人的联系电话
    // output.btoParam.patientProfileBto.creator = input?.; // String 创建人
    // output.btoParam.patientProfileBto.operator = input?.; // String 操作员
    output.btoParam.patientProfileBto.wechatAccount = input?.wechatAccount; // String 微信号
    output.btoParam.patientProfileBto.alipayAccount = input?.alipayAccount; // String 支付宝账号
    output.btoParam.unknownIndicator = input?.unknownIndicator; // Boolean 无名患者标识
    output.btoParam.bloodCardIndicator = input?.bloodCardIndicator; // Boolean 献血证标识
    output.btoParam.disabilityIndicator = input?.disabilityIndicator; // Boolean 残疾人证标识
    output.btoParam.vipIndicator = input?.vipIndicator; // Boolean VIP标识

    output.extApiParam = {};
    return output;
  };

  const convertVSPromiseTablePatientMainSearchQro2QueryPagedByPatientFullQtoReq =
    (input) => {
      const _ = window._ ?? {};
      const output = {};
      output.qto = {};
      const search = input.search;
      output.qto.displayIdIs = `${_.get(search, 'displayIdIs')}`?.length
        ? _.get(search, 'displayIdIs')
        : undefined;
      output.qto.nameLike = `${_.get(search, 'nameLike')}`?.length
        ? _.get(search, 'nameLike')
        : undefined;
      output.qto.nameInputCodeLike = `${_.get(search, 'nameInputCodeLike')}`
        ?.length
        ? _.get(search, 'nameInputCodeLike')
        : undefined;
      output.qto.genderIs = _.get(search, 'genderIs');
      output.qto.cellphoneIs = `${_.get(search, 'cellphoneIs')}`?.length
        ? _.get(search, 'cellphoneIs')
        : undefined;
      const birthdayBegin = _.get(search, 'birthdayRangeIn');
      const birthdayEnd = _.get(search, 'birthdayRangeIn');
      if (birthdayBegin || birthdayEnd) {
        output.qto.birthdayRangeIn = {};
        output.qto.birthdayRangeIn.begin = birthdayBegin
          ? dayjs(
              dayjs(birthdayBegin)?.format('YYYY-MM-DD') + ' 00:00:00',
            )?.valueOf()
          : undefined;
        output.qto.birthdayRangeIn.end = birthdayEnd
          ? dayjs(
              dayjs(birthdayEnd)?.format('YYYY-MM-DD') + ' 23:59:59',
            )?.valueOf()
          : undefined;
        output.qto.birthdayRangeIn.beginInclude = true;
        output.qto.birthdayRangeIn.endInclude = false;
      }
      const ageBegin = _.get(search, 'ageRangeIn.begin');
      const ageEnd = _.get(search, 'ageRangeIn.end');
      if (ageBegin || ageEnd) {
        output.qto.ageRangeIn = {};
        output.qto.ageRangeIn.begin = ageBegin;
        output.qto.ageRangeIn.end = ageEnd;
        output.qto.ageRangeIn.beginInclude = _.get(
          search,
          'ageRangeIn.beginInclude',
        );
        output.qto.ageRangeIn.endInclude = _.get(
          search,
          'ageRangeIn.endInclude',
        );
      }
      if (input?.search?.ident?.identificationClassIn === '患者ID') {
        output.qto.displayIdIs = input?.search?.ident?.identificationNumberIn;
        output.qto.identificationClassIn = undefined;
        output.qto.identificationNumberIn = undefined;
      } else {
        output.qto.identificationClassIn = input?.search?.ident
          ?.identificationClassIn
          ? [input?.search?.ident?.identificationClassIn]
          : undefined;
        output.qto.identificationNumberIn = input?.search?.ident
          ?.identificationNumberIn
          ? [input?.search?.ident?.identificationNumberIn]
          : undefined;
      }
      output.qto.size = input.pagination?.size ?? 20;
      output.qto.from = input.pagination?.from ?? 0;
      output.ext = {};
      return output;
    };

  const convertQueryPagedByPatientFullQtoRes2VSPromiseTablePatientMainSearchRo =
    (input) => {
      const convertResult = input?.map((item) => ({
        id: item?.id,
        displayId: item?.displayId,
        name: item?.name,
        cellphone: item?.cellphone,
        gender: item?.gender,
        birthday: item?.birthday,
        age: item?.age,
        birthAddress: {
          province: item?.birthAddress?.province ?? '',
          city: item?.birthAddress?.city ?? '',
          county: item?.birthAddress?.county ?? '',
          district: item?.birthAddress?.county ?? '',
          detail: item?.birthAddress?.detail ?? '',
        },
        currentAddress: {
          province: item?.patientProfileDto?.currentAddress?.province ?? '',
          city: item?.patientProfileDto?.currentAddress?.city ?? '',
          county: item?.patientProfileDto?.currentAddress?.county ?? '',
          district: item?.patientProfileDto?.currentAddress?.county ?? '',
          detail: item?.patientProfileDto?.currentAddress?.detail ?? '',
        },
        contactName: item?.patientProfileDto?.contact?.name ?? '',
        contactPhoneNumber: item?.patientProfileDto?.contact?.phoneNumber ?? '',
        contactRelationshipType:
          item?.patientProfileDto?.contact?.relationshipType ?? '',
        patientMergeWithProfileDtoList:
          item?.patientMergeWithProfileDtoList ?? [],
        isMerged: item?.isMerged,
      }));
      return convertResult;
    };

  const onFetch = useCallback(async (params) => {
    try {
      if (!firstFetch.current) {
        // Tips: 函数入参包含表格的顶部查询表单结果（平铺）、通过表格extraParams属性传入的额外参数(extra)、分页信息(pagination)、排序结果(orderList)、搜索结果(search)、过滤结果(filters)
        // TODO: 你需要自己补充入参input来源及出参output用法
        console.debug(params);
        const output =
          convertVSPromiseTablePatientMainSearchQro2QueryPagedByPatientFullQtoReq(
            params,
          ); // Tips: 以下是你选择的请求函数
        console.debug(output);
        const res =
          await vsf.services?.PatientMainController_queryPagedByPatientFullQto_1c8a93?.(
            output,
          ); // Tips: 需要返回 { data: 表格的数据列表，数据类型需要和表格RO一致; total: 数据的总个数 }
        // TODO: 你需要自己补充入参input来源及出参output用法
        const customResult =
          convertQueryPagedByPatientFullQtoRes2VSPromiseTablePatientMainSearchRo(
            res?.data?.result ?? [],
          );

        const originData = (customResult ?? res?.data ?? [])?.map(
          (item, _idx) => ({ ...item, customIndex: _idx + 1 }),
        );
        const realData = cloneDeep(originData);
        originData?.forEach((item, _idx) => {
          if (item?.patientMergeWithProfileDtoList?.length) {
            realData.splice(
              realData?.findIndex((v) => v?.id === item?.id) + 1,
              1,
              ...(convertQueryPagedByPatientFullQtoRes2VSPromiseTablePatientMainSearchRo(
                item?.patientMergeWithProfileDtoList?.map((v) => ({
                  ...v?.patientRelated,
                  isMerged: true,
                })),
              ) ?? []),
            );
          }
        });
        return {
          data: (realData ?? [])?.map((item) => ({
            ...item,
            rowKey: Math.random(),
          })),
          total: res?.data?.count ?? res?.data?.length ?? 0,
        };
      }
      firstFetch.current = false;
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handelIdentChange = (e) => {
    insuranceTypeIsRef.current = e.code;
  };

  const handelRowClick = async (record) => {
    const response =
      await vsf?.services?.PatientMainController_getFullInfoById_6ee318?.({
        id: record?.id,
      });
    if (response && response.code === 200) {
      setFullInfo(response?.data);
      setModalVisible(true);
    }
  };

  const handelSubmit = async () => {
    const params = convertVSFormPatientFullInfo2CreateOrUpdatePatientReq(
      await vsf?.refs?.vSFormPatientFullInfo?.validateFieldsReturnFormatValue?.(),
    );
    // Tips: 函数入参为表单数据，格式和RO一致
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.PatientMainController_createOrUpdatePatient_d8b932?.(
        params,
      ); // Tips: 需要返回提交的完整数据，和表单RO一致
    if (res && res.code === 200) {
      message.success('保存成功');
      handelCancel();
    }
    return params;
  };

  const handelCancel = () => {
    if (!showTable) {
      handelmodal?.(false);
    }
    setModalVisible(false);
  };

  const handelClearScreen = async () => {
    setFullInfo(undefined);
    vsf.refs.vSFormPatientFullInfo?.resetFields();
    // const response =
    //   await vsf?.services?.PatientMainController_getFullInfoById_6ee318?.({
    //     id: fullInfo?.id,
    //   });
    // if (response && response.code === 200) {
    //   setFullInfo(response?.data);
    message.success('清屏成功');
    // }
  };

  const renderModalTitle = () => {
    return (
      <div className="patient_search_modal_header">
        <span>患者ID：</span>
        <span>{fullInfo?.displayId}</span>
        <div className="people">
          <Icon type="icon-guanlian" size={18} />
        </div>
      </div>
    );
  };

  const handelActivityMenuChange = (key) => {
    const dom = document.getElementById('PatientFullInfoMain');
    console.log(dom, key);
    if (dom) {
      const moduleDom = document.getElementById(key);
      console.log(key, moduleDom);
      if (moduleDom) {
        // 74 = Header高度 58 + marginBottom 16
        dom.scrollTop = moduleDom?.offsetTop - 74;
        moduleDom.style.display = 'block';
      }
    }
  };

  return (
    <>
      {showTable && (
        <div className="patient_main_search_table">
          <VSPromiseTable
            vsid="92658"
            scroll={{
              x: 1700,
              y: 450,
            }}
            // className="aspirin-table patient_main_search"
            className="aspirin-table patient_main_search   
          aspirin-table-header-background-color
          aspirin-table-row-hover-background-color
          aspirin-table-body-empty-transparent-background-color table_overflow_576"
            id="vSPromiseTablePatientMainSearch"
            rowKey={'rowKey'}
            searchConfig={{
              // labelWidth: 120,
              labelWidth: 120,
              labelAlign: 'left',
              span: 6,
              // searchGutter: 80,
              searchButtonProps: {
                size: 'large',
                className: 'icon16',
              },

              resetButtonProps: {
                size: 'large',
                className: 'icon16',
                onClick: () => {
                  insuranceTypeIsRef.current = null;
                  vsf?.refs?.vSPromiseTablePatientMainSearch
                    ?.getSearchForm()
                    ?.resetFields();
                  vsf?.refs?.vSPromiseTablePatientMainSearch?.reload();
                },
              },
            }}
            onFetch={onFetch}
            rowClick={{
              rowClickType: {
                action: 'double-click',
              },
              onRowClick: handelRowClick,
            }}
            rowClassName={(record) => (record?.isMerged ? 'merge_row' : '')}
          >
            <VSTableColumn
              width={60}
              dataIndex={['customIndex']}
              title="序号"
              valueType="text"
            />

            <VSTableColumn
              width={180}
              dataIndex={['displayId']}
              title="患者ID"
              valueType="text"
              search
              order={-5}
              columnKey={'displayIdIs'}
              formItemProps={{
                rules: [
                  {
                    message: '用于显示的id长度不合法',
                    type: 'string',
                    min: 0,
                    max: 40,
                  },
                ],
              }}
              fieldProps={{}}
              render={(dom, record) => {
                return (
                  <div style={{ display: 'flex' }}>
                    {dom}
                    {record?.patientMergeWithProfileDtoList?.length ? (
                      <div style={{ color: '#3F76C8' }}>
                        <Icon type="icon-chengzulianjie" size={16} />
                      </div>
                    ) : null}
                  </div>
                );
              }}
            />

            <VSTableColumn
              width={140}
              dataIndex={['name']}
              title="姓名"
              valueType="text"
              search
              order={-2}
              columnKey={'nameLike'}
              formItemProps={{
                rules: [
                  {
                    message: '姓名长度不合法',
                    type: 'string',
                    min: 0,
                    max: 60,
                  },
                ],
                label: '姓名',
              }}
              fieldProps={{}}
            />

            <VSTableColumn
              width={154}
              dataIndex={['cellphone']}
              title="手机号"
              valueType="text"
              search
              order={-6}
              columnKey={'cellphoneIs'}
              formItemProps={{
                rules: [
                  {
                    message: '数据格式错误',
                    type: 'string',
                    pattern: /^(?:\+?86)?1[3-9]\d{9}$/,
                  },
                ],
                label: '手机号',
              }}
              fieldProps={{}}
            />

            <VSTableColumn
              width={80}
              dataIndex={['gender']}
              title="性别"
              valueType="select"
              search
              order={-4}
              columnKey={'genderIs'}
              dataSource={vsf.stores.dbenums.enums.SEX_DICT}
              fieldProps={{}}
              formItemProps={{ label: '性别' }}
            />

            <VSTableColumn
              width={124}
              dataIndex={['birthday']}
              title="出生日期"
              valueType="date"
              search
              order={-7}
              columnKey={'birthdayRangeIn'}
              fieldProps={{ format: 'YYYY-MM-DD' }}
              formItemProps={{}}
            />

            <VSTableColumn
              width={100}
              dataIndex={['age']}
              title="年龄"
              valueType="text"
              fieldProps={{}}
            />

            <VSTableColumn
              width={240}
              dataIndex={['birthAddress']}
              title="出生地"
              valueType="address"
              fieldProps={{}}
            />

            <VSTableColumn
              width={300}
              dataIndex={['currentAddress']}
              title="现住址"
              valueType="address"
              fieldProps={{}}
              formItemProps={{}}
            />

            <VSTableColumn
              width={108}
              dataIndex={['contactName']}
              title="联系人"
              valueType="text"
              formItemProps={{
                rules: [
                  {
                    message: '联系人姓名长度不合法',
                    type: 'string',
                    min: 0,
                    max: 60,
                  },
                ],
                label: undefined,
              }}
              fieldProps={{}}
            />

            <VSTableColumn
              width={154}
              dataIndex={['contactPhoneNumber']}
              title="联系人电话"
              valueType="text"
              formItemProps={{
                rules: [
                  {
                    message: '联系人的联系电话长度不合法',
                    type: 'string',
                    min: 0,
                    max: 40,
                  },
                ],
                label: undefined,
              }}
              fieldProps={{}}
            />

            <VSTableColumn
              width={86}
              dataIndex={['contactRelationshipType']}
              title="关系"
              valueType="select"
              dataSource={vsf.stores.dbenums.enums.RELATIONSHIP_DICT}
              fieldProps={{}}
              formItemProps={{}}
            />

            <VSTableColumn
              search
              hideInTable
              dataIndex={['ident']}
              title="证件及编号"
              valueType="certificateSearch"
              formItemProps={{
                style: {
                  // width: 644,
                },
                className: isMinScreen ? 'width918' : 'width934',
              }}
              fieldProps={{
                selectProps: {
                  className: isMinScreen ? 'width266' : 'width340',
                },
              }}
            />

            {/* <VSFormItem
        name={['ident']}
        label="证件及编号"
        valueType="text"
        fieldProps={{
          style: { paddingLeft: '10px' },
          addonBefore: (
            <>
              <Select
                style={{ width: '200px' }}
                dataSource={certificateData}
                fieldNames={{ label: 'name', value: 'id' }}
              />
            </>
          ),
          suffix: (
            <div
              className="suffix-readCard"
              onClick={() => {
                message.success('读卡中');
              }}
            >
              <div className="suffix-cardImg">
                <Image
                  src={readCard}
                  style={{ width: '20px', height: '20px' }}
                  preview={false}
                ></Image>
              </div>
              <div className="suffix-cardText">读卡</div>
            </div>
          ),
          placeholder: '请读卡',
        }}
      /> */}

            {/* <VSTableColumn
        dataIndex={['identificationClassIn']}
        title="证件类型"
        valueType="select"
        search
        order={0}
        columnKey={'identificationClassIn'}
        hideInTable
        searchFieldProps={{ mode: 'multiple' }}
        searchConfig={{
          transform: (values) => ({
            identificationClassIn: !values?.length ? null : values,
          }),
        }}
        fieldProps={{}}
      />

      <VSTableColumn
        dataIndex={['identificationNumberIn']}
        title="证件编码"
        valueType="text"
        search
        order={-1}
        columnKey={'identificationNumberIn'}
        hideInTable
        searchFieldProps={{ mode: 'multiple' }}
        searchConfig={{
          transform: (values) => ({
            identificationNumberIn: !values?.length ? null : values,
          }),
        }}
        fieldProps={{}}
      /> */}

            <VSTableColumn
              dataIndex={['nameInputCodeLike']}
              title="姓名拼音"
              valueType="text"
              search
              order={-3}
              columnKey={'nameInputCodeLike'}
              hideInTable
              formItemProps={{
                rules: [
                  {
                    message: '姓名拼音长度不合法',
                    type: 'string',
                    min: 0,
                    max: 200,
                  },
                ],
              }}
              fieldProps={{}}
            />

            <VSTableColumn
              search
              order={-8}
              hideInTable
              columnKey={'ageRangeIn'}
              valueType="digitRange"
              title="年龄"
              fieldProps={{
                inputNumberStyle: {
                  flex: 1,
                },
                containerStyle: {
                  display: 'flex',
                  alignItems: 'center',
                },
              }}
              searchConfig={{
                transform: (values) => {
                  if (!Array.isArray(values)) return values;
                  return {
                    ageRangeIn: {
                      begin: values[0],
                      end: values[1],
                      beginInclude: true,
                      endInclude: true,
                    },
                  };
                },
              }}
            />

            {/* <VSTableColumn
        dataIndex={['ageRangeIn']}
        title="年龄"
        valueType="digitRange"
        transform={(values) => {
          if (!Array.isArray(values)) return values;
          return ['ageRangeIn'].reduceRight(
            (acc, curr) => ({ [curr]: acc }),
            {
              begin: values[0],
              end: values[1],
              beginInclude: true,
              endInclude: true,
            },
          );
        }}
        convertValue={(obj) => {
          if (Array.isArray(obj)) return obj;
          return [obj?.begin, obj?.end];
        }}
        fieldProps={{}}
      /> */}
          </VSPromiseTable>
        </div>
      )}
      {showTable && (
        <Modal
          open={modalVisible}
          width={'90%'}
          centered
          bodyStyle={{
            height: 450,
            background: '#F4F5F7',
            display: 'flex',
            padding: '0',
          }}
          title={renderModalTitle()}
          footer={
            <div className="patient_search_modal_header_footer">
              <div className="normal" onClick={handelCancel}>
                取消
              </div>
              <div className="normal">打印条码</div>
              <div className="normal" onClick={handelClearScreen}>
                清屏
              </div>
              <div className="normal primary" onClick={handelSubmit}>
                保存
              </div>
            </div>
          }
          onCancel={() => handelCancel()}
        >
          <Block
            style={{
              padding: '0 24px',
              width: 0,
              flex: 1,
            }}
            mainStyle={{
              display: 'flex',
            }}
            scroll={{
              y: true,
            }}
          >
            <Menu
              isNeedFooter={false}
              onChange={handelActivityMenuChange}
              fullInfo={fullInfo}
            />
            <PatientFullInfo fullInfo={fullInfo} isNeedFooter={false} />
          </Block>
        </Modal>
      )}
      {/* 门诊信息栏点击头像展示 */}
      {!showTable && (
        <Drawer
          footer={
            <div className="patient_search_modal_header_footer">
              <div className="normal" onClick={handelCancel}>
                取消
              </div>
              <div className="normal">打印条码</div>
              <div className="normal" onClick={handelClearScreen}>
                清屏
              </div>
              <div className="normal primary" onClick={handelSubmit}>
                保存
              </div>
            </div>
          }
          onClose={handelCancel}
          open={true}
          placement="right"
          width={1600}
        >
          <Block
            style={{
              padding: '0 24px',
              // width: '100%',
              flex: 1,
              height: '100%',
            }}
            mainStyle={{
              display: 'flex',
            }}
            scroll={{
              y: true,
            }}
          >
            <Menu
              isNeedFooter={false}
              onChange={handelActivityMenuChange}
              fullInfo={fullInfo}
            />
            <PatientFullInfo fullInfo={fullInfo} isNeedFooter={false} />
          </Block>
        </Drawer>
      )}
    </>
  );
};
export default definePage(PatientMainSearch);
