// form
export const convertGenerateNewDischargeNoticeRes2DischargeNoticeEditForm = (
  input,
) => {
  const _ = window._ ?? {};
  const output = {};
  output.displayId = _.get(input, 'data.patientVo.displayId');
  output.bedLabel = _.get(input, 'data.patientInHospitalVo.bed.bedLabel');
  output.name = _.get(input, 'data.patientVo.name');
  output.gender = _.get(input, 'data.patientVo.gender');
  output.admissionDateTime = _.get(input, 'data.inpVisitVo.admissionDateTime');
  output.dischargeDateExpected = _.get(
    input,
    'data.inpPreDischargePatientVo.dischargeDateExpected',
  );
  output.dischargeWay = _.get(
    input,
    'data.inpPreDischargePatientVo.dischargeWay',
  );
  output.diagnosisRecordWithDiagnosisVoList = _.get(
    input,
    'data.diagnosisRecordWithDiagnosisVoList',
  );
  output.id = _.get(input, 'data.inpPreDischargePatientVo.id');
  output.wardNotifyStatus = _.get(
    input,
    'data.inpPreDischargePatientVo.wardNotifyStatus',
  );

  return output;
};
// 保存
export const convertDischargeNoticeEditForm2DischargeNoticeReq = (input) => {
  console.log(input);
  const _ = window._ ?? {};
  const output = {};
  output.createInpPreDischargePatientBto = {};
  output.createInpPreDischargePatientBto.id = _.get(input, 'id');
  output.createInpPreDischargePatientBto.dischargeDateExpected = _.get(
    input,
    'dischargeDateExpected',
  );
  output.createInpPreDischargePatientBto.dischargeWay = _.get(
    input,
    'dischargeWay',
  );
  output.createInpPreDischargePatientBto.inpVisitId = _.get(
    input,
    'inpVisitId',
  );
  output.createInpPreDischargePatientBto.patientId = _.get(input, 'patientId');
  return output;
};
// 查询诊断(回显)
export const convertQueryAllByDiagnosisRecordWithDiagnosisQtoRes2DiagnosisRecordList =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj = {};
        obj.id = _.get(d, 'id');
        obj.diagnosisType = _.get(d, 'diagnosisType');
        obj.diagnosisDescription = _.get(d, 'diagnosisDescription'); // TODO 请确认下行字段是否符合逻辑
        obj.diagnosisId = _.get(d, 'id'); // TODO 请确认下行字段是否符合逻辑
        obj.diagnosisName = _.get(d, 'diagnosisDescription');
        obj.inpVisitId = _.get(d, 'inpVisitId');
        obj.patientId = _.get(d, 'patientId');
        obj.diagnosisCode = _.get(d, 'diagnosis.diagnosisCode');
        return obj;
      }) ?? []),
    );
    return output;
  };
// 保存诊断
export const convertDiagnosisRecordList2SaveDiagnosisRecordListReq = (
  input,
) => {
  const _ = window._ ?? {};
  const output = {};
  output.btoParamList = [];
  output.btoParamList.push(
    ...(input?.map((d, i) => {
      const obj = {};
      // obj.id = _.get(d, `id`);
      obj.patientId = _.get(d, `patientId`); // Long 患者标识
      obj.diagnosisType = _.get(d, `diagnosisType`);
      obj.diagnosisDescription = _.get(d, `diagnosisName`);
      obj.diagnosisId = _.get(d, `diagnosisId`); // Long 诊断代码
      obj.inpVisitId = _.get(d, `inpVisitId`);
      return obj;
    }) ?? []),
  );
  output.extApiParam = {};
  return output;
};
