import './index.less';

import vsf, { definePage, renderRoutes, RouteConfig } from '@vs/vsf-boot';
import { Input, Layout, Menu } from '@vs/vsf-kit';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { getPrefixCls } from '@/config';
import Icon from '@/module/Icon';
import { useHorizontalScroll } from '@/utils/hooks';

// import Header from './header';
import LeftSider from './sider';

const { Sider } = Layout;

export const items = [
  {
    label: '基础平台',
    key: 'basic',
    children: [
      {
        label: '药房采购计划(西药)',
        key: 'DrugStoragePurchasePlanCreate',
        path: '/index/drug-storage-purchase-plan-create',
      },
      {
        label: '功能模块',
        key: 'function',
        path: '/index/function',
      },
      {
        label: '医疗机构',
        key: 'institution',
        path: '/index/institution',
      },
      {
        label: '科室相关',
        key: 'department',
        path: '/index/department',
      },
      {
        label: '应用参数',
        key: 'application-parameter',
        path: '/index/application-parameter',
      },
      {
        label: '系统参数',
        key: 'system-parameter',
        path: '/index/system-parameter',
      },
      {
        label: '人员字典',
        key: 'staff',
        path: '/index/staff',
      },
      // {
      //   label: '码表字典',
      //   key: 'stopwatch',
      //   path: '/index/stopwatch',
      // },
      {
        label: '标准工作站',
        key: 'standard-application',
        path: '/index/standard-application',
      },
      {
        label: '业务工作站',
        key: 'business-application',
        path: '/index/business-application',
      },

      {
        label: '医生信息',
        key: 'doctor-information',
        path: '/index/doctor-information',
      },
      {
        label: '主诊组',
        key: 'patient-group',
        path: '/index/patient-group',
      },
      {
        label: '人员签名',
        key: 'staff-signature',
        path: '/index/staff-signature',
      },
      {
        label: '入院准备中心-入院登记',
        key: 'register-information',
        path: '/index/register-information',
      },
      {
        label: '护士站床卡',
        key: 'bed-card',
        path: '/index/bed-card',
      },
      {
        label: '医生站床卡',
        key: 'doctor-station-bed-card',
        path: '/index/doctor-station-bed-card',
      },
      {
        label: '病区护士入科',
        key: 'nurse-admission',
        path: '/index/nurse-admission',
      },
      {
        label: '码表维护',
        key: 'code-uphold',
        children: [
          {
            label: '码表类型维护',
            key: 'code-table-type',
            path: '/index/code-table-type',
          },
          {
            label: '码表字典维护',
            key: 'code-dict-uphold',
            path: '/index/stopwatch',
          },
          {
            label: '手术字典维护',
            key: 'code-dict-operationdictionary',
            path: '/index/operation_dictionary',
          },
        ],
      },
      {
        label: '住院医生站转科申请',
        key: 'doctor-station-transfer',
        path: '/index/doctor-station-transfer',
      },
      {
        label: '住院医生站出院',
        key: 'doctor-station-out-hospital',
        path: '/index/doctor-station-out-hospital',
      },
      {
        label: '病房护士站出院',
        key: 'nurse-station-out-hospital',
        path: '/index/nurse-station-out-hospital',
      },
      {
        label: '病房护士站床头卡打印',
        key: 'nurse-station-bedside-card-printing',
        path: '/index/nurse-station-bedside-card-printing',
      },
      {
        label: '取消入出转',
        key: 'nurse-cancel-operation',
        path: '/index/nurse-cancel-operation',
      },
    ],
  },
  {
    label: '财务医保',
    key: 'finance',
    children: [
      {
        label: '物价管理',
        key: 'priceControl',
        children: [
          {
            label: '价格项目维护',
            key: 'price',
            path: '/index/price',
          },
          {
            label: '价格项目复核',
            key: 'price-review',
            path: '/index/price-review',
          },
          {
            label: '检查项目维护',
            key: 'exam-item',
            path: '/index/exam-item',
          },
          {
            label: '病理项目维护',
            key: 'pathology-item',
            path: '/index/pathology-item',
          },
          {
            label: '治疗项目维护',
            key: 'treat-item',
            path: '/index/treat-item',
          },
          {
            label: '检验标本字典维护',
            key: 'specimen-item',
            path: '/index/specimen',
          },
          {
            label: '检验项目维护',
            key: 'lab-item',
            path: '/index/lab-item',
          },
          {
            label: '临床诊疗项目维护',
            key: 'clinic-item',
            path: '/index/clinic-item',
          },
          {
            label: '收费模板维护',
            key: 'price-pattern',
            path: '/index/price-pattern',
          },
          {
            label: '特殊收费方案维护',
            key: 'special-charge-scheme',
            path: '/index/special-charge-scheme',
            children: [
              {
                label: '收费方案维护',
                key: 'maintenance_charge_scheme',
                path: '/index/special-charge-scheme/maintenance_charge_scheme',
              },
              {
                label: '收费方案项目维护',
                key: 'maintenance_charge_project_scheme',
                path: '/index/special-charge-scheme/maintenance_charge_project_scheme',
              },
              {
                label: '收费方案名单维护',
                key: 'maintenance_charge_list_scheme',
                path: '/index/special-charge-scheme/maintenance_charge_list_scheme',
              },
            ],
          },
        ],
      },
      {
        label: '门诊号别排班',
        key: 'schedule',
        children: [
          // {
          //   label: '首页',
          //   key: 'dictionary',
          //   path: '/index/schedule-dictionary',
          // },
          {
            label: '号别管理',
            key: 'number',
            path: '/index/number',
          },
          {
            label: '字典维护',
            key: 'number-protect',
            path: '/index/number-protect',
          },
          {
            label: '号表排班计划',
            key: 'number-type',
            path: '/index/number-type',
          },
        ],
      },
      {
        label: '门诊挂号预约',
        key: 'register',
        children: [
          {
            label: '门诊挂号',
            key: 'outpatient-register',
            path: '/index/outpatient-registration',
          },
          {
            label: '门诊预约挂号',
            key: 'appointment-register',
            path: '/index/appointment-registration',
          },
          {
            label: '门诊退号',
            key: 'appointment-withdrawal',
            path: '/index/utpatient-withdrawal',
          },
          {
            label: '托收挂号',
            key: 'registration-for-collection',
            path: '/index/registrationForCollection',
          },
        ],
      },
      {
        label: '主索引管理',
        key: 'patient-main-manage',
        children: [
          {
            label: '主索引查询',
            key: 'patient-main-search',
            path: '/index/patient-main-search',
          },
          {
            label: '新建主索引',
            key: 'patient-main-add',
            path: '/index/patient-main-add',
          },
          {
            label: '主索引合并',
            key: 'patient-main-merge',
            path: '/index/patient-main-merge',
          },
          {
            label: '主索引合并日志',
            key: 'patient-main-merge-log',
            path: '/index/patient-main-merge-log',
          },
          {
            label: '主索引修改日志',
            key: 'patient-main-update-log',
            path: '/index/patient-main-update-log',
          },
        ],
      },
      {
        label: '门诊费用结算',
        key: 'settlement-outpatient-charges',
        children: [
          {
            label: '收据查询',
            key: 'receiptInquiry',
            path: '/index/receipt-inquiry',
          },
          {
            label: '门急诊退费',
            key: 'outpatientRefunds',
            path: '/index/outpatient-refunds',
          },
        ],
      },
      {
        label: '住院费用结算',
        key: 'settlement-inpatient-charges',
        children: [
          {
            label: '日清单打印',
            key: 'listPrint',
            path: '/index/list_print',
          },
          {
            label: '住院结算记录查询',
            key: 'settlemenRecordEnquiry',
            path: '/index/in_settlement_record_enquiry',
          },
        ],
      },
      {
        label: '门诊急收费',
        key: 'emergencyCharge',
        path: '/index/emergency-charge',
      },
      {
        label: '住院预交金',
        key: 'advancePayment',
        path: '/index/avance-payment',
      },
      {
        label: '病区日费用审核',
        key: 'day-fee-review',
        path: '/index/day-fee-review',
      },
      {
        label: '住院结算',
        key: 'hospitalization-settlement',
        path: '/index/hospitalization-settlement',
      },
      {
        label: '住院取消结算',
        key: 'cancel-hospitalization-settlement',
        path: '/index/cancel-hospitalization-settlement',
      },
      {
        label: '护士补退费',
        key: 'supplement-refund-billing',
        path: '/index/supplement-refund-billing',
      },
      {
        label: '门急诊医技科室计价',
        key: 'outp-medical-technology-charge',
        path: '/index/outp-medical-technology-charge',
      },
      {
        label: '医技科室计价费用授权及模板维护',
        key: 'medical-technology-charge-template',
        path: '/index/medical-technology-charge-template',
      },
      {
        label: '住院医技科室计价',
        key: 'inp-medical-technology-charge',
        path: '/index/inp-medical-technology-charge',
      },
      {
        label: '费用审核',
        key: 'billing-audit',
        path: '/index/billing-audit',
      },
      {
        label: '门急诊检查检验回退',
        key: 'check-check-rollback',
        path: '/index/Check-check-rollback',
      },
      {
        label: '医学证明',
        key: 'medical-certificate',
        path: '/index/medical-certificate',
      },
    ],
  },
  {
    label: '药事管理',
    key: 'drug',
    children: [
      {
        label: '药品基础数据维护',
        key: 'drugFoundationDictionary',
        children: [
          {
            label: '厂家字典维护',
            key: 'manufacturerDictionary',
            path: '/index/firmManage-firm',
          },
          {
            label: '供应商字典维护',
            key: 'supplierDictionary',
            path: '/index/firmManage-supplier',
          },
          {
            label: '药品字典维护',
            key: 'drugDictionary',
            path: '/index/firmManage-drug',
          },
        ],
      },
      {
        label: '药品库存管理',
        key: 'drugInventoryManage',
        children: [
          {
            label: '药品盘点分类字典',
            key: 'drugStocktaking',
            path: '/index/drugInventoryManage-stocktaking',
          },
          {
            label: '药品库存定义',
            key: 'drugStorageProfile',
            path: '/index/drugInventoryManage-profile',
          },
          {
            label: '药品库存拆分',
            key: 'drugStorageSplit',
            path: '/index/drugInventoryManage-split',
          },
          {
            label: '药品供应标识维护',
            key: 'drugStorageSupply',
            path: '/index/drugInventoryManage-supply',
          },
          {
            label: '药品规格管理',
            key: 'drugStorageSpecification',
            path: '/index/drugInventoryManage-specification',
          },
        ],
      },
      {
        label: '药品出入库管理',
        key: 'drugStorage',
        children: [
          {
            label: '子库房维护',
            key: 'drugSubStorageManage',
            path: '/index/drugStorage-drugSubStorageManage',
          },
          {
            label: '药库药房关系对照',
            key: 'relationship',
            path: '/index/maintenance-relationship',
          },
          {
            label: '药房出入库类型字典配置',
            key: 'dictionary',
            path: '/index/storage-dictionary',
          },
          {
            label: '药房出入库自定义关系配置',
            key: 'PharmacyCustom',
            path: '/index/pharmacy-custom',
          },
          {
            label: '药房药库入库',
            key: 'drugImport',
            path: '/index/drug-import',
          },
          {
            label: '药房药库出库',
            key: 'drugExport',
            path: '/index/drug-export',
          },
          {
            label: '取整规则配置',
            key: 'integer',
            path: '/index/integer-rule',
          },
          {
            label: '皮试带药维护',
            key: 'belt',
            path: '/index/belt-protection',
          },
          {
            label: '门诊西药房发药',
            key: 'dispenses',
            path: '/index/dispenses-medicine',
          },
          {
            label: '门诊中药房发药',
            key: 'centreDispenses',
            path: '/index/centre-dispenses-medicine',
          },
          {
            label: '药品库存申请',
            key: 'drugApply',
            path: '/index/drug-apply',
          },
          {
            label: '科室领药申请',
            key: 'deptDrugApply',
            path: '/index/dept-drug-apply',
          },
          {
            label: '门诊退药申请（西药）',
            key: 'refundDrugApply',
            path: '/index/refund-drug-apply',
          },
          {
            label: '门诊退药确认（西药）',
            key: 'DrugConfirmation',
            path: '/index/drug-confirmation',
          },
          {
            label: '门诊退药确认（中药）',
            key: 'DrugChineseConfirmation',
            path: '/index/drug-confirmation-chinese',
          },
          {
            label: '住院处方发药',
            key: 'InpatientPrescriptionDispensing',
            path: '/index/inpatient-prescription-dispensing',
          },
          {
            label: '出院带药发药',
            key: 'LeaveHospitalMedicine',
            path: '/index/leave-hospital-medicine',
          },
          {
            label: '住院中药发药',
            key: 'HerbPrescriptionDispense',
            path: '/index/herb-prescription-dispense',
          },
          {
            label: '西药退药确认（病区药房）',
            key: 'InpDrugRefundPerform',
            path: '/index/inp_drug_refund_perform',
          },
        ],
      },
      {
        label: '药品相关配置',
        key: 'drugRelatedConfigurations',
        children: [
          {
            label: '窗口字典维护',
            key: 'windowDictionaryMaintenance',
            path: '/index/window-dictionary-maintenance',
          },
        ],
      },
    ],
  },

  {
    label: '医嘱相关',
    key: 'order',
    children: [
      {
        label: '医嘱首页',
        key: 'order-center',
        path: '/index/cpoe/center',
      },
      {
        label: '医嘱校对',
        key: 'order-check',
        path: '/index/cpoe/order-check',
      },
      {
        label: '医嘱执行',
        key: 'order-execute',
        path: '/index/cpoe/order-execute',
      },
      {
        label: '概览首页',
        key: 'order-index',
        path: '/index/cpoe/index',
      },
      {
        label: '医嘱套餐维护',
        key: 'medical-order-template',
        path: '/index/cpoe/medical-order-template',
      },
      {
        label: '协定方套餐维护',
        key: 'medical-order-agreed-party-package',
        path: '/index/cpoe/medical-order-agreed-party-package',
      },
      {
        label: '开单常用',
        key: 'medical-order-common',
        path: '/index/cpoe/medical-order-common',
      },
      {
        label: '医嘱单一览',
        key: 'medical-order-show',
        path: '/index/cpoe/medical-orders-show',
      },
      {
        label: '病区执行/标签',
        key: 'medical-order-execute',
        path: '/index/cpoe/medical-order-execute',
      },
      {
        label: '系统执行单维护',
        key: 'system-execution-order-maintenance',
        path: '/index/cpoe/system-execution-order-maintenance',
      },
      {
        label: '出院带药执行单',
        key: 'discharge-medication-execution',
        path: '/index/cpoe/discharge-medication-execution',
      },
      {
        label: '药品接收',
        key: 'drug-reception',
        path: '/index/drug-reception',
      },
      {
        label: '住院医嘱药品复核',
        key: 'review-of-inpatient-medication-orders',
        path: '/index/review-of-inpatient-medication-orders',
      },
      {
        label: '精麻特殊药品执行登记',
        key: 'execution-registration-of-special-drugs-for-refined-and-toxic-substances',
        path: '/index/execution-registration-of-special-drugs-for-refined-and-toxic-substances',
      },
      {
        label: '住院医嘱摆药',
        key: 'medication-prescription-for-inpatient-medication-placement',
        path: '/index/medication-prescription-for-inpatient-medication-placement',
      },
      {
        label: '处方反退药(西药)',
        key: 'prescription-return-medication-drug',
        path: '/index/prescription-return-medication-drug',
      },
      {
        label: '处方反退药(中药)',
        key: 'prescription-return-medication-herb',
        path: '/index/prescription-return-medication-herb',
      },
      {
        label: '病区西药退药申请',
        key: 'application-for-western-medicine-refund-in-ward',
        path: '/index/application-for-western-medicine-refund-in-ward',
      },
      {
        label: '病区草药退药申请',
        key: 'application-for-returning-herbal-medicine-in-the-ward',
        path: '/index/application-for-returning-herbal-medicine-in-the-ward',
      },
      {
        label: '病区草药退药确认',
        key: 'application-inpHerb-refund-perform',
        path: '/index/application-inpHerb-refund-perform',
      },
    ],
  },
  {
    label: '主诉维护',
    key: 'maintenance',
    children: [
      {
        label: '主诉维护',
        key: 'maintenance-complaint',
        path: '/index/maintenance-complaint',
      },
    ],
  },
  {
    label: '急诊',
    key: 'emergency',
    children: [
      {
        label: '预检分诊',
        key: 'preview-triage',
        path: '/index/preview-triage',
      },
      {
        label: '突发事件批量分诊',
        key: 'batch_triage',
        path: '/index/batch_triage',
      },
      {
        label: '患者接收',
        key: 'patient_administration',
        path: '/index/patient_administrantion',
      },
      // 急诊患者转出
      {
        label: '患者转出',
        key: 'patient_transfer',
        path: '/index/patient_transfer',
      },
    ],
  },
  // {
  //   label: '床位管理',
  //   key: 'aaa',
  //   children: [
  //     {
  //       label: '床位等级价表对照',
  //       key: 'bed_level',
  //       path: '/index/bed_level',
  //     },
  //     {
  //       label: '全院床位维护',
  //       key: 'bed_maintenance',
  //       path: '/index/bed_maintenance',
  //     },
  //   ],
  // },
  // {
  //   label: '权限信息',
  //   key: 'SubMenu',
  //   children: [
  //     {
  //       type: 'group',
  //       label: '子group1',
  //       children: [
  //         {
  //           label: '测试1',
  //           key: 'setting:1',
  //         },
  //         {
  //           label: '测试2',
  //           key: 'setting:2',
  //         },
  //       ],
  //     },
  //     {
  //       type: 'group',
  //       label: '子group2',
  //       children: [
  //         {
  //           label: '测试3',
  //           key: 'setting:3',
  //         },
  //         {
  //           label: '测试4',
  //           key: 'setting:4',
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   label: '医嘱信息',
  //   key: 'mail2',
  // },
  // {
  //   label: '会诊信息',
  //   key: 'mail3',
  // },
  // {
  //   label: '临床路径',
  //   key: 'mail4',
  // },
  // {
  //   label: '辅助功能',
  //   key: 'mail5',
  // },
  // {
  //   label: '系统相关',
  //   key: 'mail6',
  // },
];

const Trigger = (props: { collapsed?: boolean }) => {
  const { collapsed } = props;
  const indexCls = getPrefixCls('layout-index-sider-trigger');
  return (
    <div className={indexCls}>
      <Icon type={collapsed ? 'icon-you' : 'icon-zuo'} size={20} />
    </div>
  );
};

type ContainerProps = {
  route: RouteConfig;
  location: Location;
};
const Container = (props) => {
  const { route, stores } = props;
  const { common, dbenums } = stores || {};
  const { currentFunctionList, addFunction } = common || {};
  const menuRef = useRef<any>();
  useHorizontalScroll(menuRef);

  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const [currentItems, setCurrentItems] = useState<any[]>();

  const indexLayoutCls = getPrefixCls('layout-index');

  const findData = (trees, condition) => {
    for (let i = 0; i < trees.length; i++) {
      const result = findNodeData(trees[i], condition);
      if (result) {
        return result;
      }
    }

    // 未找到满足条件的数据
    return null;
  };

  const findNodeData = (tree, condition) => {
    // 遍历当前节点
    if (condition(tree)) {
      return tree;
    }

    // 遍历子节点
    if (tree.children) {
      for (let i = 0; i < tree.children.length; i++) {
        const result = findNodeData(tree.children[i], condition);
        if (result) {
          return result;
        }
      }
    }

    // 未找到满足条件的数据
    return null;
  };

  const onClick = (v) => {
    const target = findData(currentItems, (item) => item?.key === v?.key);
    if (target?.function) {
      addFunction(target?.function);
    }
    if (target.path || target?.function?.source) {
      vsf.navigateTo(target.path ?? target?.function?.source);
    }
  };

  const onSelect = ({ item, key, keyPath, selectedKeys, domEvent }) => {
    setSelectedKeys(selectedKeys);
    localStorage.setItem('selectedKeys', selectedKeys);
  };

  useEffect(() => {
    const target: string = props?.history?.location?.pathname;
    // const value = target?.split('/');
    // setSelectedKeys([value.at(-1) ?? '']);
    const [id] =
      currentItems?.filter((item) => {
        for (const i of item?.children) {
          if (i.key === target) {
            return true;
          } else {
            continue;
          }
        }
      }) ?? [];
    // console.log(id, 'id');
    if (id) {
      setSelectedKeys([id.key]);
    }
    // localStorage.getItem('selectedKeys') &&
    //   setSelectedKeys([localStorage.getItem('selectedKeys') || '']);
  }, [currentItems, props?.history?.location?.pathname]);

  const handleSetFunctionList = useCallback(() => {
    const target = (currentFunctionList ?? [])?.map((item) => {
      return {
        label: item?.name,
        key: `${item?.id}`,
        children: item?.applicationFunctionVsGroupWithFunctionDtoList?.map(
          (cItem) => {
            return {
              label: cItem?.function?.name,
              // key: `${cItem?.function?.id}`,
              key: cItem?.function?.source,
              path: cItem?.function?.source,
              function: cItem?.function,
            };
          },
        ),
        origin: item,
      };
    });
    setCurrentItems(target);
  }, [currentFunctionList]);

  useEffect(() => {
    if (currentFunctionList && currentFunctionList?.length > 0) {
      handleSetFunctionList();
    } else {
      setCurrentItems(items);
    }
  }, [currentFunctionList, handleSetFunctionList]);

  if (!dbenums?.enums || !Object.keys(dbenums?.enums)?.length) return null;
  // console.log(currentItems);

  return (
    <div className={indexLayoutCls}>
      <div className={`${indexLayoutCls}-header`}>
        <div className="bg"></div>
        <div className={`${indexLayoutCls}-header-menu noscroll`} ref={menuRef}>
          <Menu
            onClick={onClick}
            selectedKeys={selectedKeys}
            onSelect={onSelect}
            mode="horizontal"
            items={currentItems}
          />
        </div>
        {/* <div className="search">
          <Input
            placeholder="请输入患者名称"
            prefix={<Icon type="icon-sousuo" size={22} />}
          />
        </div> */}
      </div>
      <div className={`${indexLayoutCls}-content`}>
        <Sider
          className={`${indexLayoutCls}-sider`}
          theme="light"
          collapsible={false}
          width={98}
          collapsedWidth={98}
          // collapsed={collapsed}
          // onCollapse={(value) => setCollapsed(value)}
          // trigger={<Trigger collapsed={collapsed} />}
        >
          <LeftSider collapsed={true} />
        </Sider>
        <div className={`${indexLayoutCls}-main`}>
          <div className="filter-1"></div>
          <div className="filter-2"></div>
          <div className={`${indexLayoutCls}-main-container`}>
            {renderRoutes(route.routes, route.redirect)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default definePage(Container);
