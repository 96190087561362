import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import cn from 'classnames';
import React, { useState } from 'react';

import Icon from '@/module/cpoe/Icon';
import storage from '@/utils/storage';

type OwnProps = {
  fullInfo?: any | null;

  onChange?: (key: string) => void;
};

export enum MENUKEYENUM {
  FOUNDATION = 'FOUNDATION',
  OTHER = 'OTHER',
  DOCUMENT = 'DOCUMENT',
  VIP = 'VIP',
  FAMILY = 'FAMILY',
  ADDRESS = 'ADDRESS',
}

const MENU = [
  {
    label: '基础信息',
    key: MENUKEYENUM.FOUNDATION,
  },
  {
    label: '其他信息',
    key: MENUKEYENUM.OTHER,
  },
  {
    label: '证件信息',
    key: MENUKEYENUM.DOCUMENT,
  },
  // {
  //   label: 'VIP信息',
  //   key: MENUKEYENUM.VIP,
  // },
  {
    label: '家庭信息',
    key: MENUKEYENUM.FAMILY,
  },
  {
    label: '收货地址',
    key: MENUKEYENUM.ADDRESS,
  },
];

const Menu: React.FC<OwnProps> = (props: OwnProps) => {
  const { fullInfo, onChange } = props;

  const [activityKey, setActivityKey] = useState(MENUKEYENUM.FOUNDATION);

  const handelMenuItemClick = (key) => {
    setActivityKey(key);
    onChange?.(key);
  };

  return (
    <div className="patient_main_menu">
      <div className="info">
        <div></div>
        <div>
          <span>患者ID:</span>
          <p>{fullInfo?.displayId}</p>
          {fullInfo?.patientMergeWithProfileDtoList?.length ? (
            <div className="people">
              <Icon type="icon-guanlian" />
            </div>
          ) : null}
        </div>
      </div>
      <div className="menu">
        {MENU?.map((menu) => {
          return (
            <div
              key={menu.key}
              className={cn('item', {
                ['item-normal']: menu.key !== activityKey,
                ['item-active']: menu.key === activityKey,
              })}
              onClick={() => handelMenuItemClick(menu?.key)}
            >
              <span> {menu.label}</span>
              <div>
                <Icon type="icon-you" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default definePage(Menu);
