import {
  Image,
  message,
  Select,
  VSForm,
  VSFormLayout,
  VSFormItem,
  Button,
  Space,
  Modal,
  Form,
} from '@vs/vsf-kit';
import React, { useState } from 'react';
import readCard from '@/assets/icon/readCard.png';
import { useRequest, useUpdateEffect } from 'ahooks';
import dayjs from 'dayjs';
import { filterEmptyValues } from '@/utils/setDataUtil';
function InquiryForm({ callback, counterId }) {
  // 证件下拉框
  const { data: certificateData } = useRequest(async () => {
    const res =
      await vsf?.services?.OutpRegisterDefineController_getAllIdentificationClassDictionary_a2521d?.(
        {},
      );
    return res?.data;
  });

  const { run } = useRequest(
    async (id) => {
      const res =
        await vsf.services?.OutpSettleController_createZeroBillingDetail_33ddf8?.(
          {
            outpSettleMasterId: counterId?.id,
          },
        );
      if (res?.code === 200) {
        message.success('反交易成功');
      }
      return res?.code;
    },
    {
      manual: true,
    },
  );

  // 收款员下拉框
  const { data: receiveDatasorce } = useRequest(async () => {
    const res =
      await vsf?.services?.UserGroupController_getOutpChargeGroupMemberList_0cd8c3?.(
        {},
      );

    // console.log(
    //   res?.data?.map((item) => {
    //     return { value: item?.staff?.id, label: item?.staff?.staffName };
    //   }),
    // );
    return res?.data?.map((item) => {
      return { value: item?.staff?.id, label: item?.staff?.staffName };
    });
  });
  const [identificationClass, setIdentificationClass] = useState();

  // 查询
  const onClick = () => {
    const data = vsf?.refs?.ReceiptInquiryForm.getFieldsValue(true);

    if (identificationClass) {
      data.identificationClass = identificationClass;
    }

    console.log(identificationClass, 'table_identificationClass');
    console.log(data, 'table_data');
    console.log(filterEmptyValues(data), 'table_filterEmptyValues(data)');
    callback(filterEmptyValues(data));
  };

  const counter = () => {
    Modal.confirm({
      content: '确定反交易!',
      onOk: () => {
        run(123);
      },
    });
  };

  const viewDetails = () => {
    console.log(counterId);
    if (counterId?.clinicClass === 'CLINIC_REGISTER') {
      vsf?.navigateTo(
        `/index/utpatient-withdrawal?dispalyId=${counterId?.settleNumber}`,
      );
    } else {
      counterId &&
        vsf?.navigateTo(
          `/index/outpatient-refunds?settleNumber=${counterId?.settleNumber}`,
        );
    }
  };

  const [form] = Form.useForm();

  const displayIdIs = Form.useWatch(['displayIdIs'], form);
  const settleNumberIs = Form.useWatch(['settleNumberIs'], form);
  const identificationNumber = Form.useWatch(['identificationNumber'], form);
  const insuranceCardNumberIs = Form.useWatch(['insuranceCardNumberIs'], form);

  useUpdateEffect(() => {
    form.setFieldValue([['settleDateRangeIn']], void 0);
  }, [
    displayIdIs,
    settleNumberIs,
    identificationNumber,
    insuranceCardNumberIs,
  ]);

  return (
    <div>
      <VSForm labelAlign="left" id="ReceiptInquiryForm" form={form}>
        <VSFormLayout columnCount={4} labelWidth={100} gutter={40}>
          <VSFormItem
            name={['settleNumberIs']}
            label="票据号"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['displayIdIs']}
            label="患者ID"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['nameLike']}
            label="患者姓名"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['payTypeIs']}
            label="支付方式"
            valueType="select"
            fieldProps={{}}
            dataSource={[
              { label: '现金', value: 'CASH' },
              { label: '支付宝', value: 'ALIPAY' },
              { label: '微信', value: 'WECHAT' },
              { label: '数字人民币', value: 'DIGITAL_RMB' },
              { label: '聚合支付', value: 'INTEGRATION_PAYMENT' },
            ]}
          />
        </VSFormLayout>
        <VSFormLayout columnCount={4} labelWidth={100} gutter={40}>
          <VSFormItem
            name={['chargeTypeIs']}
            label="费别"
            valueType="select"
            dataSource={vsf.stores.dbenums.enums.CHARGE_TYPE_DICT}
            fieldProps={{}}
          />
          <VSFormItem
            name={['settleDateRangeIn']}
            label="收费日期"
            valueType="dateRange"
            transform={(values) => {
              if (values[0] === 'NaN' || values[1] === 'NaN') return null;
              return {
                createDateTimeRangeIn: {
                  begin: values[0],
                  end: values[1],
                  beginInclude: true,
                  endInclude: true,
                },
              };
            }}
            initialValue={[dayjs(), dayjs()]}
          />
          <VSFormItem
            name={['operatorStaffIdIs']}
            label="收款员"
            valueType="select"
            fieldProps={{}}
            dataSource={receiveDatasorce}
          />
          <VSFormItem
            name={['chargeStatusIs']}
            label="交费状态"
            valueType="select"
            dataSource={[
              { label: '收费', value: 'CHARGE' },
              { label: '退费', value: 'REFUND' },
              { label: '收费[已退]', value: 'CHARGE_REFUND' },
              { label: '收费[作废]', value: 'CANCEL' },
            ]}
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout columnCount={4} labelWidth={100} gutter={40}>
          <VSFormItem
            name={['settleTypeIs']}
            label="结算途径"
            valueType="select"
            fieldProps={{}}
            dataSource={[
              { label: '窗口', value: 'WINDOW' },
              { label: '自助机', value: 'SELF_MACHINE' },
              { label: '诊间结算', value: 'CLINIC_MIDDLE_SETTLE' },
              { label: '医保移动支付', value: 'INSURANCE_MOBILE_PAYMENT' },
              { label: '护士站结算', value: 'NURSE_STATION' },
              { label: '第三方订单', value: 'THIRD_PARTY_ORDER' },
              { label: '代煎收方', value: 'DECOCT' },
            ]}
          />
          <VSFormItem
            name={['totalCostIs']}
            label="总金额"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['totalChargeIs']}
            label="实收金额"
            valueType="text"
            fieldProps={{}}
          />
          <VSFormItem
            name={['insuranceCardNumberIs']}
            label="医保卡号"
            valueType="text"
            fieldProps={{}}
          />
        </VSFormLayout>
        <VSFormLayout columnCount={3} labelWidth={100} gutter={40}>
          <VSFormItem
            name={['identificationNumber']}
            label="证件及编号"
            valueType="text"
            fieldProps={{
              addonBefore: (
                <>
                  <Select
                    // style={{ width: '150px' }}
                    className='identificationNumber-select'
                    dataSource={certificateData}
                    fieldNames={{ label: 'name', value: 'code' }}
                    onChange={(v) => {
                      console.log(v, 'table_v');
                      setIdentificationClass(v.code);
                    }}
                  />
                </>
              ),

              suffix: (
                <div
                  className="suffix-readCard"
                  onClick={() => {
                    message.success('读卡中');
                  }}
                >
                  <div className="suffix-cardImg">
                    <Image
                      src={readCard}
                      // style={{ width: '20px', height: '20px' }}
                      className='suffix-cardImg-image '
                      preview={false}
                    ></Image>
                  </div>
                  <div className="suffix-cardText">读卡</div>
                </div>
              ),
              placeholder: '请读卡',
            }}
          />
          <VSFormItem
            name={['anesthesiaUsePurpose']}
            readonly
            valueType="text"
            fieldProps={{}}
          />
          <div style={{ textAlign: 'right' }}>
            <Space size={4}>
              <Button type="primary" children={'查询'} onClick={onClick} />
              <Button children="票据预览" />
              <Button children="单位发票" />
              <Button children="反交易" type="primary" onClick={counter} />
              <Button
                children="查看详情"
                type="primary"
                onClick={viewDetails}
              />
            </Space>
          </div>
        </VSFormLayout>
      </VSForm>
    </div>
  );
}

export default InquiryForm;
