import '../index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  DatePicker,
  Input,
  message,
  Section,
  Select,
  Space,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { CodeTableSelect } from '@/components';
import { isMinScreen } from '@/config';
import Container from '@/module/Container';
import { DateParticle } from '@/module/cpoe/medical/editor/core/draw/particle/DateParticle';
import { input } from '@/module/cpoe/medical/editor/core/event/handlers/input';
import Empty from '@/module/Empty';
import Loading from '@/module/Loading';
import SearchContainer from '@/module/Search';
import { Ellipsis } from '@/pages/Index/components';
import FormTitle from '@/pages/Index/components/form_title';
import { askIsInteger, askSearch } from '@/pages/Index/components/func/ask';
import TableRender from '@/pages/Index/components/func/TableRender';
import { positive, positiveMessage } from '@/pattern';
import { getScrollY, labelColWidth } from '@/utils';

import { calculateMews, cheifClassify, judgeLevel } from './calculate';

const PatientAssessment = (props) => {
  const { fallRiskRef, isRequired, bodyTemperatureRequired, handelColor } =
    props;
  const [data, setData] = useState<any>([]);
  const requiredRef = useRef<any>();
  const [chiefList, setChiefList] = useState<any>([]);
  const bodyTemperatureRequiredRef = useRef<any>();

  // console.log(bodyTemperatureRequired);

  useEffect(() => {
    const res = vsf.refs?.preExamMainForm?.getFieldsValue();
    // console.log(res, ' props?.list props?.list');

    if (res?.vitalsignStatus == 'WITHOUT_VITALSIGN' && data?.length > 0) {
      const _data = data.map((item) => {
        return item?.map((i) => {
          const a = i?.item?.value ?? i?.item?.code ?? i?.item;
          if (a == '06' || a == '04' || a == '14') {
            return { ...i, itemValue: 0, id: Math.random() };
          }
          // else if (i?.labelCode == '19') {
          //   return { ...i, itemValueSecond: 0, itemValueThird: 0 };
          // }
          else {
            return { ...i, id: Math.random() };
          }
        });
      });

      setData([..._data]);
    }
    requiredRef.current = isRequired;
    bodyTemperatureRequiredRef.current = bodyTemperatureRequired;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequired, bodyTemperatureRequired]);

  useEffect(() => {
    getAllByChief();
    // cheifClassify()
  }, []);
  // 判定依据
  const getAllByChief = async () => {
    const res =
      await vsf.services.ComplaintJudgeDictionaryController_getAllByChiefComplaintJudgeDictionaryNewQto_989929(
        { qto: {} },
      );

    if (res?.code == 200) {
      const _res = cheifClassify(res?.data ?? []);
      // console.log(res);
      setChiefList(res?.data ?? []);
      return res?.data ?? [];
    }
    return [];
  };

  // 血压是否必填
  // const [bloodPressureIsRequired, setBloodPressureIsRequired] = useState(true);
  let input1 = '';
  let input2 = '';

  useEffect(() => {
    setData(
      (props?.list ?? [])?.map((i) => {
        return i?.map((item) => {
          return { ...item, id: Math.random() };
        });
      }),
    );
  }, [props?.list]);

  const handelFieldChange = (key, value, record, form) => {
    const { item } = record;
    const _list: any = [
      ...Object.values(
        vsf.refs?.patientAssessmentFirst?.getEditForm().getFieldsValue(),
      ),
      ...Object.values(
        vsf.refs?.patientAssessmentSecond?.getEditForm().getFieldsValue(),
      ),
      ...Object.values(
        vsf.refs?.patientAssessmentThird?.getEditForm().getFieldsValue(),
      ),
    ];

    const _codeList = ['04', '05', '09', '15', '62', '63'];

    // 04 心率/脉搏 10/12(62,63)收缩压 09 呼吸 08 体温
    let hearRate = _list?.find(
      (i: any) => i?.item?.value == '04' || i?.item?.code == '04',
    )?.itemValue;
    let systolicPressure = _list?.find(
      (i: any) =>
        i?.item?.value == '62' ||
        i?.item?.code == '62' ||
        i?.item?.value == '63' ||
        i?.item?.code == '63',
    )?.itemValue?.value1;
    let breath = _list?.find(
      (i: any) => i?.item?.value == '06' || i?.item?.code == '06',
    )?.itemValue;
    let bodyTemperature = _list?.find(
      (i: any) =>
        i?.item?.labelCode == '08' ||
        JSON?.parse(i?.item?.extensionJson ?? '{}')?.preExamScoreTypeCode ==
          '08',
    )?.itemValue;
    let calculateConsciousness = _list?.find(
      (i: any) => i?.item?.value == '15' || i?.item?.code == '15',
    )?.itemValue;
    // Object.values(form.getFieldsValue()).forEach((i: any) => {

    [..._list].forEach((i: any) => {
      // console.log(i, 'ppp1');
      // hearRate = (item?.value == '04' || item?.code == '04')?;
      // systolicPressure = null;
      // breath = null;
      // bodyTemperature = null;
      // calculateConsciousness = null;

      if (item && (item?.value == '04' || item?.code == '04')) {
        hearRate = value?.itemValue;
      }
      if (
        item &&
        (item?.value == '62' ||
          item?.code == '62' ||
          item?.value == '63' ||
          item?.code == '63')
      ) {
        systolicPressure = value?.itemValue?.value1;
      }

      if (item && (item?.value == '06' || item?.code == '06')) {
        breath = value?.itemValue;
      }
      if (
        item &&
        (item?.labelCode == '08' ||
          JSON?.parse(item?.extensionJson ?? '{}')?.preExamScoreTypeCode ==
            '08')
      ) {
        bodyTemperature = value?.itemValue;
      }
      if (item && (item?.value == '15' || item?.code == '15')) {
        calculateConsciousness = value?.itemValue;
      }
    });

    //  04 心率/脉搏 05收缩压 06 呼吸 08 体温 变化影响MEWS
    if (
      'itemValue' in value &&
      (_codeList.includes(item?.value ?? item?.code ?? item) ||
        JSON?.parse(item?.extensionJson ?? '{}')?.preExamScoreTypeCode ==
          '08' ||
        item?.labelCode == '08')
    ) {
      const res = calculateMews(
        hearRate,
        systolicPressure,
        breath,
        bodyTemperature,
        calculateConsciousness,
      );

      vsf.refs?.preExamMainForm?.setFieldsValue({
        mewsScore: res,
      });
    }
  };

  const RenderValue = (props) => {
    const {
      schema,
      config: { record },
      onChange,
      ...rest
    } = props;
    // console.log(record, 'recordrecord');

    // 脉搏/呼吸
    if (record?.labelCode == '04' || record?.labelCode == '06') {
      return (
        <Input
          {...schema?.originProps}
          value={record?.itemValue}
          onChange={onChange}
          addonAfter="次/分"
          allowClear
        />
      );
    }
    // 脉搏/呼吸
    if (record?.labelCode == '14') {
      return (
        <Input
          {...schema?.originProps}
          value={record?.itemValue}
          onChange={onChange}
          addonAfter="%"
          allowClear
        />
      );
    }
    // 收缩压
    if (record?.labelCode == '05') {
      return (
        <Input
          {...schema.originProps}
          value={record?.itemValue}
          onChange={onChange}
          addonAfter="mmHg"
          allowClear
        />
      );
    }
    // 血糖
    if (record?.labelCode == '17') {
      return (
        <Input
          {...schema.originProps}
          value={record?.itemValue}
          onChange={onChange}
          addonAfter="mmol/L"
          allowClear
        />
      );
    }
    // 呼吸频率
    if (record?.code == '06') {
      return (
        <Input
          {...schema.originProps}
          value={record?.itemValue}
          onChange={onChange}
          addonAfter="次/分"
          allowClear
        />
      );
    }
    // Sp02
    if (record?.labelCode == '07') {
      return (
        <Input
          {...schema.originProps}
          value={record?.itemValue}
          onChange={onChange}
          allowClear
        />
      );
    }
    // 体温
    if (record?.labelCode == '08') {
      const res =
        cheifClassify(chiefList, '08', record?.itemValue)?.map((i) => {
          return {
            ...i,
            id: i?.chiefComplaintJudgeCode,
            chiefComplaint: null,
            chiefComplaintJudge: {
              chiefComplaintJudgeName: i?.chiefComplaintJudgeName,
            },

            secondId: null,
            secondName: null,
          };
        }) ?? [];
      // console.log(res, 'ppppppppppl');

      const _patientChiefComplaintVsJudgeDictionaryDtoList = (
        vsf.refs?.preExamMainForm?.getFieldsValue()
          ?.patientChiefComplaintVsJudgeDictionaryDtoList ?? []
      )?.filter((i) => i?.preScoreType != '08');

      const hasCheckeList1 = [
        ..._patientChiefComplaintVsJudgeDictionaryDtoList,
        ...res,
      ]?.filter((i) => i?.secondId);
      const hasCheckeList2 = [
        ..._patientChiefComplaintVsJudgeDictionaryDtoList,
        ...res,
      ]?.filter((i) => !i?.secondId);
      let __res: any = {};

      if ([...hasCheckeList1, ...hasCheckeList2]?.length > 0) {
        __res = judgeLevel([...hasCheckeList1, ...hasCheckeList2]);
      }

      vsf.refs.preExamMainForm.setFieldsValue({
        patientChiefComplaintVsJudgeDictionary: [
          ...hasCheckeList1,
          ...hasCheckeList2,
        ]
          ?.map(
            (item) =>
              (item?.chiefComplaintName ?? item?.secondName ?? '*') +
              ',' +
              item?.chiefComplaintJudgeName,
          )
          .join('；'),
        patientChiefComplaintVsJudgeDictionaryDtoList: [
          ...hasCheckeList1,
          ...hasCheckeList2,
        ],
        referenceDiseaseLevelIds: __res?.diseaseLevel?.diseaseLevelName,
        diseaseLevel: {
          ...__res?.diseaseLevel,
          label: __res?.diseaseLevel?.diseaseLevelName,
          value: __res?.diseaseLevel?.diseaseLevelCode,
        },
      });
      // handelColor(__res?.diseaseLevel?.colorCode);

      return (
        <Input
          {...schema.originProps}
          value={record?.itemValue}
          onChange={onChange}
          addonAfter="℃"
          allowClear
        />
      );
    }
    // 舒张压
    if (record?.labelCode == '10') {
      return (
        <Input
          {...schema.originProps}
          value={record?.itemValue}
          onChange={onChange}
          addonAfter="mmHg"
          allowClear
        />
      );
    }
    // 风险跌倒
    if (record?.labelCode == '18') {
      return (
        <Input
          {...schema.originProps}
          value={record?.itemValue}
          onChange={onChange}
          onClick={() => {
            fallRiskRef?.current?.handelOpen(true);
          }}
        />
      );
    }
    if (record?.labelCode == '13') {
      return (
        <CodeTableSelect
          {...schema.originProps}
          dataSource={vsf.stores.dbenums?.enums?.BREATH_STATE_DICT}
          value={record?.itemValue}
          onChange={onChange}
          allowClear
        />
      );
    }
    if (record?.labelCode == '15') {
      return (
        <CodeTableSelect
          {...schema.originProps}
          dataSource={vsf.stores.dbenums.enums.AVPU_REACT_DICT}
          value={record?.itemValue}
          onChange={onChange}
        />
      );
    }
    // 血压
    if (record?.labelCode == '19') {
      // console.log(record, 'recordrecord');

      return (
        <RenderBloodPressure
          record={{ ...record }}
          schema={schema}
          // onChange={(v) => {
          //   console.log(v, 'onChange1');
          //   onChange({
          //     itemValueSecond: v?.itemValueSecond,
          //     itemValueThird: v?.itemValueThird,
          //   });
          // }}
          onChange={onChange}
        />
      );
    }
    if (record?.labelCode == '16') {
      return (
        <DatePicker
          format="YYYY-MM-DD"
          {...schema.originProps}
          value={Number(record?.itemValue)}
          // onChange={onChange}
          onChange={(v) => {
            // console.log(v, 'vvvvvvvvvvvv13');
            onChange(v);
          }}
          width="100%"
          disabledDate={(current) => {
            return current && current > dayjs().endOf('day');
          }}
        />
      );
    }
    return (
      <Input
        {...schema.originProps}
        value={record?.itemValue}
        onChange={onChange}
        allowClear
      />
    );
  };

  const Render: any = (props) => {
    const { schema, config, onChange, ...rest } = props;

    return (
      <div style={{ display: 'flex', alignItems: 'center' }} {...rest}>
        {schema?.originProps?.dataSource()?.length > 1 && (
          <div style={{ width: 100, marginRight: 10 }}>
            <Select
              defaultValue={config?.record?.children?.[0]}
              {...schema?.originProps}
              value={config?.record?.item}
              onChange={onChange}
            />
          </div>
        )}

        {/* {schema?.originProps?.dataSource()?.length != 1 && ( */}
        <Ellipsis content={config?.record?.label}>
          {config?.record?.label} {config?.record?.labelCode}
        </Ellipsis>
        {/* )} */}
      </div>
    );
  };
  const RenderBloodPressure = (props) => {
    const { schema, record, onChange, ...rest } = props;
    // console.log(record, 'recordrecord');

    return (record?.item?.code ?? record?.item?.value ?? record?.item) ==
      '26' ? (
      <></>
    ) : (
      <div style={{ display: 'flex', alignItems: 'center' }} {...rest}>
        <div style={{ width: 100 }}>
          <Input
            {...schema?.originProps}
            placeholder="收缩压"
            // value={record?.itemValueSecond}
            value={record?.itemValue?.value1}
            onChange={(v) => {
              onChange({
                ...record?.itemValue,
                value1: v?.target?.value,
              });
              input1 = v?.target?.value;
            }}
          />
        </div>
        <div style={{ margin: '0 10px' }}> / </div>
        <div style={{ width: 100 }}>
          <Input
            {...schema?.originProps}
            placeholder="舒张压"
            // value={record?.itemValueThird}
            value={record?.itemValue?.value2}
            onChange={(v) => {
              input2 = v?.target?.value;
              onChange({
                ...record?.itemValue,
                value2: v?.target?.value,
              });
            }}
          />
        </div>
        <div style={{ margin: '0 10px' }}> mmHg</div>
      </div>
    );
  };

  const renderTable = (tableId, value) => {
    // console.log(requiredRef, value, 'value12344');

    return (
      <div>
        <VSTable
          id={tableId}
          className="
      aspirin-table-nopadding
    aspirin-table-header-background-color
     aspirin-table-row-hover-background-color
     aspirin-table-body-empty-transparent-background-color
    "
          editable={{
            editType: 'multiple',
            columnProps: {
              hideInTable: true,
            },
            onFieldChange: (key, value: any, record, form) => {
              // console.log(key, value, record, form, 'valuevaluevalue1');
              // if (record?.item?.code == '62' || record?.item?.code == '63') {
              //   form.setFieldValue(
              //     [key, 'itemValueSecond'],
              //     value?.itemValue?.itemValueSecond ?? input1,
              //   );
              //   form.setFieldValue(
              //     [key, 'itemValueThird'],
              //     value?.itemValue?.itemValueThird ?? input2,
              //   );
              // }
              // if (record?.item?.code == '26') {
              //   form.setFieldValue([key, 'itemValueSecond'], '');
              //   form.setFieldValue([key, 'itemValueThird'], '');
              //   form.setFieldValue([key, 'itemValue'], '00');
              //   form.setFieldValue([key, 'item'], '26');
              // }

              // 修改form得值
              handelFieldChange(key, value, record, form);
            },
          }}
          pagination={false}
          bordered
          value={value?.map((i, index) => {
            return (i?.labelCode == '19' && !i?.itemValue) ||
              (i?.labelCode == '08' && !i?.itemValue)
              ? {
                  ...i,
                  item: {
                    label: i?.children?.[0]?.label,
                    value: i?.children?.[0]?.code,
                    labelCode: i?.labelCode,
                  },
                  id: Math.random(),
                }
              : {
                  ...i,
                  item: {
                    label: i?.itemLabel,
                    value: i?.item,
                    labelCode: i?.labelCode,
                  },
                  id: Math.random(),
                };
          })}
        >
          <VSTableColumn
            dataIndex={['item']}
            title="评估项目"
            valueType="select"
            fieldNames={{ label: 'label', value: 'value' }}
            dataSource={(value, row) => {
              const _dataSource = row?.children;

              return value?.label
                ? _dataSource?.filter(
                    (item) =>
                      askSearch(value?.label, item, 'label') ||
                      askSearch(value?.label, item, 'pinyin'),
                  )
                : _dataSource;
            }}
            width={isMinScreen ? 120 : 170}
            fieldProps={{
              showSearch: true,
            }}
            formItemProps={(_, __) => {
              const _labelCode = __?.entity?.item?.value ?? __?.entity?.item;
              // console.log(_labelCode, __, 'pppppppp');

              return {
                rules: [
                  {
                    required:
                      __?.entity.labelCode == '08'
                        ? bodyTemperatureRequiredRef.current
                        : _labelCode == '19'
                        ? _labelCode == '62' || _labelCode == '63'
                          ? true
                          : false
                        : _labelCode == '09' ||
                          _labelCode == '16' ||
                          _labelCode == '45'
                        ? false
                        : requiredRef.current,
                    // required: isRequired,
                  },
                ],
                initialValue: __?.entity?.children?.[0],
              };
            }}
            renderFormItem={(schema, config, form, action) => {
              // console.log(config, 'configconfigconfigconfig1');

              return (
                <Render
                  config={config}
                  schema={schema}
                  onChange={schema.originProps.onChange}
                />
              );
            }}
          />

          <VSTableColumn
            dataIndex={['itemValue']}
            valueType="custom"
            title="项目值"
            width={isMinScreen ? 140 : 200}
            formItemProps={(_, __) => {
              // console.log(_, __, _.getFieldsValue(), '_, __');
              const _labelCode = __?.entity?.item?.value ?? __?.entity?.item;
              let _labelCode1 = '';
              if (__?.entity.labelCode == '19') {
                _labelCode1 =
                  _.getFieldsValue()?.[__?.entity?.id]?.item?.value ??
                  _.getFieldsValue()?.[__?.entity?.id]?.item;
              }
              // 脉搏 血氧饱和 呼吸 血压 体温 血糖
              // 血糖血压末次月经不必填
              const rules =
                __?.entity.labelCode == '04' ||
                __?.entity.labelCode == '14' ||
                __?.entity.labelCode == '08' ||
                __?.entity.labelCode == '06' ||
                __?.entity.labelCode == '17'
                  ? [
                      {
                        pattern: positive,
                        message: positiveMessage,
                      },
                    ]
                  : [];
              const rules1 =
                __?.entity.labelCode == '19'
                  ? [
                      {
                        validator: (rule, value, callback) => {
                          // console.log(
                          //   value,
                          //   !positive.test(value?.value2),
                          //   !positive.test(value?.value1),
                          //   'bbbbbbbbbbbbbbbbbbb',
                          // );

                          if (
                            !value ||
                            value?.value1?.length === 0 ||
                            value?.value2?.length === 0
                          )
                            return callback('请输入血压值');

                          if (
                            !positive.test(value?.value1) ||
                            !positive.test(value?.value2)
                          ) {
                            callback('请输入正数');
                          } else {
                            callback();
                          }
                        },
                      },
                    ]
                  : [];
              // ( (_labelCode == '62' || _labelCode == '63')
              // console.log(
              //   __?.entity.labelCode,
              //   _labelCode1,
              //   '_labelCode1_labelCode1_labelCode1',
              // );

              return {
                rules: [
                  {
                    required:
                      __?.entity.labelCode == '08'
                        ? bodyTemperatureRequiredRef.current
                        : __?.entity.labelCode == '19'
                        ? _labelCode1 == '26'
                          ? false
                          : requiredRef.current
                        : _labelCode == '09' ||
                          _labelCode == '16' ||
                          _labelCode == '45'
                        ? false
                        : requiredRef.current,
                    // required: isRequired,
                  },
                  ...rules,
                  // ...rules1,
                  // { ...rules },
                  // __?.entity.labelCode == '19' && _labelCode != '26'
                  //   ? {
                  //       validator: (rule, value, callback) => {
                  //         console.log(value, 'bbbbbbbbbbbbbbbbbbb');

                  //         if (!value || value?.length === 0)
                  //           return callback('请输入血压值');
                  //         const regex = /^\d+(\.\d{1,2})?$/;
                  //         if (!positive.test(value)) {
                  //           callback('请输入一个非负数，最多有两位小数');
                  //         } else {
                  //           callback();
                  //         }
                  //       },
                  //     }
                  //   : {},
                ],
              };
            }}
            renderFormItem={(schema, config, form, action) => {
              // const { record } = entity;
              // return renderValue(dom, record, form);
              return (
                <RenderValue
                  config={config}
                  schema={schema}
                  onChange={schema.originProps.onChange}
                />
              );
            }}
          />
        </VSTable>
      </div>
    );
  };
  // 多 vsf.stores.dbenums.enums.PRE_EXAM_ITEM_DICT
  // 少 vsf.stores.dbenums.enums.PRE_EXAM_SCORE_TYPE
  console.log(vsf.stores.dbenums.enums.PRE_EXAM_SCORE_TYPE, 'vsf');
  return (
    <div className="patientAssessmentTable">
      <Space.Compact style={{ width: '100%' }}>
        {renderTable('patientAssessmentFirst', data?.[0])}
        {renderTable('patientAssessmentSecond', data?.[1])}
        {renderTable('patientAssessmentThird', data?.[2])}
      </Space.Compact>
    </div>
  );
};

export default React.memo(PatientAssessment);
