import vsf, { definePage } from '@vs/vsf-boot';
import {
  Badge,
  Cascader,
  Checkbox,
  CheckboxGroup,
  message,
  Modal,
  Section,
  Select,
  Slider,
  Tabs,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import classNames from 'classnames';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import { Ellipsis } from '@/pages/Index/components';
import FormTitle from '@/pages/Index/components/form_title';
import { askGetTreeWhole, askTree } from '@/pages/Index/components/func/ask';
import {
  askFixed,
  askSearch,
  askTotalAmount,
} from '@/pages/Index/components/func/ask';
import LabItem from '@/pages/Index/manage/finance/labItem/tabs/LabItem';
const Index = (props, ref) => {
  const { onOk, deletePatient, deleteEvent } = props;

  const [open, setOpen] = useState(false);

  // 已选中得
  const [type, setType] = useState<any>();

  const handelOpen = (v = false, type?) => {
    setOpen(v);
    if (v) {
      setType(type);
    }
  };

  useImperativeHandle(ref, () => ({
    handelOpen,
  }));

  return (
    <div>
      <Modal
        centered
        width={600}
        open={open}
        bodyStyle={{
          height: 200,
        }}
        okText="确认 "
        cancelText="取消 "
        title={
          <div style={{ color: '#3276E8', fontWeight: 'bolder' }}>
            {type == 'patient'
              ? '用户验证'
              : type == 'event'
              ? '用户验证（第一次签名验证）'
              : type == 'event2'
              ? '用户验证（第二次签名验证）'
              : ''}
          </div>
        }
        onOk={async () => {
          const res = await onOk(vsf.refs.userVerification?.getFieldsValue());
          if (res?.code == 200) {
            if (type == 'event') {
              message.success('第一次验证成功');
              vsf.refs.userVerification.resetFields();
              setType('event2');
            } else {
              message.success(
                type == 'patient' ? '验证成功' : '第二次验证成功',
              );
              setOpen(false);
              const response =
                type == 'patient' ? await deletePatient() : await deleteEvent();
            }
          }
        }}
        onCancel={() => {
          handelOpen(false);
        }}
      >
        <VSForm labelAlign="left" id="userVerification">
          <VSFormLayout labelWidth={100} columnCount={1}>
            <VSFormItem required label="用户名" name="username" />
            <VSFormItem required label="密码" name="password" />
          </VSFormLayout>
        </VSForm>
      </Modal>
    </div>
  );
};
export default forwardRef(Index);
// 删除患者/删除事件验证
