import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  Modal,
  message,
} from '@vs/vsf-kit';
import React from 'react';
function DetallTable(props) {
  const { openDetaill, openDetaillChange, detailData, selectBedId } = props;
  return (
    <Modal
      title={<div className="modal-fee-revivw">医嘱计费详情</div>}
      open={openDetaill}
      onCancel={() => openDetaillChange(false)}
      width={1600}
      okText={'保存'}
      cancelText={'取消'}
      onOk={async () => {
        await vsf.refs.orderBillingDetailListTable
          .getEditForm()
          .validateFieldsReturnFormatValue();
        const formdata = vsf?.refs?.orderBillingDetailListTable?.getValues();
        const formData =
          formdata
            ?.map((item) => {
              return {
                ...item,
                billingDetailId: item?.id,
                billingStatus: item?.billingStatus,
                adjustAmount: item?.adjustAmount,
                memo: item?.memo,
                inpVisitId: selectBedId,
              };
            })
            ?.filter((item) => item.adjustAmount) ?? [];
        if (formData?.length === 0) {
          message.success('保存成功');
          openDetaillChange(false);
          return;
        }
        const res =
          await vsf.services?.InpBillingDetailController_adjustBillingDetailAmount_df7221?.(
            {
              adjustBillingDetailEoList: formData,
            },
          );
        if (res.code === 200) {
          message.success('保存成功');
          openDetaillChange(false);
        }
      }}
    >
      <VSPromiseTable
        editable={{
          editType: 'multiple',
          columnProps: {
            hideInTable: true,
          },
        }}
        vsid="64460"
        id="orderBillingDetailListTable"
        onFetch={async (params) => {
          console.log(detailData);
          const res =
            await vsf.services?.InpBillingDetailController_getBillingDetailByCostId_5be372?.(
              {
                inpVisitId: selectBedId,
                costId: detailData?.id,
                orderCostIndicator: detailData?.billingType === '医嘱带入',
              },
            );
          return {
            data: res?.data?.result ?? res?.data ?? [],
            total: res?.data?.count ?? res?.data?.length ?? 0,
          };
        }}
        scroll={{ x: 1000 }}
        pagination={false}
      >
        <VSTableColumn
          dataIndex={['billingStatus']}
          title="计费标识"
          valueType="select"
          dataSource={[
            { label: '未计费', value: 'UNBILLING' },
            { label: '已计费', value: 'BILLING' },
          ]}
          fieldProps={{}}
          width={150}
          preview
        />

        <VSTableColumn
          dataIndex={['itemClass']}
          title="类别"
          valueType="select"
          fieldProps={{}}
          width={150}
          dataSource={vsf?.stores?.dbenums?.enums?.BILL_ITEM_CLASS_DICT}
          preview
        />

        <VSTableColumn
          dataIndex={['itemName']}
          title="项目名称"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 200 },
            ],
          }}
          fieldProps={{}}
          width={250}
          preview
        />

        <VSTableColumn
          dataIndex={['itemSpecification']}
          title="规格"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 100 },
            ],
          }}
          fieldProps={{}}
          width={150}
          preview
        />

        <VSTableColumn
          dataIndex={['brand']}
          title="品牌"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 80 },
            ],
          }}
          fieldProps={{}}
          width={150}
          preview
        />

        <VSTableColumn
          dataIndex={['price']}
          title="单价"
          valueType="digit"
          width={150}
          fieldProps={{}}
          preview
        />

        <VSTableColumn
          dataIndex={['amount']}
          title="数量"
          valueType="digit"
          fieldProps={{}}
          preview
          width={150}
        />

        <VSTableColumn
          dataIndex={['adjustAmount']}
          title="调整数量"
          valueType="digit"
          fieldProps={{
            onChange: (c) => {
              console.log(vsf?.stores?.dbenums?.enums?.BILL_ITEM_CLASS_DICT);
            },
          }}
          formItemProps={(form, config) => {
            return {
              rules: [
                {
                  validator(record, value) {
                    if ([undefined, null].includes(value)) {
                      return Promise.reject('请输入数量');
                    } else if (!Number.isInteger(value)) {
                      return Promise.reject('数量必须是整数');
                    } else if (
                      value < 0 &&
                      config?.entity?.amount < Math.abs(value)
                    ) {
                      return Promise.reject('调整数量的绝对值不能大于数量');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ],
            };
          }}
          width={150}
        />

        <VSTableColumn
          dataIndex={['memo']}
          title="补充说明"
          valueType="text"
          formItemProps={(...[, record]) => {
            return {
              rules: [
                {
                  message: '数据格式错误',
                  type: 'string',
                  min: 0,
                  max: 100,
                  required: true,
                },
                record?.adjustAmount ? { required: true } : {},
              ],
            };
          }}
          fieldProps={{}}
          width={150}
        />

        <VSTableColumn
          dataIndex={['unit']}
          title="单位"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '数据格式错误', type: 'string', min: 0, max: 20 },
            ],
          }}
          fieldProps={{}}
          width={150}
          preview
        />

        <VSTableColumn
          dataIndex={['cost']}
          title="计价金额"
          valueType="digit"
          fieldProps={{}}
          width={150}
          preview
        />

        <VSTableColumn
          dataIndex={['orderDate']}
          title="计价时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          width={200}
          preview
        />

        <VSTableColumn
          dataIndex={['selfPaymentIndicator']}
          title="强制自费"
          valueType="switch"
          fieldProps={{}}
          width={100}
          preview
        />

        <VSTableColumn
          dataIndex={['medicalInsuranceType']}
          title="医保类别"
          valueType="select"
          dataSource={[
            { label: '甲', value: 'ALPHA' },
            { label: '乙', value: 'BETA' },
            { label: '丙', value: 'GAMMA' },
          ]}
          fieldProps={{}}
          width={150}
          preview
        />

        <VSTableColumn
          dataIndex={['orderDoctorName']}
          title="开单人"
          valueType="text"
          fieldProps={{}}
          width={150}
          preview
        />

        <VSTableColumn
          dataIndex={['orderDepartmentName']}
          title="开单科室"
          valueType="text"
          fieldProps={{}}
          width={150}
          preview
        />

        <VSTableColumn
          dataIndex={['performDepartmentName']}
          title="执行科室"
          valueType="text"
          fieldProps={{}}
          width={150}
          preview
        />

        <VSTableColumn
          dataIndex={['operateDate']}
          title="录入时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          width={200}
          preview
        />

        <VSTableColumn
          dataIndex={['operatorName']}
          title="录入人员"
          valueType="text"
          fieldProps={{}}
          width={150}
          preview
        />
      </VSPromiseTable>
    </Modal>
  );
}

export default DetallTable;
