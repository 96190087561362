// 心率
export const calculateHearRate = (value) => {
  if (!value) return null;
  if (value <= 40) {
    return 2;
  } else if (value <= 50) {
    return 1;
  } else if (value <= 100) {
    return 0;
  } else if (value <= 110) {
    return 1;
  } else if (value < 130) {
    return 2;
  } else {
    return 3;
  }
};
// 收缩压
export const calculateSystolicPressure = (value) => {
  if (!value) return null;
  if (value <= 70) {
    return 3;
  } else if (value <= 80) {
    return 2;
  } else if (value <= 100) {
    return 1;
  } else if (value <= 199) {
    return 0;
  } else {
    return 2;
  }
};
// 呼吸
export const calculateBreath = (value) => {
  if (!value) return null;
  if (value < 9) {
    return 2;
  } else if (value <= 14) {
    return 0;
  } else if (value <= 20) {
    return 1;
  } else if (value <= 29) {
    return 2;
  } else {
    return 3;
  }
};
// 体温
export const calculateBodyTemperature = (value) => {
  // console.log(value, '体温');
  if (!value) return null;
  if (value <= 35) {
    return 2;
  } else if (value <= 36) {
    return 1;
  } else if (value <= 38) {
    return 0;
  } else if (value <= 38.5) {
    return 1;
  } else {
    return 2;
  }
};
// 意识
export const calculateConsciousness = (value) => {
  // console.log(value, 'consciousness');
  if (!value) return null;
  if (value === '01') {
    return 0;
  } else if (value === '02') {
    return 1;
  } else if (value === '03') {
    return 2;
  } else if (value === '04') {
    return 3;
  }
};
// 计算Mews评分
export const calculateMews = (
  hearRate,
  systolicPressure,
  breath,
  bodyTemperature,
  consciousness,
) => {
  const res1 = calculateHearRate(hearRate);
  const res2 = calculateSystolicPressure(systolicPressure);
  const res3 = calculateBreath(breath);
  const res4 = calculateBodyTemperature(bodyTemperature);
  const res5 = calculateConsciousness(consciousness);
  // console.log(
  //   res1,
  //   hearRate,
  //   res2,
  //   systolicPressure,
  //   res3,
  //   breath,
  //   res4,
  //   bodyTemperature,
  //   res5,
  //   consciousness,
  //   '计算',
  // );

  const validNumbers = [res1, res2, res3, res4, res5].filter(
    (value) => value || value == 0,
  );

  // 如果过滤后的数组为空，返回空字符串
  if (validNumbers.length === 0) {
    return '';
  }
  // console.log(
  //   validNumbers,
  //   'validNumbers',
  //   hearRate,
  //   systolicPressure,
  //   breath,
  //   bodyTemperature,
  //   consciousness,
  // );
  // 使用 reduce 进行加法运算
  return validNumbers.reduce(
    (sum, current) => Number(sum) + Number(current),
    0,
  );
  // return res1 + res2 + res3 + res4 + res5;
};
// 评分弹窗确认
export const confirm = (
  formValue = {},
  tempErpPreExamRecordDetailWithVitalsignDictionaryList = [],
  callback,
  handelData,
) => {
  // const formValue = vsf.refs?.cramsModal?.getFieldsValue();
  // const tempErpPreExamRecordDetailWithVitalsignDictionaryList =
  //   vsf.refs?.preExamMainForm?.getFieldsValue()
  //     ?.erpPreExamRecordDetailWithVitalsignDictionaryList ?? [];
  const res = Object.values(formValue)?.reduce((pre, cur) => {
    handelData?.find((i) => i?.code == cur);
    return (
      Number(pre) +
      Number(
        cur?.itemScroe ??
          handelData?.find((i) => i?.code == cur)?.itemScroe ??
          0,
      )
    );
  }, 0);
  // 修改form erpPreExamRecordDetailWithVitalsignDictionaryList 关于gcs得值

  Object.keys(formValue)?.forEach((i) => {
    const index =
      tempErpPreExamRecordDetailWithVitalsignDictionaryList.findIndex(
        (obj) => obj?.item == i,
      );
    if (index !== -1) {
      // 如果找到具有相同id的对象的索引，则更新它
      tempErpPreExamRecordDetailWithVitalsignDictionaryList[index] = {
        item: i,
        itemValue: formValue?.[i]?.code ?? formValue?.[i],
      }; // 或者你可以选择只更新特定的属性，如 array[index].name = objToAddOrUpdate.name;
    } else {
      // 如果没有找到对象，则添加到数组
      tempErpPreExamRecordDetailWithVitalsignDictionaryList.push({
        item: i,
        itemValue: formValue?.[i]?.code ?? formValue?.[i],
      });
    }
  });
  callback?.(res, tempErpPreExamRecordDetailWithVitalsignDictionaryList);
};
// 回显评分弹窗
export const openModal = (formValue, callback) => {
  // console.log(formValue, callback, 'callbackcallback');
  const res = formValue?.erpPreExamRecordDetailWithVitalsignDictionaryList?.map(
    (i) => {
      return { [i?.item]: i?.itemValue };
    },
  );
  // console.log(res, formValue, 'rrrrrr');
  const combinedObject = res?.reduce((acc, obj) => {
    // 将当前对象的所有属性合并到累加器对象中
    return { ...acc, ...obj };
  }, {});

  if (!combinedObject) return;
  setTimeout(() => {
    callback(combinedObject);
  }, 100);
};
// 判定依据计算 preScoreLowLimit preScoreUpLimit
export const cheifClassify = (data, type, value) => {
  // 08 体温
  if (type == '08') {
    const _data = data?.filter((i) => i?.preScoreType == '08');
    if (value) {
      // let _list = [];
      const _list = _data?.filter((i) => {
        if (i?.preScoreLowLimit && i?.preScoreUpLimit) {
          return value >= i?.preScoreLowLimit && value <= i?.preScoreUpLimit;
        } else if (i?.preScoreLowLimit && !i?.preScoreUpLimit) {
          return value > i?.preScoreLowLimit;
        } else if (!i?.preScoreLowLimit && i?.preScoreUpLimit) {
          return value < i?.preScoreUpLimit;
        }
      });
      return _list ?? [];
    }
  }
};

// 判断等级
export const judgeLevel = (items = []) => {
  if (!items || items?.length <= 0) return null;
  const maxItem = items.reduce((prev, current) => {
    return prev.diseaseLevel?.diseaseLevel < current.diseaseLevel?.diseaseLevel
      ? prev
      : current;
  });
  return maxItem;
};
