// 待出院患者列表
export const convertGetWaitDischargePatientVoListByWardIdRes2WaitDischargePatientList =
  (input) => {
    const _ = window._ ?? {};
    const output = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj = {};
        obj.bedLabel = _.get(d, 'inpVisitVo.patientInHospitalDto.bed.bedLabel');
        obj.name = _.get(d, 'patientVo.name');
        obj.displayId = _.get(d, 'patientVo.displayId');
        obj.id = _.get(d, 'inpVisitVo.id');
        obj.preDischargeStatus = _.get(
          d,
          'inpPreDischargePatientVo.preDischargeStatus',
        );
        return obj;
      }) ?? []),
    );
    return output;
  };

// form
export const convertGetDischargePatientInfoVoByInpVisitIdRes2DischargePatientInfoForm =
  (input) => {
    const _ = window._ ?? {};
    const output = {};
    output.id = _.get(input, 'data.inpVisitVo.id');
    output.displayId = _.get(input, 'data.patientVo.displayId');
    output.bedLabel = _.get(
      input,
      'data.inpVisitVo.patientInHospitalDto.bed.bedLabel',
    );
    output.name = _.get(input, 'data.patientVo.name');
    output.gender = _.get(input, 'data.patientVo.gender');
    output.age = _.get(input, 'data.patientVo.age');
    output.birthAddress = {};
    output.birthAddress.province = _.get(
      input,
      'data.patientVo.birthAddress.province',
    );
    output.birthAddress.city = _.get(input, 'data.patientVo.birthAddress.city');
    output.birthAddress.county = _.get(
      input,
      'data.patientVo.birthAddress.county',
    );
    output.birthAddress.detail = _.get(
      input,
      'data.patientVo.birthAddress.detail',
    );
    output.birthAddress.code = _.get(input, 'data.patientVo.birthAddress.code');
    output.birthAddress.postcode = _.get(
      input,
      'data.patientVo.birthAddress.postcode',
    );
    output.identityCode = _.get(input, 'data.patientVo.identityCode');
    output.defaultChargeType = _.get(input, 'data.patientVo.defaultChargeType');
    output.contactName = _.get(
      input,
      'data.patientVo.patientProfileDto.contact.name',
    );
    output.phoneNumber = _.get(
      input,
      'data.patientVo.patientProfileDto.contact.phoneNumber',
    );
    output.departmentName = _.get(
      input,
      'data.inpVisitVo.patientInHospitalDto.department.departmentName',
    );
    output.admissionDateTime = _.get(
      input,
      'data.inpVisitVo.admissionDateTime',
    );
    output.admissionWardDateTime = _.get(
      input,
      'data.inpVisitVo.patientInHospitalDto.admissionWardDateTime',
    );
    output.patientCondition = _.get(
      input,
      'data.inpVisitVo.patientInHospitalDto.patientCondition',
    );
    output.diagnosisDescription = _.get(
      input,
      'data.inpVisitVo.patientInHospitalDto.diagnosisRecord.diagnosisDescription',
    );
    output.doctorInCharge = {};
    output.doctorInCharge.id = _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionStaffDto.doctorInCharge.id',
    );
    output.doctorInCharge.staffName = _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionStaffDto.doctorInCharge.staffName',
    );
    output.nursingClass = _.get(
      input,
      'data.inpVisitVo.patientInHospitalDto.nursingClass',
    );
    output.nurseInCharge = {};
    output.nurseInCharge.id = _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionStaffDto.nurseInCharge.id',
    );
    output.nurseInCharge.staffName = _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionStaffDto.nurseInCharge.staffName',
    );
    output.controlQualityNurse = {};
    output.controlQualityNurse.id = _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionStaffDto.controlQualityNurse.id',
    );
    output.controlQualityNurse.staffName = _.get(
      input,
      'data.inpVisitVo.inpVisitExtensionStaffDto.controlQualityNurse.staffName',
    );
    output.prePaymentAmount = _.get(
      input,
      'data.patientBillingRecordVo.prePaymentAmount',
    );
    output.totalCharge = _.get(
      input,
      'data.patientBillingRecordVo.totalCharge',
    );
    output.dischargeDateExpected = _.get(
      input,
      'data.inpPreDischargePatientVo.dischargeDateExpected',
    );
    output.dischargeWay = _.get(input, 'data.inpVisitVo.dischargeWay');
    output.notChargeLab = _.get(input, 'data.notChargeLab');
    output.notPerformExam = _.get(input, 'data.notPerformExam');
    output.notSubmitOrder = _.get(input, 'data.notSubmitOrder');
    output.notChargeOperation = _.get(input, 'data.notChargeOperation');
    output.notChargeTreat = _.get(input, 'data.notChargeTreat');
    output.notPerformOrder = _.get(input, 'data.notPerformOrder');
    output.notStopOrder = _.get(input, 'data.notStopOrder');
    output.notOperateDrugPrescription = _.get(
      input,
      'data.notOperateDrugPrescription',
    );
    output.notDispenseOrder = _.get(input, 'data.notDispenseOrder');
    output.notHandleReturnOrder = _.get(input, 'data.notHandleReturnOrder');
    output.notReturnDrugPrescription = _.get(
      input,
      'data.notReturnDrugPrescription',
    );
    output.notReturnDrugOrder = _.get(input, 'data.notReturnDrugOrder');
    output.dischargeDrug = _.get(input, 'data.dischargeDrug');
    return output;
  };

// 保存
export const convertDischargePatientInfoForm2DischargeNoticeSettleReq = (
  input,
) => {
  const _ = window._ ?? {};
  const output = {};
  output.saveInpVisitForDischargeBto = {};
  output.saveInpVisitForDischargeBto.id = _.get(input, 'id');
  output.saveInpVisitForDischargeBto.dischargeWay = _.get(
    input,
    'dischargeWay',
  );
  output.saveInpVisitForDischargeBto.expectedDischargeDate = _.get(
    input,
    'dischargeDateExpected',
  );
  output.saveInpVisitForDischargeBto.inpVisitExtensionStaffBto = {}; // TODO 请确认下行字段是否符合逻辑
  output.saveInpVisitForDischargeBto.inpVisitExtensionStaffBto.controlQualityNurseId =
    _.get(input, 'controlQualityNurse.id'); // TODO 请确认下行字段是否符合逻辑
  output.saveInpVisitForDischargeBto.inpVisitExtensionStaffBto.nurseInChargeId =
    _.get(input, 'nurseInCharge.id');
  output.saveInpVisitForDischargeBto.patientInHospitalBto = {};
  return output;
};
