import React from 'react';
import {
  VSForm,
  VSFormLayout,
  VSFormItem,
  message,
  Select,
  Image,
} from '@vs/vsf-kit';

import { ButtonAndKeyDown } from '@/pages/Index/components';
import readCard from '@/assets/icon/readCard.png';
import { labelColWidth, labelNumberWidth } from '@/utils';
import { useRequest } from 'ahooks';
import '@/style/adaptation.less';
import dayjs from 'dayjs';

export default function certificateTitle(props) {
  const { inquire, setCertificate } = props;

  return (
    <VSForm id="listPrintForm" labelAlign="left">
      <VSFormLayout
        columnCount={7}
        // labelCol={labelColWidth[4]}
        labelWidth={labelNumberWidth(6)}
        className="certificate_title"
      >
        <VSFormItem
          name={['orderDateRangeIn']}
          label="就诊日期"
          valueType="dateRange"
          fieldProps={{
            disabled: false,
            width: 300,
          }}
          initialValue={[dayjs()?.subtract(15, 'day'), dayjs()]}
        />
        <VSFormItem
          name={['patientBed']}
          label="就诊科室"
          valueType="select"
          fieldProps={{ showSearch: true, className: 'width190' }}
          fieldNames={{ label: 'departmentName', value: 'id' }}
          searchKey="patientName"
          initialValue={{
            departmentName: '全部',
            value: 0,
          }}
          dataSource={async (params) => {
            var qtoParams = {
              from: params.pagination?.from ?? 0,
              size: params.pagination?.size ?? 200,
              branchInstitutionIdIs: params?.toBranchInstitution?.id,
              stopIndicatorIs: false,
              stopIndicatorIsNullOrNot: true,
              clinicAttributeIs: '0',
            };
            if (params?.departmentNameLike) {
              qtoParams.departmentNameLike = params?.departmentNameLike;
            }

            const res =
              await vsf.services?.DepartmentDtoController_getAllByDepartmentNewQtoByDepartmentNewQto_4b549a?.(
                { qto: qtoParams },
              );
            console.log(res.data, 'res.data');
            // let obj=[]
            res?.data.unshift({ departmentName: '全部', id: 0 });
            return res?.data;
          }}
        />

        <VSFormItem
          name={['patientIdIs']}
          label="患者ID"
          valueType="text"
          fieldProps={{
            onPressEnter: async (e) => {
              // await getPatientID()
              // console.log(
              //   vsf?.refs?.ExportRecord?.getFieldValue()?.patientIdIs,
              //   '111',
              // );
              // console.log(value, '111');
              await getPatientID(
                vsf?.refs?.ExportRecord?.getFieldValue()?.patientIdIs,
              );
              await getCheckList();
            }, //1000014801
            className: 'patientIdIs',
            suffix: (
              <div
                className="suffix-readCard"
                onClick={() => {
                  message.success('读卡中');
                }}
              >
                <div className="suffix-cardImg">
                  <Image
                    src={readCard}
                    className="suffix-cardImg-image"
                    preview={false}
                  ></Image>
                </div>
                <div className="suffix-cardText">读卡</div>
              </div>
            ),
            placeholder: '请读卡',
          }}
        />
        <ButtonAndKeyDown methodKey="altKey" type="primary" onClick={inquire}>
          查询
        </ButtonAndKeyDown>
      </VSFormLayout>
    </VSForm>
  );
}
