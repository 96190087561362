import React, { useState, useEffect } from 'react';
import './index.less';
import OutpSettleMasterQueryList from './table';
import InquiryForm from './form';
import { useMount } from 'ahooks';
function index() {
  const [tableParam, setTableParam] = useState();
  const [counterId, setCounterId] = useState();
  useEffect(() => {
    // console.log(tableParam, 'table_tableParam');
    // settleNumberIs 票据号
    // displayIdIs  患者ID
    // nameLike 患者姓名
    // payTypeIs  支付方式
    // chargeTypeIs 费别
    // settleDateRangeIn  收费日期
    // operatorStaffIdIs  收费员
    // chargeStatusIs 缴费状态
    // settleTypeIs 结算途径
    // totalCostIs  总金额
    // totalChargeIs  实收金额
    // insuranceCardNumberIs  医保卡号
    // identificationClass  证件
    // identificationNumber 编号
  }, []);
  return (
    <div className="receipt-inquiry-layout">
      <div className="receipt-inquiry-top">
        <InquiryForm
          callback={(p) => {
            console.log(p, 'table_p');
            setTableParam(p);
          }}
          counterId={counterId}
        />
      </div>
      <div className="receipt-inquiry-bottom">
        <OutpSettleMasterQueryList
          tableParam={tableParam}
          counterIdChange={(param) => {
            setCounterId(param);
          }}
          // onTableParamCallback={onTableParamCallback}
        />
      </div>
    </div>
  );
}

export default index;
