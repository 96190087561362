import {
  Section,
  VSTableColumn,
  VSPromiseTable,
  Modal,
  Tooltip,
  Popover,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { askGroupMedicalOrders } from '@/pages/Index/components/func/ask';
import { getScrollY } from '@/utils';
import Icon from '@/module/Icon';
import vsf, { definePage } from '@vs/vsf-boot';

import OrderBilleDetails from './OrderBilleDetails';
import { getWardPatientOrderInfo } from './Service';

import OrderTable, {
  OrderTableRef,
} from '@/pages/Index/components/order_table';
import { items } from '../..';
const WardDoctorOrderInfo = (props) => {
  const {
    selectBedId,
    onSelectIdsChange,
    doctorOrderformData,
    getPatientInfo,
  } = props;
  const table = useRef(null);
  const { data: orderList, run: getOrderRun } = useRequest(
    getWardPatientOrderInfo,
    { manual: true },
  );

  const [activityRow, setActivityRow] = useState();

  const { data: fetchData, run: fetchDataRun } = useRequest(async () => {
    // console.log(doctorOrderformData);
    setActivityRow();

    if (selectBedId) {
      let repeatIndicator;
      //长期
      if (
        doctorOrderformData?.repeatIndicator &&
        !doctorOrderformData?.repeatIndicator1
      ) {
        repeatIndicator = true;
      }
      //短期
      else if (
        !doctorOrderformData?.repeatIndicator &&
        doctorOrderformData?.repeatIndicator1
      ) {
        repeatIndicator = false;
      }
      const param = {
        repeatIndicator,
        orderClass: doctorOrderformData?.orderClass,
      };

      if (
        doctorOrderformData?.repeatIndicator &&
        doctorOrderformData?.repeatIndicator1
      ) {
        delete param.repeatIndicator;
      }
      if (
        doctorOrderformData &&
        !doctorOrderformData?.repeatIndicator &&
        !doctorOrderformData?.repeatIndicator1
      ) {
        return [];
      }

      // console.log('getPatientInfo');
      // console.log(selectBedId);
      const res =
        await vsf.services?.NurseBillingController_getWardPatientOrderInfo_a2d811?.(
          {
            inpVisitId: selectBedId?.key,
            ...param,
            currentDateValidIndicator:
              doctorOrderformData?.currentDateValidIndicator,
          },
        );
      if (!res?.data?.result) {
        return [];
      }
      const a = res?.data?.result?.map((items) => {
        return {
          ...items,
          drugOrder: {
            groupNumber: items?.groupNumber,
            groupSubNumber: items?.groupSubNumber,
            frequency: { frequencyCount: items?.frequency },
            ...items,
          },
          submitDateTime: items?.startPerformDateTime,
        };
      });
      table?.current?.setValue(a);
      const _data = group?.onSliced(a);
      // console.log(a, _data, 'aaa');
      return {
        data: _data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    } else {
      return [];
    }
  });
  useEffect(() => {
    fetchDataRun();
  }, [selectBedId, doctorOrderformData]);

  // 处理成组
  const group = askGroupMedicalOrders({
    max: 5,
    order: ['DRUG'],
    eliminates: [
      ['drugOrder', 'administration'],
      ['drugOrder', 'dripSpeed'],
      ['drugOrder', 'frequency'],
      ['drugOrder', 'userDescription'],
      ['drugOrder', 'performDays'],
    ],
    tip({ onOk, onCancel }) {},
  });

  return (
    <>
      <VSPromiseTable
        editable={{ editType: 'none' }}
        vsid="18219"
        id="doctorOrderInfoList"
        scroll={{ x: 488, y: getScrollY(420) }}
        rowClick={{
          rowClickType: {
            action: 'click',
          },
          onRowClick: (record) => {
            setActivityRow(record);
            onSelectIdsChange([record?.id]);
            if (record === activityRow) {
              setActivityRow(undefined);
              onSelectIdsChange(undefined);
            }
          },
        }}
        rowClassName={(record) =>
          record?.id === activityRow?.id ? 'aspirin-row-selected-box' : ''
        }
        pagination={false}
        onFetch={() => fetchData}
        className="aspirin-table aspirin-table-search-padding-no aspirin-table-no-background-box"
        bordered
      >
        <VSTableColumn
          dataIndex={['repeatIndicator']}
          title="长/临"
          valueType="select"
          fieldProps={{}}
          width={100}
          render={(dom, value) => {
            if (value?.repeatIndicator) {
              return '长期';
            } else {
              return '临时';
            }
          }}
        />

        <VSTableColumn
          dataIndex={['orderClass']}
          title="医嘱 "
          valueType="select"
          dataSource={[
            { label: '西药', value: 'DRUG' },
            { label: '中药', value: 'HERB' },
            { label: '检验', value: 'LAB' },
            { label: '检查', value: 'EXAM' },
            { label: '治疗', value: 'TREAT' },
            { label: '手术', value: 'OPERATION' },
            { label: '麻醉', value: 'ANESTHESIA' },
            { label: '护理', value: 'NURSING' },
            { label: '膳食', value: 'MEAL' },
            { label: '营养', value: 'NUTRITION' },
            { label: '会诊', value: 'CONSULTATION' },
            { label: '用血', value: 'BLOOD' },
            { label: '其他', value: 'OTHER' },
            { label: '病理', value: 'PATHOLOGY' },
          ]}
          fieldProps={{
            showSearch: true,
          }}
          width={100}
        />

        <VSTableColumn
          dataIndex={['orderText']}
          title="医嘱内容"
          valueType="custom"
          fieldProps={{}}
          width={250}
          render={(dom, record) => {
            return (
              <Popover content={record?.orderText}>
                <div
                  className={classNames(
                    'text_ellipsis2',
                    'aspirin-order-text',
                    record?.groupName,
                  )}
                >
                  {record?.orderText}
                  {!dayjs(record?.submitDateTime).isToday() ? (
                    <Popover content="有新医嘱">
                      <Icon
                        type="icon-xinyizhu"
                        style={{ margin: '3px 0 0 5px' }}
                        size={18}
                      />
                    </Popover>
                  ) : (
                    ''
                  )}
                </div>
              </Popover>
            );
          }}
        />

        <VSTableColumn
          dataIndex={['dosage']}
          title="剂量"
          valueType="text"
          fieldProps={{}}
          width={100}
        />

        <VSTableColumn
          dataIndex={['administration']}
          title="给药途径"
          valueType="select"
          fieldProps={{}}
          width={100}
          dataSource={vsf.stores.dbenums?.enums?.ADMINISTRATION_DICT}
        />

        <VSTableColumn
          dataIndex={['billingAttribute']}
          title="计价标识"
          valueType="select"
          dataSource={[
            { label: '正常计价', value: 'COMMON_BILLING' },
            { label: '手工计价', value: 'MANUAL_BILLING' },
            { label: '不计价', value: 'NOT_BILLING' },
          ]}
          fieldProps={{}}
          width={100}
        />

        <VSTableColumn
          dataIndex={['frequency']}
          title="频次"
          valueType="text"
          fieldProps={{}}
          width={100}
        />

        <VSTableColumn
          dataIndex={['orderDepartment', 'departmentName']}
          title="开单科室"
          valueType="text"
          fieldProps={{}}
          width={100}
        />

        <VSTableColumn
          dataIndex={['performDepartment', 'departmentName']}
          title="执行科室"
          valueType="text"
          fieldProps={{}}
          width={100}
        />
        <VSTableColumn
          dataIndex={['startPerformDateTime']}
          title="开始时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          width={150}
        />

        <VSTableColumn
          dataIndex={['stopOrderDateTime']}
          title="停止时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          width={150}
        />
        <VSTableColumn
          dataIndex={['memo']}
          title="备注"
          valueType="text"
          fieldProps={{}}
          width={250}
        />
      </VSPromiseTable>
      <OrderBilleDetails activityRow={activityRow} />
    </>
  );
};
export default definePage(WardDoctorOrderInfo);
