import vsf from '@vs/vsf-boot';
// ** 住院退药确认：左侧病区待退药确认患者统计
export const getInpRefundApplyPatientCount = async (params) => {
  const res =
    await vsf?.services?.InpRefundDispenseController_getInpRefundApplyPatientCount_c54706?.(
      {},
    );
  return res?.data;
};
// 查询病区患者列表，瀑布流/分页，按床号排序
export const queryScrollWardPatientList = async (param) => {
  const res =
    await vsf?.services?.PatientInHosptialController_queryScrollWardPatientList_346269?.(
      {
        qto: {
          wardId: param?.wardId,
        },
      },
    );
  const output =
    convertQueryScrollWardPatientListRes2TableInpRefundApplyOrderToPerformRo?.(
      res,
    );
  console?.debug?.(JSON?.stringify?.(output));
  return output;
};
/**
 * convertQueryScrollWardPatientListRes2TableInpRefundApplyOrderToPerformRo
 * @param {any} input
 * @returns {any}
 */
const convertQueryScrollWardPatientListRes2TableInpRefundApplyOrderToPerformRo =
  (input) => {
    const _ = window._ ?? {};
    const output: any = []; // TODO 请确认下行字段是否符合逻辑
    output.push(
      ...(_.get(input, 'data.result')?.map((d, i) => {
        const obj = {
          id: d.id,
          PatientInHospitalId: d.id,
          inpVisitId: d.inpVisitId,
          bedLabel: d.bed?.bedLabel,
          name: d.patient?.name,
          displayId: d.patient?.displayId,
        };
        return obj;
      }) ?? []),
    );
    return output;
  };
export const getInpRefundApplyToPerform = async (params) => {
  const res =
    await vsf?.services?.InpRefundDispenseController_getInpRefundApplyToPerform_1a5feb?.(
      {
        qto: {
          ...params,
          wardIdIs: params?.wardId,
        },
      },
    );
  const output =
    convertGetInpRefundApplyToPerformRes2TableInpRefundApplyOrderToPerformRo?.(
      res,
    );
  return {
    data: output,
    total: res?.data?.count ?? res?.data?.length ?? 0,
  };
};
/**
 * convertGetInpRefundApplyToPerformRes2TableInpRefundApplyOrderToPerformRo
 * @param {any} input
 * @returns {any}
 */
const convertGetInpRefundApplyToPerformRes2TableInpRefundApplyOrderToPerformRo =
  (input) => {
    const _ = window._ ?? {};
    const output: any = [];
    output.push(
      ...(_.get(input, 'data')?.map((d, i) => {
        const obj: any = {};
        obj.id = _.get(d, 'id');
        obj.departmentName = _.get(
          d,
          'inpVisit.patientInHospitalBedDto.ward.departmentName',
        );
        obj.bedLabel = _.get(
          d,
          'inpVisit.patientInHospitalBedDto.bed.bedLabel',
        );
        obj.name = _.get(d, 'patient.name');
        obj.displayId = _.get(d, 'patient.displayId');
        obj.gender = vsf.stores.dbenums.enums.SEX_DICT?.find(
          (ele) => ele?.code == _.get(d, 'patient.gender'),
        )?.cname;
        obj.age = _.get(d, 'age');
        obj.orderList = [];
        obj.orderList.push(
          ...(_.get(d, 'drugPrescriptionWaitDispenseDetail')?.map((d, i) => {
            const obj: any = {};
            obj.id = _.get(d, 'id');
            obj.drugPriceItemId = _.get(d, 'drugPriceItem.id');
            obj.orderText = _.get(d, 'order.orderText');
            obj.firmName = _.get(
              d,
              'drugPriceItem.drugProduct.drugFirm.firmName',
            );
            obj.packageUnit = _.get(d, 'drugPriceItem.packageUnit');
            obj.amountAndUnit = _.get(d, 'amountAndUnit');
            obj.dosageAndUnit = _.get(d, 'dosageAndUnit');
            obj.packageSpecification = _.get(
              d,
              'drugPriceItem.packageSpecification',
            );
            obj.amount = _.get(d, 'amount');
            obj.administration = _.get(d, 'orderDrug.administration');
            obj.frequency = _.get(d, 'orderDrug.frequency.frequency');
            obj.prescriptionAttribute = _.get(d, 'prescriptionAttribute');
            obj.charge = _.get(d, 'inpBillingDetail.charge');
            obj.prescriptionWaitDispenseId = _.get(
              d,
              'prescriptionWaitDispenseId',
            );
            return obj;
          }) ?? []),
        );
        return obj;
      }) ?? []),
    );
    return output;
  };
export const performInpDrugRefund = async (input) => {
  const res =
    await vsf?.services?.InpRefundDispenseController_performInpDrugRefund_2060dd?.(
      { waitMasterIds: input },
    );
  return res;
};
