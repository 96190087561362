import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Badge,
  Cascader,
  Checkbox,
  CheckboxGroup,
  message,
  Modal,
  Section,
  Select,
  Slider,
  Tabs,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import classNames from 'classnames';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { isMinScreen } from '@/config';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import { Ellipsis } from '@/pages/Index/components';
import FormTitle from '@/pages/Index/components/form_title';
import { askGetTreeWhole, askTree } from '@/pages/Index/components/func/ask';
import {
  askFixed,
  askSearch,
  askTotalAmount,
} from '@/pages/Index/components/func/ask';
import LabItem from '@/pages/Index/manage/finance/labItem/tabs/LabItem';
const Index = (props, ref) => {
  const { handelOk, handelColor, onChange } = props;

  const [open, setOpen] = useState(false);
  const [dataSource1, setDataSource1] = useState<any>([]);
  const [levelOne1, setLevelOne1] = useState<any>([]);
  const [levelTwo1, setLevelTwo1] = useState<any>([]);
  const [levelThree1, setLevelThree1] = useState<any>([]);

  const [dataSource, setDataSource] = useState<any>([]);
  // 当前选中级
  const [levelOne, setLevelOne] = useState<any>([]);
  const [levelTwo, setLevelTwo] = useState<any>([]);
  const [levelThree, setLevelThree] = useState<any>([]);
  const [threeChildrenData, setThreeChildrenData] = useState<any>([]);
  // 已选中得
  const [hasChecked, setHasChecked] = useState<any>([]);
  // 批量分针行id
  const [rowId, setRowId] = useState();

  const handelOpen = (v = false, value?, id?) => {
    if (handelOk) {
      const _list = value?.map((i) => {
        const _find = threeChildrenData?.find(
          (val) => val?.chiefComplaintVsJudgeId == i?.chiefComplaintVsJudgeId,
        );
        return _find ? { ...i, diseaseLevel: _find?.diseaseLevel } : i;
      });
      setRowId(id);
      setHasChecked(_list);
    } else {
      // 预检分诊
      if (v) {
        const _list = value?.patientChiefComplaintVsJudgeDictionaryDtoList?.map(
          (i) => {
            const _find = threeChildrenData?.find(
              (val) =>
                val?.chiefComplaintVsJudgeId == i?.chiefComplaintVsJudgeId,
            );

            return _find ? { ...i, diseaseLevel: _find?.diseaseLevel } : i;
          },
        );
        // console.log(_list, '_list_list_list');

        setHasChecked(_list);
      }
    }

    setOpen(v);
  };

  const arrayToObjectByKey = (arr) => {
    const _arr: any = [];
    const _obj: any = {};
    arr?.forEach((i) => {
      if (!_obj[i?.chiefComplaint?.id]) {
        // chiefComplaintVsJudgeId: i?.id
        const _itemObj = {
          ...i?.chiefComplaint,
          chiefComplaintId: i?.chiefComplaint?.id,
          children: [
            { ...i?.chiefComplaintJudge, chiefComplaintVsJudgeId: i?.id },
          ],
        };
        _arr.push(_itemObj);
        _obj[i?.chiefComplaint?.id] = _itemObj;
      } else {
        _obj[i?.chiefComplaint?.id].children.push({
          ...i?.chiefComplaintJudge,
          chiefComplaintVsJudgeId: i?.id,
        });
      }
    });
    // console.log(_arr, '==============-');

    return _arr;
  };

  const getList = useCallback(async () => {
    if (!open) return [];
    const res =
      await vsf.services.ChiefComplaintDictionaryController_getChiefComplaintVsJudgeDiseaseFull_b4a4e9();
    if (res?.code == 200) {
      const _res = (res?.data ?? [])?.map((i, index) => {
        const _arr = arrayToObjectByKey(i?.chiefComplaintVsJudgeVoList);
        return {
          ...i,
          id: i?.baseCodeVo?.id,
          name: i?.baseCodeVo?.name,
          children: [..._arr]?.map((i) => {
            return {
              ...i,
            };
          }),
        };
      });
      const _thirdData = _res
        ?.map((i) => i?.children)
        .flat()
        ?.map((i) => i?.children)
        .flat();
      // console.log(_thirdData, _res, '_thirdData_thirdData');
      setLevelOne(_res?.[0]);
      // setLevelOne1(_res?.[0]);
      setLevelTwo(_res?.[0]?.children?.[0]);
      // setLevelTwo1(_res?.[0]?.children?.[0]);
      setThreeChildrenData(_thirdData);
      setDataSource([..._res]);
      setDataSource1([..._res]);
    }
  }, [open]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handelSearch = (v: any = {}) => {
    const res1 = v?.name1
      ? dataSource1?.filter((item) => {
          return (
            askSearch(v?.name1, item?.baseCodeVo, 'name') ||
            askSearch(v?.name1, item?.baseCodeVo, 'inputCode')
          );
        })
      : dataSource1;
    const res2 = v?.name2
      ? levelOne1?.children?.filter((item) => {
          return (
            askSearch(v?.name2, item, 'chiefComplaintName') ||
            askSearch(v?.name2, item, 'inputCode')
          );
        })
      : levelOne1?.children;

    const res3 = v?.name3
      ? levelTwo1?.children?.filter((item) => {
          return (
            askSearch(v?.name3, item, 'chiefComplaintJudgeName') ||
            askSearch(v?.name3, item, 'inputCode')
          );
        })
      : levelTwo1?.children;

    setDataSource([...res1]);
    setLevelOne({ ...levelOne, children: res2 });
    setLevelTwo({ ...levelTwo, children: res3 });
  };

  const renderEmpty = (width = '200px') => {
    return (
      <Empty
        className="empty"
        imgStyle={{
          width: width,
        }}
      />
    );
  };

  const handelDelete = (item) => {
    setHasChecked(
      hasChecked?.filter(
        (checkItem) =>
          checkItem?.chiefComplaintVsJudgeId != item?.chiefComplaintVsJudgeId,
      ),
    );
  };
  // 判断等级
  const judgeLevel = (items) => {
    if (!items || items?.length <= 0) return null;
    const maxItem = (items ?? []).reduce((prev, current) => {
      return prev.diseaseLevel?.diseaseLevel <
        current.diseaseLevel?.diseaseLevel
        ? prev
        : current;
    });
    return maxItem;
  };

  useImperativeHandle(ref, () => ({
    handelOpen,
  }));

  return (
    <div>
      <Modal
        centered
        width={isMinScreen ? 900 : 1200}
        open={open}
        bodyStyle={{
          height: isMinScreen ? 550 : 680,
          padding: 0,
        }}
        okText="确认 "
        cancelText="取消 "
        title={
          <div style={{ color: '#3276E8', fontWeight: 'bolder' }}>
            主诉及判定依据
          </div>
        }
        onOk={() => {
          // console.log(hasChecked, 'hasCheckedhasCheckedhasChecked');
          const hasCheckeList1 = hasChecked?.filter((i) => i?.secondId);
          const hasCheckeList2 = hasChecked?.filter((i) => !i?.secondId);
          const text = [...hasCheckeList1, ...hasCheckeList2]
            .map(
              (item) =>
                (item.secondName ?? '*') + ',' + item?.chiefComplaintJudgeName,
            )
            .join('；');

          if (handelOk) {
            handelOk(hasChecked, text, rowId);
          } else {
            // 预检分诊
            const _res = judgeLevel(hasChecked ?? []);

            vsf.refs.preExamMainForm.setFieldsValue({
              patientChiefComplaintVsJudgeDictionary: text,
              // patientChiefComplaintVsJudgeDictionaryList: hasChecked,
              patientChiefComplaintVsJudgeDictionaryDtoList: [
                ...hasCheckeList1,
                ...hasCheckeList2,
              ],
              referenceDiseaseLevelId: {
                ..._res?.diseaseLevel,
                label: _res?.diseaseLevel?.diseaseLevelName,
                value: _res?.diseaseLevel?.diseaseLevelCode,
              },
              referenceDiseaseLevelIds: _res?.diseaseLevel?.diseaseLevelName,
              diseaseLevel: {
                ..._res?.diseaseLevel,
                label: _res?.diseaseLevel?.diseaseLevelName,
                value: _res?.diseaseLevel?.diseaseLevelCode,
              },

              treatAreaCode: _res?.diseaseLevel?.treatArea,
            });
            handelColor(_res?.diseaseLevel?.colorCode);
            // vsf.refs.preExamMainForm.setFieldsValue({
            //   patientChiefComplaintVsJudgeDictionaryDtoList: hasChecked,
            // });
            onChange?.({
              diseaseLevel: {
                ..._res?.diseaseLevel,
                label: _res?.diseaseLevel?.diseaseLevelName,
                value: _res?.diseaseLevel?.diseaseLevelCode,
              },

              treatAreaCode: _res?.diseaseLevel?.treatArea,
            });
          }
          handelOpen(false);
        }}
        onCancel={() => {
          handelOpen(false);
        }}
      >
        <div className="action_cheif ">
          <div className="action_cheif_cascader">
            <div style={{ height: 102 }} className="">
              <FormTitle
                titleStytle={{
                  fontSize: 16,
                  padding: '16px 26px',
                }}
                headerStyle={{
                  paddingBottom: 0,
                }}
                borderBottom="none"
                style={{ padding: 0, background: 'transparent' }}
                bodyStytle={{ padding: '0 26px' }}
                title="判定依据列表"
                isNeedTag={true}
              >
                <VSForm
                  id="searchForm"
                  onChange={(v) => {
                    handelSearch(v);
                  }}
                >
                  <VSFormLayout columnCount={3}>
                    <VSFormItem
                      name={['name1']}
                      valueType="text"
                      fieldProps={{
                        placeholder: '主诉分类名称',
                      }}
                    />
                    <VSFormItem
                      name={['name2']}
                      valueType="text"
                      fieldProps={{
                        placeholder: '主诉',
                      }}
                    />
                    <VSFormItem
                      name={['name3']}
                      valueType="text"
                      fieldProps={{
                        placeholder: '判定依据',
                      }}
                    />
                  </VSFormLayout>
                </VSForm>
              </FormTitle>
            </div>
            <div className="action_cheif_cascader_content icon18">
              {dataSource && dataSource?.length > 0 ? (
                <div className="level1 level hideScroll">
                  {dataSource.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={classNames(
                          'item',
                          levelOne?.id == item?.id ? 'active_item' : '',
                        )}
                        onClick={() => {
                          const formValue =
                            vsf.refs.searchForm.getFieldsValue();

                          setLevelOne({
                            ...item,
                            children: formValue?.name2
                              ? item?.children?.filter((item) => {
                                  return (
                                    askSearch(
                                      formValue?.name2,
                                      item,
                                      'chiefComplaintName',
                                    ) ||
                                    askSearch(
                                      formValue?.name2,
                                      item,
                                      'inputCode',
                                    )
                                  );
                                })
                              : item?.children,
                          });
                          setLevelOne1(item);
                          setLevelTwo({});
                          setLevelTwo1({});
                          // setLevelTwo({
                          //   ...item?.children?.[0],
                          //   children: formValue?.name3
                          //     ? item?.children?.[0]?.children?.filter(
                          //         (item) => {
                          //           return (
                          //             askSearch(
                          //               formValue?.name3,
                          //               item,
                          //               'chiefComplaintJudgeName',
                          //             ) ||
                          //             askSearch(
                          //               formValue?.name3,
                          //               item,
                          //               'inputCode',
                          //             )
                          //           );
                          //         },
                          //       )
                          //     : item?.children?.[0]?.children,
                          // });
                          // setLevelTwo1(item?.children);
                          // 可以取消选择
                          if (levelOne?.id && item?.id == levelOne?.id) {
                            setLevelOne(null);
                            setLevelTwo(null);
                          } else {
                            setLevelOne(item);
                            // setLevelTwo(item?.children?.[0]);
                          }
                        }}
                      >
                        <div className="text">
                          <Ellipsis content={item?.name}>{item?.name}</Ellipsis>
                        </div>
                        <div className="icon">
                          <Icon type="icon-you" size={16} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="level1 level hideScroll">
                  {renderEmpty('180px')}
                </div>
              )}
              {levelOne?.children && levelOne?.children?.length > 0 ? (
                <div className="level2 level hideScroll icon18">
                  {levelOne?.children?.map((item, index) => {
                    const _arr = item?.children?.map(
                      (val) => val?.chiefComplaintVsJudgeId,
                    );
                    const _selectLength = hasChecked?.filter((i) => {
                      return _arr?.find((v) => i?.chiefComplaintVsJudgeId == v);
                    });

                    return (
                      <div
                        key={index}
                        className={classNames(
                          'item',
                          levelTwo?.id == item?.id ? 'active_item' : '',
                        )}
                        onClick={() => {
                          // setLevelTwo(item);
                          const formValue =
                            vsf.refs.searchForm.getFieldsValue();
                          setLevelTwo1(item);
                          setLevelTwo({
                            ...item,
                            children: formValue?.name3
                              ? item?.children?.filter((item) => {
                                  return (
                                    askSearch(
                                      formValue?.name3,
                                      item,
                                      'chiefComplaintJudgeName',
                                    ) ||
                                    askSearch(
                                      formValue?.name3,
                                      item,
                                      'inputCode',
                                    )
                                  );
                                })
                              : item?.children,
                          });
                          // setLevelThree(item?.children?.[0]);
                          // setLevelThree1(item?.children?.[0]);
                        }}
                      >
                        <div className="text">
                          <Ellipsis content={item?.chiefComplaintName}>
                            {item?.chiefComplaintName}
                          </Ellipsis>
                        </div>
                        {_selectLength?.length > 0 ? (
                          <Badge
                            color="#69A1FF"
                            count={_selectLength?.length}
                          />
                        ) : (
                          <div className="icon"></div>
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="level2 level hideScroll"> {renderEmpty()} </div>
              )}
              {levelTwo?.children && levelTwo?.children?.length > 0 ? (
                <div className="icon18 level3 level hideScroll">
                  {levelTwo?.children?.map((item, index) => {
                    return (
                      <div key={index} className="item">
                        <Checkbox
                          style={{
                            width: '100%',
                            display: 'flex',
                          }}
                          value={hasChecked?.some((i: any) => {
                            return (
                              i?.chiefComplaintVsJudgeId ==
                              item?.chiefComplaintVsJudgeId
                            );
                          })}
                          onChange={(v) => {
                            // console.log(v);

                            if (v) {
                              const _res = {
                                ...item,
                                firstId: levelOne?.id,
                                firstName: levelOne?.name,
                                secondId: levelTwo?.id,
                                secondName: levelTwo?.chiefComplaintName,
                                chiefComplaintJudgeName:
                                  item?.chiefComplaintJudgeName,
                                id: item?.id,
                              };

                              setHasChecked([
                                ...(hasChecked ?? []),
                                { ..._res },
                              ]);
                            } else {
                              const _res = (hasChecked ?? [])?.filter((i) => {
                                return !(
                                  i?.chiefComplaintVsJudgeId ==
                                  item?.chiefComplaintVsJudgeId
                                );
                              });

                              setHasChecked([..._res]);
                            }
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              display: 'flex',
                              // justifyContent: 'space-between',
                            }}
                            className="icon18"
                          >
                            <div
                              style={{
                                flex: 1,
                                flexShrink: 0,
                                marginRight: 5,
                                width: 197,
                              }}
                            >
                              <Ellipsis content={item?.chiefComplaintJudgeName}>
                                {item?.chiefComplaintJudgeName}
                              </Ellipsis>
                            </div>
                            <div
                              style={{
                                color: item?.diseaseLevel?.colorCode ?? '',
                                width: 30,
                                flexShrink: 0,
                                textAlign: 'right',
                                fontWeight: 'normal',
                              }}
                              className="icon18"
                            >
                              <Ellipsis
                                content={
                                  item?.diseaseLevel?.diseaseLevelName
                                    ? item?.diseaseLevel?.diseaseLevelName +
                                      '级'
                                    : ''
                                }
                              >
                                {item?.diseaseLevel?.diseaseLevelName
                                  ? item?.diseaseLevel?.diseaseLevelName + '级'
                                  : ''}
                              </Ellipsis>
                            </div>
                          </div>
                        </Checkbox>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="level3 level hideScroll">{renderEmpty()}</div>
              )}
            </div>
          </div>
          <div className="action_cheif_list icon18">
            <div style={{ height: 57 }}>
              <FormTitle
                titleStytle={{
                  fontSize: 16,
                  padding: '16px 26px',
                }}
                headerStyle={{
                  paddingBottom: 0,
                }}
                borderBottom="none"
                style={{ padding: 0, background: 'transparent' }}
                bodyStytle={{ padding: '0 26px' }}
                title="主诉及判定依据"
                isNeedTag={true}
              ></FormTitle>
            </div>
            <div className="action_cheif_list_content">
              {hasChecked?.length > 0
                ? hasChecked.map((i) => {
                    return (
                      <div key={i} className="item">
                        <div className="item_left">
                          <div className="text">
                            <Ellipsis content="pp">
                              {(i?.secondName ?? '') +
                                '，' +
                                (i?.chiefComplaintJudgeName ?? '')}
                            </Ellipsis>
                          </div>
                          <div className="level">
                            <Ellipsis content="pp">
                              {i?.diseaseLevel?.diseaseLevelName
                                ? i?.diseaseLevel?.diseaseLevelName + '级'
                                : ''}
                            </Ellipsis>
                          </div>
                        </div>
                        <div
                          className="item_right"
                          onClick={() => {
                            handelDelete(i);
                          }}
                        >
                          <Icon
                            type="icon-cuo"
                            size={16}
                            style={{ color: '#85898D', cursor: 'pointer' }}
                          />
                        </div>
                      </div>
                    );
                  })
                : renderEmpty('400px')}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default forwardRef(Index);
// GCS
