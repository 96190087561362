import React, { useState } from 'react';
import { VSTable, VSTableColumn } from '@vs/vsf-kit';
import { getScrollY, labelNumberWidth } from '@/utils';

import '@/style/adaptation.less';

export default function certificateContent(props) {
  const { dataStore, setDataStore } = props;
  const [selectedRows, setSelectedRows] = useState([]);

  return (
    <VSTable
      value={dataStore}
      rowSelection={{
        onSelectedRowsChange: (_value) => {
          props.onChange?.(_value);
        },
      }}
      //   单击
      rowClick={{
        rowClickType: 'select',
        onRowClick: (data) => {
          setDataStore({
            id: data,
          });
        },
      }}
      //   选中高亮
      rowClassName={(...arr) => {
        const [recode] = arr;
        return `aspirin-vs-table-row-box ${
          recode?.id === dataStore?.id?.id
            ? 'aspirin-row-selected-box'
            : 'aspirin-row-select-box'
        }`;
      }}
      bordered
      scroll={{
        y: getScrollY(300),
      }}
      pagination={false}
      id="listTable"
    >
      <VSTableColumn
        title="序号"
        valueType="index"
        width={80}
        render={(v, index, n) => {
          return n + 1;
        }}
      />
      <VSTableColumn
        title="患者ID"
        dataIndex={['name']}
        valueType="text"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="姓名"
        dataIndex={['name']}
        valueType="text"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="性别"
        dataIndex={['name']}
        valueType="text"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="年龄"
        dataIndex={['name']}
        valueType="text"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="就诊科室"
        dataIndex={['name']}
        valueType="text"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="接诊医生"
        dataIndex={['name']}
        valueType="text"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="诊断"
        dataIndex={['name']}
        valueType="text"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="工作单位或地址"
        dataIndex={['name']}
        valueType="text"
        width={labelNumberWidth(10)}
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="接诊时间"
        dataIndex={['name']}
        valueType="text"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="就诊类别"
        dataIndex={['name']}
        valueType="text"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="备注"
        dataIndex={['name']}
        valueType="text"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="打印次数"
        dataIndex={['name']}
        valueType="text"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="开立日期"
        dataIndex={['name']}
        valueType="text"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />
      <VSTableColumn
        title="开立医师"
        dataIndex={['name']}
        valueType="text"
        fieldProps={{}}
        ellipsis={{
          showTitle: true,
        }}
      />
    </VSTable>
  );
}
