import {
  Image,
  VSPromiseTable,
  VSTableColumn,
  VSForm,
  VSFormItem,
  VSFormLayout,
  TabPane,
  Tabs,
  Button,
  VSTable,
  Form,
  VSFormDependency,
} from '@vs/vsf-kit';
import {
  convertGetMedicalRecordVoByIdRes2DiagnosisInfoForm,
  convertGetMedicalRecordVoByIdRes2DiagnosisRecordTable,
} from '../convert';
import { useLockFn, useRequest, useSetState } from 'ahooks';
import { Select } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import moment from 'moment';
import React, { useCallback, useEffect, useState, useRef } from 'react';

import { askSearch } from '@/pages/Index/components/func/ask';
import { dayMessage, day } from '@/pattern';
import {
  getScrollY,
  getScrollYRem,
  labelColWidth,
  labelNumberWidth,
} from '@/utils';
import Block from '@/pages/Index/components/block_no_padding';
import Empty from '@/module/Empty';
import Icon from '@/module/Icon';
import Loading from '@/module/Loading';
import Tip from '@/pages/Index/components/func/Tip';
import vsf, { definePage } from '@vs/vsf-boot';

import './index.less';
import { isMinScreen } from '@/config';

const formItemWidth = 200;
const tempTrue = true;
const tempFalse = false;

const Index = (props) => {
  const { data, diagnosticForm } = props;
  const { user, common } = props?.stores ?? {};
  const { initKey, currentPatient, inVisitInfo } = user ?? {};
  const { setCurrentApplication, getServerTime } = common || {};
  const [dataStore, setDataStore] = useSetState({
    data: [],
    department: [],
  });
  // 双击table 行id
  const [currentRowId, setCurrentRowId] = useState();
  const tableRef = useRef();
  const [tableData, setTableData] = useState();
  const [showAdmissionAgainPurpose, setShowAdmissionAgainPurpose] =
    useState(false);
  useEffect(() => {
    setTableData(data);
    setShowAdmissionAgainPurpose(
      diagnosticForm?.haveAdmissionAgainPlan ?? false,
    );
  }, [data]);

  // 获取当前时间
  const getTime = useCallback(async () => {
    const res = await getServerTime?.();
    return res;
  }, [getServerTime]);

  // 点击行新增
  const onAddAndEdit = async (index, entity) => {
    const id = {
      id: 'create_' + Math.random(),
      diagnosisType: entity?.diagnosisType,
      isDelete: true,
    };
    setTableData(tableData.toSpliced(index + 1, 0, id));
  };
  // 点击行删除
  const onRemove = async (entity) => {
    vsf.refs.diagnosticInfoTable.remove(entity);
  };

  const getFormData = useCallback(async (params) => {
    return diagnosticForm;
  }, []);

  /**
   * 如果校验表格项
   */
  // const PricePatternTable = async () => {
  //   const res =
  //     await vsf.refs.PricePatternTable?.getEditForm()?.validateFieldsReturnFormatValue();
  //   if (res) {
  //     return res;
  //   } else {
  //     throw '有表格项未填写';
  //   }
  // };
  // const onSubmit = useLockFn(() => {
  //   Promise.all([
  //     vsf.refs.PricePattern.validateFieldsReturnFormatValue(),
  //     PricePatternTable(),
  //   ])
  //     .then(success)
  //     .catch((reject) => reject);
  // });

  return (
    <div className="homepageinfo_diagnostic_info hideScroll">
      <div className="homepageinfo_diagnostic_info_table">
        <VSTable
          vsid="27807"
          pagination={false}
          ref={tableRef}
          scroll={{
            // y: getScrollY(isMinScreen ? 370 : 642),
            y: getScrollYRem(43),
          }}
          editable={{
            editType: 'multiple',
            saveOnClickOutside: true,
            columnProps: {
              dataIndex: '__vs__table__edit',
              hideInTable: true,
            },
            onFieldChange: (key, value, record, form) => {
              if ('diagnosisDescriptions' in value) {
                console.log(value, '诊断');
                form.setFieldValue([key, 'diagnosisDescriptions'], {
                  ...value?.diagnosisDescriptions,
                  prefix: value?.diagnosisDescriptions?.prefix,
                  selectValue:
                    value?.diagnosisDescriptions?.diagnosisName ??
                    value?.diagnosisDescriptions?.diagnosisDescription,
                  suffix: value?.diagnosisDescriptions?.suffix,
                  diagnosis: value?.diagnosisDescriptions?.diagnosis
                    ?.diagnosisDescription
                    ? {
                        ...value?.diagnosisDescriptions?.diagnosis,
                        // diagnosisDescription:
                        //   value?.diagnosisDescriptions?.diagnosis
                        //     ?.diagnosisDescription,
                      }
                    : {
                        // ...value?.diagnosisDescriptions,
                        diagnosisDescription:
                          value?.diagnosisDescriptions?.diagnosisName ??
                          value?.diagnosisDescriptions?.selectValue,
                        diagnosisCode:
                          value?.diagnosisDescriptions?.diagnosisCode,
                        id: value?.diagnosisDescriptions?.id,
                      },
                });
                // form.setFieldValue(
                //   [key, 'diagnosis', 'diagnosisCode'],
                //   value?.diagnosisDescriptions?.diagnosisCode,
                // );
                form.setFieldValue(
                  [key, 'diagnosis'],
                  value?.diagnosisDescriptions?.diagnosis?.diagnosisDescription
                    ? {
                        ...value?.diagnosisDescriptions?.diagnosis,
                        diagnosisDescription:
                          value?.diagnosisDescriptions?.diagnosisName,
                      }
                    : {
                        // ...value?.diagnosisDescriptions,
                        diagnosisDescription:
                          value?.diagnosisDescriptions?.diagnosisName ??
                          value?.diagnosisDescriptions?.selectValue,
                        diagnosisCode:
                          value?.diagnosisDescriptions?.diagnosisCode,
                        id: value?.diagnosisDescriptions?.id,
                      },
                );
              }
            },
          }}
          rowClassName={(v) => {
            return v.id === currentRowId
              ? 'aspirin-table-row-select-background-color'
              : '';
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                setCurrentRowId(record?.id);
              },
            };
          }}
          onChange={setTableData}
          id="diagnosticInfoTable"
          className="
                  aspirin-table-header-background-color
                 aspirin-table-row-hover-background-color
                 aspirin-table-nopadding
                 aspirin-table-body-empty-transparent-background-color
        "
          value={tableData}
        >
          <VSTableColumn
            dataIndex={['diagnosisType']}
            title="诊断类别"
            editable={false}
            valueType="select"
            dataSource={vsf.stores.dbenums?.enums?.DIAGNOSIS_TYPE_DICT}
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 20 },
              ],
            }}
          />

          <VSTableColumn
            dataIndex={['diagnosisDescriptions']}
            title="诊断描述"
            valueType="aspirinSelectInput"
            formItemProps={
              {
                // rules: [{ required: true, message: '请选择诊断描述' }],
              }
            }
            fieldProps={{
              showSearch: true,
              searchKey: 'searchInputLike',
              dataSource: async (params) => {
                const res =
                  await vsf?.services?.BaseCommonController_queryPageFallBySearchPageFallDiagnosisDictionaryQto_9223e5?.(
                    {
                      qto: {
                        from: params?.pagination?.from ?? 0,
                        size: params?.pagination?.size ?? 2000,
                        orderList: params?.orderList,
                        ...params?.search,
                        ...params?.filters,
                        ...params?.extra,
                        ...(params ?? {}),
                      },
                      ext: {},
                    },
                  ); // TODO: 你可能需要整理返回数据格式
                return res?.data?.result?.map((item) => {
                  return { ...item, diagnosisDescription: item?.diagnosisName };
                });
              },
              fieldNames: { label: 'diagnosisDescription', value: 'id' },
              attributeName: 'diagnosisDescriptions',
            }}
            editable={(value, row) => {
              return (
                row?.diagnosisType !== '1' &&
                row?.diagnosisType !== '2' &&
                row?.diagnosisType !== '3' &&
                row?.diagnosisType !== 'A'
              );
            }}
            // render={(_, value) => {
            //   console.log(value,'valuew');
            //   return (
            //     <div>
            //       {value?.prefix}
            //       <span contenteditable="false">
            //         {/* {value?.diagnosisDescription} */}
            //       </span>
            //       {value?.suffix}
            //     </div>
            //   );
            // }}
          />

          <VSTableColumn
            dataIndex={['diagnosis', 'diagnosisCode']}
            title="诊断编码"
            // editable={false}
            preview
            valueType="text"
            formItemProps={{
              rules: [
                { message: '数据格式错误', type: 'string', min: 0, max: 50 },
              ],
            }}
          />

          <VSTableColumn
            dataIndex={['admissionCondition']}
            title="入院病情"
            valueType="select"
            fieldProps={{
              showSearch: true,
              dataSource: vsf.stores.dbenums.enums.ADMISSION_CONDITION_DICT,
            }}
            editable={(value, row) => {
              return row?.diagnosisType !== 'A';
            }}
          />

          <VSTableColumn
            dataIndex={['treatOutcome']}
            title="治疗转归情况"
            valueType="select"
            fieldProps={{
              showSearch: true,
              dataSource: vsf.stores.dbenums.enums.PROGNOSIS_DICT,
            }}
            editable={(value, row) => {
              return row?.diagnosisType !== 'A';
            }}
          />
          <VSTableColumn
            dataIndex={['__vs__table__edit']}
            title="操作"
            valueType="custom"
            width={80}
            renderFormItem={(dom, entity, form) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 5,
                  }}
                >
                  {
                    <Icon
                      style={{
                        color: '#3276e8',
                        visibility:
                          entity?.record?.diagnosisType !== '1' &&
                          entity?.record?.diagnosisType !== '2' &&
                          entity?.record?.diagnosisType !== '3' &&
                          entity?.record?.diagnosisType !== 'A'
                            ? 'visible'
                            : 'hidden',
                      }}
                      type="icon-tianjia1"
                      size={16}
                      onClick={(event) => {
                        event.stopPropagation();
                        onAddAndEdit(dom?.index, entity?.record);
                      }}
                    />
                  }
                  <Icon
                    type="icon-wudishanchu"
                    size={15}
                    onClick={(event) => {
                      event.stopPropagation();
                      /**
                       * 如果点击第一行只清空数据，不删除行isDelete为true，不是第一行
                       */

                      const _tableData = tableData?.filter((item) => {
                        return (
                          entity?.record?.diagnosisType == item?.diagnosisType
                        );
                      });
                      if (_tableData?.length == 1) {
                        // form?.resetFields();
                        // form?.setFieldValue([entity?.record?.id], null);
                        const _tableData1 = tableData?.map((item) => {
                          return item?.diagnosisType ==
                            _tableData?.[0]?.diagnosisType
                            ? {
                                diagnosisType: item?.diagnosisType,
                                id: 'create_' + Math.random(),
                              }
                            : item;
                        });
                        console.log(_tableData1);
                        setTableData(_tableData1);
                      } else {
                        onRemove(entity?.record);
                        // form?.setFieldValue([entity?.record?.id], null);
                      }
                      // if (entity?.record?.isDelete) {
                      //   onRemove(entity?.record);
                      // } else {
                      //   form?.setFieldValue([entity?.record?.id], null);
                      // }
                    }}
                  />
                </div>
              );
            }}
          />
        </VSTable>
      </div>
      <div className="homepageinfo_diagnostic_info_form hideScroll">
        <VSForm
          vsid="12949"
          id="diagnosisInfoForm"
          labelAlign="left"
          labelWrap
          onFetch={getFormData}
        >
          {/* <VSFormLayout key="0" columnCount={3} labelCol={labelColWidth[6]}> */}
          <VSFormLayout key="0" columnCount={3} labelCol={labelNumberWidth(10)}>
            <VSFormItem
              name={['haveInternalHospitalInfect']}
              label="有无院内感染"
              valueType="radio"
              fieldProps={{ width: formItemWidth, defaultValue: '2' }}
              dataSource={[
                { label: '有', value: '1' },
                { label: '无', value: '2' },
              ]}
            />
            <VSFormItem
              name={['internalHospitalInfectDate']}
              label="确诊日期"
              valueType="date"
              dataFormat="YYYY-MM-DD"
              fieldProps={{
                width: formItemWidth,
                onChange: async (v) => {
                  const res = await getTime();
                  const day = (res - v) / 86400000;
                  console.log(v, res, day);
                  vsf?.refs?.diagnosisInfoForm?.setFieldValue(
                    'internalHospitalInfectDays',
                    day.toFixed(1),
                  );
                },
              }}
            />

            <VSFormDependency name={['internalHospitalInfectDate']}>
              {(data) => {
                return (
                  <VSFormLayout
                    key="20"
                    columnCount={1}
                    labelCol={labelColWidth[6]}
                  >
                    <VSFormItem
                      name={['internalHospitalInfectDays']}
                      label="确诊天数"
                      valueType="text"
                      fieldProps={{
                        width: formItemWidth,
                      }}
                      convertValue={() => {
                        return data.internalHospitalInfectDate
                          ? Number(
                              dayjs(new Date())
                                .diff(
                                  data.internalHospitalInfectDate,
                                  'day',
                                  true,
                                )
                                .toFixed(1),
                            )
                          : null;
                      }}
                      rules={[
                        {
                          pattern: day,
                          message: dayMessage,
                        },
                      ]}
                    />
                  </VSFormLayout>
                );
              }}
            </VSFormDependency>

            <VSFormItem
              name={['topDiagnosisBasis']}
              label="最高诊断依据"
              valueType="custom"
              fieldProps={{
                width: formItemWidth,
                showSearch: true,
              }}
              // dataSource={vsf.stores.dbenums?.enums?.DIAGNOSIS_BASIS_DICT}
            >
              <Select
                options={vsf.stores.dbenums.enums.DIAGNOSIS_BASIS_DICT}
                showSearch
                placeholder="请选择最高诊断依据"
                filterOption={askSearch}
                style={{ width: formItemWidth }}
              />
            </VSFormItem>
            <VSFormItem
              name={['haveAdmissionAgainPlan']}
              label="出院31天内再住院计划"
              valueType="radio"
              fieldProps={{
                width: formItemWidth,
                defaultValue: tempFalse,
                onChange: (value) => {
                  console.log(value);
                  setShowAdmissionAgainPurpose(value);
                },
              }}
              dataSource={[
                { label: '有', value: tempTrue },
                { label: '无', value: tempFalse },
              ]}
            />
            {showAdmissionAgainPurpose && (
              <VSFormItem
                name={['admissionAgainPurpose']}
                label="再入院目的"
                valueType="text"
                formItemProps={{
                  style: {
                    display: 'none',
                  },
                }}
                rules={[
                  {
                    message: '数据格式错误',
                    type: 'string',
                    min: 0,
                    max: 200,
                  },
                ]}
                fieldProps={{
                  width: formItemWidth,
                  // required: true,
                }}
              />
            )}

            <VSFormItem
              name={['returnAdmission']}
              label="入院重返"
              valueType="select"
              dataSource={[
                { label: '无', value: 'NON' },
                { label: '当天', value: 'THE_SAME_DAY' },
                { label: '2-15天', value: 'TWO_TO_FIFTEEN' },
                { label: '16-31天', value: 'SIXTEEN_TO_THIRTY_ONE' },
              ]}
              fieldProps={{ width: formItemWidth }}
            />
            <VSFormItem
              name={['pathologyNumber']}
              label="病理号"
              valueType="text"
              rules={[
                {
                  message: '病理号长度不合法',
                  type: 'string',
                  min: 0,
                  max: 50,
                },
              ]}
              fieldProps={{ width: formItemWidth }}
            />
            <VSFormItem
              name={['havePathologyReportIndicator']}
              label="是否有病理报告"
              valueType="radio"
              dataSource={[
                { label: '有病理报告且未打印', value: tempFalse },
                { label: '无病理报告或已打印', value: tempTrue },
              ]}
              fieldProps={{
                width: 500,
              }}
            />
            <VSFormItem
              name={['dischargeWay']}
              label="出院方式"
              valueType="custom"
              fieldProps={{
                width: formItemWidth,
                showSearch: true,
              }}
              dataSource={vsf.stores.dbenums?.enums?.DISCHARGE_DISPOSITION_DICT}
            >
              <Select
                options={vsf.stores.dbenums.enums.DISCHARGE_DISPOSITION_DICT}
                showSearch
                placeholder="请选择出院方式"
                filterOption={askSearch}
                style={{ width: formItemWidth }}
              />
            </VSFormItem>
            <VSFormItem
              name={['tumourDifferentiation']}
              label="肿瘤分化程度(病理活检为恶性者填写):"
              valueType="select"
              dataSource={[
                { label: '高分化', value: 'HIGH' },
                { label: '中分化', value: 'MIDDLE' },
                { label: '低分化', value: 'LOW' },
                { label: '未分化', value: 'NON' },
                { label: '不确定', value: 'NOT_CONFIRM' },
              ]}
              // fieldProps={{ width: formItemWidth, showSearch: true }}
              fieldProps={{ showSearch: true }}
            />
            <VSFormLayout key="0" columnCount={3}>
              <VSFormItem
                name={['beforeAdmissionComaDays']}
                label="入院前昏迷时间"
                valueType="text"
                fieldProps={{
                  width: 45,
                  allowClear: false,
                  placeholder: '',
                }}
                addonAfter="天"
                style={{
                  fontSize: 16,
                }}
                rules={[
                  {
                    pattern: /^[1-9]\d*$/,
                    message: '请输入正整数',
                  },
                ]}
              />
              <VSFormItem
                style={{ marginLeft: 60 }}
                name={['beforeAdmissionComaHour']}
                // label="入院前颅脑损伤患者昏迷时间（小时）"
                valueType="text"
                rules={[
                  {
                    pattern: /^23$|^(?:\d|1\d|2[0-3])?$/,
                    message: '请输入0-23的正整数',
                  },
                ]}
                fieldProps={{ width: 45, allowClear: false, placeholder: '' }}
                addonAfter={<div style={{ fontSize: 16 }}>时</div>}
              />
              <VSFormItem
                name={['beforeAdmissionComaMinute']}
                // label="入院前颅脑损伤患者昏迷时间（分）"
                valueType="text"
                rules={[
                  {
                    pattern: /^59$|^(?:\d|[1-5]\d)?$/,
                    message: '请输入0-59的正整数',
                  },
                ]}
                fieldProps={{ width: 45, placeholder: '', allowClear: false }}
                addonAfter={
                  <div style={{ marginLeft: -40, fontSize: 16 }}>分</div>
                }
                style={{ marginLeft: 12 }}
              />
            </VSFormLayout>
            <VSFormLayout>
              <VSFormItem
                name={['afterAdmissionComaDays']}
                label="入院后昏迷时间"
                valueType="text"
                rules={[
                  {
                    pattern: /^23$|^(?:\d|1\d|2[0-3])?$/,
                    message: '请输入0-23的正整数',
                  },
                ]}
                fieldProps={{ width: 45, placeholder: '', allowClear: false }}
                addonAfter={<div style={{ fontSize: 16 }}>天</div>}
              />
              <VSFormItem
                name={['afterAdmissionComaHour']}
                // label="入院后颅脑损伤患者昏迷时间（小时）"
                valueType="text"
                rules={[
                  {
                    pattern: /^23$|^(?:\d|1\d|2[0-3])?$/,
                    message: '请输入0-23的正整数',
                  },
                ]}
                style={{ marginLeft: 60 }}
                fieldProps={{ width: 45, placeholder: '', allowClear: false }}
                addonAfter={<div style={{ fontSize: 16 }}>时</div>}
              />
              <VSFormItem
                name={['afterAdmissionComaMinute']}
                // label="入院后颅脑损伤患者昏迷时间（分）"
                valueType="text"
                rules={[
                  {
                    pattern: /^59$|^(?:\d|[1-5]\d)?$/,
                    message: '请输入0-59的正整数',
                  },
                ]}
                fieldProps={{ width: 45, placeholder: '', allowClear: false }}
                addonAfter={
                  <div style={{ marginLeft: -45, fontSize: 14 }}>分</div>
                }
                style={{ marginLeft: 12 }}
              />
            </VSFormLayout>
          </VSFormLayout>
          {/* <VSFormLayout columnCount={1} labelCol={labelColWidth[6]}> */}
          <VSFormLayout columnCount={1} labelCol={labelNumberWidth(8)}>
            <VSFormItem
              name={['diagnosisCompareRecordDtoList']}
              label="诊断符合情况"
              valueType="selectList"
              fieldProps={{
                selectDataSource: [
                  { label: '未做', value: 'WAIT_HANDLE' },
                  { label: '符合', value: 'CORRESPOND' },
                  { label: '不符合', value: 'NOT_CORRESPOND' },
                  { label: '不肯定', value: 'UNKNOWN' },
                ],
                dataMenu: vsf.stores.dbenums?.enums?.DIAG_COMP_GROUP_DICT,
              }}
            />
          </VSFormLayout>
        </VSForm>
      </div>
    </div>
  );
};
export default definePage(Index);
