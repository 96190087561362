import { createServices } from '@vs/vsf-boot';
export default createServices({
  ClinicDoctorWorkShiftController_createDoctorWorkMaster_7adc67: {
    method: 'post',
    url: '/api/clinic-doctor-work-shift/create-doctor-work-master',
    parameterFomatter: (data?: {
      bto: CreateWorkShiftMasterBtoClinicDoctorWorkShiftServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ClinicDoctorWorkShiftController_saveWorkShiftPatient_a8fcd1: {
    method: 'post',
    url: '/api/clinic-doctor-work-shift/save-work-shift-patient',
    parameterFomatter: (data?: {
      bto: CreateDoctorWorkShiftSeriousPatientBtoClinicDoctorWorkShiftServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ClinicDoctorWorkShiftController_updateDoctorWorkMaster_b5e5b1: {
    method: 'post',
    url: '/api/clinic-doctor-work-shift/update-doctor-work-master',
    parameterFomatter: (data?: {
      bto: CreateWorkShiftMasterBtoClinicDoctorWorkShiftServiceBto;
      staffRecordBto: CreateDoctorWorkShiftStaffRecordBtoClinicDoctorWorkShiftServiceBto;
      patientList: UpdateDoctorWorkShiftSeriousPatientBtoClinicDoctorWorkShiftServiceBto[];
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ClinicDoctorWorkShiftController_getDoctorWorkShift_c36740: {
    method: 'post',
    url: '/api/clinic-doctor-work-shift/get-doctor-work-shift',
    parameterFomatter: (data?: {
      masterQto: DoctorWorkShiftMasterStatisticsQtoClinicDoctorWorkShiftPersistQto;
      patientQto: DoctorWorkShiftSeriousPatientWithStaffBedGroupDiagnosisQtoClinicDoctorWorkShiftPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DoctorWorkShiftVoClinicDoctorWorkShiftEntranceWebVo,
    ) => data,
  },
  ClinicDoctorWorkShiftController_getWorkShiftPatientList_5eaf53: {
    method: 'post',
    url: '/api/clinic-doctor-work-shift/get-work-shift-patient-list',
    parameterFomatter: (data?: {
      qto: ClinicBedWithPatientQtoClinicBedPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: WorkShiftPatientVoClinicDoctorWorkShiftEntranceWebVo[],
    ) => data,
  },
  PatientJourneyController_getPatientWorkShiftOrder_fef344: {
    method: 'post',
    url: '/api/clinic-record-order/get-patient-work-shift-order',
    parameterFomatter: (data?: {
      qto: OrderWithDrugHerbDisposalQtoClinicRecordOrderPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrderWithDrugHerbDisposalVoClinicRecordOrderEntranceWebVo[],
    ) => data,
  },
  ClinicWorkShiftSettingController_getDepartmentWordShiftNotifyTemplate_ebf666:
    {
      method: 'post',
      url: '/api/clinic-work-shift-setting/get-department-word-shift-notify-template',
      parameterFomatter: (data?: any) => data,
      responseFormatter: (
        _,
        __,
        data?: WordShiftNotifyTemplateBaseVoClinicWorkShiftSettingEntranceWebVo[],
      ) => data,
    },
});
