import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Divider,
  Select,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import { omitBy } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { CertificateSearch } from '@/components';
import { deepClone } from '@/module/cpoe/medical/editor/utils';
import { Flex } from '@/pages/Index/components';
import { useOnChangeValue } from '@/pages/Index/components/func/hooks';
import {
  getIdentification,
  getPromiseHeight,
  getScrollY,
  getScrollYRem,
  labelColWidthRem,
} from '@/utils';

import { isEmpty } from '../../MedicalTechnology/OutpMedicalTechnologyCharge/methods';
import {
  getHerbDispenseCountForInpRefundDrugApply,
  getPatientDetall,
  queryScrollWardPatientList,
} from './services';

const Search = (props) => {
  const { select } = props;
  const planDateTime = [
    dayjs()?.subtract(7, 'day')?.valueOf(),
    dayjs()?.valueOf(),
  ];

  const search = useRef<any>({
    dispenseTimeEnd: planDateTime[1],
    dispenseTimeStart: planDateTime[0],
  });
  const table = useRef<any>(null);
  const onFetch = useCallback(async (params) => {
    delete search?.current.dispenseTime;
    const inquire_form = vsf?.refs?.inquire_form.getFieldValue();
    // console.log(inquire_form, '7474_inquire_form');
    const res = await getHerbDispenseCountForInpRefundDrugApply({
      query: {
        ...search?.current,
        // patientId: inquire_form?.patientId,
      },
    });
    return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onClickRow = (node?: any) => select?.onChange?.(node);
  const { data: WardDataSource } = useRequest(queryScrollWardPatientList);
  const onHandleDataMethods = useCallback(async (key, value) => {
    switch (key) {
      // 患者ID
      case 'patientId':
        return (
          getIdentification(value) &&
          (await getPatientDetall(getIdentification(value)))
        );
      case 'dispenseTime': {
        const [dispenseTimeStart, dispenseTimeEnd] = value;
        return { dispenseTimeStart, dispenseTimeEnd };
      }
      case 'inpVisitId':
        return value?.inpVisitId ?? '';
      default:
        return value;
    }
  }, []);
  const onInquire = async () => {
    const searchs = vsf?.refs?.inquire_form?.getFieldsValue();
    const searchs_keys = Object?.keys(searchs);
    let values = { dispenseTime: [] };
    let i = 0;
    while (searchs_keys?.length > i) {
      if (!isEmpty(searchs[searchs_keys[i]]))
        values = {
          ...values,
          [searchs_keys[i]]: await onHandleDataMethods(
            searchs_keys[i],
            searchs[searchs_keys[i]],
          ),
        };
      i++;
    }
    values = { ...values, ...values.dispenseTime };
    search.current = omitBy(values, (value) => isEmpty(value));
    table?.current?.reload();
    onClickRow();
  };
  return (
    <Flex vertical>
      <Flex gap={10} vertical>
        <VSForm labelAlign="left" id="inquire_form">
          <VSFormLayout labelCol={labelColWidthRem('4rem')} columnCount={1}>
            {/* <VSFormItem label="患者ID" name={['patientId']} valueType="text" /> */}
            <VSFormItem label="" name={['patientId']} valueType="custom">
              <CertificateSearch />
            </VSFormItem>
            <VSFormItem label="姓名" valueType="text" name={['nameLike']} />
            <VSFormItem
              label="床号"
              name={['inpVisitId']}
              valueType="selectCode"
              fieldProps={{
                placeholder: '请选择床号',
                allowClear: true,
              }}
              dataSource={WardDataSource}
              fieldNames={{ label: 'name', value: 'id' }}
              searchKey="searchText"
            />
            <VSFormItem
              valueType="dateRange"
              name={['dispenseTime']}
              initialValue={planDateTime}
              fieldProps={{
                format: 'YYYY/MM/DD HH:mm:ss',
              }}
              transform={(value) => {
                const [dispenseTimeStart, dispenseTimeEnd] = Object.values(
                  value,
                )?.map((item: any) => dayjs(item).valueOf());
                return {
                  dispenseTimeStart,
                  dispenseTimeEnd,
                };
              }}
            />
          </VSFormLayout>
        </VSForm>
        <Button type="primary" onClick={onInquire}>
          查询
        </Button>
      </Flex>
      <Divider
        style={{
          background: 'var(--blue)',
        }}
      />

      <VSPromiseTable
        onFetch={onFetch}
        className="common_table"
        rowClassName={(record) => {
          return record?.id === select?.value?.id
            ? 'aspirin-row-selected-box'
            : 'aspirin-row-select-box';
        }}
        pagination={false}
        scroll={{
          y: getScrollYRem(34.6),
        }}
        onRow={(record) => ({
          onClick: () => {
            if (record?.id === select?.value?.id) return onClickRow?.();
            onClickRow?.(record);
          },
        })}
        ref={table}
      >
        <VSTableColumn
          dataIndex={['name']}
          title="姓名"
          valueType="text"
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['bedLabel']}
          title="床号"
          valueType="text"
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['dispenseCount']}
          title="可退处方数量"
          valueType="digit"
          fieldProps={{}}
        />
      </VSPromiseTable>
    </Flex>
  );
};

export default definePage(Search);
