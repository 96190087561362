import vsf, { definePage } from '@vs/vsf-boot';
import { message } from '@vs/vsf-kit';
import React, { useContext, useState } from 'react';
import ImportEdit from './edit';
import Container from '@/module/Container';
import Block from '@/module/Block';
import OutDrawer from './outdrawer';
import DrugAccounting from './accounting';
import ButtonAndKeyDown from '@/pages/Index/components/button_and_keydown';
import { CreateScreen } from '@/pages/Index/components/screen';
import Tip from '@/pages/Index/components/func/Tip';
import Screen from '../../../../components/screen';
import DrugModal from './DrugModal';
import { useRequest } from 'ahooks';

const DrugImport = () => {
  /**
   * 清屏
   */
  const onScreen = useContext(CreateScreen);

  const [selectTableParam, setSelectTableParam] = useState();

  /**
   * 首次进入页面默认打开待记账单据抽屉
   */
  const [disabledAccountStatus, setDisabledAccountStatus] = useState(true);

  const [isSave, setIsSave] = useState();

  const businessType = vsf.stores.dbenums.enums.DRUG_IMPEXP_CLASS_DICT;

  const outBusinessType = vsf.stores.dbenums.enums.DRUG_IMPEXP_CLASS_DICT;

  const [drugSupplyData, setDrugSupplyData] = useState([]);

  //记账后返回的药品供应信息
  const { run: importTableDataRequest } = useRequest(
    async (param) => {
      const res =
        await vsf.services?.DrugImportRecordCreateController_getDrugImportMasterWithDetail_19192c(
          { id: param },
        );
      vsf?.refs?.ImportStashForm?.setFieldsValue({
        ...res?.data,
        subStorageId: res?.data?.subStorage?.id,
        drugImportDetailWithProductDtoList:
          res?.data?.drugImportDetailWithProductDtoList
            ?.map((ele, index) => ({
              ...ele,
              index,
              drugProductId: ele?.drugProductId ?? ele?.drugProduct?.id,
            }))
            ?.sort((a, b) => b?.index - a?.index),
        batch_number: 1,
      });
      // console.log(object);
      return res?.data?.drugImportDetailWithProductDtoList;
    },
    { manual: true },
  );

  const selectTableParamAction = (data) => {
    setDisabledAccountStatus(false);
    setSelectTableParam(data);
  };

  // 一把保存
  const handleAdd = async () => {
    let tableDataParam;
    const [tableData] = await Promise?.all([
      vsf?.refs?.InventoryListOfDrugsTable?.getEditForm()?.validateFieldsReturnFormatValue(),
    ]);
    const param = tableData ? Object.values(tableData) : {};
    vsf?.refs?.ImportStashForm?.validateFields().then(async (val) => {
      const data = vsf?.refs?.InventoryListOfDrugsTable?.getValues();
      const formatData = data.map((d) => {
        const dict = {
          ...d,
        };
        if (('' + d.id).startsWith('create_')) {
          const { id, ...rest } = dict;
          return rest;
        } else {
          dict.firmId = dict?.drugProduct?.drugFirm?.id;
          return dict;
        }
      });
      if (Array.isArray(param)) {
        tableDataParam = formatData?.map((item, index) => {
          return {
            ...item,
            batchNumber: param[index]?.batchNumber,
            expirationDate: param[index]?.expirationDate,
            amount: param[index]?.amount,
          };
        });
      } else {
        let arr = [param];

        tableDataParam = formatData?.map((item, index) => {
          return {
            ...item,
            batchNumber: arr[index]?.batchNumber,
            expirationDate: arr[index]?.expirationDate,
          };
        });
      }
      const bto = {
        ...selectTableParam,
        ...val,
        id: val?.id || isSave,
        subStorageId: val?.subStorageId?.id,
        accountantStatus: 'WAIT_ACCOUNTANT',
        drugImportDetailBtoList: tableDataParam,
        exportImportCode: val?.code,
        firmId: val?.drugProduct?.drugFirm?.id,
        supplierId: val?.supplierId?.id ?? val?.supplierId, //
        exportImportCode: val?.exportImportCode?.code,
        drugExportMasterId: selectTableParam?.id,
      };

      delete bto.drugImportDetailWithProductDtoList;
      delete bto.drugProvideNoticeDto;
      const params = {
        bto,
      };
      if (selectTableParam?.drugProvideNoticeId) {
        const filterData = data?.map((item) => {
          return {
            ...item,
            // amountPerPackage: item?.amount,
            drugProductId: item?.drugProduct?.drugId,
            firmId: item?.firm?.id,
            drugProductName: item?.drugProduct?.drugProductName,
            unit: item?.unit ?? item?.drugProduct?.unit,
          };
        });

        bto['drugImportDetailBtoList'] = filterData;
        bto['cost'] = 121;
        bto['exportImportCode'] = '04';
      }

      const res =
        await vsf.services?.DrugImportRecordCreateController_createOrUpdateDrugStorageImportRecord_be0cad?.(
          params,
        );
      setDisabledAccountStatus(false);
      if (res.code === 200) {
        setIsSave(res.data);
        importTableDataRequest(res?.data);
        message.success('入库信息已保存');
        vsf.refs.accountingPaperTable.reload();
      } else {
        message.error('入库信息保存失败');
      }
    });
  };

  // 记账
  const handleCharge = async () => {
    let tableDataParam;
    const [tableData] = await Promise?.all([
      vsf?.refs?.InventoryListOfDrugsTable?.getEditForm()?.validateFieldsReturnFormatValue(),
    ]);
    const param = Object.keys(tableData)?.length
      ? Object.values(tableData)
      : Object.values(await vsf?.refs?.InventoryListOfDrugsTable?.getValues());
    vsf?.refs?.ImportStashForm?.validateFields().then(async (val) => {
      const data = vsf?.refs?.InventoryListOfDrugsTable?.getValues();
      const formatData = data.map((d) => {
        const dict = {
          ...d,
        };
        if (('' + d.id).startsWith('create_')) {
          const { id, ...rest } = dict;
          return rest;
        } else {
          dict.firmId = dict?.drugProduct?.drugFirm?.id ?? dict?.firm?.id;
          return dict;
        }
      });

      if (Array.isArray(param)) {
        tableDataParam = formatData?.map((item, index) => {
          return {
            ...item,
            batchNumber: param[index]?.batchNumber,
            expirationDate: param[index]?.expirationDate,
            amount: param[index]?.amount,
            drugProductId: item?.drugProductId ?? item?.id,
            unit: item?.unit ?? item?.drugProduct?.unit,
          };
        });
      } else {
        let arr = [param];
        tableDataParam = formatData?.map((item, index) => {
          return {
            ...item,
            batchNumber: arr[index]?.batchNumber,
            expirationDate: arr[index]?.expirationDate,
          };
        });
      }
      delete val.drugImportDetailWithProductDtoList;
      // console.log(selectTableParam, 'drugImport_selectTableParam');
      const params = {
        bto: {
          ...selectTableParam,
          ...val,
          cost: val?.cost ?? 0.5,
          id: val?.id || isSave,
          subStorageId: val?.subStorageId?.id,
          accountantStatus: 'WAIT_ACCOUNTANT',
          // accountantStatus: 'ACCOUNTANT',
          drugImportDetailBtoList: tableDataParam,
          firmId: val?.drugProduct?.drugFirm?.id,
          supplierId: val?.supplierId?.id,
          exportImportCode:
            val?.exportImportCode?.code ?? val?.exportImportCode,
          drugExportMasterId: selectTableParam?.id,
        },
      };
      // console.log(params, 'drugImport_params');
      // const res =
      //   await vsf.services?.DrugImportRecordCreateController_drugImportRecordAccounting_4cc5e6?.(
      //     params,
      //   );
      // setDisabledAccountStatus(false);

      // if (res?.code === 200) {
      //   setIsSave(res.data);
      //   // 清空数据
      //   onScreen(() => {
      //     message.success('入库信息记账成功');
      //   });
      //   vsf?.refs?.accountingPaperTable?.reload();
      // } else {
      //   message.error('记账失败或者重复记账');
      //   vsf?.refs?.ImportStashForm?.resetFields();
      // }

      const res =
        await vsf.services?.DrugImportRecordCreateController_createOrUpdateDrugStorageImportRecord_be0cad?.(
          params,
        );
      if (res?.code === 200) {
        setIsSave(res.data);
        // 记账后回显数据
        importTableDataRequest(res?.data);
        vsf?.refs?.accountingPaperTable?.reload().then(() => {
          vsf?.refs?.ImportStashForm?.validateFields().then(async (val) => {
            const data = vsf?.refs?.InventoryListOfDrugsTable?.getValues();
            const formatData = data.map((d) => {
              const dict = {
                ...d,
                // amountPerPackage: d.amount,
              };
              if (('' + d.id).startsWith('create_')) {
                const { id, ...rest } = dict;
                return rest;
              } else {
                dict.firmId = dict?.drugProduct?.drugFirm?.id;
                return dict;
              }
            });
            const params = {
              bto: {
                ...selectTableParam,
                ...val,
                subStorageId: val?.subStorageId?.id,
                drugImportDetailBtoList: tableDataParam,
                exportImportCode:
                  val?.exportImportCode?.code ?? val?.exportImportCode,
                supplierId: val?.supplierId?.id,
                firmId: val?.drugProduct?.drugFirm?.id,
                accountantStatus: 'WAIT_ACCOUNTANT',
              },
            };
            const res =
              await vsf.services?.DrugImportRecordCreateController_drugImportRecordAccounting_4cc5e6?.(
                params,
              );

            if (res?.code === 200) {
              message.success('记账成功');
              setDrugSupplyData(
                res?.data?.map((e) => ({
                  ...e,
                  details: tableDataParam?.find(
                    (f) => f?.drugProductId === e?.drugProductId,
                  ),
                })),
              );
              setSelectTableParam(undefined);
              vsf?.refs?.ImportStashForm?.resetFields();

              vsf?.refs?.ImportStashForm?.setFieldsValue({
                documentNumber: new Date()
                  .toLocaleString()
                  .replaceAll('/', '')
                  .replaceAll(':', '')
                  .replace(/\s/, ''),
              });
            } else {
              message.error('记账失败或者重复记账');
              vsf?.refs?.ImportStashForm?.resetFields();
            }
          });
        });
      } else {
        message.error('记账失败或者重复记账');
        vsf?.refs?.ImportStashForm?.resetFields();
      }
    });
  };

  const button = [
    {
      text: '保存',
      ordinaryKey: 'S',
      methodKey: 'altKey',
      disabled: selectTableParam?.drugProvideNoticeId,
      onClick: () => {
        handleAdd();
      },
    },
    {
      text: '打印',
      ordinaryKey: 'P',
      methodKey: 'altKey',
      disabled: false,
      onClick: () => {
        print();
      },
    },
    {
      text: '导入',
      ordinaryKey: 'D',
      methodKey: 'altKey',
      disabled: true,
      onClick: () => {},
    },
    {
      text: '记账',
      ordinaryKey: 'A',
      methodKey: 'altKey',
      disabled: false,
      onClick: () => {
        handleCharge();
      },
    },

    {
      text: '清屏',
      ordinaryKey: 'R',
      methodKey: 'altKey',
      disabled: false,
      onClick: onScreen,
    },
  ];

  const footer = () => {
    return (
      <div className="aspirin-table-footer-button">
        {button.map((item) => (
          <ButtonAndKeyDown key={item.text} {...item} type="primary">
            {item.text}
          </ButtonAndKeyDown>
        ))}
      </div>
    );
  };

  return (
    <>
      <DrugModal drugSupplyData={drugSupplyData}></DrugModal>
      {/* 入库单 */}
      <DrugAccounting
        businessType={businessType}
        selectTableParamAction={selectTableParamAction}
      />
      {/* 出库单 */}
      <OutDrawer
        outBusinessType={outBusinessType}
        selectTableParamAction={selectTableParamAction}
      />
      <Container
        Container
        layout={{
          items: [
            {
              block: 1,
              bgColor: '#ffffff',
              // padding: '24px'
            },
          ],
        }}
      >
        <Block footer={footer()}>
          <ImportEdit
            selectTableParam={selectTableParam}
            disabledAccountStatus={disabledAccountStatus}
          />
        </Block>
      </Container>
    </>
  );
};

const DrugImportMain = () => {
  return (
    <Screen>
      <DrugImport></DrugImport>
    </Screen>
  );
};

export default DrugImportMain;
