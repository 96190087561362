import vsf from '@vs/vsf-boot';
import {
  Col,
  compose,
  Form,
  withField,
  withPreview,
  withRef,
} from '@vs/vsf-kit';
import { useUpdateEffect } from 'ahooks';
import { Table } from 'antd';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import ComplexSelect from '../complex_select';
import { useInputEnter } from '../dosage/utils';
import { askFixed } from '../func/ask';

/**
 * DrugSelect
 */
const DrugStockBatchNumber = forwardRef((props: any, ref) => {
  const {
    value,
    onFieldEnter,
    onKeyUp,
    onChange,
    record,
    id: key,
    ...rest
  } = props;
  const fieldEnterProps = useInputEnter({ onFieldEnter, onKeyUp });

  const form = Form?.useFormInstance();
  // const key = Object?.keys(form?.getFieldsValue())?.at(-1) as string;
  const stockList = record?.stockList ?? record?.drugStockList;

  /**
   * 获取已选批号，进行过滤处理
   */
  const otherSelect = vsf?.refs?.ExportRecord?.getFieldValue(
    'drugExportDetailBtoList',
  )
    ?.map((item) => item?.stockId ?? item?.productId)
    ?.filter((item) => {
      return item !== record?.stockId;
    });

  const list = stockList
    ?.filter((item) => {
      return !otherSelect?.includes(item?.id);
    })
    ?.map((item) => ({
      ...item,
      key: item?.id,
      stockAmount: item.amount,
    }));

  const [dataSource, setDataSource] = useState([]);

  const columns = [
    {
      title: '批号',
      dataIndex: ['batchNumber'],
      key: 'batch_number',
    },
    {
      title: '结存',
      dataIndex: ['stockAmount'],
      key: 'stock_amount',
      render: (...[, record]) => {
        const newLocal = askFixed?.(
          (record?.stockAmount ?? 0) / (record?.amountPerPackage ?? 0),
          2,
        );
        return newLocal;
      },
    },
    {
      title: '有效期',
      dataIndex: ['expirationDate'],
      key: 'expiration_date',
    },
  ];
  const table = useRef<any>(null);

  useEffect(() => {
    setDataSource(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockList]);

  useImperativeHandle(ref, () => ({
    ...table?.current,
  }));

  return (
    <ComplexSelect
      hideSelectIcon
      mode="radio"
      showLabel="batchNumber"
      value={value}
      onChange={(value) => {
        console.log(value);
        form.setFieldValue(key, {
          ...form?.getFieldValue(key),
          retailPrice: value?.retailPrice,
          expirationDate: value?.expirationDate,
          batchNumber: value?.batchNumber,
          purchasePrice: value?.retailPrice,
          amountPerPackage: value?.amountPerPackage,
          drugStockLocation: value?.location,
          stockId: value?.id,
        });
        onChange(value.batchNumber);
      }}
      onSearch={(inputCode, filterOption) => {
        setDataSource(filterOption(inputCode, list));
      }}
      ref={table}
      dropdownMatchSelectWidth={false}
      dropdownStyle={{
        width: 700,
      }}
      listHeight={1000}
      {...rest}
      {...fieldEnterProps}
      placeholder={
        dataSource?.length
          ? '输入批号相关/首字母可搜索'
          : '选择药品后才可以选批号'
      }
    >
      <Table
        rowClassName="aspirin-table-row-box"
        columns={columns}
        scroll={{
          y: 400,
        }}
        size="middle"
        pagination={false}
        dataSource={dataSource}
      />
    </ComplexSelect>
  );
});

DrugStockBatchNumber.displayName = 'drugStockBatchNumber';

export default compose(
  withRef(),
  withField<any>({
    name: 'drugStockBatchNumber',
    handleEnter: true,
  }),
  withPreview<any>({
    renderPreview: (props) => {
      const { value } = props;
      return <>{value}</>;
    },
  }),
)(DrugStockBatchNumber) as typeof DrugStockBatchNumber;
