import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Section,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { CertificateSearch } from '@/components';
import { isMinScreen } from '@/config';
import Container from '@/module/Container';
import SearchContainer from '@/module/Search';
import TableRender from '@/pages/Index/components/func/TableRender';
import { getScrollY } from '@/utils';

import { convertCreateErpPatientForm2CreateErpPatientReq } from './convert';

const Index = (props) => {
  const { searchOnChange } = props;
  const [init, setInit] = useState<any>(0);
  // 新建病人
  const creatPatient = async () => {
    await vsf.refs.patientSearch.validateFields();
    const values = vsf.refs.patientSearch.getFieldsValue();
    const output = convertCreateErpPatientForm2CreateErpPatientReq?.(values);
    const res =
      await vsf?.services?.PatientMainController_createErpPatient_d16e18?.({
        ...output,
        extApiParam: {},
      });
    if (res?.code == 200) {
      message.success('新建成功');
      const _displayId = res?.data?.displayId;
      searchOnChange(_displayId);
    }
  };
  const handelSearch = () => {
    setInit(init + 1);
    searchOnChange(vsf?.refs?.patientSearch?.getFieldsValue()?.displayId);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <VSForm id="patientSearch" colon={false}>
        <VSFormLayout columnCount={5} gutter={isMinScreen ? 20 : 45}>
          <VSFormLayout columnCount={2} columnSpan={[16, 8]}>
            <VSFormItem
              name={['displayId']}
              label="患者ID"
              valueType="text"
              fieldProps={{
                onPressEnter: () => {
                  handelSearch();
                },
              }}
            />
            <VSFormItem
              label=""
              valueType="custom"
              fieldProps={{
                style: {
                  width: 30,
                },
              }}
            >
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  handelSearch();
                }}
              >
                搜索
              </Button>
            </VSFormItem>
          </VSFormLayout>

          <VSFormItem
            name={['certificate']}
            label="证件及编号"
            valueType="custom"
            fieldProps={
              {
                // style: {
                //   width: 200,
                // },
              }
            }
          >
            <CertificateSearch
              onEnter={(event) => {
                const { identificationClassIn, identificationNumberIn } = event;
                searchOnChange(
                  vsf?.refs?.patientSearch?.getFieldsValue()?.certificate
                    ?.identificationNumberIn,
                );
              }}
              style={{
                height: 20,
              }}
              inputProps={{
                classNames: 'width560',
              }}
              selectProps={{
                classNames: 'width200',
              }}
            />
          </VSFormItem>
          <VSFormItem
            fieldProps={{ className: 'width140' }}
            name={['name']}
            label="姓名"
            valueType="text"
            rules={[{ required: true, message: '' }]}
          />
          <VSFormItem
            name={['birthday']}
            fieldProps={{ className: 'width130' }}
            label="出生日期"
            valueType="date"
            rules={[{ required: true, message: '' }]}
          />
          <VSFormItem
            name={['gender']}
            fieldProps={{ className: 'width140', showSearch: true }}
            label="性别"
            valueType="select"
            dataSource={vsf.stores.dbenums?.enums?.SEX_DICT}
            rules={[{ required: true, message: '' }]}
          />
        </VSFormLayout>
      </VSForm>
      <Button
        style={{ marginLeft: 24 }}
        type="primary"
        size="small"
        onClick={() => {
          // patientSearch
          creatPatient();
        }}
      >
        新建病人
      </Button>
    </div>
  );
};
export default Index;
