import vsf, { definePage } from '@vs/vsf-boot';
import {
  VSTableColumn,
  VSPromiseTable,
  Modal,
  Button,
  message,
  Input,
  Space,
  Select,
  Checkbox,
  VSTable,
  Radio,
} from '@vs/vsf-kit';
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  Fragment,
} from 'react';
import { isMinScreen } from '@/config';

import Icon from '@/module/Icon';
import Tip from '@/pages/Index/components/func/Tip';
import ModalCom from './modalCom';
import { useRequest } from 'ahooks';
import { getScrollY } from '@/utils';
import SearchContainer from '@/module/Search';
import Block from '@/module/Block';
import ButtonAndKeyDown from '@/pages/Index/components/button_and_keydown';
import { useOnChangeValue } from '@/pages/Index/components/func/hooks';
import { useFetch } from '@/pages/Index/components/func/hooks';
import { onExport } from '@/pages/Index/manage/execution_registration/util';
import './index.less';

const DictionaryList = (props) => {
  const [currentItem, setCurrentItem] = useState();
  const [searchCurrentItem, setSearchCurrentItem] = useState();
  const { common } = props?.stores ?? {};
  const { currentApplication } = common || {};
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  const [diagOpen, setDiagOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [inquire, setInquire] = useState(false);

  const [operationCode, setOperationCode] = useState();
  const [operationName, setOperationName] = useState();
  const [select, setSelect] = useState();
  const [blockUp, setBlockUp] = useState(true);
  const [radioValue, setRadioValue] = useState('1');

  const [search] = useOnChangeValue([]);
  const doubleClickCount = useRef(0);
  const deploy = useRef();

  const listData = useRef();
  const { data: ListData, run } = useRequest(
    async () => {
      const res =
        await vsf.services?.BaseDictionaryController_getEoClassByTypeId_d570f0?.(
          {
            typeId: props.value?.id,
          },
        );
      return res?.data;
    },
    {
      manual: true,
    },
  );

  const onSave = async () => {
    const extendedDataForm =
      await vsf?.refs?.operationDictionaryFrom?.validateFieldsReturnFormatValue();

    if (extendedDataForm?.id) {
      const res =
        await vsf.services?.OperationDictionaryController_updateOperationDictionary_b93514?.(
          {
            btoParam: extendedDataForm,
          },
        );
      if (res?.code === 200) {
        message.success('编辑成功');
        setOpen(false);
        setData(void 0);
        vsf?.refs?.operationDictionaryList?.reload();
      }
    } else {
      const res =
        await vsf.services?.OperationDictionaryController_createOperationDictionary_3b9dcf?.(
          {
            btoParam: extendedDataForm,
          },
        );
      if (res?.code === 200) {
        message.success('新增成功');
        setOpen(false);
        setData(void 0);
        vsf?.refs?.operationDictionaryList?.reload();
      }
    }
  };

  const onRemove = async (data) => {
    const res =
      await vsf.services?.OperationDictionaryController_deleteOperationDictionary_f3b6eb?.(
        {
          btoParam: { id: data?.id },
        },
      );
    if (res?.code === 200) {
      message.success('删除成功');
      setData(void 0);
      vsf?.refs?.operationDictionaryList?.reload();
    }
  };

  const onFetch_t3547 = useCallback(
    async (params) => {
      const res =
        await vsf?.services?.OperationDictionaryController_getPagedByOperationDictionaryPageQto_33ffa3?.(
          {
            qto: {
              from: params?.pagination?.from ?? 0,
              size: params?.pagination?.size ?? 10,
              operationNameLike: operationName,
              daySurgeryIndicatorIs: select,
              operationCodeIs: operationCode,
              // stopIndicatorIs: !blockUp,

              ...params?.search,
              ...params?.extra,
              ...(params ?? {}),
              stopIndicatorIs:
                params?.search?.stopIndicatorIs?.length <= 0
                  ? true
                  : params?.search?.stopIndicatorIs?.[0],
            },
          },
        );
      return {
        data: res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [inquire, blockUp],
  );

  const onFetch = useCallback(async (params) => {
    const res =
      await vsf?.services?.OperationDictionaryController_queryOperationDictionaryByOperationCode_7ff372?.(
        {
          operationSortQto: {
            rule: 'hz',
            operationCodeLike: '',
            sortField: [
              'input_code_wubi',
              'input_code',
              'operation_code',
              'han_zi',
            ],
            from: params?.pagination?.from ?? 0,
            size: params?.pagination?.size ?? 10,
          },
        },
      );
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  const onSearch = async (params) => {
    const searchKeyword = params;
    if (!searchKeyword) {
      search?.onChange?.([]);
      return;
    }
    const res =
      await vsf.services?.BaseDictionaryController_queryAllByBaseCodeIndexQto_cefe27?.(
        {
          qto: {
            orNameLike: searchKeyword,
            orInputCodeLike: searchKeyword,
          },
          ext: {},
        },
      );
    const queryResult = res?.data?.result;
    if (queryResult) {
      search?.onChange?.(queryResult);
    }
    return;
  };

  const onAdd = () => {
    setOpen(true);
  };

  const onExportLoadDown = useFetch({
    fetch: async () => {
      const dataList = await vsf?.refs?.operationDictionaryList?.getValues();
      const lastData = dataList?.map((item) => {
        return {
          ...item,
          stopIndicator: item?.stopIndicator ? '停用' : '在用',
          packageUnit: item?.packageUnit ? '是' : '否',
          transportAllowModify: item?.transportAllowModify ? '是' : '否',
          standardCode: item?.standardCode ? '是' : '否',
          notNeedArrange: item?.notNeedArrange ? '是' : '否',
          levelFourIndicator: item?.levelFourIndicator ? '是' : '否',
          minimalInvasiveIndicator: item?.minimalInvasiveIndicator
            ? '是'
            : '否',
          operationInfoType:
            item?.operationInfoType === 'OPERATION'
              ? '手术'
              : item?.operationInfoType === 'INTERVENTIONAL'
              ? '介入'
              : item?.operationInfoType === 'DIAGNOSTIC_PROCEDURE'
              ? '诊断性操作'
              : item?.operationInfoType === 'THERAPEUTIC_PROCEDURE'
              ? '治疗性操作'
              : item?.operationInfoType === 'PROCEDURE'
              ? '操作'
              : '',
        };
      });
      console.log(dataList, lastData, 'daalsits');

      try {
        onExport({
          title: '手术字典',
          columns: [
            {
              header: '手术名称',
              key: 'operationName',
              width: 80,
            },
            {
              header: '手术编码',
              key: 'operationCode',
              width: 40,
            },
            {
              header: '编码体系',
              key: 'codeVersion',
              width: 10,
            },
            {
              header: '手术等级',
              key: 'operationLevel',
              width: 10,
            },
            {
              header: '拼音码',
              key: 'inputCode',
              width: 40,
            },
            {
              header: '停用',
              key: 'stopIndicator',
              width: 10,
            },
            {
              header: '执行科室',
              key: 'packageSpecification',
              width: 20,
            },
            {
              header: '是否需要运送',
              key: 'packageUnit',
              width: 10,
            },
            {
              header: '默认运送工具',
              key: 'transportTool',
              width: 10,
            },
            {
              header: '是否允许修改',
              key: 'transportAllowModify',
              width: 10,
            },
            {
              header: '不能被安排',
              key: 'notNeedArrange',
              width: 10,
            },
            {
              header: '三公四级',
              key: 'levelFourIndicator',
              width: 10,
            },
            {
              header: '三公微创',
              key: 'minimalInvasiveIndicator',
              width: 10,
            },
            {
              header: '三公操作类别',
              key: 'threePublicHandleType',
              width: 10,
            },
            {
              header: '切口等级',
              key: 'incisionLevelCode',
              width: 10,
            },
            {
              header: '操作信息',
              key: 'operationInfoType',
              width: 10,
            },
            {
              header: '描述',
              key: 'operationDescription',
              width: 10,
            },
            {
              header: '标准名称',
              key: 'standardName',
              width: 10,
            },
            {
              header: '是否标准编码',
              key: 'standardCode',
              width: 10,
            },
            {
              header: '国家编码',
              key: 'insuranceNationCode',
              width: 10,
            },
          ],
          value: lastData,
        });
        return {
          code: 200,
        };
      } catch (e) {
        console.log(e);
        return {
          code: 500,
        };
      }
    },
    message: {
      loading: '正在导出中',
      error: '导出失败',
      success: '导出成功',
    },
  });

  const onAllExportLoadDown = useFetch({
    fetch: async () => {
      const dataList =
        await vsf?.services?.OperationDictionaryController_getAllByOperationAllDictionaryQto_7990f7?.(
          {
            qto: {
              stopIndicatorIs: !blockUp,
            },
          },
        );
      const lastData = dataList?.data.map((item) => {
        return {
          ...item,
          stopIndicator: item?.stopIndicator ? '停用' : '在用',
          packageUnit: item?.packageUnit ? '是' : '否',
          transportAllowModify: item?.transportAllowModify ? '是' : '否',
          standardCode: item?.standardCode ? '是' : '否',
          notNeedArrange: item?.notNeedArrange ? '是' : '否',
          levelFourIndicator: item?.levelFourIndicator ? '是' : '否',
          minimalInvasiveIndicator: item?.minimalInvasiveIndicator
            ? '是'
            : '否',
          operationInfoType:
            item?.operationInfoType === 'OPERATION'
              ? '手术'
              : item?.operationInfoType === 'INTERVENTIONAL'
              ? '介入'
              : item?.operationInfoType === 'DIAGNOSTIC_PROCEDURE'
              ? '诊断性操作'
              : item?.operationInfoType === 'THERAPEUTIC_PROCEDURE'
              ? '治疗性操作'
              : item?.operationInfoType === 'PROCEDURE'
              ? '操作'
              : '',
        };
      });

      try {
        onExport({
          title: '手术字典',
          columns: [
            {
              header: '手术名称',
              key: 'operationName',
              width: 80,
            },
            {
              header: '手术编码',
              key: 'operationCode',
              width: 40,
            },
            {
              header: '编码体系',
              key: 'codeVersion',
              width: 10,
            },
            {
              header: '手术等级',
              key: 'operationLevel',
              width: 10,
            },
            {
              header: '拼音码',
              key: 'inputCode',
              width: 40,
            },
            {
              header: '停用',
              key: 'stopIndicator',
              width: 10,
            },
            {
              header: '执行科室',
              key: 'packageSpecification',
              width: 20,
            },
            {
              header: '是否需要运送',
              key: 'packageUnit',
              width: 10,
            },
            {
              header: '默认运送工具',
              key: 'transportTool',
              width: 10,
            },
            {
              header: '是否允许修改',
              key: 'transportAllowModify',
              width: 10,
            },
            {
              header: '不能被安排',
              key: 'notNeedArrange',
              width: 10,
            },
            {
              header: '三公四级',
              key: 'levelFourIndicator',
              width: 10,
            },
            {
              header: '三公微创',
              key: 'minimalInvasiveIndicator',
              width: 10,
            },
            {
              header: '三公操作类别',
              key: 'threePublicHandleType',
              width: 10,
            },
            {
              header: '切口等级',
              key: 'incisionLevelCode',
              width: 10,
            },
            {
              header: '操作信息',
              key: 'operationInfoType',
              width: 10,
            },
            {
              header: '描述',
              key: 'operationDescription',
              width: 10,
            },
            {
              header: '标准名称',
              key: 'standardName',
              width: 10,
            },
            {
              header: '是否标准编码',
              key: 'standardCode',
              width: 10,
            },
            {
              header: '国家编码',
              key: 'insuranceNationCode',
              width: 10,
            },
          ],
          value: lastData,
        });
        return {
          code: 200,
        };
      } catch (e) {
        console.log(e);
        return {
          code: 500,
        };
      }
    },
    message: {
      loading: '正在导出中',
      error: '导出失败',
      success: '导出成功',
    },
  });

  const handelCopyClick = () => {
    const formData = {
      ...currentItem?.[0],
      id: undefined,
      operationName: currentItem?.[0].operationName + '副本',
    };
    setData(formData);
    setOpen(true);
  };

  const button = [
    {
      text: '一键复制',
      methodKey: 'altKey',
      disabled: !currentItem?.length > 0,
      onClick: handelCopyClick,
    },
    {
      text: '导入文件',
      methodKey: 'altKey',
      disabled: false,
    },
    {
      text: '导出Excel',
      methodKey: 'altKey',
      disabled: false,
      onClick: onExportLoadDown,
    },
    {
      text: '导出所有手术',
      methodKey: 'altKey',
      disabled: false,
      onClick: onAllExportLoadDown,
    },
  ];

  const footer = () => {
    return (
      <div className="aspirin-table-footer-button">
        {button.map((item) => (
          <ButtonAndKeyDown key={item.text} {...item} type="primary">
            {item.text}
          </ButtonAndKeyDown>
        ))}
      </div>
    );
  };

  const onChange = (value) => {};
  const handleSubmit = () => {
    setOperationCode(searchCurrentItem?.[0]?.operationCode);
    setDiagOpen(false);
  };

  const handleChange = () => {
    setDiagOpen(true);
  };

  const onChangeOperationName = (e) => {
    setOperationName(e.target.value);
  };

  const onchangeSelect = (e) => {
    setSelect(e);
  };

  const onChangeBlockUp = (e) => {
    setBlockUp(e);
  };

  const onInquire = () => {
    setInquire(!inquire);
  };

  const onReset = () => {
    setOperationName(null);
    setSelect(null);
    setOperationCode(null);
    setInquire(!inquire);
  };

  const tableData = () => {
    const tableData = [
      {
        key: 'input_code_wubi',
        index: 1,
        value: '五笔码',
      },
      {
        key: 'input_code',
        index: 2,
        value: '拼音码',
      },
      {
        key: 'han_zi',
        index: 3,
        value: '汉字',
      },
      {
        key: 'operation_code',
        index: 4,
        value: '疾病编码',
      },
    ];
    return tableData;
  };

  return (
    <div className="dictionary_list">
      {/* <Block
        footer={footer()}
        style={{
          padding: 0,
        }}
        // className="paddingTop24"
      > */}
      {/* <SearchContainer.Header
        title="手术编码"
        style={{
          paddingBottom: 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            // marginTop: '-30px',
          }}
          // className="marginTops30"
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div className="icon18 marginLeft150">
              手术编码
              <Input
                className="icon16"
                onClick={handleChange}
                value={operationCode}
                placeholder="请输入手术编码"
                style={{
                  marginLeft: '20px',
                }}
                width={200}
                onChange={onChange}
              ></Input>
            </div>

            <div className="icon18 marginLeft30">
              手术名称
              <Input
                className="icon18"
                placeholder="请输入手术名称"
                style={{
                  marginLeft: '20px',
                }}
                width={200}
                onChange={onChangeOperationName}
              ></Input>
            </div>

            <div className="icon18 marginLeft30">
              日间手术
              <Select
                className="icon18 marginLeft20"
                // className="aspirin-inputUnitDynamic-select"
                dataSource={[
                  { label: '是', value: true },
                  { label: '否', value: false },
                ]}
                value={select}
                placeholder="请选择"
                style={
                  {
                    // marginLeft: '20px',
                  }
                }
                width={200}
                onChange={onchangeSelect}
              ></Select>
            </div>

            <div className="icon18 marginTop5 marginLeft20">
              <Checkbox
                className="icon18"
                onChange={onChangeBlockUp}
                value={blockUp}
              >
                显示停用
              </Checkbox>
            </div>

            <Button onClick={() => onReset()} className="marginLeft20">
              重置
            </Button>

            <Button
              type="primary"
              onClick={() => onInquire()}
              style={
                {
                  // marginLeft: '10px',
                }
              }
              className="marginLeft10"
            >
              查询
            </Button>
          </div>
          <Button type="primary" onClick={() => onAdd()}>
            新增
          </Button>
        </div>
      </SearchContainer.Header> */}
      <VSPromiseTable
        editable={{
          editType: 'single',
          onCanEdit: () => null,
          onCanDelete: () => null,
          columnProps: {
            width: 80,
          },
          extraOperations: [
            {
              children: <Icon type="icon-bianji" size={24} />,
              order: 1,
              onClick: (event, data) => {
                setOpen(true);
                setData(data);
              },
            },
            {
              children: <Icon type="icon-shanchu1" size={24} />,
              order: 1,
              onClick: (event, data) => {
                Tip({
                  content: '是否删除该码表！',
                  onOk: () => {
                    onRemove(data);
                  },
                });
              },
            },
          ],
        }}
        id="operationDictionaryList"
        className="aspirin-table-no-background-box table_overflow_435"
        scroll={{ x: 3000, y: getScrollY(320) }}
        onFetch={onFetch_t3547}
        rowSelection={{
          selectType: 'single',
          hideColumn: true,
          canUnSelectRadio: true,
          selectedRows: currentItem,
          onSelectedRowsChange: (_value) => {
            setCurrentItem(_value);
          },
        }}
        searchConfig={{
          labelWidth: isMinScreen ? 70 : 120,
          collapsed: false,
          collapseRender: false,
          disableSearchButtonLoading: true,
          span: 6,
          searchButtonProps: {
            size: 'large',
            className: 'icon16',
          },
          resetButtonProps: {
            size: 'large',
            className: 'icon16',
          },
          optionRender: (...arr) => {
            const [_, __, dom] = arr;
            return (
              <SearchContainer.Header title="筛选">
                {dom}
              </SearchContainer.Header>
            );
          },
        }}
        searchFormRender={(item, dom) => {
          return (
            <SearchContainer
              formPadding={isMinScreen ? '0 100px 0 80px' : '0 10px 0 80px'}
              mode="inline"
            >
              {dom}
            </SearchContainer>
          );
        }}
        rowClick={{ rowClickType: 'select' }}
      >
        <VSTableColumn
          valueType="index"
          render={(v) => {
            if (v?.props?.text === 9) {
              return (
                <div className="ant-pro-field-index-column top-three">0</div>
              );
            }
            return v;
          }}
          title="序号"
          width={50}
          preview
        />

        <VSTableColumn
          dataIndex={['daySurgeryIndicator']}
          title="日间手术"
          // width={150}
          hideInTable
          valueType="select"
          fieldProps={{
            showSearch: true,
          }}
          order={1}
          search
          dataSource={[
            { label: '是', value: true },
            { label: '否', value: false },
          ]}
          columnKey="daySurgeryIndicatorIs"
        />
        <VSTableColumn
          dataIndex={['stopIndicator']}
          title=""
          // width={150}
          hideInTable
          valueType="checkbox"
          fieldProps={{}}
          order={0}
          search
          initialValue={[true]}
          dataSource={[{ label: '显示停用', value: false }]}
          columnKey="stopIndicatorIs"
        />
        <VSTableColumn
          dataIndex={['operationName']}
          title="手术名称"
          width={150}
          valueType="text"
          fieldProps={{}}
          search
          columnKey="operationNameLike"
          order={2}
        />

        <VSTableColumn
          dataIndex={['operationCode']}
          title="手术编码"
          valueType="text"
          width={100}
          fieldProps={{}}
          search
          order={3}
          columnKey="operationCodeIs"
        />

        <VSTableColumn
          dataIndex={['codeVersion']}
          title="编码体系"
          valueType="text"
          width={100}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['operationLevel']}
          title="手术等级"
          width={100}
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['inputCode']}
          title="拼音码"
          width={100}
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['operationDepartmentName']}
          title="可以执行该手术的科室"
          valueType="text"
          width={180}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['forceBloodIndicator']}
          title="是否强制备血"
          valueType="switch"
          width={150}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['restrictiveTechnologyIndicator']}
          title="是否国家级限制性技术"
          valueType="switch"
          width={160}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['needTransportIndicator']}
          title="是否需要运送"
          valueType="switch"
          width={100}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['transportTool']}
          title="默认运送工具"
          width={100}
          valueType="select"
          dataSource={vsf?.stores?.dbenums?.enums?.TRANSFER_METHOD_DICT}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['transportAllowModify']}
          title="是否允许修改运送工具"
          width={160}
          valueType="switch"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['notNeedArrange']}
          title="是否不需要安排"
          valueType="switch"
          width={120}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['levelFourIndicator']}
          title="三公四级"
          width={100}
          valueType="switch"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['minimalInvasiveIndicator']}
          title="三公微创"
          width={100}
          valueType="switch"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['threePublicHandleType']}
          title="三公操作类别"
          width={100}
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.ANESTHETIC_USE_PURPOSE}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['operationInfoType']}
          title="操作信息"
          valueType="select"
          width={100}
          dataSource={[
            { label: '手术', value: 'OPERATION' },
            { label: '介入', value: 'INTERVENTIONAL' },
            { label: '诊断性操作', value: 'DIAGNOSTIC_PROCEDURE' },
            { label: '治疗性操作', value: 'THERAPEUTIC_PROCEDURE' },
            { label: '操作', value: 'PROCEDURE' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['operationDescription']}
          title="描述"
          valueType="text"
          width={100}
          fieldProps={{}}
        />

        <VSTableColumn
          width={100}
          dataIndex={['standardName']}
          title="标准名称"
          valueType="text"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['standardCode']}
          title="是否标准编码"
          width={100}
          valueType="digit"
          fieldProps={{}}
        />
      </VSPromiseTable>
      {footer()}
      {/* </Block> */}

      <Modal
        open={open}
        onCancel={() => {
          setOpen(false);
          setData(void 0);
        }}
        width="80%"
        title="手术字典维护"
        onOk={onSave}
        cancelText="取消"
        okText="确定"
      >
        <ModalCom
          data={data}
          id={currentApplication?.department?.branchInstitution?.id}
          extendedData={ListData}
          list={listData}
        />
      </Modal>

      <Modal
        open={diagOpen}
        onCancel={() => {
          setDiagOpen(false);
        }}
        style={{ left: -350, top: 180 }}
        width="500px"
        title={<div style={{ color: '#3276E8' }}>{'手术编码查询'}</div>}
        footer={
          <div>
            <Space size={4}>
              <Button
                onClick={() => {
                  setSearchOpen(true);
                }}
              >
                设置
              </Button>
              <Button
                onClick={() => {
                  setOperationCode(null);
                  setDiagOpen(false);
                }}
              >
                取消
              </Button>
              <Button type="primary" onClick={handleSubmit}>
                确定
              </Button>
            </Space>
          </div>
        }
      >
        <VSPromiseTable
          id="operationDictionaryList"
          onFetch={onFetch}
          pagination={{
            defaultPageSize: 10,
            simple: true,
            showTotal: false,
            showQuickJumper: true,
            showSizeChanger: false,
          }}
          rowSelection={{
            selectType: 'single',
            hideColumn: true,
            canUnSelectRadio: true,
            selectedRows: currentItem,
            onSelectedRowsChange: (_value) => {
              setSearchCurrentItem(_value);
            },
          }}
          rowClick={{ rowClickType: 'select' }}
        >
          <VSTableColumn
            dataIndex={['operationName']}
            title="手术名称"
            width={150}
            valueType="text"
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['operationCode']}
            title="手术编码"
            valueType="text"
            width={100}
            fieldProps={{}}
          />
        </VSPromiseTable>
      </Modal>

      <Modal
        open={searchOpen}
        style={{ left: 130, top: 180 }}
        onCancel={() => {
          setSearchOpen(false);
        }}
        width="400px"
        title={<div style={{ color: '#3276E8' }}>{'检索优先级'}</div>}
      >
        <VSPromiseTable
          editable={{
            editType: 'single',
            onCanEdit: () => null,
            onCanDelete: () => null,
            columnProps: {
              width: 90,
            },
          }}
          pagination={false}
          showHeader={false}
          value={tableData}
          bordered
          id="stopwatchTable"
        >
          <VSTableColumn valueType="sort" dataIndex={['sort']} width={50} />
          <VSTableColumn
            dataIndex={['index']}
            title="序列号"
            valueType="digit"
            fieldProps={{}}
            width={100}
          />
          <VSTableColumn
            dataIndex={['value']}
            title="显示名"
            valueType="text"
          />
        </VSPromiseTable>
        <div>查找方式</div>
        <div>
          <Radio.Group
            value={radioValue}
            onChange={setRadioValue}
            dataSource={[
              {
                value: '1',
                label: '前匹配',
              },
              {
                value: '2',
                label: '后匹配',
              },
              {
                value: '3',
                label: '模糊匹配',
              },
            ]}
          />
        </div>
      </Modal>
    </div>
  );
};
export default definePage(DictionaryList);
