import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Container from '@/module/Container';
import SearchContainer from '@/module/Search';
import { Checkbox, Input, message, Select } from '@vs/vsf-kit';
import vsf, { definePage } from '@vs/vsf-boot';
import Icon from '@/module/cpoe/Icon';
import Menu from './menu';
import Block from '@/module/Block';
import PatientFullInfo from './patient_full_info';
import Screen, { CreateScreen } from '@/pages/Index/components/screen';

import './index.less';

const PATIENTID = 'PATIENTID';

const PatientMain = definePage((props) => {
  const onScreen = useContext(CreateScreen);
  const { routes } = props;

  const [identification, setIdentification] = useState();
  const [fullInfo, setFullInfo] = useState();
  const [patientAttr, setPatientAttr] = useState([]);
  const [isIdNumber, setIsIdNumber] = useState(false);

  const identificationClass = useRef();
  const identificationNumber = useRef();

  const handelSearchClick = async () => {
    try {
      if (identificationClass.current === '患者ID') {
        const response =
          await vsf?.services?.PatientMainController_getPatientWithProfileByDisplayId_40245d?.(
            {
              displayId: identificationNumber.current,
            },
          );
        if (response && response.code === 200) {
          if (response.code === 200 && response?.data?.patientProfileDto?.id) {
            const fullInfoResponse =
              await vsf?.services?.PatientMainController_getFullInfoById_6ee318?.(
                {
                  id: response?.data?.patientProfileDto?.patientId,
                },
              );
            if (response && response.code === 200) {
              // 患者其他属性
              const attributes = [
                'unknownIndicator',
                'bloodCardIndicator',
                'disabilityIndicator',
                'vipIndicator',
              ];
              const _patientAttr = [];
              attributes?.forEach((item) => {
                if (fullInfoResponse?.data?.[item]) {
                  _patientAttr?.push(item);
                }
              });
              setPatientAttr(_patientAttr);
              setFullInfo(fullInfoResponse?.data);
            }
          } else {
            message.error('未查询到患者');
          }
        } else {
          message.error('未查询到患者');
        }
      } else {
        const response =
          await vsf?.services?.PatientMainController_getIdentificaitonByIdentificationNumberAndIdentificationClass_c14a4e?.(
            {
              identificationNumberAndClass: {
                identificationClass: identificationClass.current,
                identificationNumber: identificationNumber.current,
              },
            },
          );
        if (response && response.code === 200) {
          setIdentification(response?.data);
          if (response?.data?.patientId) {
            const fullInfoResponse =
              await vsf?.services?.PatientMainController_getFullInfoById_6ee318?.(
                {
                  id: response?.data?.patientId,
                },
              );
            if (response && response.code === 200) {
              setFullInfo(fullInfoResponse?.data);
            }
          } else {
            message.error('未查询到患者');
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelSelectChange = (e) => {
    identificationClass.current = e;
  };

  const handelInputChange = (e) => {
    identificationNumber.current = e.target.value;
  };

  const handelCheckChange = async (e) => {
    setPatientAttr(e);
    if (e?.includes('unknownIndicator')) {
      // 无名氏逻辑
      vsf.refs?.vSFormPatientFullInfo?.setFieldsValue({
        ...(await vsf.refs?.vSFormPatientFullInfo.getFieldsValue()),
        name: '无名氏',
        birthday: new Date('1000-01-01').getTime(),
        defaultChargeType: '1',
        identityCode: '0101',
      });
    }
  };

  const handelIdNumberChange = (flag) => {
    if(flag) {
      setPatientAttr(patientAttr?.filter((item) => item !== 'unknownIndicator'));
      setIsIdNumber(true);
    }else {
      setPatientAttr(patientAttr);
      setIsIdNumber(false);
    }
  };

  const handelActivityMenuChange = (key) => {
    const dom = document.getElementById('PatientFullInfoMain');
    if (dom) {
      const moduleDom = document.getElementById(key);

      if (moduleDom) {
        // 74 = Header高度 58 + marginBottom 16
        dom.scrollTop = moduleDom?.offsetTop - 74;
        moduleDom.style.display = 'block';
      }
    }
  };

  const getPatientIdentification = useCallback(() => {
    return [
      {
        label: '无名患者标识',
        value: 'unknownIndicator',
        disabled: isIdNumber,
      },
      {
        label: '献血证标识',
        value: 'bloodCardIndicator',
      },
      {
        label: '残疾人证标识',
        value: 'disabilityIndicator',
      },
      {
        label: 'VIP标识',
        value: 'vipIndicator',
      },
    ];
  }, [isIdNumber]);

  const handelSubmit = async (_fullInfo) => {
    setFullInfo(_fullInfo);
  };

  return (
    <Container
      className="patient_main"
      layout={{
        direction: 'vertical',
        items: [
          {
            block: '100%',
            bgColor: '#fff',
            padding: '0 24px',
          },
          {
            block: '100%',
            bgColor: '#fff',
          },
        ],
      }}
    >
      <div style={{ display: 'flex', flex: 'none' }}>
        <div className="aspirin-block-title">筛选</div>
        <div className="patient_main_search">
          <div>
            <Select
              showSearch
              className="select"
              dataSource={[
                ...(vsf.stores.dbenums?.enums?.IDENTIFICATION_CLASS_DICT ?? []),
                // {
                //   label: '患者ID',
                //   value: PATIENTID,
                // },
              ]}
              onChange={handelSelectChange}
            />
          </div>
          <div />
          <div>
            <Input
              className="input"
              onChange={handelInputChange}
              onKeyDown={(e) => {
                if (e?.key === 'Enter') {
                  handelSearchClick();
                }
              }}
            />
            <div className="search" onClick={handelSearchClick}>
              <Icon type="icon-sousuo" />
            </div>
          </div>
          <div>
            <div className="scan">
              <Icon type="icon-a-Scan-codesaoma" />
            </div>
            <div className="list">
              <Icon type="icon-a-List-viewliebiaoshitu"  />
            </div>
          </div>
        </div>
        <div className="patient_main_check">
          <Checkbox.Group
            value={patientAttr}
            dataSource={getPatientIdentification()}
            onChange={handelCheckChange}
          />
        </div>
      </div>
      <div style={{ display: 'flex', flex: 1 }}>
        <Menu fullInfo={fullInfo} onChange={handelActivityMenuChange} />
        <Block
          style={{
            padding: '0 24px',
            width: 0,
            flex: 1,
          }}
          mainStyle={{
            display: 'flex',
          }}
          scroll={{
            y: true,
          }}
        >
          <PatientFullInfo
            patientAttr={patientAttr}
            identification={identification}
            fullInfo={fullInfo}
            onIdNumberChange={handelIdNumberChange}
            onSubmit={handelSubmit}
            onClearScreen={onScreen}
            routes={routes}
          />
        </Block>
      </div>
    </Container>
  );
});

const PatientMainScreen = (props) => {
  return (
    <Screen>
      <PatientMain {...props} />
    </Screen>
  );
};

export default PatientMainScreen;
