import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Modal,
  Section,
  Slider,
  Tabs,
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
  VSTableToolbar,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

const Index = (props, ref) => {
  const [open, setOpen] = useState(false);

  const handelOpen = (v = false, value?) => {
    // cramsScore
    setOpen(v);
  };

  useImperativeHandle(ref, () => ({
    handelOpen,
  }));
  return (
    <div className="fall_risk">
      <Modal
        centered
        width={900}
        open={open}
        okText="确认 "
        cancelText="取消 "
        title={
          <div className="icon18" style={{ color: '#3276E8' }}>
            跌倒风险评估单
          </div>
        }
        onOk={() => {
          handelOpen(false);
        }}
        onCancel={() => {
          handelOpen(false);
        }}
      >
        <VSForm id="fallRiskModal" labelAlign="left" colon={false}>
          <VSFormLayout columnCount={1} labelWidth={300}>
            <VSFormItem
              name={['need']}
              valueType="radio"
              label={
                <div className="fall_risk_form_label icon18">
                  是否存在跌倒危险因素评估的需求
                </div>
              }
              initialValue={true}
              dataSource={[
                { label: '是', value: true },
                { label: '否', value: false },
              ]}
            />
          </VSFormLayout>
          <VSFormLayout columnCount={1} labelWidth={80}>
            <VSFormItem
              name={['name']}
              valueType="checkbox"
              label={
                <div className="fall_risk_form_label icon18">评估项目</div>
              }
              dataSource={vsf.stores.dbenums.enums.ERGREEN_FALL_ASSESSMENT_DICT}
              fieldProps={{
                style: {
                  display: 'grid',
                },
              }}
            />
            <VSFormItem
              name={['way']}
              valueType="checkbox"
              label={
                <div className="fall_risk_form_label icon18">采取措施</div>
              }
              dataSource={vsf.stores.dbenums.enums.ERGREEN_FALL_ASSESSMENT_DICT}
              fieldProps={{
                style: {
                  display: 'grid',
                },
              }}
            />
          </VSFormLayout>
          <VSFormLayout columnCount={2} labelWidth={80} gutter={100}>
            <VSFormItem
              label={<div className="fall_risk_form_label icon18">评估人</div>}
            />
            <VSFormItem
              label={
                <div className="fall_risk_form_label icon18">评估时间</div>
              }
            />
          </VSFormLayout>
        </VSForm>
      </Modal>
    </div>
  );
};
export default forwardRef(Index);
// 跌倒风险评估
