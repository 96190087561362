/* eslint-disable no-prototype-builtins */
import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Button, message, Tooltip, VSTableColumn } from '@vs/vsf-kit';
import { useAsyncEffect, useUpdateEffect } from 'ahooks';
import dayjs from 'dayjs';
import React, {
  createContext,
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { cdssShow } from '@/mock';
import Block from '@/module/Block';
import { getUUID } from '@/module/cpoe/medical/editor/utils';
import Icon from '@/module/Icon';
import { askMedicalOrders } from '@/pages/Index/components/func/ask';
import {
  useFetch,
  useOnChangeValue,
} from '@/pages/Index/components/func/hooks';
import Tip from '@/pages/Index/components/func/Tip';
import Stop from '@/pages/Index/cpoe/center/main/stop';

import CdssTable from '../cdss_modal';
import { convertOrderInField } from '../out_patient/table/convert';
import { AuxiliaryRef } from './assist/auxiliary';
import { HeaderRefs } from './assist/header';
import BeInHospital, { Refs, TableRefs, Value } from './table';
import { convertOrderOutTable, convertOtherOrderInfo } from './table/convert';
import LagTimeOrder from './table/lag_time_order';
export const BeIn = createContext((value) => {});

export type Drug = {
  onDrug: (value?: never) => void;
  onOther: () => void;
};
export type AuxiliaryRefs = AuxiliaryRef;

const BeInHospitals = (props) => {
  const {
    user: { currentPatient, inVisitInfo },
    cdss: { cdssOrderResponse },
  } = vsf?.stores;

  const auxiliary = useRef<AuxiliaryRefs | null>(null);
  const table = useRef<
    Drug &
      TableRefs &
      Refs & { tableRef: Element } & { id: () => string | number }
  >(null);
  const header = useRef<HeaderRefs>(null);
  const footer = useRef<{ click: () => void }>();
  const lag = useRef<any>(null);
  const stopRef = useRef<any & { onClose: () => void }>();
  const cdssTable = useRef<any>();

  const onSubmit = useCallback(async () => {
    await table?.current?.saveEdit();
    const values = table?.current?.onSliced(
      ((table?.current?.getValue?.() ?? []) as any)
        ?.filter(
          (item: any) =>
            !item?.orderStatus ||
            ['SAVE', 'OPEN', 'AUDIT_FAIL']?.includes(item?.orderStatus),
        )
        ?.map((item: any) => {
          const parentRelatedUuid = (table?.current?.getValue?.() as any)?.find(
            ({ uuid }) => uuid === item?.parentRelatedUuid,
          );
          return {
            ...item,
            orderStatus: 'SAVE',
            startPerformDateTime:
              parentRelatedUuid?.startPerformDateTime ??
              item?.startPerformDateTime ??
              void 0,
          };
        }),
    );
    const visitInfo = { inpVisitId: inVisitInfo?.inpVisitId };
    return convertOrderOutTable(values, visitInfo, currentPatient?.patientId);
  }, [currentPatient?.patientId, inVisitInfo?.inpVisitId]);

  const onSend = useFetch({
    fetch: async () => {
      const order = await onSubmit?.();
      const res =
        await vsf.services?.ClinicRecordOrderController_submitOrder_89360b?.({
          order,
        });

      return res;
    },
    message: {
      loading: '提交中',
      success: '提交成功',
      error: '提交失败',
    },
    success() {
      header?.current?.onReload?.();
    },
  });
  const Footer = forwardRef((props: any, ref) => {
    const [click] = useOnChangeValue<any>({});
    const [group] = useOnChangeValue(true);

    const onSubmit = useCallback(async () => {
      await table?.current?.saveEdit();
      const values = table?.current?.onSliced(
        (table?.current?.getValue?.() ?? []) as any,
      );
      const visitInfo = { inpVisitId: inVisitInfo?.inpVisitId };
      return convertOrderOutTable(values, visitInfo, currentPatient?.patientId);
    }, []);

    const onSave = useFetch({
      fetch: async () => {
        try {
          const order = await onSubmit?.();
          const res =
            await vsf.services?.ClinicRecordOrderController_saveOrder_c9c63c?.({
              order,
            });
          return res;
        } catch (error) {
          return {
            code: 500,
          };
        }
      },
      message: {
        loading: '保存中',
        success: '保存成功',
        error: '保存失败',
      },
      success() {
        header?.current?.onReload?.();
        table?.current?.tableRef?.scrollTo?.(
          0,
          table?.current?.tableRef?.scrollHeight,
        );
      },
    });

    const onCancelSend = useFetch({
      fetch: async (params) => {
        console.log(params, 'cancel_obj');
        const res =
          await vsf.services?.ClinicRecordOrderController_cancelSubmitOrder_28057d?.(
            {
              orderId: params?.id,
            },
          );
        return res;
      },
      message: '取消提交',
      success() {
        const list: any = table?.current?.getValue?.() ?? [];
        const test =
          list?.filter?.(
            (item) => item?.parentRelatedUuid === click?.value?.uuid,
          ) ?? [];
        const group = (list ?? [])?.filter?.((...[, index]) =>
          click?.value?.groupIndex?.includes(index),
        );
        const array = test?.length ? [click?.value, ...test] : group;
        const state = array?.length ? array : [click?.value];
        const index = list?.findIndex?.(
          (item) => item?.id === click?.value?.id,
        );
        if (index !== -1) {
          table?.current?.setValue?.((value) =>
            value?.toSpliced?.(
              index,
              state?.length,
              ...state?.map((item) => ({
                ...item,
                orderStatus: 'SAVE',
              })),
            ),
          );
        }
      },
    });

    const onSend = useFetch({
      fetch: async () => {
        const order = await onSubmit?.();
        const res =
          await vsf.services?.ClinicRecordOrderController_submitOrder_89360b?.({
            order,
          });
        return res;
      },
      message: {
        loading: '提交中',
        success: '提交成功',
        error: '提交失败',
      },
      success() {
        header?.current?.onReload?.();
      },
    });

    const onStop = () => {
      stopRef?.current?.onOpen(table?.current?.getValue?.());
    };

    useImperativeHandle(ref, () => ({
      click: click?.onChange,
      group: group?.onChange,
    }));

    const button: any = [
      {
        type: 'primary',
        ghost: true,
        title: '刷新',
        onClick: () => {
          header?.current?.onReload();
        },
      },
      {
        type: 'primary',
        title: '成组',
        onClick: () => table?.current?.onGroup?.(),
        disabled: group?.value,
      },
      {
        type: 'primary',
        ghost: true,
        title: '取消成组',
        onClick: () => table?.current?.onCancelGroup?.(),
        /**
         * 执行和提交状态，不可以取消成组
         * 并且有关联的医嘱不可以取消成组
         */
        disabled:
          click?.value?.parentRelatedUuid ||
          ['PERFORM', 'SUBMIT']?.includes(click?.value?.orderStatus) ||
          !click?.value?.groupIndex?.length,
      },
      {
        type: 'primary',
        ghost: true,
        title: '取消提交',
        /**
         * 不是执行和提交状态，不可以取消提交
         * 并且有关联的医嘱只可以取消提交主医嘱
         */
        disabled:
          !['SUBMIT']?.includes(click?.value?.orderStatus) ||
          click?.value?.parentRelatedUuid,
        onClick: () => onCancelSend(click?.value),
      },
      {
        type: 'primary',
        title: '保存',
        onClick: async () => {
          const save = await table?.current?.saveEdit?.();
          if (save) {
            onSave();
          }
        },
      },
      {
        type: 'primary',
        title: '提交',
        // onClick: onSend,
        onClick: async () => {
          const save = await table.current?.onSave?.();
          if (save) {
            const order = table.current?.getValue() as unknown as any[];
            if (order?.length) {
              const value = await lag?.current?.onOpen?.(order);
              if (Array?.isArray(value)) {
                if (value?.length) {
                  table.current?.setValue(table.current?.onSliced(value));
                  return;
                }
              } else if (value === false) {
                return;
              }
            }
            if (cdssShow) {
              const order = await onSubmit?.();
              if (order?.length > 0) {
                message.open({
                  type: 'loading',
                  content: '加载中',
                });

                const res = await cdssOrderResponse({
                  inpVisitId: inVisitInfo?.inpVisitId,
                  saveOrderEoList: order?.map((i) => {
                    return i?.orderClass == 'TREAT'
                      ? i
                      : {
                          ...i,
                          drugOrder: {
                            ...i?.drugOrder,
                            drugId: i?.drugOrder?.drugId
                              ? i?.drugOrder?.drugId
                              : i?.drugOrder?.drug?.id,
                          },
                        };
                  }),
                });
                message.destroy();
                if (res?.code !== 200) return message.error(res?.message);
                if (res?.data?.filterStatus == 'BLOCKED') {
                  cdssTable.current.handelOpen(true, order);
                  cdssTable.current.getTableData(res?.data);
                } else {
                  onSend();
                }
              } else {
                message.info('请选择医嘱');
              }
            } else {
              onSend();
            }
          }
        },
      },
      {
        type: 'primary',
        title: '停止',
        onClick: onStop,
      },
      {
        type: 'primary',
        title: '其他操作',
      },
    ];

    return (
      <div
        className="footer aspirin-order-module-footer"
        style={{
          zIndex: 1000,
        }}
      >
        <div className="first">
          <div className="desc" style={{ color: '#babcbd' }}>
            <Icon
              style={{ color: '#babcbd', marginRight: '5px' }}
              type="icon-bangzhu"
              size={14}
            />
            图标说明
            <span
              style={{
                marginLeft: 24,
              }}
            >
              药品成组请在途径内输入“/” 后按回车，或点击下方成组按钮
            </span>
          </div>
          <div className="oper">
            {button?.map((item, index) => {
              const { title, ...rest } = item;
              return (
                <Button {...rest} key={index}>
                  {item?.title}
                </Button>
              );
            })}
          </div>
        </div>
      </div>
    );
  });
  Footer.displayName = 'Footer';

  /**
   * 抽屉操作: 提交数据到单行
   */
  const onDrawerSubmit = useCallback((value: Value) => {
    const id = 'create' + Math?.random();
    const target = (table?.current?.getValue() ?? []) as Value[] as any;
    const self = target?.find((item) => item?.id === table?.current?.id());
    if (self?.insert) {
      const is = Array?.isArray(value);
      const index = target?.findIndex(
        (item) => item?.id === table?.current?.id(),
      );
      const startPerformDateTime =
        dayjs(self?.startPerformDateTime).valueOf() - 72000;
      const timer = 360;
      let timers = 0;
      table?.current?.setValue(
        target?.toSpliced?.(
          index,
          1,
          ...(is
            ? value?.map((item) => {
                timers += timer;
                return {
                  id: 'create' + Math?.random(),
                  uuid: getUUID(),
                  orderStatus: 'OPEN',
                  ...item,
                  startPerformDateTime: dayjs(
                    startPerformDateTime + timers,
                  ).format('YYYY/MM/DD HH:mm:ss'),
                };
              })
            : [
                {
                  id,
                  ...value,
                  startPerformDateTime: dayjs(
                    startPerformDateTime - timer,
                  ).format('YYYY/MM/DD HH:mm:ss'),
                },
              ]),
        ),
      );
      return;
    }
    if (Array?.isArray(value)) {
      if (value?.length) {
        if (
          value
            ?.map?.((item) =>
              Object?.keys(item).some((value) => /^(.*)Order$/.test(value)),
            )
            ?.includes(true)
        ) {
          table?.current?.setValue([
            ...target,
            ...value?.map((item) => {
              return {
                id: 'create' + Math?.random(),
                uuid: getUUID(),
                orderStatus: 'OPEN',
                ...item,
              };
            }),
          ]);
        } else {
          const index = (target ?? [])?.findIndex(
            (item) => item?.uuid === value?.uuid,
          );
          const frequency = vsf.stores.dbenums.enums.PERFORM_FREQ_DICT?.map(
            (item) => {
              return {
                ...item,
                ...JSON?.parse(item?.extensionJson ?? '{}'),
              };
            },
          )?.find(({ frequency }) => frequency === 'once');

          /**
           * 查找最后一个组号补上
           */
          const last = (table?.current?.getValue() as unknown as any[])
            ?.map((item) => item?.drugOrder?.groupNumber)
            ?.filter(Boolean);
          const group = last?.length
            ? Math?.max(...(new Set(last) as unknown as any[]))
            : 0;
          const items = value?.map((item, index) => {
            const order = table?.current?.countablePreview({
              orderClass: item?.orderClass,
            })
              ? 'disposalOrder'
              : item?.orderClass?.toLocaleLowerCase() + 'Order';
            const data = {
              id: 'create' + Math?.random(),
              uuid: getUUID(),
              orderStatus: 'OPEN',
              orderText:
                order === 'disposalOrder'
                  ? `${item?.orderText}(${value?.[0]?.orderText})`
                  : item?.orderText +
                    (item?.packageSpecification
                      ? `(${item?.packageSpecification})`
                      : ''),
              orderClass: item?.orderClass,
              performDepartment: item?.performDepartment,
              ...(index === 0
                ? {
                    relatedType: 'SKIN_TEST_VS_DRUG',
                  }
                : {
                    relatedType: 'SKIN_TEST_VS_DRUG',
                    skinTestType: null,
                  }),
              // prescriptionIndicator:
              //   value?.[0]?.prescriptionIndicator ??
              //   value?.[0]?.prescriptionDefaultIndicator,
              ...(order === 'drugOrder'
                ? {
                    drugBillingAttribute: 'NORMAL',
                  }
                : {}),
              [order]: {
                ...item,
                ...(order === 'disposalOrder'
                  ? {
                      totalAmount: 1,
                      frequency,
                    }
                  : {
                      prescriptionIndicator: (
                        Object?.values(
                          table?.current?.getEditForm()?.getFieldsValue(),
                        )?.[0] as any
                      )?.repeatIndicator
                        ? false
                        : value?.[0]?.prescriptionIndicator ??
                          value?.[0]?.prescriptionDefaultIndicator,
                      groupNumber: item?.groupNumber ? group + 1 : null,
                      groupSubNumber: item?.groupNumber
                        ? item?.groupSubNumber
                        : null,
                      ...(index === 0
                        ? {
                            frequency:
                              value?.[0]?.repeatIndicator === false
                                ? frequency
                                : undefined,
                          }
                        : {
                            skinTestType: null,
                            frequency,
                          }),
                    }),
              },
            };
            return {
              ...convertOrderInField(data),
              ...convertOtherOrderInfo(data),
            };
          });

          table?.current?.setValue(
            target?.toSpliced(
              index,
              1,
              ...table?.current?.onSliced(
                items?.map((item, _index) => ({
                  ...item,
                  ...(_index === 0
                    ? {
                        _skin: item?.id,
                        is_one: true,
                        repeatIndicator: (
                          Object?.values(
                            table?.current?.getEditForm()?.getFieldsValue(),
                          )?.[0] as any
                        )?.repeatIndicator,
                        startPerformDateTime: (
                          Object?.values(
                            table?.current?.getEditForm()?.getFieldsValue(),
                          )?.[0] as any
                        )?.startPerformDateTime,
                      }
                    : {
                        repeatIndicator: false,
                        startPerformDateTime: void 0,
                        parentRelatedUuid: items?.[0]?.uuid,
                      }),
                  /**
                   * 皮试关联关系
                   */
                  _skinTest: items?.map((item) => item?.id),
                })),
              ),
            ),
          );
          new Promise((resolve, reject) => {
            setTimeout(() => {
              try {
                resolve(table?.current?.onStart?.(items?.[0]?.id));
              } catch (error) {
                reject(error);
              }
            }, 500);
          }).then(() => {
            table?.current
              ?.getEditForm()
              ?.getFieldInstance([items?.[0]?.id, 'dosage'])
              ?.focus();
          });
        }
      }
    } else {
      const index = (target ?? [])?.findIndex(
        (item) => item?.uuid === value?.uuid,
      );
      if (index !== -1) {
        table?.current?.setValue(
          target?.toSpliced(index, 1, {
            ...target[index],
            ...value,
          }),
        );
      } else {
        table?.current?.setValue([...target, { id, ...value }]);
      }
    }
    setTimeout(() => {
      table?.current?.tableRef?.scrollTo(
        0,
        table?.current?.tableRef?.scrollHeight,
      );
    }, 500);
  }, []);

  const onRemove = useFetch({
    fetch: async (params) => {
      const res =
        await vsf.services?.ClinicRecordOrderController_deleteOrder_0de5cf?.({
          orderId: params?.id,
          deleteGroup: params?.deleteGroup ?? false,
        });
      if (res?.code === 200) {
        table?.current?.setValue((value) => {
          return table?.current?.onSliced(
            value?.filter(
              (item, index) =>
                item?.id !== params?.id &&
                item?.parentRelatedUuid !== params?.uuid &&
                (params?.deleteGroup
                  ? !params?.groupIndex?.includes?.(index)
                  : true),
            ),
          );
        });
      }
      return res;
    },
    message: '删除医嘱',
  });

  const onUndo = useFetch({
    fetch: async (params) => {
      const res =
        await vsf.services?.ClinicRecordOrderController_undoOrder_57be5d?.({
          orderId: params?.id,
        });
      return res;
    },
    message: '撤销医嘱',
    success() {
      header?.current?.onReload?.();
    },
  });

  const onUnStop = useFetch({
    fetch: async (params) => {
      const res =
        await vsf.services?.ClinicRecordOrderController_unstopOrder_753c9a?.({
          orderId: params?.id,
        });
      return res;
    },
    message: '取消停止医嘱',
    success() {
      header?.current?.onReload?.();
    },
  });

  // 作废
  const onCancel = useFetch({
    fetch: async (params) => {
      const res =
        await vsf.services?.ClinicRecordOrderController_cancelOrder_40b666?.({
          orderId: params?.id,
        });
      return res;
      console.log(params, '作废医嘱');
      return params;
    },
    message: '作废医嘱',
    success() {
      header?.current?.onReload?.();
    },
  });

  const on = {
    delete(_value) {
      const remove = () => {
        table?.current?.setValue((value) =>
          table?.current?.onSliced(
            value?.filter((item) => item?.id !== _value?.id),
          ),
        );
      };
      if (_value?.relatedType === 'SKIN_TEST_VS_DRUG' || _value?._skin) {
        Tip({
          content: '当前医嘱为皮试医嘱，删除会连带关联医嘱一起删除',
          onOk: () => {
            if (_value?._skin) {
              table?.current?.setValue((value) =>
                value?.filter((item) => !_value?._skinTest?.includes(item?.id)),
              );
            } else {
              onRemove?.(_value);
            }
          },
        });
      } else if (_value?.groupName) {
        Tip({
          title: '是否删除整组医嘱?',
          content: '你删除的医嘱附带成组数据,是否删除与当前医嘱成组的所有数据',
          onCancel() {
            if (!_value?.id || String(_value?.id)?.startsWith('create')) {
              table?.current?.setValue((value) =>
                table?.current?.onSliced(
                  value?.filter(
                    (...[, index]) => !_value?.groupIndex?.includes?.(index),
                  ),
                ),
              );
            } else {
              onRemove?.({
                ..._value,
                deleteGroup: true,
                groupIndex: _value?.groupIndex,
              });
            }
          },
          onOk() {
            if (!_value?.id || String(_value?.id)?.startsWith('create')) {
              remove?.();
            } else {
              onRemove?.({
                ..._value,
                deleteGroup: false,
              });
            }
          },
          okButtonProps: {
            danger: false,
            type: 'primary',
          },
          okText: '删除单条医嘱',
          cancelText: '删除整组医嘱',
        });
      } else if (!_value?.id || String(_value?.id)?.startsWith('create')) {
        remove?.();
      } else {
        Tip({
          content: `确定删除医嘱 {${_value?.orderText}} ?`,
          onOk() {
            onRemove?.(_value);
          },
        });
      }
    },
    undo(_value) {
      const text = _value?.groupName
        ? (table?.current?.getValue() as any)
            ?.filter((...[, index]) => _value?.groupIndex?.includes?.(index))
            ?.map((item) => `{${item?.orderText}}`)
        : [`{${_value?.orderText}}`];
      Tip({
        content: `医嘱撤销后，将在医嘱单中体现撤销医嘱，是否继续撤销 ${text?.join(
          ',',
        )}`,
        onOk() {
          onUndo?.(_value);
        },
      });
    },
    stop(_value) {
      Tip({
        content: `确定取消停止医嘱 {${_value?.orderText}} ?`,
        onOk() {
          onUnStop?.(_value);
        },
      });
    },
    cancel(_value) {
      const text = _value?.groupName
        ? (table?.current?.getValue() as any)
            ?.filter((...[, index]) => _value?.groupIndex?.includes?.(index))
            ?.map((item) => `{${item?.orderText}}`)
        : [`{${_value?.orderText}}`];
      if (_value?.removableIndicator) {
        Tip({
          content: `该医嘱可作废也可删除，是否继续作废?`,
          onOk() {
            onCancel?.(_value);
          },
          okText: '是',
          cancelText: '否',
        });
      } else {
        Tip({
          content: `确定作废医嘱 ${text?.join(',')} ?`,
          onOk() {
            onCancel?.(_value);
          },
        });
      }
    },
  };

  useUpdateEffect(() => {
    header?.current?.onReload?.();
  }, [inVisitInfo?.id]);

  const onStop = useFetch({
    fetch: async (values) => {
      return vsf.services?.ClinicRecordOrderController_stopOrder_69695f?.({
        orderIdList: values?.list?.map((item) => item?.id),
        stopDate: values?.time,
      });
    },
    message: '停用医嘱',
    success: () => {
      stopRef?.current?.onClose();
      header?.current?.onReload?.();
    },
  });
  // console.log(footer, Footer);

  return (
    <BeIn.Provider value={onDrawerSubmit}>
      <Block footer={<Footer ref={footer} />} scroll={false}>
        <BeInHospital.Header auxiliary={auxiliary} ref={header} table={table} />
        <BeInHospital
          auxiliary={auxiliary}
          ref={table}
          operation={on}
          header={header}
          footer={footer}
        />
        <BeInHospital.Auxiliary ref={auxiliary} />
      </Block>
      <CdssTable ref={cdssTable} onConfirm={onSend} />
      <Stop ref={stopRef} onSubmit={onStop} />
      <LagTimeOrder ref={lag} />
    </BeIn.Provider>
  );
};

export default definePage(BeInHospitals);
