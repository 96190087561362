import vsf, { definePage } from '@vs/vsf-boot';
import {
  VSForm,
  VSFormItem,
  VSFormLayout,
  VSPromiseTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { useRequest } from 'ahooks';
import {
  getPagedByPageUserStaffByInputCodeQto,
  getPagedBySearchCode,
} from './services';
import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import ProjectNameSelect from '../../../MedicalTechnology/ProjectNameSelect';
import { onTableFieldEllipsis } from '@/utils/setDataUtil';
import { askFixed, askTotalAmount } from '@/pages/Index/components/func/ask';
import { isEmpty, isNull, isObject } from 'lodash';
import { getScrollY, getScrollYRem } from '@/utils';
const CommonTable = (props) => {
  //
  const {
      onButtonProps,
      onButton,
      selectedRows,
      setSelectedRows,
      table__id,
      onFetch: onFunFetch,
      yMaxHeight,
      applyDepartment,
    } = props,
    { common, user } = props?.stores || {},
    { staffInfo } = user || {},
    { applicationList, currentApplication } = common || {};
  //   按钮
  const { btnDelete, btnAdd, btnSava } = onButtonProps;
  //   科室
  //   const { data: departmentData } = useRequest(getPagedBySearchCode);
  //   医生
  const { data: staffData } = useRequest(getPagedByPageUserStaffByInputCodeQto);
  //
  const onRecord = async () => {
    let params = {
      TheRowType: 'New', // 新增标识
      amount: 1, // 数量
      adjustAmount: undefined, //调整数量
      cost: 0, // 计价费用
      charge: 0, // 应收金额
      orderDate: dayjs().format('YYYY-MM-DD HH:mm:ss').valueOf(), // 业务时间
      operateDate: dayjs().format('YYYY-MM-DD HH:mm:ss').valueOf(), // 录入时间
      operatorStaffName: staffInfo?.staffName, // 录入人员名称
      operatorStaffId: staffInfo?.id, //  录入人员主键
      applyDepartment: {
        departmentName: applyDepartment?.departmentName, // 科室名称
        id: applyDepartment?.id, // 科室主键
      },
      performDepartment: {
        departmentName: currentApplication?.department?.departmentName, // 科室名称
        id: currentApplication?.department?.id, // 科室主键
      },
    };
    return {
      ...params,
      id: `TEMP_${Math?.random?.()}`,
    };
  };
  const onChanegUpdata = async (ele) => {
    let params = {};
    return { ...ele, ...params };
  };
  //   列表编辑
  const onFieldChange = (key, value, record, form) => {
    const therow = vsf?.refs?.[table__id]
      ?.getValues()
      ?.find((ele) => ele.id === key);
    if (
      Object.keys(value)?.includes('amount') &&
      ['New', 'Temp'].includes(therow?.TheRowType)
    ) {
      form?.setFieldValue?.(
        [key, 'cost'],
        askTotalAmount([[therow?.price, record?.amount]]),
      );
    }
  };
  const [itemsDataSource, setItemsDataSource] = useState();
  const [onFetchRest, setFetchRest] = useState();
  const onFetch = useCallback(async () => {
    const inquireProps = vsf?.refs?.IiemForm.getFieldValue('item');
    const res = await onFunFetch();
    setItemsDataSource(
      Array.from(new Set(res?.data?.map((obj) => obj.itemId)))
        ?.map((itemId) => {
          return res?.data?.find((obj) => obj.itemId === itemId);
        })
        ?.map((ele) => ({
          itemId: ele?.itemId,
          itemName: ele?.itemName + `（${ele?.itemId}）`,
        })),
    );
    if (inquireProps)
      return {
        ...res,
        data: res?.data?.filter((ele) => ele?.itemId === inquireProps?.itemId),
      };

    return res;
  }, [onFunFetch, onFetchRest]);
  const inquire = async () => setFetchRest(Math?.random());
  return (
    <div id="CommonTable">
      <div className="title-container">
        <div style={{ width: 'calc(150px + 300px) ', display: 'flex' }}>
          <p className="title">费用明细</p>
          <VSForm id="IiemForm" style={{ width: '300px', marginLeft: '16px' }}>
            <VSFormLayout columnCount={1}>
              <VSFormItem
                name="item"
                valueType="selectCode"
                dataSource={itemsDataSource}
                fieldProps={{
                  placeholder: '请选择项目名称',
                  allowClear: true,
                  onChange: inquire,
                }}
                fieldNames={{
                  label: 'itemName',
                  value: 'itemId',
                }}
              />
            </VSFormLayout>
          </VSForm>
        </div>
        {onButton([btnAdd, btnDelete, btnSava])}
      </div>
      <VSPromiseTable
        id={table__id}
        scroll={{ y: yMaxHeight ?? getScrollY(369), x: 2369 }}
        // scroll={{ y: getScrollYRem(32), x: 2369 }}
        className="common_table"
        pagination={false}
        bordered
        onFetch={onFetch}
        onRecord={onRecord}
        onUpdate={async (data) => {
          return await onChanegUpdata(data);
        }}
        rowSelection={{
          selectType: 'multiple',
          selectedRows,
          onSelectedRowsChange: (data) => setSelectedRows(data),
        }}
        editable={{
          editType: 'multiple',
          columnProps: {
            hideInTable: true,
          },
          onFieldChange: onFieldChange,
        }}
      >
        <VSTableColumn
          dataIndex={['itemClass']}
          title="类别"
          valueType="select"
          dataSource={vsf.stores.dbenums.enums.BILL_ITEM_CLASS_DICT}
          fieldProps={{}}
          preview
          ellipsis
          editable={false}
        />

        <VSTableColumn
          dataIndex={['itemName']}
          title="项目名称"
          valueType="custom"
          formItemProps={{
            rules: [
              {
                required: true,
              },
            ],
          }}
          width={250}
          ellipsis
          fieldProps={{
            placeholder: '请选择',
          }}
          renderFormItem={(schema, rows, form) => {
            let component = onTableFieldEllipsis(rows?.record?.itemName ?? '/');
            if (['New'].includes(rows?.record?.TheRowType))
              component = (
                <ProjectNameSelect
                  {...{
                    ...schema?.originProps,
                    ...rows,
                    form,
                    table_id: table__id,
                  }}
                />
              );
            return component;
          }}
        />

        <VSTableColumn
          width={128}
          dataIndex={['itemSpecification']}
          title="规格"
          valueType="text"
          formItemProps={{}}
          fieldProps={{}}
          ellipsis
          renderFormItem={(_, rows) =>
            onTableFieldEllipsis(rows?.record?.itemSpecification ?? '/')
          }
          preview
          editable={false}
        />

        <VSTableColumn
          dataIndex={['brand']}
          title="品牌"
          valueType="text"
          formItemProps={{}}
          ellipsis
          fieldProps={{}}
          renderFormItem={(_, rows) =>
            onTableFieldEllipsis(rows?.record?.brand ?? '/')
          }
          preview
          editable={false}
        />

        <VSTableColumn
          width={108}
          dataIndex={['price']}
          title="单价"
          valueType="digit"
          fieldProps={{}}
          // renderFormItem={(_, rows) => askFixed(rows?.record?.price ?? 0, 2)}
          render={(_, { price }) => askFixed(price ?? 0, 2)}
          preview
          editable={false}
        />

        <VSTableColumn
          className="number_input"
          width={108}
          dataIndex={['amount']}
          title="数量"
          valueType="digit"
          fieldProps={{}}
          formItemProps={{
            rules: [
              {
                required: true,
                min: 1,
                type: 'number',
              },
            ],
          }}
          // preview={(_, node) => !['New'].includes(node?.TheRowType)}

          editable={(_, node) => ['New'].includes(node?.TheRowType)}
        />

        <VSTableColumn
          width={108}
          dataIndex={['adjustAmount']}
          title="调整数量"
          valueType="digit"
          fieldProps={{}}
          preview={(_, node) =>
            !['Sava'].includes(node?.TheRowType) ||
            ['A', 'B', 'M'].includes(node?.itemClass)
          }
          formItemProps={(form, { entry, ...conig }) => {
            return {
              rules: [
                {
                  validator: (_, value) => {
                    if (value === 0) return Promise.reject('不能为0');
                    // 使用正则表达式来匹配可能的输入：负号开头，后面跟着0个或多个数字
                    const regex = /^(-?\d*)$/;
                    if (value && !regex.test(value))
                      return Promise.reject('只能输入“-”负号');
                    if (
                      value &&
                      String(value).startsWith('-') &&
                      Math.abs(value) > entry?.amount
                    )
                      return Promise.reject('调整数量不可大于数量');
                    return Promise.resolve();
                  },
                },
              ],
            };
          }}
        />

        <VSTableColumn
          dataIndex={['memo']}
          title="退补说明"
          valueType="text"
          fieldProps={{}}
          formItemProps={(from, record) => {
            const { entity } = record;
            return {
              rules: [
                {
                  validator: (_, value) => {
                    const { adjustAmount, TheRowType } = vsf?.refs?.[table__id]
                      ?.getValues?.()
                      ?.find((element) => element?.id === entity?.id);
                    const methods = {
                      // 新增时
                      New: () => {
                        if (isEmpty(value))
                          return Promise.reject('请填写补退说明');
                        return Promise.resolve();
                      },
                      // 保存时
                      Sava: () => {
                        // 调整数量为空是 补退费 可不填
                        if (isNull(adjustAmount)) return Promise.resolve();

                        // 调整数量为空是 补退费 为必填
                        if (adjustAmount && isEmpty(value))
                          return Promise.reject('请填写补退说明');
                        return Promise.resolve();
                      },
                    };

                    return methods?.[
                      ['Sava'].includes(TheRowType) ? 'Sava' : 'New'
                    ]();
                  },
                },
              ],
            };
          }}
          preview={(_, node) => ['A', 'B', 'M'].includes(node?.itemClass)}
        />

        <VSTableColumn
          width={68}
          dataIndex={['unit']}
          title="单位"
          valueType="select"
          formItemProps={{}}
          fieldProps={{}}
          dataSource={vsf.stores.dbenums.enums.MEASURES_DICT}
          preview
          editable={false}
        />

        <VSTableColumn
          width={108}
          dataIndex={['cost']}
          title="应收金额"
          valueType="digit"
          fieldProps={{}}
          renderFormItem={(_, rows) => askFixed(rows?.record?.cost ?? 0, 2)}
          preview
        />

        <VSTableColumn
          dataIndex={['orderDate']}
          title="业务时间"
          valueType="aspirinDateTimePicker"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss', valueType: 'timestamp' }}
          // preview={(_, node) => !['New'].includes(node?.TheRowType)}

          editable={(_, node) => ['New'].includes(node?.TheRowType)}
        />

        <VSTableColumn
          dataIndex={['applyDepartment']}
          title="开单科室"
          valueType="select"
          fieldProps={{}}
          dataSource={applicationList?.map(({ department }) => ({
            departmentName: department?.departmentName,
            id: department?.id,
          }))}
          fieldNames={{
            label: 'departmentName',
            value: 'id',
          }}
          editable={(_, node) => ['New'].includes(node?.TheRowType)}
        />

        <VSTableColumn
          dataIndex={['performDepartment']}
          title="执行科室"
          valueType="select"
          fieldProps={{}}
          dataSource={applicationList?.map(({ department }) => ({
            departmentName: department?.departmentName,
            id: department?.id,
          }))}
          fieldNames={{
            label: 'departmentName',
            value: 'id',
          }}
          editable={(_, node) => ['New'].includes(node?.TheRowType)}
        />

        <VSTableColumn
          dataIndex={['operateDate']}
          title="录入时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          preview
          editable={false}
        />

        <VSTableColumn
          dataIndex={['operatorStaffName']}
          title="录入人员"
          valueType="select"
          fieldProps={{
            fieldNames: { label: 'staffName', value: 'id' },
          }}
          dataSource={staffData}
          preview
          editable={false}
        />

        <VSTableColumn
          dataIndex={['medicalInsuranceType']}
          title="医保类别"
          valueType="select"
          dataSource={[
            { label: '甲', value: 'ALPHA' },
            { label: '乙', value: 'BETA' },
            { label: '丙', value: 'GAMMA' },
          ]}
          fieldProps={{}}
          preview
          editable={false}
        />
      </VSPromiseTable>
    </div>
  );
};

export default definePage(CommonTable);
