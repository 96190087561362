import vsf, { definePage } from '@vs/vsf-boot';
import React, { useCallback, useEffect, useMemo } from 'react';

import { cdssShow } from '@/mock';
const MedicalIframe = (props) => {
  const {
    user: {
      currentPatient,
      outVisitInfo,
      inVisitInfo,
      staffInfo,
      hospitalType,
      outRegisterInfo,
    },
    cdss: { cdssOpenTask },
    common: { currentApplication },
  } = props?.stores || {};

  useEffect(() => {
    if (!cdssShow) return;
    cdssOpenTask({
      patientId: currentPatient?.patientId,
      outpVisitId: outRegisterInfo?.id,
      inpVisitId: inVisitInfo?.inpVisitId,
    });
  }, [cdssOpenTask, currentPatient, outRegisterInfo, inVisitInfo]);

  const clinicType = useMemo(() => {
    return hospitalType === 'out' ? 'OUTP' : 'INP';
  }, [hospitalType]);

  const encounterId = useMemo(() => {
    if (clinicType === 'OUTP') {
      return outRegisterInfo?.id;
    }
    return inVisitInfo?.inpVisitId;
  }, [clinicType, inVisitInfo, outRegisterInfo]);

  const deptCode = useMemo(() => {
    if (clinicType === 'OUTP') {
      return currentApplication?.department?.id;
    }
    return currentApplication?.department?.id;
  }, [clinicType, currentApplication]);

  const doctorCode = useMemo(() => {
    if (clinicType === 'OUTP') {
      return staffInfo?.doctorDto?.staffId;
    }
    return staffInfo?.doctorDto?.staffId;
  }, [clinicType, staffInfo]);

  const localUrl = () => {
    if (['localhost', 'aspirin.teitui.com']?.includes(location.hostname)) {
      return 'https://triptorelin-aspirin.teitui.com';
    }
    // return 'http://172.17.10.40';
    return 'http://triptorelin.172-17-10-40.nip.io';
  };

  const renderIframe = useCallback(() => {
    return (
      <iframe
        style={{ width: '100%', height: '100%', border: 'none' }}
        src={`${localUrl()}/index?encounterId=${encounterId}&clinicType=${clinicType}&deptCode=${deptCode}&doctorCode=${doctorCode}`}
      />
    );
  }, [encounterId, clinicType, deptCode, doctorCode]);

  console.log(
    `${localUrl()}/index?encounterId=${encounterId}&clinicType=${clinicType}&deptCode=${deptCode}&doctorCode=${doctorCode}`,
    '电子病历WebView URL',
  );

  return renderIframe();
};

export default definePage(MedicalIframe);
